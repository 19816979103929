
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { useLocation } from "@reach/router"
import { Row, Col } from 'react-bootstrap';
import { GPContext } from "../context";
import React from "react";
import { withTranslation } from 'react-i18next';
import { CDN } from '../constants'

const MyProgressBar = (props) => {
    const { t } = props;
    const { state } = React.useContext(GPContext);
    let instore = sessionStorage.getItem('instore');

    const location = useLocation();
    var { children } = props;
    var percent;
    var checks = location.pathname.split("/").length - 1;
    if (state.partner === 'istorm' || state.partner === 'istormcy' || instore || state.partner==='xiaomigr') {
        percent = (checks) / 7.0 * 100;
    }else{
        percent = (checks) / 8.0 * 100;
    }
    var none = "d-none d-md-block"

    const partner_bar = {
        'gp': '#42D900',
        'pandas': '#1A1A1A',
        'plaisio': '#2E438F',
        'verizon': '#D52B1E',
        'istorm': '#EBEBEC',
        'istormcy': 'linear-gradient(90deg,#1767ab,#00a4e1 40%,#00e600 80%',
        'mistore': '#FF6800',
        'publiccy': '#F15922',
        'yougr': '#FFC439',
        'germanos': '#1c447c',
        'samsunggr': '#1a1a1a',
        'xiaomigr': '#F26A21',
        'pmmgr': '#f55023',
        'pmmcy': '#f55023',
        'mediamarktgr': '#f4847c'
    }

    return (
        <>
            <div className="mb-2 container">
                <Row className="justify-content-center">
                    <Col xs="12" lg="10" className="d-flex justify-content-center justify-content-md-between mb-3 mt-4">
                        <div className={checks > 5 ? none : ""}>
                            {checks <= 5 && <img className="mr-2" src={`${CDN}/pricing-app/partners/${state.partner}/info_active_icon.svg`} alt="info" width="30" />}
                            {(checks > 5) && <img className="mr-2" src={`${CDN}/pricing-app/icons/info_inactive_icon.svg`} alt="info" width="30" />}
                            <b>{t('progress.info')}</b>
                        </div>
                        <div className={checks !== 6 ? none : ""}>
                            {checks === 6 && <img className="mr-2" src={`${CDN}/pricing-app/partners/${state.partner}/condition_active_icon.svg`} alt="condition" width="30" />}
                            {(checks !== 6) && <img className="mr-2" src={CDN + "/pricing-app/icons/condition_inactive_icon.svg"} alt="condition" width="30" />}
                            <b>{t('progress.condition')}</b>
                        </div>
                        <div className={checks < 7 ? none : ""}>
                            {checks >= 7 && <img className="mr-2" src={`${CDN}/pricing-app/partners/${state.partner}/proposal_active_icon.svg`} alt="proposal" width="30" />}
                            {(checks < 7) && <img className="mr-2" src={CDN + "/pricing-app/icons/proposal_inactive_icon.svg"} alt="proposal" width="30" />}
                            <b>{t('progress.offer')}</b>
                        </div>
                    </Col>
                </Row>
                <ProgressBar percent={percent} filledBackground={partner_bar[state.partner]}>
                </ProgressBar>
            </div><br />
            {children}
        </>
    );
}

export default withTranslation()(MyProgressBar);
