import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import { Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import { GPContext } from "../context";
import { InlineWidget } from "react-calendly";
import {CDN} from '../constants'
import { Link, navigate  } from "@reach/router";

const Calendar = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    const [loading, setLoading] = useState(true);
    const [getTK, setGetTK] = useState([]);
    const getData = () => {
        fetch(`${process.env.PUBLIC_URL}/scooter/postcodes.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function (response) {
                console.log(response)
                return response.json();
            })
            .then(function (myJson) {
                setGetTK(myJson)
                setLoading(false)
            });
    }

    const wordingToResult = (result) => {
        if(result === "like_new") return "very_good";
        if(result === "good") return "good";
        if(result === "acceptable") return "poor";
        if(result === "broken") return "faulty";
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (window.location.pathname === '/scooter-form/') {
            console.log('User came directly')
        } else {
            console.log("User did not came directly");
        }
    })

    const [inputTK, setInputTK] = useState('');
    var checkTK = getTK.find(v => v === inputTK)


    function checkEnterKey(e){
        if(e.key === 'Enter'){
            e.preventDefault();
            handleTK();
        }
    }

    const handleTK = () => {
        if (checkTK === inputTK) {
            setStep2(true);
            setStep1(false);
        } else {
            setShow(true);
        }
    }
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let name, price;

    if(urlParams.get('brand')){
        brand = urlParams.get('brand');
        model = urlParams.get('model');
        model = model.replaceAll(' ', '-');
        brand = brand.toLocaleLowerCase();
        condition = urlParams.get('condition').replaceAll(' ', '_').toLowerCase();
        capacity = urlParams.get('capacity');
        condition = wordingToResult(condition);
    }
    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});
    var device = variants[capacity.toUpperCase()];
    name = device.name
    price = device[`${condition}_price`]
    
    var email = localStorage.getItem('email')

    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    if (loading) {
        return (
            <Row className="justify-content-md-center mb-4">
                <Col lg="12" className="text-center">
                    <div className="loader">Loading...</div>
                </Col>
            </Row>)
    }
    else {
        return (
            <div className='justify-content-center d-flex'> 
                <Row className="justify-content-between mt-2 mt-lg-5" style={{maxWidth: '90%'}}>
                    <Col className='mb-5'>
                        <Row className='pt-3 pb-2 pb-lg-5 mb-3 mb-lg-0 d-flex flex-column  justify-content-center align-items-center '>
                            <Col xs="12" xl="1" style={{padding: '0'}} className="d-flex justify-content-center align-items-center ">      
                                <div><img src={CDN + "/pricing-app/partners/yougr/poi_icon.svg"} alt="ping_icon" width="40" /></div>  
                            </Col>
                            <Col xs='11' sm='10' md="9" lg="11" className='align-self-center' style={{padding: '0'}}>
                                <div className="text-center d-md-flex justify-content-center align-items-center">
                                    <h6 className="mt-2 fw600 fs22" style={{fontSize: '28px'}}>Πού θα ήθελες να έρθει το Pandas scooter;</h6>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row style={{justifyContent: "space-around"}} className="mb-5 pb-5">                          
                            <Col xs="12" sm="10" md="8" lg="5" xl="5" style={{ maxWidth: '470px'}}>   
                                <Row className="yougrCard justify-content-around pt-2 pt-3 pb-3 pl-1 pr-1" style={{position: 'sticky', top: '70px'}}>
                                    <Col xs="12" className='mb-3 mb-md-2'>
                                        <div className="d-md-block fs20 fw700 text-start" style={{fontSize: '23px'}}>{name}</div>
                                        <div className="d-none d-md-block mt-2 mb-3" style={{ borderBottom: '2px dashed #FFFFFF' }}></div>
                                    </Col>
                                    {brand === 'apple' && <Col xs="3" sm="3" md="4" className="whiteBox mb-3 ml-2"><img className='fullWidth ' src={`${CDN}/devices/` + brand.toLowerCase() + "/" + model.replaceAll('-', '_').toLowerCase() + ".jpg"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} alt="phone"/></Col>}
                                    {brand !== 'apple' && <Col xs="3" sm="3" md="4" className="whiteBox mb-3 ml-2"><img className='fullWidth calendarImage' src={`${CDN}/devices/` + brand.toLowerCase() + "/" + model.replaceAll('-', '_').toLowerCase() + ".png"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} alt="phone"/></Col>}
                                    <Col xs="7" className="text-start pl-0" style={{ color: '#FFFFFF' }}>
                                        <div style={{fontSize: '20px'}} className="fw500 fs16 widthXs pr-xl-4">Πάρε σήμερα έκπτωση έως</div>
                                        <h3 className="fw800 fs22">{price}€<span className="ms-1 fw500"> {t('scooter.appointmentGetToday3')}</span></h3>
                                    </Col>
                                    <Col xs="12" className="d-md-block my-lg-3 pl-3 pr-3 pl-md-3 pr-md-3">
                                        <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: '10px' }}>
                                            <div className="d-flex pl-3 pt-2 pr-2">
                                                <div className="ms-2 fs13 text-start">Επιστρέφοντας αυτό το smartphone:</div>
                                            </div>
                                            <div className="d-flex pl-3 pb-3 pt-3 pr-2">
                                                <img className='mr-3' src={CDN + "/website/scooter/tree.svg"} alt="mobile_green_blue" width="20" />
                                                <div className="ms-2 fs13 text-start">+ 1 δέντρο θα φυτευτεί</div>
                                            </div>
                                            <div className="d-flex pl-3 pb-3 pr-2">
                                                <img className='mr-3' src={CDN + "/pricing-app/icons/car_icon.svg"} alt="car-icon" width="20" />
                                                <div className="ms-2 fs13 text-start">- 60kg CO2 από το περιβάλλον</div>
                                            </div>
                                        </div>
                                        
                                    </Col>
                                </Row>
                            </Col>
                            {step1 &&
                            <Col xs="12" sm="10" md="8" lg="6" xl="7" className="text-center text-lg-left mt-3 mt-lg-0 pl-0 mb-5 pb-5 pl-xl-4">
                                <div style={{fontSize: '22px'}} className="fs20"><b>Μάθε αν μπορεί να εξυπηρετήσει την περιοχή σου</b></div>
                                <div className='mt-2 mb-3 fs12' style={{color: '#B4B6B8', fontSize: '14px'}}>Αυτή τη στιγμή το Pandas Scooter εξυπηρετεί το μεγαλύτερο μέρος της Αττικής. Καθημερινά “ξεκλειδώνουμε” νέες περιοχές.</div>
                                <InputGroup className='tk-input-group' size="lg">
                                    <FormControl style={{ border: '1px solid #E5E4E5', boxShadow: 'inset 0 0px 0', height:'auto', borderRadius: '10', fontSize: '14px', color: '#B4B6B8'}} aria-label="Default" aria-describedby="inputGroup-sizing-default" placeholder='Συμπλήρωσε τον Τ.Κ σου' onChange={e => setInputTK(e.target.value)} onKeyPress={(e) => { checkEnterKey(e)}}/>
                                    {show && <div style={{background:'#FFFFFF', color: '#FC573B', border:'none', borderRadius:0, padding: '0', fontSize: '12px'}} className="d-md-block text-left" >Δυστυχώς, αυτή τη στιγμή το Pandas Scooter καλύπτει μόνο τις περιοχές στο κέντρο της Αθήνας. Επίλεξε μία διαφορετική περιοχή.</div>}
                                    <InputGroup.Text style={{background:'#FFFFFF', border:'none', borderRadius:0, padding: ''}} className="d-block fullWidth mt-3 text-lg-right text-center" id="basic-addon1"><button style={{ fontSize: '18px', borderRadius: '10' }} className="yougrButton" onClick={handleTK} >{t('scooter.pCodeButton')}</button></InputGroup.Text>
                                </InputGroup>
                            </Col>}
                            {step2 && 
                             <Col xs="12" sm="12" md="8" lg="6" xl="6" style={{ width: '550px'}} className="mt-3 mt-lg-4 mt-lg-0 calendlyTop">
                                <InlineWidget url="https://calendly.com/pandas-appointment/yougr-trade-in-at-home"
                                    pageSettings={{
                                        backgroundColor: 'ffffff',
                                        hideEventTypeDetails: true,
                                        hideLandingPageDetails: true,
                                        primaryColor: 'FFC439',
                                        textColor: '263746',
                                        hideGdprBanner: true,
                                    }}
                                    styles={{
                                        width: '100%',
                                        height: '1500px',
                                        border: 'none',
                                    }}
                                    prefill={{
                                        email: email ? email : '',
                                        customAnswers: {
                                            a5: inputTK,
                                            a6: name
                                        }
                                    }}
                                />
                            </Col>
                            }
                        </Row>
                    </Col>
                </Row>
                <div className="footer row justify-content-start align-items-center px-3 px-md-5">
                <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer`} className={`back`}>Ακύρωση</Link>
                </div>
            </div >
        );
    }

}

export default withTranslation()(Calendar);