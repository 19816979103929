import { Link } from "@reach/router";
import React from "react";
import { GPContext } from "../context";

const Footer = (props) => {
    const { state } = React.useContext(GPContext);

    return (
        (props.back || props.next) ?
            <div className="footer row justify-content-end align-items-center px-3 px-md-5">
                {props.back && <Link to={props.back.path ? props.back.path : ''} className={`back mr-3 mr-md-5 ${!props.back.path ? 'disabled' : ''}`}> {props.back.text}</Link>}
                {props.next && !props.next.cb && <Link to={props.next.path ? props.next.path : ''} className={`${state.partner}Next next foot ${!props.next.path ? 'disabled' : ''}`}>{props.next.text} </Link>}
                {props.next && props.next.cb && <div onClick={props.next.cb} className={`${state.partner}Next next foot ${!props.next.path ? 'disabled' : ''}`}>{props.next.text} </div>}
            </div>
            : ""
    );
}

export default Footer;

