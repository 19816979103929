import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import { CDN } from '../constants'
import axios from 'axios';

const TradeinOptions = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    const [getTK, setGetTK] = useState([]);
    let partner = props.partner ? props.partner : sessionStorage.getItem('partner');
    let brand = props.brand ? props.brand : sessionStorage.getItem('brand');
    let model = props.model ? props.model : sessionStorage.getItem('model')
    let condition = props.model ? props.model : sessionStorage.getItem('condition');
    let capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');
    const zipcode = state.zipcode;
    sessionStorage.setItem('zipcode', state.zipcode);
    sessionStorage.setItem('address', state.address);
    const getData = () => {
        fetch(`${process.env.PUBLIC_URL}/scooter/postcodes.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function (response) {
                console.log(response)
                return response.json();
            })
            .then(function (myJson) {
                setGetTK(myJson)
                // setLoading(false)
            });
    }

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Trade-in Options' });
        getData()
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'trade-in options'

    useEffect(() => {
        if (window.location.pathname === '/scooter-form/') {
            console.log('User came directly')
        } else {
            console.log("User did not came directly");
        }
    })
    
    var checkTK = getTK.find(v => v === zipcode)

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }
    
    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);


    if (!state.address){ 
        return (<div style={{display: 'none'}}>{window.location = `${process.env.PUBLIC_URL}/${props.partner}/location/`} </div>);
    }

        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${state.partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div style={{maxWidth: '350px'}} className='m-auto'>
            <div className=' tradeinHeading text-md-center'>
                <h1 style={{fontWeight: '800', lineHeight: '28px', fontSize: '32px'}}>Eπιλογές trade-in</h1>
                <div className='m-auto pt-2 d-flex justify-content-md-center' style={{fontSize: "14px", maxWidth: '380px', fontWeight: '500', lineHeight: '18px', color: '#6c6c6c'}}><div>{sessionStorage.getItem('address')}</div><div><Link to={`${process.env.PUBLIC_URL}/${props.partner}/location`} className=""><img className='mb-1 ml-2' id='address' src={`${process.env.PUBLIC_URL}/edit-icon.svg`} alt="edit icon" /></Link></div></div>
            </div>
            <div className='d-block d-md-flex justify-content-center' style={{gap: '10px'}}>
            {checkTK && <div className='mb-3'>
                <Link id='trade-in-option-scooter' className='d-md-none' style={{color: '#1d1d1d'}} to={`${process.env.PUBLIC_URL}/${props.partner}/scooter`} >
                    <div className='tradeinCard'>
                        <img className='d-none d-md-block' style={{position: 'absolute', top: '-70px', left: '50%', transform: 'translateX(-50%)'}} src={`${CDN}/pricing-app/samsung/home-desktop.png`} alt='person on couch' height='124px' />
                        <div className='d-flex d-md-block'>
                            <div>
                                <h5>Trade in από το σπίτι</h5>
                                <p>Κλείσε ραντεβού με το Pandas Scooter και πούλησε ένα smartphone έξω από την πόρτα σου.</p>
                            </div>
                            <div className='d-md-none' style={{borderRadius: '16px'}}><img src={`${CDN}/pricing-app/samsung/home.png`} alt='person on couch' width='177px' height='180px' /></div>
                        </div>

                    </div>
                </Link>
                <div className='tradeinCard d-none d-md-block'>
                        <img className='d-none d-md-block' style={{position: 'absolute', top: '-70px', left: '50%', transform: 'translateX(-50%)'}} src={`${CDN}/pricing-app/samsung/home-desktop.png`} alt='person on couch' height='124px' />
                        <div className='d-flex d-md-block'>
                            <div>
                                <h5>Trade in από το σπίτι</h5>
                                <p>Κλείσε ραντεβού με το Pandas Scooter και πούλησε ένα smartphone έξω από την πόρτα σου.</p>
                            </div>
                            <div className='d-md-none' style={{borderRadius: '16px'}}><img src={`${CDN}/pricing-app/samsung/home.png`} alt='person on couch' width='177px' height='180px' /></div>
                        </div>
                        <Row className="justify-content-start d-none d-md-block">
                            <Col xs="10" className='test'>
                                <Link id='trade-in-option-scooter' to={`${process.env.PUBLIC_URL}/${props.partner}/scooter`} className="">
                                    <div className="samsunggrNext next px-3 mt-5 text-center">Επιλογή</div>
                                </Link> 
                            </Col>
                        </Row>
                    </div>
                </div>}
                
                <div>
                    <Link id='trade-in-option-courier' className='d-md-none' style={{color: '#1d1d1d'}} to={`${process.env.PUBLIC_URL}/${props.partner}/courier`} >
                        <div className='tradeinCard'>
                            <img className='d-none d-md-block' style={{position: 'absolute', top: '-70px', left: '50%', transform: 'translateX(-50%)'}} src={`${CDN}/pricing-app/samsung/courier-desktop.png`} alt='courier girl' height='124px' />
                            <div className='d-flex d-md-block'>
                                <div>
                                    <h5>Trade-in μέσω Courier</h5>
                                    <p>Στείλε τη συσκευή σου στα κεντρικά γραφεία της Pandas μέσω courier.</p>
                                </div>
                                <div className='d-md-none' style={{borderRadius: '16px'}}><img src={`${CDN}/pricing-app/samsung/courier.png`} alt='courier girl' width='177px' height='180px' /></div>
                            </div>
                        </div>
                    </Link>
                    <div className='d-none d-md-block tradeinCard'>
                            <img className='d-none d-md-block' style={{position: 'absolute', top: '-70px', left: '50%', transform: 'translateX(-50%)'}} src={`${CDN}/pricing-app/samsung/courier-desktop.png`} alt='courier girl' height='124px' />
                            <div className='d-flex d-md-block'>
                                <div>
                                    <h5>Trade-in μέσω Courier</h5>
                                    <p>Στείλε τη συσκευή σου στα κεντρικά γραφεία της Pandas μέσω courier.</p>
                                </div>
                                <div className='d-md-none' style={{borderRadius: '16px'}}><img src={`${CDN}/pricing-app/samsung/courier.png`} alt='courier girl' width='177px' height='180px' /></div>
                            </div>
                            <Row className="justify-content-start d-none d-md-block">
                                <Col xs="10">
                                    <Link id='trade-in-option-courier' to={`${process.env.PUBLIC_URL}/${props.partner}/courier`} className="">
                                        <div className="samsunggrNext next px-3 mt-5 text-center" style={{fontWeight: '400'}}>Επιλογή</div>
                                    </Link> 
                                </Col>
                            </Row>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TradeinOptions);