import { withTranslation } from 'react-i18next';
import React, { useState, useContext } from "react";
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { GPContext } from "../context";
import { faApple } from "@fortawesome/free-brands-svg-icons"
import {CDN} from '../constants'

const PrepContent = (props) => {

    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);

    const [iphone, setIphone] = useState(true);
    const [android, setAndroid] = useState(false);

    const clickIphone = () => {
        setIphone(true);
        setAndroid(false);
    }
    const clickAndroid = () => {
        setIphone(false);
        setAndroid(true);
    }


    return (
        <div>
            <Row className="" style={{justifyContent: 'space-evenly'}}>
                <Col xs="8" className='mb-5'>
                    <Row className='justify-content-center'>
                        <Col xs="6" md="6" lg="6" xl="6" className="mb-2" style={{padding: '0'}} onClick={clickIphone}>
                            <div className={iphone ? `device activeTab fs18` : `device fs18`}><FontAwesomeIcon className="me-2" icon={faApple} /><span> Για iPhone</span></div>
                        </Col>
                        <Col xs="6" md="6" lg="6" xl="6" style={{padding: '0'}} onClick={clickAndroid}>
                            <div className={android ? `device activeTab fs18` : `device fs18`}><img className="me-2 pb-1" src={CDN + "/pricing-app/icons/android.svg"} /><span> Για Android</span></div>
                        </Col>
                    </Row>
                </Col>
                
                {iphone && <Row className="justify-content-center text-center mb-5 pb-5 fadeIn">
                <Col xs="8" sm="10" md="8" xl="6" className="mw2">
                        <Row className="justify-content-center text-center">
                            <Col className="prepCard" style={{position:'relative'}}>
                                <div className="d-flex align-items-center">
                                    <div style={{position:'absolute', left:'-25px'}}  xs="2"><img className="gpText fs40 checkIcon" src={CDN + "/pricing-app/partners/yougr/check_icon.svg"} alt="check" /></div>
                                    <div ><div className="fs18 fw400 text-left ps-2 ps-sm-0">Σιγουρέψου ότι το smartphone σου είναι σε λειτουργία και έχει <span className="fw800">πάνω από <span style={{color: '#4960F2'}}>5%</span></span> μπαταρία.</div></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center text-center my-4">
                            <Col className="prepCard" style={{position:'relative'}}>
                                <div className="d-flex align-items-center">
                                    <div style={{position:'absolute', left:'-25px'}}  xs="2"><img className="gpText fs40 checkIcon" src={CDN + "/pricing-app/partners/yougr/check_icon.svg"} alt="check" /></div>
                                    <div xs="10"><div className="fs18 fw400 text-left ps-2 ps-sm-0">Αποσύνδεσε το <b>Find My iPhone</b> σου από τη συσκευή.</div></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center text-center">
                        <Col className="prepCard" style={{position:'relative'}}>
                                <div className="d-flex align-items-center">
                                    <div style={{position:'absolute', left:'-25px'}}  xs="2"><img className="gpText fs40 checkIcon" src={CDN + "/pricing-app/partners/yougr/check_icon.svg"} alt="check" /></div>
                                    <div xs="10"><div className="fs18 fw400 text-left ps-2 ps-sm-0">Κάνε <b>επαναφορά</b> εργοστασιακών ρυθμίσεων για να <b>διαγραφούν</b> όλα τα δεδομένα και ρυθμίσεις από κινητό.</div></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
                {android && <Row className="justify-content-center text-center mb-5 pb-5 fadeIn">
                    <Col xs="8" sm="10" md="8" xl="6" className="mw2">
                        <Row className="justify-content-center text-center">
                            <Col className="prepCard" style={{position:'relative'}}>
                                <div className="d-flex align-items-center">
                                    <div style={{position:'absolute', left:'-25px'}}  xs="2"><img className="gpText fs40 checkIcon" src={CDN + "/pricing-app/partners/yougr/check_icon.svg"} alt="check" /></div>
                                    <div ><div className="fs18 fw400 text-left ps-2 ps-sm-0">Σιγουρέψου ότι το smartphone σου είναι σε λειτουργία και έχει <span className="fw800">πάνω από <span style={{color: '#4960F2'}}>5%</span></span> μπαταρία.</div></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center text-center my-4">
                            <Col className="prepCard" style={{position:'relative'}}>
                                <div className="d-flex align-items-center">
                                    <div style={{position:'absolute', left:'-25px'}}  xs="2"><img className="gpText fs40 checkIcon" src={CDN + "/pricing-app/partners/yougr/check_icon.svg"} alt="check" /></div>
                                    <div xs="10"><div className="fs18 fw400 text-left ps-2 ps-sm-0">Αποσύνδεσε το <b>Google account</b> σου από τη συσκευή.</div></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center text-center">
                        <Col className="prepCard" style={{position:'relative'}}>
                                <div className="d-flex align-items-center">
                                    <div style={{position:'absolute', left:'-25px'}}  xs="2"><img className="gpText fs40 checkIcon" src={CDN + "/pricing-app/partners/yougr/check_icon.svg"} alt="check" /></div>
                                    <div xs="10"><div className="fs18 fw400 text-left ps-2 ps-sm-0">Κάνε <b>επαναφορά</b> εργοστασιακών ρυθμίσεων για να <b>διαγραφούν</b> όλα τα δεδομένα και ρυθμίσεις από κινητό.</div></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
            </Row>
        </div>
    );
}

export default withTranslation()(PrepContent);