import { Link, navigate } from "@reach/router";
import { Row, Col, Modal, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { GPContext } from "../context";
import { iPadTextfix, replaceSpecialChars } from "../viewHelperFunctions";
import { CDN } from "../constants";

const Condition = (props) => {
  const { t, i18n } = props;
  const jsondata = require(`../locales/${i18n.language}/translation.json`);
  console.log(i18n.language);
  const [conditionData, setConditionData] = useState([]);
  const [iconsCondition, setIconsCondition] = useState([]);
  const { state, dispatch } = React.useContext(GPContext);
  const instore = sessionStorage.getItem("instore");
  sessionStorage.setItem("promoPrice", 0);
  let types, typesMap;
  if (state.category === "smartwatch") {
    types = ["good", "poor", "faulty"];
    typesMap = {
      good: "no_light_scratches",
      poor: "deep_scratches",
      faulty: "cracks",
    };
  } else {
    types = ["very_good", "good", "poor", "faulty"];
  }

  if (state.cap) {
    sessionStorage.setItem("capacity", state.cap);
  }
  let link;
  if (props.partner === "xiaomigr") {
    link = "email";
  } else {
    link = "samsung-voucher";
  }

  var partner = state.partner ? state.partner.toLowerCase() : "";
  var brand = props.brand ? props.brand.toLowerCase() : "";
  var model = props.model ? props.model.toLowerCase() : "";
  var capacity =
    props.capacity && props.capacity !== "stock"
      ? props.capacity.toLowerCase()
      : "";
  var powerson = props.powerson ? props.powerson.toLowerCase() : "";
  var condition = props.condition ? props.condition.toLowerCase() : "";

  var devices = state.devices.filter(
    (a) =>
      a.brand.toLowerCase() === brand.toLowerCase() &&
      a.model.toLowerCase() ===
        model.replaceAll("-", " ").replaceAll("_", "/").toLowerCase()
  );
  var variants = devices.reduce((map, obj) => {
    map[obj.capacity] = obj;
    return map;
  }, {});
  var device = variants[capacity.toUpperCase()];
  var finalPrice = device
    ? powerson === "ber"
      ? 1
      : device[condition + "_price"]
    : 0;

  if (capacity === "") capacity = "stock";

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showError, setShowError] = useState(false);
  const handleCloseBer = () => setShowError(false);
  const handleShowBer = () => setShowError(true);

  let category;
  if (state.category) {
    category = state.category;
  } else {
    category = "iphone";
  }

  useEffect(() => {
    window.dataLayer.push({ event: "Step Condition" });
  }, []);

  const handleClick = (condition) => {
    if (state.category === "smartwatch") {
      let arr = [...state.activeConditions];
      for (let key in state.activeConditions) {
        if (state.activeConditions[key] === "default") {
          arr[key] = {
            code: "watch_scratch_cosmetics",
            condition: typesMap[condition],
          };
          break;
        } else if (
          state.activeConditions[key].code === "watch_scratch_cosmetics"
        ) {
          state.activeConditions[key].condition = typesMap[condition];
        }
      }
      dispatch({
        type: "SET_ACTIVECONDITIONS",
        payload: arr,
      });
    }
  };

  useEffect(() => {
    if (condition) {
      let i = 0;
      let data = [];
      let iconsData = [];
      for (var key in jsondata[condition][category]) {
        iconsData[i] = key.split("-")[0];
        data[i] = jsondata[condition][category][key];
        i++;
      }
      setConditionData(data);
      setIconsCondition(iconsData);
    }
  }, [condition]);

  function changeToGreek(c) {
    if (c === "very_good") return "Σαν Καινούρια";
    if (c === "good") return "Καλή";
    if (c === "poor") return "Μέτρια";
    if (c === "faulty") return "Κακή";
  }
  var offer = {
    condition: changeToGreek(condition),
  };

  sessionStorage.setItem("path", window.location.href);
  let width;
  if (state.category === "smartwatch") {
    width = 4;
  } else {
    width = 6;
  }

  window.brand = brand;
  window.partner = partner;
  window.model = model;
  window.language = i18n.language;
  window.capacity = capacity.toUpperCase();
  window.step = "condition";

  const setCondition = () => {
    sessionStorage.setItem("condition", "unknown");
  };

  const next = () => {
    if (condition && (partner === "samsunggr" || partner === "xiaomigr")) {
      navigate(`${process.env.PUBLIC_URL}/${props.partner}/${link}`);
    } else if (condition) {
      sessionStorage.setItem("condition", condition);
      navigate(
        `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/loading`
      );
    }
  };
  const back = () => {
    navigate(
      `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}`
    );
  };
  let displayedModel;
  if (state.category === "ipad") displayedModel = iPadTextfix(device?.model);
  else displayedModel = device?.model;
  let modelImage = replaceSpecialChars(model);

  if (!device) return <>{(window.location = process.env.PUBLIC_URL + "/")} </>;
  else
    return (
      <div>
        {powerson === "working" && (
          <>
            <Container className="pb-5">
              <Row className="justify-content-md-center mb-4">
                <Col lg="12" className="text-center">
                  <h1 className="title">{t("condition.title")}</h1>
                  <small className="infoLink" onClick={handleShow}>
                    <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                    {t("condition.error")}
                  </small>
                </Col>
              </Row>
              <Row className="justify-content-center ">
                {types.map((type, index) => {
                  return (
                    <>
                      <Col
                        xs="12"
                        md={width}
                        className="d-none d-md-flex"
                        lg="3"
                        key={index}
                        onClick={() => {
                          handleClick(type);
                        }}
                      >
                        <Link
                          className="answer  d-flex align-items-center mb-3"
                          to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${type}`}
                          onClick={() => {
                            dispatch({
                              type: "SAVE",
                              data: { condition: type },
                            });
                            sessionStorage.setItem("condition", type);
                          }}
                        >
                          <div className="mr-3">
                            {type !== condition ? (
                              <img
                                src={CDN + "/pricing-app/icons/checkbox_up.svg"}
                                alt="checkbox"
                                width="20"
                              />
                            ) : (
                              <img
                                src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                alt="checkbox"
                                width="20"
                              />
                            )}
                          </div>
                          <div>
                            <div>
                              <b>{t(`condition.${type}`)}</b>
                            </div>
                            <div style={{ fontSize: "10px", opacity: "0.5" }}>
                              {t(`condition.${type}${category}1`)}
                            </div>
                          </div>
                        </Link>
                      </Col>

                      <Col
                        xs="12"
                        className="d-flex d-md-none justify-content-center"
                      >
                        <Col
                          xs="12"
                          className="px-0"
                          onClick={() => {
                            handleClick(type);
                          }}
                        >
                          <Link
                            className="answer d-md-none d-flex justify-content-between align-items-center mb-3"
                            to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${type}`}
                            onClick={() => {
                              dispatch({
                                type: "SAVE",
                                data: { condition: type },
                              });
                              sessionStorage.setItem("condition", type);
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div className="mr-3">
                                {type !== condition ? (
                                  <img
                                    src={
                                      CDN + "/pricing-app/icons/checkbox_up.svg"
                                    }
                                    alt="checkbox"
                                    width="20"
                                  />
                                ) : (
                                  <img
                                    src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                    alt="checkbox"
                                    width="20"
                                  />
                                )}
                              </div>
                              <div>
                                <div>
                                  <b>{t(`condition.${type}`)}</b>
                                </div>
                                <div
                                  style={{ fontSize: "10px", opacity: "0.5" }}
                                >
                                  {t(`condition.${type}${category}1`)}
                                </div>
                              </div>
                            </div>
                            <div className="mr-2">
                              {type !== condition ? (
                                <img
                                  src={CDN + "/pricing-app/icons/more_icon.svg"}
                                  alt="checkbox"
                                  width="20"
                                />
                              ) : (
                                <img
                                  src={CDN + "/pricing-app/icons/less_icon.svg"}
                                  alt="checkbox"
                                  width="20"
                                />
                              )}
                            </div>
                          </Link>
                        </Col>
                      </Col>

                      <div className="d-md-none">
                        {type === condition ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${type}`}
                            onClick={() => {
                              dispatch({
                                type: "SAVE",
                                data: { condition: type },
                              });
                              sessionStorage.setItem("condition", type);
                            }}
                          >
                            <input
                              type="radio"
                              className="btn-check d-none"
                              name="condition"
                              value={type}
                              onChange={() => {}}
                              checked={condition === type ? true : false}
                            />
                            <Row className="align-items-start justify-content-center conditionCard mx-3 mx-md-0 mb-3">
                              <Col xs="12" md="6">
                                <div className="d-flex justify-content-center justify-content-md-start ml-md-4 mb-3">
                                  <img
                                    src={
                                      CDN +
                                      `/pricing-app/icons/${condition}-icon.svg`
                                    }
                                    alt="condition"
                                    width="40"
                                    height="40"
                                    className="mt-1"
                                  />
                                  <div className="ml-3">
                                    <div style={{ fontSize: "18px" }}>
                                      <b>{t(`condition.${type}`)}</b>
                                    </div>
                                    <div>{t("condition.myDevice")}</div>
                                  </div>
                                </div>
                                <img
                                  style={{ marginBottom: "50px" }}
                                  src={
                                    state.category === "ipad"
                                      ? `${CDN}/pricing-app/conditions/ipad_${type}_condition.svg`
                                      : state.category === "smartwatch"
                                      ? `${CDN}/pricing-app/conditions/${type}-watch.svg`
                                      : props.brand.toLowerCase() === "apple"
                                      ? `${CDN}/pricing-app/conditions/${type}_iphone.svg`
                                      : `${CDN}/pricing-app/conditions/${type}-phone.svg`
                                  }
                                  alt={type}
                                  className="conditionImage"
                                />
                              </Col>
                              <Col xs="12" sm="10" md="6" className="mt-3">
                                <ul>
                                  {conditionData.map((data, index) => {
                                    return (
                                      <>
                                        <li className="d-flex">
                                          {iconsCondition[index] !==
                                            "noIcon" && (
                                            <img
                                              src={`${CDN}/pricing-app/icons/${iconsCondition[index]}.svg`}
                                              alt="condition icon"
                                            />
                                          )}
                                          {iconsCondition[index] !==
                                          "noIcon" ? (
                                            <div>{data}</div>
                                          ) : (
                                            <div
                                              style={{
                                                fontWeight: "600",
                                                marginLeft: "22px",
                                              }}
                                            >
                                              {data}
                                            </div>
                                          )}
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </Col>
                            </Row>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
                })}

                {types.map((type, index) => {
                  return (
                    <div className="d-none d-md-flex">
                      {type === condition ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${type}`}
                          onClick={() => {
                            dispatch({
                              type: "SAVE",
                              data: { condition: type },
                            });
                            sessionStorage.setItem("condition", type);
                          }}
                        >
                          <input
                            type="radio"
                            className="btn-check d-none"
                            name="condition"
                            value={type}
                            onChange={() => {}}
                            checked={condition === type ? true : false}
                          />
                          <Row className="align-items-start justify-content-center conditionCard mx-3 mx-md-0 mb-3">
                            <Col xs="12" md="6">
                              <div className="d-flex justify-content-center justify-content-md-start ml-md-4 mb-3">
                                <img
                                  src={
                                    CDN +
                                    `/pricing-app/icons/${condition}-icon.svg`
                                  }
                                  alt="condition"
                                  width="40"
                                  height="40"
                                  className="mt-1"
                                />
                                <div className="ml-3">
                                  <div style={{ fontSize: "18px" }}>
                                    <b>{t(`condition.${type}`)}</b>
                                  </div>
                                  <div>{t("condition.myDevice")}</div>
                                </div>
                              </div>
                              <img
                                style={{ marginBottom: "40px" }}
                                src={
                                  state.category === "ipad"
                                    ? `${CDN}/pricing-app/conditions/ipad_${type}_condition.svg`
                                    : state.category === "smartwatch"
                                    ? `${CDN}/pricing-app/conditions/${type}-watch.svg`
                                    : props.brand.toLowerCase() === "apple"
                                    ? `${CDN}/pricing-app/conditions/${type}_iphone.svg`
                                    : `${CDN}/pricing-app/conditions/${type}-phone.svg`
                                }
                                alt={type}
                                className="conditionImage"
                              />
                            </Col>
                            <Col xs="12" sm="10" md="6" className="mt-3">
                              <ul>
                                {conditionData.map((data, index) => {
                                  return (
                                    <>
                                      <li className="d-flex">
                                        {iconsCondition[index] !== "noIcon" && (
                                          <img
                                            src={`${CDN}/pricing-app/icons/${iconsCondition[index]}.svg`}
                                            alt="condition icon"
                                          />
                                        )}
                                        {iconsCondition[index] !== "noIcon" ? (
                                          <div>{data}</div>
                                        ) : (
                                          <div
                                            style={{
                                              fontWeight: "600",
                                              marginLeft: "22px",
                                            }}
                                          >
                                            {data}
                                          </div>
                                        )}
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </Col>
                          </Row>
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Row>
            </Container>
            {partner !== "samsunggr" && partner !== "xiaomigr" ? (
              <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <h3
                    className="float-right"
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </h3>
                  <Row
                    className="d-flex flex-column"
                    style={{ height: "100%" }}
                  >
                    <Col className="text-center text-lg-left my-3">
                      <Row className="justify-content-center align-items-center">
                        <Col xs="6" lg="4" className="text-center">
                          <img
                            className={`${partner}Image`}
                            src={`${CDN}/pricing-app/partners/${partner}/image.png`}
                            alt="logo"
                            width="100%"
                          />
                        </Col>
                        <Col xs="12" className="mt-3 text-center">
                          <h5>
                            <b>{t("condition.errorModal")}</b>
                          </h5>
                        </Col>
                        <Col
                          xs="12"
                          className="text-center"
                          style={{ fontSize: "15px" }}
                        >
                          {t("condition.errorModal1")}
                          {partner === "gp" && (
                            <span>
                              {" "}
                              {t("condition.errorModal2")}
                              <b className={`${partner}Text`}>
                                {t("condition.errorModal3")}
                              </b>
                              {t("condition.errorModal4")}
                              <b className={`${partner}Text`}>
                                {t("condition.errorModal5")}
                              </b>
                            </span>
                          )}
                        </Col>
                        {partner === "gp" ? (
                          <Col xs="10" lg="6" className="text-center mt-5">
                            <a
                              className={`${partner}Next next`}
                              href="https://greenpanda.io/atm"
                            >
                              {t("atm")}
                            </a>
                          </Col>
                        ) : (
                          <Col xs="10" lg="6" className="text-center mt-5">
                            <a
                              className={`${partner}Next next`}
                              href={`${process.env.PUBLIC_URL}/${props.partner}`}
                            >
                              {t("new")}
                            </a>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            ) : (
              <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <h3
                    className="float-right"
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </h3>
                  <Row
                    className="d-flex flex-column"
                    style={{ height: "100%" }}
                  >
                    <Col className="text-center text-lg-left my-3">
                      <Row className="justify-content-center align-items-center">
                        <Col
                          xs="12"
                          className="text-center pt-5"
                          style={{ fontSize: "18px" }}
                        >
                          <p
                            className="m-auto"
                            style={{ maxWidth: "230px", fontWeight: "500" }}
                          >
                            Έχεις επιλέξει ότι η συσκευή σου δεν πληροί κανένα
                            από τα κριτήρια.{" "}
                          </p>
                        </Col>
                        <Col
                          xs="10"
                          lg="6"
                          className="text-center mt-5"
                          onClick={setCondition}
                        >
                          <a
                            className={`${partner}Next next`}
                            href={`${process.env.PUBLIC_URL}/${props.partner}/${link}`}
                          >
                            Δες εδώ την προσφορά
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            )}
            <Modal
              show={showError}
              onHide={handleCloseBer}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <h3
                  className="float-right"
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseBer}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </h3>
                <Row className="d-flex flex-column" style={{ height: "100%" }}>
                  <Col className="text-center text-lg-left my-3">
                    <Row className="justify-content-center align-items-center">
                      <Col
                        xs="6"
                        lg="4"
                        className={`${partner}Bg text-center mb-4 p-0`}
                      >
                        <img
                          className={`${partner}Image`}
                          src={`${CDN}/pricing-app/partners/${partner}/image.png`}
                          alt="logo"
                          width="100%"
                        />
                      </Col>
                      <Col xs="12" className="mt-3 text-center">
                        <h5>
                          <b>{t("device.popup1")}</b>
                        </h5>
                      </Col>

                      <Col
                        xs="11"
                        className="text-center"
                        style={{ fontSize: "15px" }}
                      >
                        <span>
                          {t("device.popup6")}
                          <b className={`${partner}Text`}>
                            {device.brand} {device.model}
                          </b>
                          {t("device.popup7")}
                          <b className={`${partner}Text`}>
                            {i18n.language !== "en"
                              ? offer.condition
                                ? offer.condition.toLocaleLowerCase()
                                : ""
                              : condition}{" "}
                            {t("device.popup8")}
                          </b>
                        </span>
                        {partner === "gp" && (
                          <span>
                            {t("device.popup4")}
                            <b className={`${partner}Text`}>
                              {t("device.popup5")}
                            </b>
                            !
                          </span>
                        )}
                      </Col>

                      {partner === "gp" ? (
                        <Col xs="10" lg="6" className="text-center mt-5">
                          <a
                            className={`${partner}Next next`}
                            href="https://greenpanda.io/atm"
                          >
                            {t("atm")}
                          </a>
                        </Col>
                      ) : (
                        <Col xs="10" lg="6" className="text-center mt-5">
                          <a
                            className={`${partner}Next next`}
                            href={`${process.env.PUBLIC_URL}/${props.partner}`}
                          >
                            {t("new")}
                          </a>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
            <div className="footer row justify-content-end justify-content-md-between align-items-center px-3 px-md-5">
              <div className="d-none d-md-flex align-items-center">
                <div>
                  <b>{t("footer.descr")}</b>
                </div>
                <div
                  className="mx-4"
                  style={{ borderRight: "1px solid #E5E4E5", height: "60px" }}
                ></div>
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    {device.brand === "Apple" && (
                      <img
                        src={
                          `${CDN}/devices/` + brand + "/" + modelImage + ".jpg"
                        }
                        onError={(e) => {
                          e.target.src = process.env.PUBLIC_URL + "/phone.png";
                          e.target.onError = null;
                        }}
                        width="40"
                        alt="phone"
                      />
                    )}
                    {device.brand !== "Apple" && (
                      <img
                        src={
                          `${CDN}/devices/` + brand + "/" + modelImage + ".png"
                        }
                        onError={(e) => {
                          e.target.src = process.env.PUBLIC_URL + "/phone.png";
                          e.target.onError = null;
                        }}
                        width="40"
                        alt="phone"
                      />
                    )}
                  </div>
                  <div className="text-left">
                    <div style={{ fontSize: "18px" }}>
                      <b>
                        {device.brand} {displayedModel}
                      </b>
                    </div>
                    <div style={{ opacity: "0.5" }}>
                      {device.capacity}{" "}
                      {condition === "very_good"
                        ? `${t("footer.veryGoodCon")}`
                        : condition === "good"
                        ? `${t("footer.goodCon")}`
                        : condition === "poor"
                        ? `${t("footer.poorCon")}`
                        : condition === "faulty"
                        ? `${t("footer.faultyCon")}`
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="back mr-3 mr-md-5" onClick={back}>
                  {t("footer.back")}
                </div>
                <div
                  className={
                    condition
                      ? `${partner}Next next foot`
                      : `${partner}Next next foot disabled`
                  }
                  onClick={finalPrice === 0 ? handleShowBer : next}
                >
                  {t("footer.next")}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
};

export default withTranslation()(Condition);
