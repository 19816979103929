import { navigate } from "@reach/router"
import { Row, Col } from 'react-bootstrap';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { withTranslation } from 'react-i18next';
import React, { useEffect } from "react";
import { GPContext } from "../context";

const Loading = (props) => {
  const { t } = props;
  const { state, dispatch } = React.useContext(GPContext);
  

  var partner = state.partner ? state.partner.toLowerCase() : '';
  var brand = props.brand ? props.brand.toLowerCase() : '';
  var model = props.model ? props.model.toLowerCase() : '';
  var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
  var powerson = props.powerson ? props.powerson.toLowerCase() : '';
  var condition = props.condition ? props.condition.toLowerCase() : '';
  let instore = sessionStorage.getItem('instore');

  var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());

  var variants = devices.reduce((map, obj) => {
    map[obj.capacity] = obj;
    return map;
  }, {});

  capacity = capacity.toUpperCase()
  var device = variants[capacity.toUpperCase()];
  if (capacity === '') capacity = 'stock';
  var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];

  useEffect(() => {
    console.log("State: ", state);
    dispatch({ type: 'partner', payload: state.partner })
    if(state.category === 'smartwatch'){
      getOffer();
    }
  }, [])

  async function getOffer(){
    const data = {
      brand: device.brand,
      model: device.model,
      category: 'smartwatch',
      flow: 'pandas',
      parts: state.activeConditions

    }

    console.log('data',data)
    const res = await fetch(`https://imei-service.greenpanda.io/v2/offers/webquote/parts`, {
      // fetch(`http://localhost:8080/v2/offers/webquote/parts`, {
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      }).then(response => response.json())
          .then(data => {
            console.log('penny', data)
              for(let key in data.offers){
                  if(data.offers[key].partner === partner){
                      // setPartResult(data.offers[key])
                      dispatch({
                        type: "SET_PARTSOFFER",
                        payload: data.offers[key]
                      });
                      break;
                  }
              }
    })
  }

  useEffect(() => {
    setTimeout(() => {
      if (finalPrice === 0)
        navigate(`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/ber/recycling`);
      else
        if ((partner === 'istorm' || partner === 'istormcy') || instore) {
          navigate(`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer`, dispatch({ type: "SAVE", data: { finalPrice } }));
        } else {
          navigate(`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/email`, dispatch({ type: "SAVE", data: { finalPrice } }));
        }
    }, 1500)
  }, [])


  return (
    <Row className="justify-content-md-center mb-4">
      <Col lg="12" className="text-center">
        <h1 className="title mb-4">{t('loading')}</h1>
        <div className={`${partner}Loader`}>Loading...</div>
      </Col>
    </Row>
  );
}

export default withTranslation()(Loading);