import { Row, Col, InputGroup, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext, Suspense } from "react";
import { navigate } from '@reach/router';
import { GPContext } from "../context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

let Terms

const SamsungEmail = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = useContext(GPContext);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = urlParams.get("brand") ? urlParams.get("brand") : sessionStorage.getItem('brand');
    var capacity = urlParams.get("capacity") ? urlParams.get("capacity") : sessionStorage.getItem('capacity') ? sessionStorage.getItem('capacity') : state.cap;
    var powerson = urlParams.get("powerson") ? urlParams.get("powerson") : sessionStorage.getItem('answer');
    var condition = urlParams.get("condition") ? urlParams.get("condition") : sessionStorage.getItem('condition')
    let promoPrice = partner === 'xiaomigr' ? 100 : sessionStorage.getItem('promoPrice');
    var model = urlParams.get("model") ? urlParams.get("model") : sessionStorage.getItem('model');

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    capacity = capacity ? capacity.toUpperCase() : '';
    var device = variants[capacity.toUpperCase()];
    let finalPrice
    if(device && powerson!=='ber' && powerson!=='' && condition !== 'unknown'){
        console.log('')
        finalPrice = device[condition + '_price'];
    }else{
        finalPrice = 0;
        device = {
            brand: brand,
            model: model,
            name: model,
            capacity: capacity
        }
    }

    function changeToEng(c) {
        if (c === 'very_good') return 'Like New';
        if (c === 'good') return 'Good';
        if (c === 'poor') return 'Acceptable';
        if (c === 'faulty') return 'Faulty';
    }
    function changeToGreek(c) {
        if (c === 'very_good') return 'Σαν Καινούρια';
        if (c === 'good') return 'Καλή';
        if (c === 'poor') return 'Μέτρια';
        if (c === 'faulty') return 'Κακή';
    }

    const [email, setEmail] = useState("");

    useEffect(() => {
        console.log(sessionStorage.getItem('path'))
        window.dataLayer.push({ event: 'Step Email Form' });
    }, [])

    sessionStorage.setItem('finalPrice', finalPrice)
    if(urlParams){
        sessionStorage.setItem('partner', partner);
        sessionStorage.setItem('brand', device.brand);
        sessionStorage.setItem('capacity', capacity);
        sessionStorage.setItem('condition', condition);
        sessionStorage.setItem('model', device.model);
        sessionStorage.setItem('answer', powerson);
    }

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'email form';

    useEffect(() => {
        Terms = React.lazy(() => import('../terms/samsung-terms_' + i18n.language));
    }, [i18n.language])


    function validateEmail(email) {
        if (/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)) return true;
        return false;
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [promoCode, setPromoCode] = useState('')
    const handlePromo = (e) => {
        setPromoCode(e.target.value);
        setPromoError('')
    }


    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${brand.toLowerCase()}/${model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    

    function sendOffer() {
        var offer = {
            brand: brand,
            model: model,
            name: model.length < 5 ? brand + " " + model : model,
            capacity: capacity,
            powerson: powerson,
            condition: changeToGreek(condition),
            condition_en: changeToEng(condition),
            finalPrice: finalPrice,
            // promoPrice: sessionStorage.getItem('promoPrice') ? sessionStorage.getItem('promoPrice') : 0,
            imageUrl: imageUrl,
            fbclid: localStorage.getItem('fbclid'),
            ttclid: localStorage.getItem('ttclid'),
            gclid: localStorage.getItem('gclid'),
            lang: i18n.language
        }
    
        localStorage.setItem("email", email);
        offer.email = email;

        dispatch({ type: "contact", payload: { email: email } })

        const headers2 =
            { 'Content-Type': 'application/json' };

        var category;
        if (state.category === 'ipad') {
            category = 'tablet'
        } else {
            category = 'smartphone'
        }

        axios.post(`https://communications.greenpanda.io/webquote/samsunggr`, {
            email: offer.email,
            brand: offer.brand,
            model: offer.model,
            price: offer.finalPrice,
            promo: promoPrice,
            condition: offer.condition_en,
            capacity: offer.capacity,
            lang: i18n.language,
            partner: partner,
            category: category
        }, { headers2 }).then(res => {
            console.log(res);
            console.log(res.data);
        })

        let hookPayload = {};
        var currentDate = new Date().toISOString().slice(0, 10);
        Object.assign(hookPayload, offer);
        hookPayload.device_image_url = imageUrl;
        hookPayload.partner = 'samsunggr';
        hookPayload.device_category = category;
        hookPayload.date = currentDate;
        hookPayload.quote_page_url = window.location.href + '/offer'
        hookPayload.price_voucher = state.voucherOffers ? state.voucherOffers[0].price + state.voucherOffers[0].coupon + state.voucherOffers[0].promo : finalPrice
        hookPayload.price_cash = state.cashOffers ? state.cashOffers[0].price + state.cashOffers[0].coupon + state.cashOffers[0].promo : finalPrice
        delete hookPayload.istormPrice;
        delete hookPayload.powerson;
        delete hookPayload.imageUrl;
        axios.post(`https://hook.integromat.com/mt2kbea6lmbpka30lxewowg8lq969zdv`, hookPayload, { headers2 }).then(res => {  
            // navigate(`${process.env.PUBLIC_URL}/${partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer`);
        })
    }

    const [promoError, setPromoError] = useState('')
    const [emailError, setEmailError] = useState('')

    
    const validation = (e) => {
        e.preventDefault();
        let validEmail = validateEmail(email);
        if(!validEmail){
            setEmailError('Invalid email')
        }else{
            setEmailError('')
            sessionStorage.setItem("email", email);
        }
        if(promoCode){
            axios.get(`https://imei-service.greenpanda.io/promos?code=${promoCode}&partner=samsunggr`).then(res => {
                if(res.data.result === 'promo_already_used'){
                    if (promoCode.startsWith('SMM2022')) {
                      setPromoError('Promo expired')
                    } else {
                      setPromoError('Already used promo code')
                    }
                }else if(res.data.result === 'unknown_promo') {
                    setPromoError('Invalid promo code')
                }else{
                    if(validEmail){
                        sessionStorage.setItem("promoCode", promoCode);
                        sessionStorage.setItem('promoPrice', res.data.amount)
                        promoPrice = res.data.amount;
                        sendOffer();
                        navigate(process.env.PUBLIC_URL+`/${props.partner}/offer/`);
                    }      
                }
            })
        }
    }

    const back = () => {
        navigate(sessionStorage.getItem('path'));
    }

    return(
        <div>
            <div className='text-center'>
                <h1 style={{fontWeight: '700', fontSize: '32px', lineHeight: '30px', marginTop: '50px', marginBottom: '20px'}}>Η προσφορά <br/>σου είναι έτοιμη</h1>
                <p className='m-auto' style={{fontSize: '14px', fontWeight: '500', width: '275px'}}>Συμπλήρωσε το email σου και τον κωδικό του voucher που έλαβες από το Samsung Customer Support Center για να λάβεις την προσφορά.</p>
            </div>
           
            <Row className='justify-content-center text-center samsungInput mt-2' style={{marginBottom: '180px'}}>
                <div>
                    <form onSubmit={validation} className='floatingForm mt-4' style={{maxWidth: '320px'}}>
                        <label for="email" style={{marginBottom: '0'}}>
                            <input type="text" id="email" placeholder="" onChange={e => {setEmail(e.target.value); setEmailError('')}}/>
                            <span>Email</span>
                        </label>
                        <p style={{height: '20px', margin: '0px 0 5px 15px'}}>{emailError}</p>
                        <label for="code" style={{marginBottom: '0'}}>
                            <input type="text" id="code" placeholder="" onChange={handlePromo}/>
                            <span>Voucher Code</span>
                        </label>
                        <p style={{height: '20px', margin: '5px 0 5px 0px'}}>{promoError}</p>
                    </form>
                    <p className='m-auto' style={{fontSize: '11px', color: '#747676'}}>Σεβόμαστε τα προσωπικά σας δεδομένα. <u onClick={handleShow} style={{color: '#263847', fontWeight: '600', maxWidth: '320px'}}> Πολιτική Απορρήτου</u></p>
                </div>
            </Row>
            
            
            <div className="footer row justify-content-end align-items-center px-3 px-md-5">
                <div className="d-flex">
                    <div className="back backEmail mr-3 mr-md-5" onClick={back}>
                        {t('footer.back')}
                    </div>
                    <button type="submit" className={`${partner}Next next px-3  foot`} onClick={validation}>{t('email.getOffer')}</button>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <h3 className="text-right mt-3 mr-3 mb-0" onClick={handleClose} style={{ cursor: "pointer", overflow: 'st' }} ><FontAwesomeIcon icon={faTimes} /></h3>
                <Modal.Body style={{ height: '75vh', overflow: 'scroll' }} className="pt-0">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Terms />
                    </Suspense>
                </Modal.Body>
            </Modal>
        </div>
    );
}


export default withTranslation()(SamsungEmail);
