import { withTranslation } from "react-i18next";
import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import * as turf from "@turf/turf";
import { GPContext } from "../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { Link, navigate } from "@reach/router";
import Footer from "./footer";

const Locator = (props) => {
  const { t, i18n } = props;
  const { state, dispatch } = useContext(GPContext);

  var partner = state.partner ? state.partner.toLowerCase() : "";
  var brand = props.brand ? props.brand.toLowerCase() : "";
  var model = props.model ? props.model.toLowerCase() : "";
  var capacity =
    props.capacity && props.capacity !== "stock"
      ? props.capacity.toLowerCase()
      : "";
  var powerson = props.powerson ? props.powerson.toLowerCase() : "";
  var condition = props.condition ? props.condition.toLowerCase() : "";
  var contact = props.contact ? props.contact.toLowerCase() : "";
  let instore = sessionStorage.getItem("instore");

  const [userLocationLoading, setUserLocationLoading] = useState(false);
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyDrwWAOVkgtQ1pLDi9ma-Tq7RUtqCqAiDg";
  const [status, setStatus] = useState(true);

  const calculateDistances = (lat, lon) => {
    const from = turf.point([parseFloat(lon), parseFloat(lat)]);
    axios
      .get(
        `https://session-service.greenpanda.io/locations${
          state.partner === "pandas"
            ? "?flow=pandas"
            : "?partner=" + props.partner ?? state.partner
        }`
      )
      .then((response) => {
        dispatch({
          type: "SET_LOCATIONS",
          payload: response.data.map((l) => {
            if (!parseFloat(l.lng)) return l;
            const to = turf.point([parseFloat(l.lng), parseFloat(l.lat)]);
            const distance = turf.distance(from, to, { units: "kilometers" });
            console.log(l.title, to, distance);
            l.distance = distance.toFixed(2);
            return l;
          }),
        });
      })
      .catch((err) => {
        dispatch({ type: "FETCH_FAILURE" });
      });
  };

  const searchDistance = (lat, lon) => {
    calculateDistances(lat, lon);
    dispatch({
      type: "CLICK",
      payload1: parseFloat(lat),
      payload2: parseFloat(lon),
      payload3: 1,
    });
  };

  const userDistance = () => {
    setUserLocationLoading(true);
    if (!navigator.geolocation) {
      setStatus(false);
      dispatch({ type: "SHARE", payload: false });
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          calculateDistances(
            position.coords.latitude,
            position.coords.longitude
          );
          dispatch({ type: "SEARCH", payload: true });
          if (partner !== "gp" || partner !== "pandas" || instore)
            navigate(
              `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator/locatorResults`
            );
          else
            navigate(
              `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator/locatorResults`
            );
        },
        () => {
          setStatus(false);
        }
      );
    }
  };

  const inputChange = (e) => {
    if (e.target.value === "") {
      dispatch({ type: "SEARCH", payload: false });
    } else {
      dispatch({ type: "SEARCH", payload: true });
    }
  };
  useEffect(() => {
    dispatch({ type: "SEARCH", payload: false });
    dispatch({ type: "partner", payload: state.partner });
  }, []);

  return (
    <Container>
      <Row className="ms-md-5 d-flex justify-content-center pt-4">
        <Col sm="12" className="mb-5 pb-5">
          <div className="d-flex flex-column align-items-center">
            <h1 className="title my-2 ms-1 fw-bold text-center text-md-start">
              {partner === "gp" ? (
                <span>{t("locator.atm")}</span>
              ) : (
                <span>Βρες ένα κατάστημα δίπλα σου</span>
              )}
            </h1>
            <Col xs="12" sm="8" md="6" lg="5">
              <div className="text-center mt-3 mb-4">
                {partner === "gp" ? (
                  <span>{t("locator.descr1")}</span>
                ) : (
                  <span>
                    Μοιράσου την τοποθεσία σου για να δεις ποιο κατάστημα
                    βρίσκεται δίπλα σου.
                  </span>
                )}
              </div>
            </Col>
            {status && (
              <>
                <Col xs="9" sm="6" md="5" lg="4" xl="3" className="text-center">
                  {userLocationLoading && (
                    <div
                      style={{ margin: "35px auto", fontSize: "10px" }}
                      className={`${partner}Loader mb-5`}
                    >
                      Loading...
                    </div>
                  )}
                  {!userLocationLoading && (
                    <button
                      className="btn locatorButton"
                      onClick={() => userDistance()}
                    >
                      <FontAwesomeIcon
                        fill="#B4B6B8"
                        className="mr-2"
                        icon={faLocationArrow}
                      />
                      <span>Share my location</span>
                    </button>
                  )}
                  <div
                    className="mt-4"
                    style={{ borderBottom: "2px dashed #E5E4E5" }}
                  ></div>
                </Col>
                <div className="mt-4">{t("locator.descr2")}</div>
              </>
            )}
            <Col xs="11" sm="8" md="6" lg="5" xl="4" className="mt-4">
              <Autocomplete
                apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                style={{
                  width: "100%",
                  height: "40px",
                  textAlign: "start",
                  padding: "0 10px",
                }}
                onChange={inputChange}
                onPlaceSelected={(place) => {
                  if (place.geometry) {
                    var lat = place.geometry.location.lat();
                    var lng = place.geometry.location.lng();
                    searchDistance(lat, lng);
                  }
                }}
                options={{
                  types: [],
                  componentRestrictions: { country: ["gr", "cy"] },
                }}
                placeholder={
                  i18n.language === "el" ? "Αναζήτηση..." : "Search..."
                }
              />
              {partner === "istorm" || partner === "istormcy" ? (
                <Link
                  to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator/locatorResults`}
                >
                  <button
                    style={{ border: "none", width: "100%" }}
                    disabled={!state.search}
                    className={
                      state.search
                        ? `${partner}Next next py-3 mt-3`
                        : `${partner}Next foot next disabled py-3 mt-3`
                    }
                  >
                    Βρες το πλησιέστερο σημείο
                  </button>
                </Link>
              ) : (
                <Link
                  to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator/locatorResults`}
                >
                  {partner === "gp" ? (
                    <button
                      style={{ border: "none", width: "100%" }}
                      disabled={!state.search}
                      className={
                        state.search
                          ? `${partner}Next next py-3 mt-3`
                          : `${partner}Next foot next disabled py-3 mt-3`
                      }
                    >
                      {t("locator.store")}
                    </button>
                  ) : (
                    <button
                      style={{ border: "none", width: "100%" }}
                      disabled={!state.search}
                      className={
                        state.search
                          ? `${partner}Next next py-3 mt-3`
                          : `${partner}Next foot next disabled py-3 mt-3`
                      }
                    >
                      Βρες το πλησιέστερο σημείο
                    </button>
                  )}
                </Link>
              )}
            </Col>
          </div>
        </Col>
      </Row>
      {partner === "istorm" || partner === "istormcy" ? (
        <Footer
          back={{
            text: `${t("footer.back")}`,
            path: `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer`,
          }}
        ></Footer>
      ) : (
        <Footer
          back={{
            text: `${t("footer.back")}`,
            path: `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer`,
          }}
        ></Footer>
      )}
    </Container>
  );
};

export default withTranslation()(Locator);
