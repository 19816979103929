import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import { CDN } from '../constants'

const TradeinOptions = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    const [getZc, setGetZc] = useState([]);
    let partner = props.partner ? props.partner.toLowerCase() : '';
    let brand = props.brand ? props.brand.toLowerCase() : '';
    let model = props.model ? props.model.toLowerCase() : '';
    let capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    let powerson = props.powerson ? props.powerson.toLowerCase() : '';
    let condition = props.condition ? props.condition.toLowerCase() : '';
    let contact = props.contact ? props.contact.toLowerCase() : '';
    let zipcode = sessionStorage.getItem('zipcode');
    let checkZc = partner==='samsunggr' ? getZc.find(v => v === zipcode) : false;
    const [finalPrice, setFinalPrice] = useState();

    const getData = () => {
        fetch(`${process.env.PUBLIC_URL}/scooter/postcodes.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function (response) {
                console.log(response);
                return response.json();
            })
            .then(function (myJson) {
                setGetZc(myJson);
            });
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if(props.partner==='pandas' && state.cashOffers){
            setFinalPrice(state.cashOffers[0].price);
        }else if (props.partner !== 'pandas'){
            setFinalPrice(sessionStorage.getItem('finalPrice'));
        }
    }, [state])

    useEffect( () => {
        // if(state.userLocation && state.userLocation.code === 0){
        //     dispatch({ type: "USER_LOCATION", data: { latitude: state.latitude, longitude: state.longitude} })
        // }
        window.dataLayer.push({ event: 'Step Trade-in Options' });
    },[])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'trade-in options'

    return (
        <>
        {state.cashOffers === undefined?<div></div>:
        <div className='m-auto'>
            <div className=' tradeinHeading text-center'>
                <h1 style={{ fontWeight: '800', lineHeight: '28px', fontSize: '32px' }}>Eπιλογές trade-in</h1>
            </div>
            <div className='d-flex justify-content-center'>
            <Row className='-center p-0 m-0'>
                <Col xs="12" md="6" className='px-0'>
                    <div>
                        <Link id='trade-in-option-cash-in-store' className='d-md-none' style={{ color: '#1d1d1d' }} to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/offerLocations?type=cash`} >
                            <div className='tradeinCard mx-auto mb-3' style={{height:'190px'}}>
                                <div className='d-flex d-md-block'>
                                    <div className='py-1 pl-2'>
                                        <h5 style={{fontWeight:'700', letterSpacing:'-0.3px'}}>Σε κατάστημα</h5>
                                        <p className='mb-2' style={{fontSize:'12px', lineHeight:'1.15'}}>Επίλεξε το κοντινότερο Pandas ATM για να ολοκληρώσεις το trade-in και να λάβεις τραπεζική κατάθεση.</p>
                                        <h5 className='pb-2 m-0'><span style={{fontSize:"20px", fontWeight:'700'}} >έως και </span><span style={{fontSize:'30px', fontWeight:'700'}}>{state.cashOffers[0].price}€</span></h5>
                                    </div>
                                    <div className='d-md-none' style={{ borderRadius: '16px', width:'175px', display:'flex', justifyContent:'end' }}><img src={`${CDN}/pricing-app/samsung/store.png`} alt='person on couch' width='175px' height='190px' /></div>
                                </div>

                            </div>
                        </Link>
                        <div className='tradeinPandasCard d-none d-md-block pb-4'>
                            <img className='d-none d-md-block' style={{ position: 'absolute', top: '-70px', left: '50%', transform: 'translateX(-50%)' }} src={`${CDN}/pricing-app/samsung/store-desktop.png`} alt='person on couch' height='124px' />
                            <div className='d-flex d-md-block'>
                                <div>
                                    <h5 className='py-2'><b>Σε κατάστημα</b></h5>
                                    <p>Επίλεξε το κοντινότερο Pandas ATM για να ολοκληρώσεις το trade-in και να λάβεις τραπεζική κατάθεση.</p>
                                    <h5 className='py-2'><b>έως και <span style={{fontSize:'30px'}}>{state.cashOffers[0].price}€</span></b></h5>
                                </div>
                            </div>
                            <Row className="justify-content-start d-none d-md-block">
                                <Col xs="10" className='test'>
                                    <Link id='trade-in-option-cash-in-store' to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/offerLocations?type=cash`} className="">
                                        <div className="samsunggrNext next px-3 mt-5 text-center">Επιλογή</div>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                {(finalPrice >= 150 && sessionStorage.getItem('country')!=='cyprus') && <>
                    {checkZc ?
                        <Col xs="12" md="6" className='pr-0'>
                        <div>
                            <a className='d-md-none' id='trade-in-option-scooter' style={{ color: '#1d1d1d' }} href={`https://www.pandas.io/el-GR/scooter`} >
                                <div className='tradeinCard mx-auto' style={{height:'190px'}}>
                                    <div className='d-flex d-md-block'>
                                        <div className='py-2 pl-2'>
                                            <h5 style={{fontWeight:'700', letterSpacing:'-0.3px'}}>Από το σπίτι</h5>
                                            <p className='mb-2' style={{fontSize:'12px', lineHeight:'1.15'}}>Κλείσε ραντεβού με το Pandas Scooter και πούλησε ένα smartphone έξω από την πόρτα σου.</p>
                                            <h5 className='pb-2 m-0' style={{fontSize: '20px', fontWeight:'700'}}>έως και <span style={{fontSize:'30px', fontWeight:'700'}}>{state.cashOffers[0].price}€</span></h5>
                                        </div>
                                        <div className='d-md-none' style={{ borderRadius: '16px' ,width:'177px', display:'flex', justifyContent:'end'}}><img src={`${CDN}/pricing-app/samsung/home.png`} alt='person on couch' width='177px' height='190px' /></div>
                                    </div>
                                </div>
                            </a>
                            <div className='tradeinPandasCard d-none d-md-block pb-4'>
                                <img className='d-none d-md-block' style={{ position: 'absolute', top: '-70px', left: '50%', transform: 'translateX(-50%)' }} src={`${CDN}/pricing-app/samsung/scooter-desktop.png`} alt='person on couch' height='124px' />
                                <div className='d-flex d-md-block'>
                                    <div>
                                        <h5 className='py-2'><b>Από το σπίτι</b></h5>
                                        <p style={{marginBottom: '39px'}}>Κλείσε ραντεβού με το Pandas Scooter και πούλησε ένα smartphone έξω από την πόρτα σου.</p>
                                        <h5 className='py-2'><b>έως και <span style={{fontSize:'30px'}}>{state.cashOffers[0].price}€</span></b></h5>
                                    </div>
                                    <Row className="justify-content-start d-none d-md-block">
                                    <Col xs="10" className='test'>
                                    <Link id='trade-in-option-scooter' to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/scooter`} className="">
                                    <div className="samsunggrNext next px-3 mt-5 text-center">Επιλογή</div>
                                    </Link>
                                        {/* <a id='trade-in-option-scooter' href={`https://www.pandas.io/el-GR/scooter`} className="">
                                            <div className="samsunggrNext next px-3 mt-5 text-center">Επιλογή</div>
                                        </a> */}
                                    </Col>
                                </Row>
                                </div>
                            </div>
                        </div>

                        </Col>
                        :

                        <Col xs="12" md="6" className='pr-0'>
                            <div>
                            <Link id='trade-in-option-courier' className='d-md-none' style={{ color: '#1d1d1d' }} to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/courier`} >
                                    <div className='tradeinCard mx-auto' style={{height:'190px'}}>
                                        <div className='d-flex d-md-block'>
                                            <div>
                                                <h5><b style={{fontWeight:'700'}}>Μέσω courier</b></h5>
                                                <p style={{fontSize: '12px', lineHeight:'1.15'}} className='mb-4 pb-3' >Διάλεξε ημέρα και ώρα που προτιμάς και ένα courier θα παραλάβει τη συσκευή σου.</p>
                                                <h5 className='pb-2 m-0' style={{fontSize: '20px', fontWeight:'700'}}>έως και <span style={{fontSize:'30px'}}>{state.cashOffers[0].price}€</span></h5>
                                            </div>
                                            <div className='d-md-none' style={{borderRadius: '16px'}}><img src={`${CDN}/pricing-app/samsung/courier.png`} alt='courier girl' width='177px' height='180px' /></div>
                                        </div>
                                    </div>
                                </Link>
                                <div className='tradeinPandasCard d-none d-md-block pb-4'>
                                    <img className='d-none d-md-block' style={{position: 'absolute', top: '-70px', left: '50%', transform: 'translateX(-50%)'}} src={`${CDN}/pricing-app/samsung/courier-desktop.png`} alt='courier girl' height='124px' />
                                    <div className='d-flex d-md-block'>
                                        <div>
                                            <h5 className='py-2'><b>Μέσω courier</b></h5>
                                            <p style={{marginBottom: '62px'}}>Διάλεξε ημέρα και ώρα που προτιμάς και ένα courier θα παραλάβει τη συσκευή σου.</p>
                                            <h5 className='py-2'><b>έως και <span style={{fontSize:'30px'}}>{state.cashOffers[0].price}€</span></b></h5>
                                        </div>
                                        <Row className="justify-content-start d-none d-md-block">
                                        <Col xs="10" className=''>
                                            <Link id='trade-in-option-courier' to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/courier`} className="">
                                                <div className="samsunggrNext next px-3 mt-5 text-center">Επιλογή</div>
                                            </Link>
                                        </Col>
                                    </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    }
                </>
                }
                
                </Row>
            </div>

        </div>}</>
    );
}

export default withTranslation()(TradeinOptions);