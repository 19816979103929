import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from "@reach/router";
import { GPContext } from "../context";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { redirectAfterTimeout } from '../viewHelperFunctions';
import {CDN} from '../constants'
 
const Publiccy = (props) => {
    const { state, dispatch } = React.useContext(GPContext);
    const { t, i18n } = props;
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    let instore = sessionStorage.getItem('instore');
    const promoDevices = ['Apple iPhone 14 Pro', 'Apple iPhone 14 Pro Max', 'Apple iPhone 14 Plus', 'Apple iPhone 14', 'Apple iPhone 13 Pro', 'Apple iPhone 13 Pro Max', 'Apple iPhone 13', 'Apple iPhone 13 mini', 'Apple iPhone 12 Pro Max', 'Apple iPhone 12 Pro', 'Apple iPhone 12', 'Apple iPhone 12 mini', 'Apple iPhone 11 Pro Max', 'Apple iPhone 11 Pro', 'Apple iPhone 11','Apple iPhone SE (2022)', 'Apple iPhone SE (2020)']

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return 'Πολή Καλή';
        if (c === 'good') return 'Καλή';
        if (c === 'poor') return 'Μέτρια';
        if (c === 'faulty') return 'Κακή';
    }
    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl
    }

    console.log(offer);
    console.log(finalPrice);

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Offer Page' });
        dispatch({ type: 'partner', payload: state.partner })
        if(instore){
            redirectAfterTimeout('/' + props.partner);
        }
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = sessionStorage.getItem('lang');
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'offer';
    window.price = finalPrice;

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
    }, []);

    const [open1, setOpen1] = useState(false);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">Παρακαλώ περιμένετε...</h3>
                        <div className={`${partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        } return (
            <div>
                <Row className="justify-content-center">
                    <Col xs="12" md="11" lg="8" className="text-center mb-2">
                        <div className="title">Αποχαιρέτησε το {device.name} και καλωσόρισε <span style={{ color: "#F39324" }}> νέα προϊόντα </span> με μεγάλο <span style={{ color: "#F39324" }}> όφελος</span>!</div>
                    </Col>
                    {/* {promoDevices.includes(device.name) && <Row className="ml-1 ml-md-0" style={{position: 'relative', width: '100%', maxWidth: '789px', padding: '30px', border: '1px solid #e6e6e6', borderRadius: '16px', boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)', marginBlock: '40px 30px'}}>
                        <div style={{position: 'absolute', right: '3px', top:'3px', borderRadius: '0 13px', backgroundColor: 'rgba(245, 80, 35, 0.20)', color: '#f55023', padding: '5px 10px', fontWeight: '700'}}>PROMO</div>
                        <Col xs="7" md="5" className="mx-auto">
                            <img src={CDN + "/pricing-app/photos/pmm-iphone15.png"} style={{ width: "100%", maxWidth: '238px' }} alt="Phone" />
                        </Col>
                        <Col xs="12" md="7" className='text-center text-md-left d-flex flex-column justify-content-center align-items-center align-items-md-start'>
                            <p className='mt-3 mt-md-0' style={{fontWeight: '700', marginBottom: '0'}}>Απόκτησε τη σειρά iPhone 14 ή 15</p>
                            <p style={{fontWeight: '700', fontSize: '32px', color: '#f55023', lineHeight: '38px'}}>έως και {finalPrice + 65}€ φθηνότερα</p>
                            <div style={{maxWidth: '260px'}}>
                                {instore ? <Link to={`${process.env.PUBLIC_URL}/${props.partner}`} className="">
                                    <div className="pmmGreenNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                                </Link>
                                :
                                <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator`} className="">
                                    <div className="pmmGreenNext next px-4 mt-4">Βρες ένα κατάστημα Public</div>
                                </Link>
                                }
                            </div>
                        </Col>
                    </Row>} */}
                    <Row className="justify-content-center align-items-center mt-4">
                        <Col xs="12" md="7" className="text-center text-md-right mb-">
                            <img src={CDN + "/pricing-app/partners/mistore/imagecollection.png"} style={{ width: "80%" }} alt="Phone" />
                        </Col>
                        <Col xs="12" md="5" className="">
                            <h5 className="text-center text-md-left mt-2 mt-md-0 mb-3">Πάρε <b>δωροεπιταγή</b> <br /> <b>Pandas</b> έως <span style={{ fontSize: '30px', fontWeight: 'bold' }}> {finalPrice}€</span></h5>
                            <Row className="justify-content-center justify-content-md-start">
                                <Col xs="7" sm="6" md="9" lg="8" xl="7">
                                    {instore ? <Link to={`${process.env.PUBLIC_URL}/${instore}`} className="">
                                        <div className="publiccyNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                                    </Link>
                                    :
                                    <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator`} className="">
                                        <div className="publiccyNext next px-3 mt-4">Βρες ένα κατάστημα Public</div>
                                    </Link>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Col xs="11" sm="9" md="12" className="text-center mt-2 mb-5">
                        <small>*Η τελική τιμή καθορίζεται αφού αξιολογηθεί η κατάσταση της συσκευής και η υγεία της μπαταρίας.</small>
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col xs="12" md="12" className="text-center mb-md-5">
                        <h3 className="d-none d-md-block title">Είναι τόσο απλό!</h3>
                        <h5 className="d-md-none title">Είναι τόσο απλό!</h5>
                    </Col>
                </Row>
                <Row style={{ background: '#F0F3F5' }} className="d-none d-md-block mb-5 py-4">
                    <Col className="d-flex justify-content-center">
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/publiccy/map_icon.svg"} width="70" alt="map" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα <b>Public</b></div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/publiccy/smartphone_icon.svg"} width="70" alt="smartphone" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b>, αφού το αξιολογήσουμε</div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/publiccy/wallet_icon.svg"} width="70" alt="wallet" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                        </Col>
                    </Col>
                </Row>
                <Slider className="d-md-none mt-2 mb-5" {...settings}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/publiccy/map_icon.svg"} alt="map" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα <b>Public</b></div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/publiccy/smartphone_icon.svg"} alt="smartphone" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b> του αφού το αξιολογήσουμε</div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/publiccy/wallet_icon.svg"} alt="wallet" height="50px" />
                        <div style={{ maxWidth: '180px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                    </div>
                </Slider>
                <Row className="d-flex justify-content-center">
                    <Col xs="11" md="10" className="text-center">
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι είναι το πρόγραμμα Exchange Mi  ;</div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Μέσω του προγράμματος Exchange Mi αγοράζεις με έκπτωση smartphone ή οποιαδήποτε άλλη συσκευή επιθυμείς από το οικοσύστημα της Xiaomi! Μπορείς να φέρεις τo παλιό σου smartphone σε ένα Xiaomi Store για απόσυρση. Η ειδικά εκπαιδευμένη μας ομάδα θα εκτιμήσει την κατάσταση του παλιού σου κινητού και θα σου δώσει voucher αντίστοιχης χρηματικής αξίας ώστε να το εξαργυρώσεις επιτόπου στη νέα σου συσκευή.                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι πρέπει να κάνω; </div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Το μόνο που χρειάζεται να κάνεις είναι να φέρεις το παλιό σου smartphone σε Xiaomi Store. Θα αξιολογήσουμε άμεσα τη συσκευή σου και θα σε ενημερώσουμε για το όφελος που μπορείς να λάβεις. Στη συνέχεια, μπορείς να αποκτήσεις τη νέα σου συσκευή σε χαμηλότερη τιμή.                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Ποιες συσκευές συμμετέχουν στο πρόγραμμα;   </div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Μπορείς να δεις τα brands αλλά και τις συσκευές που συμμετέχουν στο προγραμμα Exchange Mi  <a href="/publiccy">ΕΔΩ</a>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι όφελος θα έχω ανταλλάσσοντας το παλιό μου Smartphone;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η αξία του κάθε smartphone εξαρτάται από το μοντέλο και την κατάσταση στην οποία βρίσκεται.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Πρέπει η συσκευή να είναι λειτουργική προκειμένου να μπορεί να μπει στο πρόγραμμα Exchange Mi;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Ναι, η συσκευή σου θα πρέπει να είναι λειτουργική και ενεργή, προκειμένου να λάβεις την αξία που της αντιστοιχεί με βάση την κατάστασή της.                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Με ποια κριτήρια καθορίζεται η αξία της συσκευής μου;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η αξία της συσκευής καθορίζεται από τη μάρκα, το μοντέλο, τη χρονολογία, την κατάσταση, και την τρέχουσα τιμή της στην αγορά ως μεταχειρισμένη συσκευή.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι θα πρέπει να κάνω πριν παραδώσω το smartphone μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Θα πρέπει αρχικά να κάνεις αντιγραφή όλων των δεδομένων σου σε κάποιο ασφαλές αποθηκευτικό χώρο και να επαναφέρεις τη συσκευή στις αρχικές της ρυθμίσεις . Σε κάθε περίπτωση τα δεδομένα θα διαγραφούν για τη δική σου ασφάλεια μόλις παραλάβουμε τη συσκευή. Φυσικά με το back up Μπορούμε να σε βοηθήσουμε και εμείς οπότε μην αγχώνεσαι, αρκεί να έχεις μαζί σου τους κατάλληλους κωδικούς !
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι γίνεται με τα δεδομένα μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Πριν παραδώσεις το παλιό σου smartphone θα πρέπει να έχεις κάνει backup σε κάποια άλλη συσκευή σου και να έχεις κάνει επαναφορά δεδομένων και ρυθμίσεων.  Ωστόσο, όλες οι συσκευές περνούν από μια συγκεκριμένη διαδικασία διαλογής μετά την παραλαβή. Στην περίπτωση όπου δεν έχει γίνει επαναφορά, το εξειδικευμένο μας προσωπικό πραγματοποιεί μια αυτοματοποιημένη διαδικασία επαναφοράς για να διασφαλίσει την προστασία και την οριστική διαγραφή των προσωπικών σου δεδομένων.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μπορώ να αγοράσω όποια συσκευή θέλω;</div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η εξαργύρωση μπορεί να γίνει πάνω σε όποιο προϊόν επιλέξεις από τα Xiaomi stores.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μπορώ να κάνω την αγορά της νέας μου συσκευής με τιμολόγιο;</div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Δυστυχώς όχι ακόμα. Αν αλλάξει κάτι θα σε ενημερώσουμε άμεσα.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μέχρι πότε μπορώ να εξαργυρώσω το voucher μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/publiccy/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η εξαργύρωση του voucher που έλαβες πραγματοποιείται εντός της ημέρας απόκτησής του.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="my-5 py-2 justify-content-center">
                    <Col xs="8" sm="7" md="5" lg="4" xl="3">
                        {instore ? <Link to={`${process.env.PUBLIC_URL}/${instore}`} className="">
                            <div className="publiccyNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                        </Link>
                        :
                        <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator`} className="">
                            <div className="publiccyNext next px-3 mt-4">Βρες ένα κατάστημα Public</div>
                        </Link>
                        }
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{ color: "#263747" }}>
                    <Col xs="11" md="10">
                        <Row className="py-5" style={{ background: '#EBEBEB', borderRadius: '8px' }}>
                            <Col className="text-center mb-4">
                                <div className="">
                                    <h3 className="font-weight-bold">Έχεις απορίες;</h3>
                                    <h6 className="font-weight-bold">Είμαστε εδώ για να σου τις λύσουμε.</h6>
                                </div>
                            </Col>
                            <Col xs="12">
                                <Row className="d-flex justify-content-between font-weight-bold">
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="">Κάλεσέ μας στο: </div>
                                        <div className="d-flex align-items-center" style={{ marginRight: '24px' }}>
                                            <FontAwesomeIcon className="mr-2" style={{ color: "#F39324" }} icon={faMobileAlt} />
                                            <a href="tel:7777 0300" target="_blank" style={{ color: "#F39324" }} rel="noreferrer">77770300</a>
                                        </div>

                                    </Col>
                                    <Col className="d-flex justify-content-center"><div>Συμπλήρωσε τη φόρμα<br /> επικοινωνίας <a href="https://www.public.cy/help/contact-us" style={{ color: "#F39324" }} target="_blank" rel="noreferrer"> <u>εδώ</u></a>.</div></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <Row className="mb-5 pb-5">
                    <Col></Col>
                </Row>
            </div >
        );
}


export default Publiccy;