
import { navigate } from "@reach/router"
import { withTranslation } from 'react-i18next';
import { useEffect } from "react";

const Language = (props) => {
    const { lang, i18n } = props;
    var partner = props.partner ? props.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = props.capacity ? props.capacity.toLowerCase() : '';


    useEffect(() => {
        i18n.changeLanguage(lang);
        // window.location = '/';
        sessionStorage.setItem('lang', lang)
        if (capacity !== '')
            navigate(`${process.env.PUBLIC_URL}/${partner}/${brand}/${model}/${capacity}`)
        else if (model !== '')
            navigate(`${process.env.PUBLIC_URL}/${partner}/${brand}/${model}`)
        else if (brand !== '')
            navigate(`${process.env.PUBLIC_URL}/${partner}/${brand}`)
        else if (partner !== '')
            navigate(`${process.env.PUBLIC_URL}/${partner}`);
        else
            navigate(`${process.env.PUBLIC_URL}/`);


    }, [i18n, lang])

    return (<></>);
}

export default withTranslation()(Language);