import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect } from "react";
import { GPContext } from "../context";
import { Link } from "@reach/router"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CDN } from '../constants'

const PromoOffer = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = urlParams.get("brand") ? urlParams.get("brand") : sessionStorage.getItem('brand');
    var capacity = urlParams.get("capacity") ? urlParams.get("capacity") : sessionStorage.getItem('capacity') ? sessionStorage.getItem('capacity') : state.cap;
    var powerson = urlParams.get("powerson") ? urlParams.get("powerson") : sessionStorage.getItem('answer');
    var condition = urlParams.get("condition") ? urlParams.get("condition") : sessionStorage.getItem('condition')
    const promoPrice = partner === 'xiaomigr' ? 100 : sessionStorage.getItem('promoPrice');
    var model = urlParams.get("model") ? urlParams.get("model") : sessionStorage.getItem('model');
    let modelImage = urlParams.get("modelImage") ? urlParams.get("modelImage") : sessionStorage.getItem('modelImage');
    const samsungNewPromoFlow = sessionStorage.getItem('promoCode').includes('SMM2023');
    let nextPage;
    if(samsungNewPromoFlow){
        nextPage = 'courier';
    }else{
        nextPage = 'courier'
    }

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }

    capacity = capacity && capacity.toLowerCase() !== 'stock' ? capacity.toUpperCase() : '';
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice
    if(device && powerson!=='ber' && powerson!=='' && condition !== 'unknown'){
        console.log('')
        finalPrice = device[condition + '_price'];
    }else{
        finalPrice = 0;
        device = {
            brand: brand,
            model: model,
            name: model,
            capacity: capacity
        }
    }

    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        coupon: device.coupon,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl,
        contact: state.contact
    }

    sessionStorage.setItem('finalPrice', finalPrice)
    if(urlParams){
        sessionStorage.setItem('partner', partner);
        sessionStorage.setItem('brand', device.brand);
        sessionStorage.setItem('capacity', capacity);
        sessionStorage.setItem('condition', condition);
        sessionStorage.setItem('model', device.model);
    }
    useEffect(() => {
        console.log("State: ", state);
        console.log(props)
        if(!window.location.href.includes('localhost')){
            window.dataLayer.push({ event: 'Step Offer Page' });
        }
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'offer';
    window.price = finalPrice;

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);

    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${state.partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div className='mt-md-5 mt-0'>
            <Row className="justify-content-center pb-5">
                <Col xs="12" sm="12" md="12" lg="10" xl="9">
                    <Row style={{ padding: '20px 0px' }} className="justify-content-around mt-md-5">
                        <Col xs="12" md="6" className="text-center d-none d-md-block">
                            {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand.toLowerCase() + "/" + modelImage + ".jpg"} onError={(e) => { e.target.src = `${CDN}/pricing-app/icons/smartphone-icon.svg`; e.target.onError = null; }} style={{ maxWidth: '220px' }} width="100%" alt="phone" />}
                            {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand.toLowerCase() + "/" + modelImage + ".png"} onError={(e) => { e.target.src = `${CDN}/pricing-app/icons/smartphone-icon.svg`; e.target.onError = null; }} style={{ maxWidth: '220px' }} width="100%" alt="phone" />}
                        </Col>
                        <Col xs="12" md="6" className="text-left mt-3 mt-md-0 pr-md-0">
                            <div className=''>
                                {brand!=='unknown' ?
                                    <h6 className="title d-none d-md-inline-block mb-1" style={{textTransform: 'capitalize'}}>{device.brand}</h6>
                                :
                                    <h6 className="title d-none d-md-inline-block mb-1">Η συσκευή σου</h6>

                                }
                                {model!=='unknown' ? 
                                    <h6 className="d-none d-md-inline-block mb-1 ml-2" style={{fontSize: '20px', color: '#808080', textTransform: 'capitalize'}}>{device.model}{device.brand === 'Apple' && ', ' + capacity}</h6>
                                :
                                    <h6 className="d-none d-md-inline-block ml-2 mb-1" style={{fontSize: '20px', color: '#808080'}}>Smartphone</h6>
                                }
                                {brand!=='unknown' ?
                                    <h6 className="title d-md-none text-center mb-1" style={{textTransform: 'capitalize'}}>{device.brand}</h6>
                                :
                                    <h6 className="title d-md-none text-center mb-1">Η συσκευή σου</h6>
                                }
                                {model!=='unknown' ? 
                                    <h6 className="d-md-none text-center mb-1" style={{fontSize: '16px', color: '#808080'}}>{device.model}{device.brand === 'Apple' && ', ' + capacity}</h6>
                                :
                                    <h6 className="d-md-none text-center mb-1" style={{fontSize: '16px', color: '#808080'}}>Smartphone</h6>
                                }
                                
                                {condition !=='unknown' && <div className="d-flex flex-column flex-md-row align-items-center mt-3 mb-4">
                                    {condition === 'very_good' && <div style={{ padding: '2px 20px 3px 5px', borderRadius: '10px' }} className="d-flex align-items-center purple">
                                        <div className="mr-3"><img src={CDN + "/pricing-app/icons/very_good-icon.svg"} alt="warning" width="27" /></div>
                                        <div style={{ fontSize: '14px' }}><b>{t('offer.veryGood')}</b></div>
                                    </div>}
                                    {condition === 'good' && <div style={{ backgroundColor: 'rgb(90,242,128, 0.3)', padding: '2px 20px 3px 5px', borderRadius: '8px' }} className="d-flex align-items-center">
                                        <div className="mr-3 pl-1"><img src={CDN + "/pricing-app/icons/good-icon.svg"} alt="warning" width="27" /></div>
                                        <div style={{ fontSize: '14px' }}><b>{t('offer.good')} κατάσταση</b></div>
                                    </div>}
                                    {condition === 'poor' && <div style={{ backgroundColor: '#FFF5E8', padding: '2px 20px 3px 5px', borderRadius: '10px' }} className="d-flex align-items-center">
                                        <div className="mr-3"><img src={CDN + "/pricing-app/icons/poor-icon.svg"} alt="warning" width="27" /></div>
                                        <div style={{ fontSize: '14px' }}><b>{t('offer.poor')}</b></div>
                                    </div>}
                                    {condition === 'faulty' && <div style={{ backgroundColor: '#FFE6E2', padding: '2px 20px 3px 5px', borderRadius: '10px' }} className="d-flex align-items-center">
                                        <div className="mr-3"><img src={CDN + "/pricing-app/icons/faulty-icon.svg"} alt="warning" width="27" /></div>
                                        <div style={{ fontSize: '14px' }}><b>{t('offer.faulty')}</b></div>
                                    </div>}
                                </div>}
                                <Row className='justify-content-center d-md-none mb-2 mb-md-0'>
                                    {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand.toLowerCase() + "/" + modelImage + ".jpg"} onError={(e) => { e.target.src = `${CDN}/pricing-app/icons/smartphone-icon.svg`; e.target.onError = null; }} style={{ maxWidth: '130px' }} width="100%" alt="phone" />}
                                    {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand.toLowerCase() + "/" + modelImage + ".png"} onError={(e) => { e.target.src = `${CDN}/pricing-app/icons/smartphone-icon.svg`; e.target.onError = null; }} style={{ maxWidth: '130px' }} width="100%" alt="phone" />}
                                </Row>
                                {/* <div > */}
                                { finalPrice!==0 && <p className='mb-3 text-center text-md-left mt-3 mt-md-0' style={{fontSize: '12px', color: '#4d4d4d', fontWeight: '500'}}>H αξία της συσκευής σου είναι {finalPrice}€ </p>}
                                { finalPrice!==0 ? <div className='d-flex align-items-center m-auto m-md-0' style={{border: '2px solid #e6e6e6', borderRadius: '8px', padding: '15px 0px 15px 10px', maxWidth: '360px'}}>
                                    <div><img src={`${CDN}/pricing-app/icons/gift.svg`} alt='gift icon' /> </div>
                                    {props.partner==='samsunggr' ? 
                                        <p className='greyText mb-0 ml-3' style={{fontWeight: '400', fontSize: '12px', lineHeight: '20px'}}>
                                            Έχεις επιπλέον <span style={{fontWeight: '600'}}>{promoPrice}€</span> από την Samsung επειδή <span>προπαρήγγειλες και </span> αγόρασες τα νέα <span style={{fontWeight: '600'}}>Samsung Galaxy S24|S24+|S24 Ultra</span>
                                        </p>
                                    :
                                        <p className='greyText mb-0 ml-3' style={{fontWeight: '400', fontSize: '12px', lineHeight: '20px', paddingRight: '40px'}}>
                                            <b style={{fontWeight: '600'}}>H αξία της συσκευής σου είναι {finalPrice}€<br/></b> Έχεις επιπλέον  <b style={{fontWeight: '600'}}>100€</b> από την Xiaomi επειδή αγόρασες το Xiaomi 13 ή το 13 pro
                                        </p>
                                    }
                                </div>
                                :
                                
                                <div className='align-items-center m-auto m-md-0 mt-md-3' style={{border: '2px solid #e6e6e6', borderRadius: '8px', padding: '15px 5px 15px 5px', maxWidth: '360px'}}>
                                    {props.partner==='samsunggr' ?
                                    <p className='greyText mb-0 ml-3 text-center text-md-left' style={{fontWeight: '400', fontSize: '12px', lineHeight: '16px', color: '#4d4d4d'}}>
                                        {(model==='unknown' || brand==='unknown')  ? <span>Το smartphone σου δεν είναι στη λίστα συσκευών της Pandas με οικονομικό αντίτιμο για trade-in.</span> :  <span>Η κατάσταση του smartphone σου δεν υπάγεται στη λίστα  της Pandas με οικονομικό αντίτιμο για trade-in.</span>} Παρ’ όλα αυτά έχεις <span style={{fontWeight: '600'}}>{promoPrice}€</span> κέρδος από τη Samsung επειδή προπαρήγγειλες και αγόρασες τα νέα <span style={{fontWeight: '600'}}>Samsung Galaxy S24|S24+|S24 Ultra.</span>
                                    </p>
                                    :
                                    props.partner === 'xiaomigr' ?
                                        <p className='greyText mb-0 ml-3 text-center text-md-left' style={{fontWeight: '400', fontSize: '12px', lineHeight: '16px', color: '#4d4d4d'}}>
                                            Η κατάσταση του smartphone σου δεν υπάγεται στη λίστα της Pandas με οικονομικό αντίτιμο για trade-in. Παρ’ όλα αυτά έχεις 100€ κέρδος από τη Xiaomi  επειδή αγόρασες μια απο τις νέες συσκευές Xiaomi 13 ή 13 Pro.
                                        </p>
                                    :
                                        <p className='greyText mb-0 ml-3 text-center text-md-left' style={{fontWeight: '400', fontSize: '12px', lineHeight: '16px', color: '#4d4d4d'}}>
                                            Το smartphone σου δεν είναι στη λίστα συσκευών της Pandas με οικονομικό αντίτιμο για trade-in.
                                        </p>
                                    }
                                </div>
                                
                                }
                            </div>
                            <Row className='align-items-center justify-content-center justify-content-md-start text-center text-md-left mt-4 pl-md-3'>
                                <div className='samsungOffer'>
                                    <p style={{fontWeight: '600', marginBottom: '2px'}}>Τελική προσφορά</p>
                                    <div style={{fontSize: '53px', fontWeight: '800', lineHeight: '47px', marginBottom: '15px'}}>{finalPrice + parseInt(promoPrice)}€</div>
                                </div>
                            </Row>
                            <Row className="justify-content-center justify-content-md-start">
                                <Col className=' mt-1 mt-md-3' xs="8" sm="8" md="8" lg="7" xl="7">
                                    {props.partner==='samsunggr' ?
                                        <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${nextPage}`} className="">
                                            <div className={`${props.partner}Next next px-3 mt-4 mb-3 text-center`}>Κάνε trade-in</div>
                                        </Link> 
                                    :
                                        <Link to={`${process.env.PUBLIC_URL}/${props.partner}/imei`} className="">
                                            <div className={`${props.partner}Next next px-3 mt-4 mb-3 text-center`}>Κάνε trade-in</div>
                                        </Link> 
                                    }   
                                </Col>
                                <p className='pl-md-3 p-width text-center text-md-left' style={{fontSize: '12px', fontWeight: '400', opacity: '0.5'}}>*{props.partner === 'xiaomigr' && <span>Οι τιμές ανανεώνονται κάθε Τρίτη.</span>}  Η προσφορά επιβεβαιώνεται μετά την αξίολόγηση της συσκευής</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Footer back={{ text: `${t('footer.back')}`, path: `/${partner}/${brand}/${model}/${capacity}/${powerson}/${condition}` }}></Footer> */}
        </div>
    );
}

export default withTranslation()(PromoOffer);