import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";
import * as turf from "@turf/turf";
import { withTranslation } from 'react-i18next';
import { GPContext } from "../context";
import { CDN } from "../constants";
import { navigate } from "@reach/router";


const Maplist = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = useContext(GPContext);
    const { loading, error, data } = state;
    const [geolocation, setGeolocation] = useState({});
    const [search, setSearch] = useState(false);
    const condition = sessionStorage.getItem('condition') == 'very_good' ? 'veryGood' : sessionStorage.getItem('condition')


    const partnerMapping = {
        'greenpanda': 'Pandas',
        'abvasilopoulos': 'AB Vasilopoulos',
        'istorm': 'iStorm (GR)',
        'wind': 'Wind',
        'windCash': 'Wind',
        'plaisio': 'Plaisio',
        'istormcy': 'iStorm (CY)',
        'mistore': 'Xiaomi Store',
        'pmmgr': 'Public',
        'mediamarktgr': 'MediaMarkt'
    }

    const reversePartnerMapping = {
        'Pandas': 'greenpanda',
        'AB Vasilopoulos': 'abvasilopoulos',
        'iStorm': 'istorm',
        'Wind': 'wind',
        'Plaisio': 'plaisio',
        'iStorm Cyprus': 'istormcy',
        'Xiaomi Store': 'mistore',
        'Public': 'pmmgr',
        'MediaMarkt': 'mediamarktgr'
    }

    const partnerSubtitle = {
        'greenpanda': 'ΔΕΥ - ΠΑΡ 09:00 - 17:00',
        'abvasilopoulos': 'ΔΕΥ - ΠΑΡ 09:00 - 21:00     ΣΑΒ 11:00 - 19:00',
        'istorm': 'Ισχύει μόνο για μοντέλα iPhone με έκδοση κουπονιού για αγορές εντός καταστήματος',
        'wind': '',
        'windCash': '',
        'plaisio': 'Ισχύει μόνο με έκδοση κουπονιού για αγορές εντός καταστήματος',
        'istormcy': 'Ισχύει μόνο για μοντέλα iPhone με έκδοση κουπονιού για αγορές εντός καταστήματος',
        'mistore': 'Ισχύει μόνο με έκδοση κουπονιού για αγορές εντός καταστήματος',
        'mediamarktgr': 'Ισχύει μόνο με έκδοση κουπονιού για αγορές εντός καταστήματος',
        'pmmgr': 'Ισχύει μόνο με έκδοση κουπονιού για αγορές εντός καταστήματος',
        'pmmcy': 'Ισχύει μόνο με έκδοση κουπονιού για αγορές εντός καταστήματος'
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('type')
    let offers = Object.assign([], (state[`${type}Offers`]))


    let partners = [];
    let offerMap = {};

    if(sessionStorage.getItem('country')==='cyprus'){
        partners = ['istormcy']
        offers.forEach(offer => {
            if(offer.partner === 'istormcy'){
                partners.push(offer.partner)
                offerMap[`${offer.partner}`] = offer;
                offers=[offer];
            }

        });
    }else{
        offers.forEach(offer => {
            partners.push(offer.partner)
            offerMap[`${offer.partner}`] = offer;
        });
    }

    const [locationPartners, setLocationPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState('')

    useEffect(() => {
        let partnerList = []
        state.locations.filter(locations => !search || locations.distance < 30).forEach(location => {
            if (!partnerList.includes(location.partner_name) && location.partner_name) {
                partnerList.push(location.partner_name)
            }
        })
        setLocationPartners(partnerList)
    }, [state.locations])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setGeolocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        });
        if(state.userLocation && state.userLocation.latitude && state.userLocation.longitude){
            userDistance(state.userLocation.latitude, state.userLocation.longitude);
        }
    }, [])

    const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyDrwWAOVkgtQ1pLDi9ma-Tq7RUtqCqAiDg";
    const calculateDistances = (lat, lon) => {
        const from = turf.point([parseFloat(lon), parseFloat(lat)]);
        dispatch({
            type: "SET_LOCATIONS",
            payload: state.locations.map(l => {
                if (!parseFloat(l.lng))
                    return l;
                const to = turf.point([parseFloat(l.lng), parseFloat(l.lat)]);
                const distance = turf.distance(from, to, { units: 'kilometers' });
                l.distance = distance.toFixed(2);
                return l;
            })
        });
    }

    const searchDistance = (lat, lng) => {
        calculateDistances(lat, lng);
        dispatch({ type: "SHARE", payload: { latitude: lat, longitude: parseFloat(lng), code: 0 } })
        setSearch(true)
    }

    const userDistance = (lat, lon) => {
        if (lat && lon) {
            calculateDistances(lat, lon);
            dispatch({ type: "USER_LOCATION", data: { latitude: lat, longitude: parseFloat(lon), code: 0 } })
            dispatch({ type: "CLICK", payload: { latitude: lat, longitude: parseFloat(lon), code: 0 } })
            setSearch(true)
        } else
            return 0;
    }

    const handleClick = (lat, lng, code) => {
        dispatch({ type: "CLICK", payload: { latitude: lat, longitude: lng, code: code }, payload1: lat, payload2: lng, payload3: code })
    }

    const inputChange = (e) => {
        if (e.target.value === '')
            setSearch(false)
    }

    return (
        <div className="pandasmaplist d-block  mx-auto" style={{width:'97%', maxWidth:'500px'}} >
            {state.cashOffers === undefined ? <div></div> :
                <Row className="justify-content-center mx-0 mb-md-5">
                    <Col>
                        {!loading && <>
                            <Row className={`m-0`}>
                                <Col>
                                <p style={{ fontSize: '30px', lineHeight:'1.2' }} className="mt-4 mb-0"><b>Βρες καταστήματα</b></p>
                                <p style={{ fontSize: '16px', lineHeight:'1.2' }} className="mb-4">{type === 'voucher'&& <span>που μπορείς να πάρεις <b>Voucher</b></span>}{type ==='cash' && <span>που μπορείς να λάβεις <b>κατάθεση</b></span>}</p>
                                </Col>
                            </Row>
                            <Row className={`mx-0 ${props.openMap?'mb-3':'mb-3'} mb-md-4`}>
                                <Col xs="10" className="p-0">
                                    <div className="pandasmyInput">
                                        <img src={`${CDN}/pandas-website/faq/search-icon.svg`} alt={'shearch icon'} width='16' height='16' className="ml-3" />
                                        <Autocomplete id="pandasmap"
                                            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                            style={{ width: '80%', textAlign: 'start', height: '38px' }}
                                            onChange={inputChange}
                                            onPlaceSelected={(place) => {
                                                if (place.geometry) {
                                                    var lat = place.geometry.location.lat();
                                                    var lng = place.geometry.location.lng();
                                                    searchDistance(lat, lng);
                                                }
                                            }}
                                            options={{
                                                types: [],
                                                componentRestrictions: { country: ['gr', 'cy'] }
                                            }}
                                            placeholder={"Αναζήτηση τοποθεσίας..."}
                                        />
                                    </div>
                                </Col>
                                <Col xs="2" style={{ width: 'fit-content', margin: 'auto 0', paddingLeft:'0' }}>
                                    <a className="pandasshare" href="#" onClick={() => userDistance(geolocation.latitude, geolocation.longitude)}>
                                        <img src={`${CDN}/pricing-app/icons/path-icon-blackbackground.svg`} alt={'find_location'} width='40' height='40' />
                                    </a>
                                </Col>
                            </Row>
                            {type === "cash" && state.cashOffers[0] && (state.cashOffers[0]?.price >= 150) &&
                                <Row onClick={() => navigate("courier")} className="courierBanner mx-0 my-4">
                                    <Col xs="8" sm="7" md="9" lg="7" style={{zIndex: "1"}}>
                                    <div className="thicker fs20 mb-4">
                                        <span style={{fontFamily: "ThickerBold", color: "#FFEF5D"}}>Στείλε</span> μας δωρεάν τη συσκευή σου με courier.
                                    </div>
                                    <div className="courierBannerBtn fw500 d-flex">
                                        <span>Ξεκίνα εδώ</span>
                                        <div><img className="ml-3 mb-1" width={7} src={`${CDN}/emails/icons/arrow-white.png`} alt="arrow-next" /></div>
                                    </div>
                                    </Col>
                                    <img className="courierBannerImg" src={`${CDN}/pricing-app/photos/courier-banner.svg`} alt="courier-banner" />
                                </Row>}
                            { offers.filter((offer) => locationPartners.includes(partnerMapping[offer.partner])).map((offer, index) => <>
                            { state.locations.filter(locations => parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38 && parseInt(locations.store) !== 71 && parseInt(locations.store) !== 155 && locations.partner_name === partnerMapping[offer.partner]  && (locations.distance == null || locations.distance<30)).length > 0 && 
                            <div className={` ${props.openMap?'d-none':'d-block'} d-md-block`} id={`${type} map location`} style={offer.partner == selectedPartner? {border: "2px solid #F2F2F2", borderRadius:"8px", paddingInline: '13px', cursor: 'pointer'}:{border: "2px solid #fff", borderRadius:"8px", paddingInline: '13px', cursor: 'pointer'}}>
                                <Row id={`${type} map location`} style={{ paddingBlock: '8px', marginInline: 0, verticalAlign: 'center' }} key={index} onClick={() => {setSelectedPartner(selectedPartner!=offer.partner?offer.partner: '');  window.mapLocationPartner = offer.partner; window.dataLayer.push({ event: 'Map Partner Click' });}}>
                                    <Col id={`${type} map location`} xs="1" className="p-0 pt-2">
                                        <div id={`${type} map location`} className="pt-1 mr-2"><img id={`${type} map location`} src={CDN + `/pricing-app/icons/right_arrow.png`} alt="info" width="12" /></div>
                                    </Col>
                                    <Col id={`${type} map location`} xs="2" lg="2" className='pl-0 pr-3'>
                                        <div id={`${type} map location`} className="justify-content-center"><img id={`${type} map location`} src={CDN + `/pricing-app/logos/${offer.partner}_block.png`} alt="info" width="56" /></div>
                                    </Col>
                                    <Col id={`${type} map location`} className="pr-0">
                                    <Row id={`${type} map location`} style={{ borderBottom: "1px solid #F2F2F2", paddingBottom:"22px", marginInline:"5px"}}>
                                    <Col id={`${type} map location`} xs="7">
                                        <p id={`${type} map location`} style={{ fontSize: '20px', margin: '0', lineHeight:'1.2', paddingBottom:'5px' }}><b id={`${type} map location`}>{ partnerMapping[`${offer.partner}`].replace(' Vasilopoulos', ' Βασιλόπουλος').replace('Wind', 'Nova')}</b></p>
                                        {offer.type === 'voucher' && <div id={`${type} map location`} style={{ background: '#FFEF5D33', color: '#998A00', fontSize: '10px', width: 'fit-content'}}>VOUCHER</div>}
                                        {offer.type === 'cash' && <div id={`${type} map location`} style={{ background: '#E5FFEC', color: '#009926', fontSize: '10px', width: 'fit-content'}}>ΚΑΤΑΘΕΣΗ</div>}
                                    </Col>
                                    <Col id={`${type} map location`} xs="4" className="text-right p-0">
                                        <div id={`${type} map location`} style={{ fontSize: '24px' }}><b id={`${type} map location`}> {offer.price + offer.coupon + offer.promo}€</b></div>
                                        { offer.buyPrices[condition] && offer.buyPrices[condition] < offer.price  &&
                                            <div id={`${type} map location`} style={{ color: '#B3B3B3', fontSize: '16px' }}><del id={`${type} map location`}>{offer.buyPrices[condition]}€</del></div>
                                        }
                                    </Col>
                                    <Col>
                                        <p id={`${type} map location`} style={{color:'#808080', fontSize:'12px', lineHeight:'1.2'}} className="mb-0 mt-2">{partnerSubtitle[offer.partner]}</p>
                                    </Col>
                                    </Row>
                                    </Col>
                                </Row>
                                {state.locations.filter(locations => parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38 && parseInt(locations.store) !== 71 && parseInt(locations.store) !== 155 && locations.partner_name === partnerMapping[offer.partner] && selectedPartner==offer.partner && (locations.distance == null || locations.distance<30)).sort((a, b) => a.distance - b.distance).map((location, index) =>
                                <Row style={{ paddingBlock: '16px', marginInline: 0, verticalAlign: 'center', lineHeight: '1.2' }} className="align-items-center" key={index} onClick={() => handleClick(location.latitude, location.longitude, parseInt(location.store))} onMouseEnter={() => handleClick(location.latitude, location.longitude, parseInt(location.store))}>
                                    <Col>
                                        <Row>
                                            <Col >
                                                <p style={{ fontSize: '16px', margin: '0', fontWeight: '600' }}>{location.name_gr}</p>
                                            </Col>
                                        </Row>
                                        <Row className="pt-1">
                                            <Col xs="12">
                                            <a className="directions" href={location.location} target="_blank" rel="noreferrer"><u style={{ fontSize: '12px', margin: '0', color: '#808080' }}>{location.address_gr}</u></a>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {search && <Col xs="3" className="text-right pr-2">
                                        <p style={{fontSize:'15px', margin:'0'}}>{location.distance}km</p>
                                    </Col>}
                                </Row>
                                )}
                            </div> }</>
                            )}
                        </>}
                        <Row className={`mb-5 mb-md-0 ${props.openMap?'d-none':'d-block'} d-md-block`}>
                            <Col className={`  text-center`}>
                                {type=='voucher' && <a id="cashOption" href={'./offerLocations?type=cash'}><u style={{color:'#808080', fontSize:'12px'}}>Προτιμάς Κατάθεση έως {state.cashOffers[0].price}€ ;</u></a>}
                                {type=='cash' && <a id="voucherOption" href={'./offerLocations?type=voucher'}><u style={{color:'#808080', fontSize:'12px'}}>Προτιμάς Voucher έως {state.voucherOffers[0].price}€ ;</u></a>}
                            </Col>
                        </Row>
                        <div className={`m-auto mb-5 mb-md-5 ${props.openMap?'d-none':'d-block'} d-md-none`} style={{color:'transparent'}}><>_</></div>
                    </Col>
                </Row>}
        </div >
    );
}

export default withTranslation()(Maplist);