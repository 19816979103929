import { Row, Col, InputGroup, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext, Suspense } from "react";
import { navigate } from '@reach/router';
import { GPContext } from "../context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CDN } from '../constants'


const ImeiCode = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = useContext(GPContext);
    var partner = sessionStorage.getItem('partner');
    var brand = sessionStorage.getItem('brand');
    var model = sessionStorage.getItem('model');
    var condition = sessionStorage.getItem('condition');
    var capacity = sessionStorage.getItem('capacity');
    useEffect(() => {
        console.log(sessionStorage.getItem('path'))
        window.dataLayer.push({ event: 'Step Type Promo Code'});
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'type promo code';


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState(false)
    const [promoCode, setPromoCode] = useState('')

    const handlePromo = (e) => {
        setPromoCode(e.target.value);
        setPromoError('')
    }   

    const [promoError, setPromoError] = useState('');
    const [styleError, setStyleError] = useState('');

    
    const validation = (e) => {
        e.preventDefault();
        if(promoCode){
            axios.get(`https://imei-service.greenpanda.io/promos?code=${promoCode}&partner=xiaomigr`).then(res => {
                // let res.data.result = 'promo_already_used';
                // let res = {data: {
                //     result: ''
                // }};
                if(res.data.result === 'promo_already_used'){
                    setPromoError('Το κουπόνι που σχετίζεται με το ΙΜΕΙ έχει εξαργυρωθεί');
                    setStyleError('error');
                }else if(res.data.result === 'unknown_promo') {
                    setPromoError('Το ΙΜΕΙ δεν είναι έγκυρο ή δεν συμμετέχει στην προσφορά');
                    setStyleError('error');
                }else{
                    sessionStorage.setItem("promoCode", promoCode);
                    // sessionStorage.setItem('promoPrice', res.data.amount);
                    setStyleError('');
                    navigate(process.env.PUBLIC_URL+`/${props.partner}/courier/`);
                }      
                
            });
        }
    }

    const back = () => {
        navigate(sessionStorage.getItem('path'));
        // navigate(`/${props.partner}/offer/`);
    }

    return(
        <div>
            <div className='text-center'>
                <h1 style={{fontWeight: '700', fontSize: '32px', lineHeight: '40px', marginTop: '50px', marginBottom: '20px'}}>Συμπλήρωσε το ΙΜΕΙ<br/> του νέου σου smartphone. </h1>
                <p className='m-auto' style={{fontSize: '14px', fontWeight: '500'}}>Συμπλήρωσε τον 15ψήφιο αριθμό ΙΜΕΙ του νέου σου<br/>  Xiaomi 13 ή Xiaomi 13 Pro </p>
            </div>
           
            <Row className='justify-content-center text-center samsungInput mt-2' style={{marginBottom: '50px', borderBottom: '1.5px solid #e6e6e6'}}>
                <div>
                    <form onSubmit={validation} className='floatingForm mt-4' style={{width: '340px'}}>
                        <label className={`${styleError}`} for="code" style={{width: '100%'}}>
                            <input type="text" id="imei" placeholder="IMEI" onChange={handlePromo} required/>
                            <span>IMEI</span>
                        </label>
                        <p style={{height: '20px', margin: '0px 0 5px 15px'}}>{promoError}</p>
                        <div className='text-center'>
                            {<button id='form' disabled={true} className={`${props.partner}Next ${props.partner}Border formButton`} style={{marginBottom: '20px'}} type='submit' >Επιβεβαίωση</button>}
                        </div>
                        <p className='mt-0'>H προωθητική ενέργεια έχει λήξει</p>
                    </form>
                </div>

            </Row>
            <Row className='text-center justify-content-center flex-column' style={{marginBottom: '100px'}}>
                <h3 className='' style={{fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '55px'}}>Πώς θα βρεις το IMEI του νέου σου smartphone</h3>
                <Row className='flex-column flex-md-row'>
                    <Col>
                        <div><img src={CDN + "/pricing-app/partners/xiaomigr/find-imei.svg"} alt='find imei' /></div>
                    </Col>
                    <Col className='text-md-left pr-0'>
                        <h4 style={{fontSize: '16px', fontWeight: '600'}} className="mb-4 mt-3 mt-md-0">Στην νέα σου συσκευή ακολούθησε τα βηματα:</h4>
                        <div className='imeiSteps'>
                        <div className='d-flex mb-3'>
                            <div  className='numberCircle mr-3'>1</div>
                            <div>Ρυθμίσεις</div>
                        </div>
                        <div className='d-flex mb-3'>
                            <div className='numberCircle mr-3'>2</div>
                            <div>Σχετικά με το τηλέφωνο</div>
                        </div>
                        <div className='d-flex mb-3'>
                            <div className='numberCircle mr-3'>3</div>
                            <div>Όλα τα χαρακτηριστικά</div>
                        </div>
                        <div className='d-flex mb-3'>
                            <div className='numberCircle mr-3'>4</div>
                            <div>Κατάσταση</div>
                        </div>
                        <div className='d-flex mb-3'>
                            <div className='numberCircle mr-3'>5</div>
                            <div>Κάνε copy το IMEI (το πρώτο IMEI)</div>
                        </div>
                        <div style={{marginTop: '25px'}}>
                            <b style={{fontWeight: '600', fontSize: '16px'}}>ή βρες το επάνω στη συσκευασία της συσκευής</b>
                        </div>
                        </div>
                    </Col>
                </Row>
            </Row>
            
            
            {/* <div className="footer row justify-content-end align-items-center px-3 px-md-5">
                <div className="d-flex">
                    <div className="back backEmail mr-3 mr-md-5" onClick={back}>
                        {t('footer.back')}
                    </div>
                    <div className={`${props.partner}Next ${props.partner}Border next px-3  foot`} onClick={validation}>{t('email.getOffer')}</div>
                </div>
            </div> */}
        </div>
    );
}


export default withTranslation()(ImeiCode);