import { Link } from "@reach/router";
import { Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { GPContext } from "../context";
import {CDN} from '../constants'

const Categories = (props) => {
    const { t } = props;
    const { state, dispatch } = React.useContext(GPContext);

    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brands = state.brands.sort((a, b) => a.order > b.order).map(a => a.brand);

    if (partner === 'mistore')
        brands = ['Xiaomi'].concat(brands.filter(a => a !== 'Xiaomi'));


    console.log(brands)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Categories' });
    }, [])

    const partner_link = {
        'istorm': 'getDevicesIstorm.php',
        'istormcy': 'getDevicesIstormCy.php',
    }
    const chooseCategory = (category) => {
        var url = {
            ipad: `https://server.greenpanda.io/scripts/getDevicesIstormIpad.php?partner=${partner}`,
            iphone: `https://server.greenpanda.io/scripts/${partner_link[partner]}`,
            smartwatch: `https://server.greenpanda.io/scripts/getDevicesSmartwatches.php`
        }
        sessionStorage.setItem('device_category', category);
        dispatch({
            type: "SET_CATEGORY",
            payload: category
        });
        dispatch({
            type: "SET_DEVICES",
            payload: null
        });
        axios.get(url[category]).then(res => {
            dispatch({
                type: "SET_DEVICES",
                payload: res.data
            });
        })
    }



    window.step = 'categories';

    return (
        <div id="brands">
            <Row className="justify-content-md-center mb-4">
                <Col lg="12" className="text-center"><h1 className="title">{t('categories.title')}</h1></Col>
            </Row>
            <Row className="justify-content-center mb-5">
                <Col lg={3} md={4} xs={11} className="mb-3 " key={'iPhone'}>
                    <Link to={`${process.env.PUBLIC_URL}/${props.partner}/apple`} onClick={() => {
                        dispatch({ type: "SAVE", data: { brand: 'apple' } });
                        chooseCategory('iphone');
                    }}>
                        <div className={`${partner}Item item brandItem text-center`}>
                            <Row style={{ height: "100%" }}>
                                <Col lg={12} md={12} xs={4} className="pt-md-4 pt-2">
                                    <img src={CDN + "/categories/iphone.jpg"} alt={'iphone'} height="40px" className="brandLogo" />
                                </Col>
                                <Col lg={12} md={12} xs={8} className="pt-3 text-left text-md-center">
                                    iPhone
                                </Col>
                            </Row>
                        </div>
                    </Link>
                </Col>
                <Col lg={3} md={4} xs={11} className="mb-3 " key={'iPad'}>
                    <Link to={`${process.env.PUBLIC_URL}/${props.partner}/apple`} onClick={() => {
                        dispatch({ type: "SAVE", data: { brand: 'apple' } });
                        chooseCategory('ipad');
                    }}>
                        <div className={`${partner}Item item brandItem text-center`}>
                            <Row style={{ height: "100%" }}>
                                <Col lg={12} md={12} xs={4} className="pt-md-4 pt-2">
                                    <img src={CDN + "/categories/ipad.jpg"} alt={'ipad'} height="40px" className="brandLogo" />
                                </Col>
                                <Col lg={12} md={12} xs={8} className="pt-3 text-left text-md-center">
                                    iPad
                                </Col>
                            </Row>
                        </div>
                    </Link>
                </Col>
                {process.env.REACT_APP_SMARTWATCH_ENABLED.toLowerCase() === 'true' && <Col lg={3} md={4} xs={11} className="mb-3 " key={'appleWatch'}>
                    <Link to={`${process.env.PUBLIC_URL}/${props.partner}/apple`} onClick={() => {
                        dispatch({ type: "SAVE", data: { brand: 'apple' } });
                        chooseCategory('smartwatch');
                    }}>
                        <div className={`${partner}Item item brandItem text-center`}>
                            <Row style={{ height: "100%" }}>
                                <Col lg={12} md={12} xs={4} className="pt-md-4 pt-2">
                                    <img src={CDN + "/categories/smartwatch.png"} alt={'apple watch'} height="40px" className="brandLogo" />
                                </Col>
                                <Col lg={12} md={12} xs={8} className="pt-3 text-left text-md-center">
                                    Apple Watch
                                </Col>
                            </Row>
                        </div>
                    </Link>
                </Col>}

            </Row>
        </div >
    );
}

export default withTranslation()(Categories);