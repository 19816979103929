import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, navigate  } from "@reach/router";
import { GPContext } from "../context";
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { redirectAfterTimeout } from '../viewHelperFunctions';
import {CDN} from '../constants'
import Footer from './footer';
import { replaceSpecialChars } from '../viewHelperFunctions';

const Yougr = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    let instore = sessionStorage.getItem('instore');
    let modelImage = replaceSpecialChars(model)
    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return 'Πολή Καλή';
        if (c === 'good') return 'Καλή';
        if (c === 'poor') return 'Μέτρια';
        if (c === 'faulty') return 'Κακή';
    }

    useEffect(()=>{
        dispatch({ type: 'price', payload: finalPrice })
    }, [finalPrice])

    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl
    }

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Offer Page' });
        sessionStorage.setItem('price', finalPrice);
        if(instore){
            redirectAfterTimeout('/' + props.partner);
        }
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = sessionStorage.getItem('lang');
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'offer';
    window.price = finalPrice;

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
    }, []);

    const [open1, setOpen1] = useState(false);


    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">Παρακαλώ περιμένετε...</h3>
                        <div className={`${partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        } return (
            <div>
                <Row className="justify-content-center">
                    <Col xs="11" className='mb-4'>    
                        <Row  className="justify-content-around align-items-left sideBorder pt-5 flex-column-reverse flex-md-row">
                            <Row className="d-md-none  justify-content-center justify-content-md-start">
                                
                                <Col xs="9" sm="9" md="12" lg="8" xl="7">
                                    <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/courier`} className="">
                                        <div className="yougrNext next  mt-4 mb-2 text-center pb-3 pt-3" style={{fontSize: '20px'}}>Κλείσε ραντεβού πώλησης <img className='ml-3' src={`${CDN}/pricing-app/icons/next_arrow_icon.png`} alt="arrow" width='25px'></img></div>
                                    </Link>    
                                </Col>
                                <Col xs="9" className='mb-5'>
                                    <div className="d-flex align-items-center justify-content-start pt-3">
                                        <div className="mr-3">
                                            <img src={`${CDN}/pricing-app/icons/info_inactive_icon.svg`} alt="info" width='25px'></img>
                                        </div>
                                        <div>
                                            <div className="text-left d-md-block fs10" style={{ fontSize: '14px', opacity: '0.5' }}>*Η τελική τιμή καθορίζεται αφού αξιολογηθεί η κατάσταση της συσκευής και η υγεία της μπαταρίας.</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-md-none  justify-content-center justify-content-md-start">
                                
                            </Row>
                            <Col md="4" className="text-center">
                                <div className=' d-md-flex flex-column justify-content text-center mt-2 mt-md-0' style={{height:'100%'}}>
                                    <div className='text-center'>
                                        {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand + "/" + model.replaceAll('-', '_') + ".jpg"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                                        {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand + "/" + modelImage + ".png"}  onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                                    </div>
                                    <div className="d-flex flex-column flex-md-row align-items-center mt-4 justify-content-center">
                                        {condition === 'very_good' && <div style={{ backgroundColor: '#f4e6ff', padding: '10px 15px', borderRadius: '10px' }} className="d-flex align-items-center">
                                            <div className="mr-3"><img src={CDN + "/pricing-app/icons/very_good-icon.svg"} alt="warning" width="27" /></div>
                                            <div style={{ fontSize: '14px' }}><b>Σαν Καινούρια</b></div>
                                        </div>}
                                        {condition === 'good' && <div style={{ backgroundColor: '#E4F3FF', padding: '15px 20px', borderRadius: '10px' }} className="d-flex align-items-center">
                                            <div className="mr-3"><img src={CDN + "/pricing-app/icons/good-icon.svg"} alt="warning" width="27" /></div>
                                            <div style={{ fontSize: '14px' }}><b>Καλή Κατάσταση</b></div>
                                        </div>}
                                        {condition === 'poor' && <div style={{ backgroundColor: '#FFF5E8', padding: '15px 20px', borderRadius: '10px' }} className="d-flex align-items-center">
                                            <div className="mr-3"><img src={CDN + "/pricing-app/icons/poor-icon.svg"} alt="warning" width="27" /></div>
                                            <div style={{ fontSize: '14px' }}><b>Μέτρια Κατάσταση</b></div>
                                        </div>}
                                        {condition === 'faulty' && <div style={{ backgroundColor: '#FFE6E2', padding: '15px 20px', borderRadius: '10px' }} className="d-flex align-items-center">
                                            <div className="mr-3"><img src={CDN + "/pricing-app/icons/faulty-icon.svg"} alt="warning" width="27" /></div>
                                            <div style={{ fontSize: '14px' }}><b>Ελαττωματική Κατάσταση</b></div>
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                            <Col md="7" lg="7" className="text-center text-md-left mt-md-0 mb-4">
                                <div className='fs22 ml-lg-2' style={{ fontSize: '24px', fontWeight: '300', lineHeight: '35px' }}><b>Πούλησέ το</b></div>
                                <h5 style={{ fontSize: '30px', lineHeight: '35px'}} className="d-none d-md-block title text-left mb-4 fs22 ml-lg-2">{device.name}{device.brand === 'Apple' && ', ' + capacity}</h5>
                                <h6 style={{ fontSize: '26px'}} className="title d-md-none text-center mb-1 fs22 ml-lg-2">{device.name}{device.brand === 'Apple' && ', ' + capacity}</h6>
                                
                                <div className='greyBox mt-4 pt-2 pt-md-0 pb-3 pb-md-0 mt-md-3 pl-0'>
                                    <div className="ml-md-3 mr-md-2 mt-2 d-md-block pt-2 fw500" style={{ fontSize: '34px', lineHeight: '35px' }}>Πάρε έως <b style={{ fontSize: '38px', color: '#4960F2' }} className="fs35">{offer.finalPrice}</b><b style={{ fontSize: '24px', color: '#4960F2' }}>€</b></div>
                                    <div className="ml-md-3 mr-md-2 mt-lg-0 d-md-block mb-3" ><span style={{ fontSize: '28px' }} className="fs22"> για αγορές στο <b style={{ fontSize: '28px', color: '#FFC439' }} className="fs20">you.gr</b></span></div>
                                </div>
                                <Col md="0" className='mt-3 d-none d-md-block'>
                                    <div className="d-flex align-items-center justify-content-start pt-3">
                                        <div className="mr-3">
                                            <img src={`${CDN}/pricing-app/icons/info_inactive_icon.svg`} alt="info" width='25px'></img>
                                        </div>
                                        <div>
                                            <div className="text-left d-md-block" style={{ fontSize: '13px', opacity: '0.5' }}>*Η τελική τιμή καθορίζεται αφού αξιολογηθεί η κατάσταση της συσκευής και η υγεία της μπαταρίας.</div>
                                        </div>
                                    </div>
                                </Col>
                                <Row className="d-none d-md-block justify-content-center justify-content-md-start">
                                    <Col xs="8" sm="8" md="12" lg="8" xl="7">
                                        <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/courier`} className="">
                                            <div className="yougrNext next px-3 mt-4 mb-4 text-center">Κλείσε ραντεβού πώλησης <img className='ml-3' src={`${CDN}/pricing-app/icons/next_arrow_icon.png`} alt="arrow" width='25px'></img></div>
                                        </Link>
                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
                <Row className="d-flex justify-content-center mt-5 " style={{paddingBottom: '200px'}}>
                    <Col xs="11" md="10" className="text-center">
                        <h3 className='text-left' style={{color: '#4960F2', fontSize: '20px', fontWeight: '500'}}>Συχνές ερωτήσεις</h3>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι είναι το πρόγραμμα <span style={{color: '#FFC439'}}>you.gr </span>trade-in;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                    <p>Θέλεις έκπτωση στην επόμενη αγορά σου στο you.gr;</p>
                                    <p>Τώρα με το πρόγραμμα <span style={{color: '#FFC439'}}>you.gr </span>trade-in, μπορείς να επιστρέψεις το smartphone σου και να πληρωθείς με ηλεκτρονικό κουπόνι αντίστοιχης αξίας, για την επόμενη αγορά σου! Η ειδικά εκπαιδευμένη μας ομάδα θα εκτιμήσει την κατάσταση της συσκευής σου και θα σου δώσει κουπόνι αντίστοιχης χρηματικής αξίας ώστε να το εξαργυρώσεις εντός 6 μηνών στο online κατάστημα του <span style={{color: '#FFC439'}}>you.gr</span>.</p>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Πως μπορώ να επιστρέψω το smartphone μου στο you.gr; </div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                    Το μόνο που χρειάζεται να κάνεις είναι να κλείσεις ραντεβού με το Pandas Scooter. Ο ειδικός της ομάδας μας θα έρθει την ημερομηνία και ώρα που έχεις επιλέξει και σε λιγότερο από 3 λεπτά θα αξιολογήσει τη συσκευή σου και θα σε ενημερώσει για το όφελος που μπορείς να λάβεις. Εάν επιλέξεις να το επιστρέψεις, θα λάβεις εκπτωτικό κουπόνι για τις επόμενες αγορές σου στο you.gr. Τόσο εύκολο.

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι πρέπει να κάνω πριν επιστρέψω το smartphone μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        <p>Η διαδικασία είναι πολύ απλή! Το μόνο που θα χρειαστεί είναι:</p>
                                        <ul className='listStyleNone' style={{padding: '0'}}>
                                            <li>- Η συσκευή σου να βρίσκεται σε λειτουργία και να έχει τουλάχιστον 5% μπαταρία.</li>
                                            <li>- Να απενεργοποιήσεις το λογαριασμό iCloud ή Google account από τη συσκευή.</li>
                                            <li>- Να κάνεις επαναφορά εργοστασιακών ρυθμίσεων  στη συσκευή, ώστε να διαγραφούν όλα  τα δεδομένα και οι ρυθμίσεις από το κινητό.</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left"> Ποιές περιοχές καλύπτει το πρόγραμμα;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Το Pandas Scooter αυτή τη στιγμή εξυπηρετεί το μεγαλύτερο μέρος της Αττικής. Μπορείς να ελέγξεις εάν καλύπτουμε την περιοχή σου 
                                            <a href={`/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/scooter`}> <u style={{color: '#FFC539'}}><b>εδώ</b></u></a>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Ποιες συσκευές συμμετέχουν στο πρόγραμμα;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Μπορείς να δεις όλα τα smartphone που συμμετέχουν στο πρόγραμμα <a href={`/${props.partner}`}><u style={{color: '#FFC539'}}><b>εδώ</b></u></a>. Στην περίπτωση που δεν βλέπεις το μοντέλο που σε ενδιαφέρει στη λίστα μας, σημαίνει ότι τη χρονική αυτή στιγμή δεν δίνεται χρηματικό ποσό για την επιστροφή του.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι όφελος θα έχω επιστρέφοντας το smartphone μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η αξία του κάθε smartphone εξαρτάται από το μοντέλο, τη χρονολογία, την κατάσταση στην οποία βρίσκεται και την τρέχουσα τιμή της στην αγορά ως μεταχειρισμένη συσκευή
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Πρέπει η συσκευή να είναι λειτουργική προκειμένου να την επιστρέψω;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Ναι, η συσκευή σου θα πρέπει να είναι λειτουργική και ενεργή, προκειμένου να λάβεις την αξία που της αντιστοιχεί με βάση την κατάστασή της.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Πώς πληρώνομαι για την πώληση του smartphone μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        <p>Η πληρωμή σου πραγματοποιείται με τη μορφή ηλεκτρονικού κουπονιού έκπτωσης, για την επόμενη αγορά σου στο <span style={{color: '#FFC439'}}>you.gr</span>.</p>
                                        <p>Πιο αναλυτικά, με την πώληση της συσκευής σου, λαμβάνεις άμεσα το ηλεκτρονικό σου κουπόνι, το οποίο περιέχει ένα μοναδικό κωδικό, που εξαργυρώνεις στο καλάθι αγορών σου, στο <span style={{color: '#FFC439'}}>you.gr</span>.</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Ποιά είναι η προθεσμία εξαργύρωσης του κουπονιού μου, από την επιστροφή του smartphone μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Το κουπόνι που λαμβάνεις, έχει ισχύ 6 μήνες και μπορείς να το εξαργυρώσεις στην επόμενη αγορά σου στο <span style={{color: '#FFC439'}}>you.gr</span>.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι γίνεται με τα προσωπικά μου δεδομένα;</div>
                                            <div><img src={CDN + "/pricing-app/partners/yougr/less_icon.png"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Πριν παραδώσεις το smartphone σου, προτείνουμε να κάνεις backup τη συσκευή σου και να πραγματοποιήσεις επαναφορά δεδομένων και ρυθμίσεων. Ωστόσο, όλες οι συσκευές περνούν από μια συγκεκριμένη διαδικασία διαλογής μετά την παραλαβή. Στην περίπτωση όπου δεν έχει γίνει επαναφορά, το εξειδικευμένο μας προσωπικό πραγματοποιεί μια αυτοματοποιημένη διαδικασία επαναφοράς για να διασφαλίσει την προστασία και την οριστική διαγραφή των προσωπικών σου δεδομένων.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <div className="footer row justify-content-center justify-content-md-end align-items-center px-3 px-md-5">
                <Link to={`${process.env.PUBLIC_URL}/${props.partner}`} className={`back mr-3 mr-md-5`}>Νέα προσφορά</Link>
                <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/courier`} className={`next foot yougrNext`}>Κλείσε ραντεβού</Link>
                </div>
            </div >
        );
}


export default Yougr;