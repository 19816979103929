import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, navigate  } from "@reach/router";
import { GPContext } from "../context";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { redirectAfterTimeout } from '../viewHelperFunctions';
import {CDN} from '../constants'

const Pmm = (props) => {
    const { state, dispatch } = React.useContext(GPContext);

    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    let instore = sessionStorage.getItem('instore');
    const promoDevices = ['Apple iPhone 15 Pro', 'Apple iPhone 15 Pro Max', 'Apple iPhone 15 Plus', 'Apple iPhone 15', 'Apple iPhone 14 Pro', 'Apple iPhone 14 Pro Max', 'Apple iPhone 14 Plus', 'Apple iPhone 14', 'Apple iPhone 13 Pro', 'Apple iPhone 13 Pro Max', 'Apple iPhone 13', 'Apple iPhone 13 mini', 'Apple iPhone 12 Pro Max', 'Apple iPhone 12 Pro', 'Apple iPhone 12', 'Apple iPhone 12 mini', 'Apple iPhone 11 Pro Max', 'Apple iPhone 11 Pro', 'Apple iPhone 11','Apple iPhone SE (2022)', 'Apple iPhone SE (2020)']

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return 'Πολή Καλή';
        if (c === 'good') return 'Καλή';
        if (c === 'poor') return 'Μέτρια';
        if (c === 'faulty') return 'Κακή';
    }
    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    if(promoDevices.includes(device.name)) finalPrice = finalPrice + 50;
    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl
    }

    window.brand = brand;
    window.model = model;
    window.capacity = capacity.toUpperCase();
    window.working = powerson;
    window.condition = condition;
    window.step = 'offer';
    window.price = finalPrice;


    useEffect(() => {
       window.dataLayer.push({ event: 'Step Offer Page' });
       dispatch({ type: 'partner', payload: state.partner })
        if(instore){
            redirectAfterTimeout('/' + props.partner);
        }
    }, [])

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
    }, []);

    const [open1, setOpen1] = useState(false);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">Παρακαλώ περιμένετε...</h3>
                        <div className={`${partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        } return (
            <div>
                <Row className="justify-content-center">
                    <Col xs="12" md="11" lg="8" className="text-center mb-2">
                        <div className="title">Αποχαιρέτησε το {device.name} και καλωσόρισε <span style={{ color: "#2AAF9D" }}> νέα προϊόντα </span> με μεγάλο <span style={{ color: "#2AAF9D" }}> όφελος</span>!</div>
                        {/* <h5 className="title d-md-none mb-3"><b>Αποχαιρέτησε το {device.name} και καλωσόρισε <span style={{ color: "#2AAF9D" }}> νέα προϊόντα </span> με μεγάλο <span style={{ color: "#2AAF9D" }}> όφελος</span>!</b></h5> */}
                    </Col>
                    <Row className="ml-1 ml-md-0" style={{position: 'relative', width: '100%', maxWidth: '789px', padding: '30px', border: '1px solid #e6e6e6', borderRadius: '16px', boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)', marginBlock: '40px 30px'}}>
                        <div style={{position: 'absolute', right: '3px', top:'3px', borderRadius: '0 13px', backgroundColor: 'rgba(245, 80, 35, 0.20)', color: '#f55023', padding: '5px 10px', fontWeight: '700'}}>PROMO</div>
                        <Col xs="7" md="5" className="mx-auto">
                            <img src={CDN + "/pricing-app/photos/imagecollection.png"} style={{ width: "100%", maxWidth: '238px' }} alt="Phone" />
                        </Col>
                        <Col xs="12" md="7" className='text-center text-md-left d-flex flex-column justify-content-center align-items-center align-items-md-start'>
                            <p className='mt-3 mt-md-0' style={{fontWeight: '700', marginBottom: '0'}}>Απόκτησε τη νέα σου συσκευή</p>
                            <p style={{fontWeight: '700', fontSize: '32px', color: '#f55023', lineHeight: '38px'}}>έως και {finalPrice}€ φθηνότερα</p>
                            <div style={{maxWidth: '260px'}}>
                                {instore ? <Link to={`${process.env.PUBLIC_URL}/${props.partner}`} className="">
                                    <div className="pmmGreenNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                                </Link>
                                :
                                <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator`} className="">
                                    <div className="pmmGreenNext next px-4 mt-4">Βρες ένα κατάστημα Public</div>
                                </Link>
                                }
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="justify-content-center align-items-center">
                        <Col xs="8" md="6" className="text-center text-md-right mb-">
                            <img src={CDN + "/pricing-app/photos/imagecollection.png"} style={{ width: "100%" }} alt="Phone" />
                        </Col>
                        <Col xs="12" md="6" className="d-flex flex-column align-items-center">
                            <h5 className="text-center text-md-left mt-2 mt-md-0 mb-0">Πάρε <b>δωροεπιταγή</b> <br /> <b>Pandas</b> έως <span style={{ fontSize: '30px', fontWeight: 'bold' }}> {finalPrice}€</span></h5>
                            {instore ? <Link to={`${process.env.PUBLIC_URL}/${props.partner}`} className="">
                                <div className="pmmGreenNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                            </Link>
                            :
                            <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator`} className="">
                                <div className="pmmGreenNext next px-4 mt-4">Βρες ένα κατάστημα Public</div>
                            </Link>
                            }
                        </Col>
                    </Row> */}
                    <Col xs="11" sm="9" md="12" className="text-center mt-2 mb-5">
                        <small>*Η τιμή είναι ενδεικτική, η τελική αξία έκπτωσης θα επιβεβαιωθεί μετά τον έλεγχο της συσκευής στο κατάστημα.</small>
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col xs="12" md="12" className="text-center mb-md-5">
                        <h3 className="d-none d-md-block title">Είναι τόσο απλό!</h3>
                        <h5 className="d-md-none title">Είναι τόσο απλό!</h5>
                    </Col>
                </Row>
                <Row style={{ background: '#F0F3F5' }} className="d-none d-md-block mb-5 py-4">
                    <Col className="d-flex justify-content-center">
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/pmmgr/map_icon.svg"} width="70" alt="map" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα <b>Public</b></div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/pmmgr/smartphone_icon.svg"} width="70" alt="smartphone" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b>, αφού το αξιολογήσουμε</div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/pmmgr/wallet_icon.svg"} width="70" alt="wallet" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                        </Col>
                    </Col>
                </Row>
                <Slider className="d-md-none mt-2 mb-5" {...settings}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/icons/map_icon.svg"} alt="map" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα <b>Public</b></div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/plaisio/smartphone_icon.svg"} alt="smartphone" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b> του αφού το αξιολογήσουμε</div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/icons/wallet_icon.svg"} alt="wallet" height="50px" />
                        <div style={{ maxWidth: '180px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                    </div>
                </Slider>
                <Row className="d-flex justify-content-center">
                    <Col xs="11" md="10" className="text-center">
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι είναι το πρόγραμμα trade&save ;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                    Το Trade & Save είναι ένα πρόγραμμα trade-in που σου επιτρέπει να αποκτήσεις το νέο σου smartphone ή άλλα προϊόντα φθηνότερα. Χάρη σ’ αυτό μπορείς να φέρεις το παλιό σου smartphone σε ένα κατάστημα Public ή Media Markt για επιστροφή, να ενημερωθείς για την αξία του από την ειδικά καταρτισμένη ομάδα μας που θα αναλάβει να εκτιμήσει την κατάστασή του, και να παραλάβεις voucher αντίστοιχης χρηματικής αξίας, το οποίο μπορείς να εξαργυρώσεις εντός 14 ημερών επιλέγοντας όποιο προϊόν θες σε ένα από τα καταστήματά μας.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι πρέπει να κάνω; </div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Το μόνο που χρειάζεται να κάνεις είναι να φέρεις το smartphone που θέλεις να επιστρέψεις σε ένα κατάστημα Public ή Media Markt. Θα αξιολογήσουμε άμεσα τη συσκευή σου και θα σε ενημερώσουμε για το όφελος που μπορείς να λάβεις από το trade-in του κινητού σου βάσει της κατάστασής του. Εάν σε ικανοποιεί η προσφορά, μπορείς στη συνέχεια να αποκτήσεις το νέο σου smartphone ή κάποιο άλλο προϊόν φθηνότερα, με έκπτωση αντίστοιχη της αξίας της παλιάς σου συσκευής.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Ποιες συσκευές συμμετέχουν στο πρόγραμμα;   </div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Μπορείς να δεις τα brands αλλά και τις συσκευές που συμμετέχουν στον μηχανισμό απόσυρσης <a href={`https://pricing.pandas.io/${partner}`}>εδώ</a>. Εάν δε βλέπεις το μοντέλο σου στη λίστα, σημαίνει ότι τη δεδομένη χρονική αυτή στιγμή δεν δίνεται χρηματικό ποσό για το trade-in του, αλλά μπορείς πάντα να μας το φέρεις για ανακύκλωση!
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι όφελος θα έχω επιστρέφοντας το smartphone μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                    Η αξία του κάθε smartphone εξαρτάται από το μοντέλο και την κατάσταση στην οποία βρίσκεται. Ξεκίνα <a href={`https://pricing.pandas.io/${partner}`}>εδώ</a> για να πάρεις προσφορά και να μάθεις την αξία της συσκευής σου.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Πρέπει η συσκευή να είναι λειτουργική προκειμένου να μπορεί να μπει στο πρόγραμμα trade&save;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                    Ναι, η συσκευή σου θα πρέπει να είναι λειτουργική και ενεργή για να λάβεις την αξία που θα αντιστοιχεί στην κατάστασή της όταν τη φέρεις για trade-in. Εάν το smartphone σου δεν είναι λειτουργικό, μπορείς και πάλι να μας το φέρεις, χωρίς όμως να λάβεις κάποιο χρηματικό ποσό, και εμείς θα αναλάβουμε να το ανακυκλώσουμε υπεύθυνα!</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Με ποια κριτήρια καθορίζεται η αξία του smartphone μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Αξιολογούμε τη συσκευή σου με βάση τα παρακάτω κριτήρια:
                                        <ul>
                                            <li>μοντέλο</li>
                                            <li>χρονολογία</li>
                                            <li>φυσική κατάσταση</li>
                                            <li>τρέχουσα τιμή στην αγορά ως μεταχειρισμένη συσκευή</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Αν δεν με ικανοποιεί το ποσό που μου δίνεται ως voucher, μπορώ να ακυρώσω τη συναλλαγή;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Βεβαίως! Εάν για οποιοδήποτε λόγο δε συμφωνείς με το ποσό που σου προσφέρουμε για τη συσκευή σου, μπορείς εύκολα να διακόψεις τη διαδικασία χωρίς καμία επιβάρυνση.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι θα πρέπει να κάνω πριν παραδώσω το smartphone μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                    Αρχικά θα πρέπει να κάνεις αντιγραφή όλων των δεδομένων σου σε κάποιο ασφαλές αποθηκευτικό χώρο και έπειτα να επαναφέρεις τη συσκευή στις αρχικές της ρυθμίσεις. Σε περίπτωση που δεν το κάνεις εσύ, για τη δική σου ασφάλεια θα διαγράψουμε όλα τα δεδομένα της συσκευής σου μόλις την παραλάβουμε. Φυσικά, εάν δυσκολεύεσαι με το back-up, μπορούμε να βοηθήσουμε, αρκεί να έχεις μαζί σου τους κατάλληλους κωδικούς!
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι γίνεται με τα δεδομένα μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Πριν επιστρέψεις το smartphone σου, σου προτείνουμε να κάνεις back-up και επαναφορά εργοστασιακών ρυθμίσεων. Ωστόσο, όλες οι συσκευές που λαμβάνουμε περνούν από μια συγκεκριμένη διαδικασία διαλογής μετά την παραλαβή. Σε περίπτωση επομένως που δεν έχεις κάνει κάποια ενέργεια, η ομάδα μας πραγματοποιεί μια αυτοματοποιημένη διαδικασία επαναφοράς για να διασφαλίσει την προστασία και την οριστική διαγραφή των προσωπικών σου δεδομένων.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μπορώ να αγοράσω όποια συσκευή θέλω;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Ναι! Εφόσον η συσκευή σου είναι λειτουργική, μπορείς να εξαργυρώσεις το voucher σου διαλέγοντας οποιοδήποτε smartphone μέσα από μία μεγάλη ποικιλία <a href={`https://www.public.${partner === 'pmmgr'?'gr':'cy'}/root/tilefonia/kinita-smartphones/smartphone-brands`}>επώνυμων brands</a> όπως <a href={`https://www.public.${partner === 'pmmgr'?'gr':'cy'}/cat/tilefonia/kinita-smartphones/smartphone-brands/samsung-smartphones`}>Samsung</a>, <a href={`https://www.public.${partner === 'pmmgr'?'gr':'cy'}/cat/tilefonia/kinita-smartphones/smartphone-brands/sony`}>Sony</a>, <a href={`https://www.public.${partner === 'pmmgr'?'gr':'cy'}/root/tilefonia/kinita-smartphones/smartphone-brands/xiaomi`}>Xiaomi</a>, <a href={`https://www.public.${partner === 'pmmgr'?'gr':'cy'}/cat/apple/iphone`}>Apple</a>, ή όποιο άλλο προϊόν θες από το δίκτυο καταστημάτων Public και Media Markt.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μπορώ να κάνω την αγορά της νέας μου συσκευής με τιμολόγιο;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Δυστυχώς όχι ακόμα. Εάν αλλάξει κάτι, θα βρεις τις αντίστοιχες πληροφορίες σε αυτήν τη σελίδα.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μέχρι πότε μπορώ να εξαργυρώσω το voucher μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η εξαργύρωση του voucher θα πρέπει να πραγματοποιηθεί μέσα σε 14 ημερολογιακές ημέρες από την ημέρα που έκανες trade-in το smartphone σου.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι έγγραφα χρειάζεται να έχω μαζί μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/pmmgr/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Χρειάζεται να έχεις μαζί την αστυνομική ταυτότητα ή το διαβατήριό σου.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="my-5 py-2 justify-content-center">
                    <Col xs="9" sm="7" md="5" lg="4" xl="3">
                        {instore ? <Link to={`${process.env.PUBLIC_URL}/${instore}`} className="">
                            <div className="pmmGreenNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                        </Link>
                        :
                        <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`} className="">
                            <div className="pmmGreenNext next px-3 mt-4">Βρες ένα κατάστημα Public</div>
                        </Link>
                        }
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="11" md="10">
                        <Row className="py-4" style={{ background: '#05b392af', borderRadius: '20px' }}>
                            <Col className="text-center mb-4">
                                <div className="">
                                    <h3 className="font-weight-bold"><span style={{ color: "#ffff" }}>Έχεις απορίες;</span></h3>
                                    <h6 style={{ color: "#ffff" }} className="font-weight-bold">Είμαστε εδώ για να σου τις λύσουμε.</h6>
                                </div>
                            </Col>
                            <Col xs="12">
                                <Row>
                                <Col className="d-flex justify-content-center" style={{ color: "#ffff" }}>
                                        <b style={{fontSize: '50px'}}>01.</b>
                                </Col>
                                <Col className="d-flex justify-content-center" style={{ color: "#ffff" }}>
                                        <b style={{fontSize: '50px'}}>02.</b>
                                </Col>
                                <Col className="d-flex justify-content-center" style={{ color: "#ffff" }}>
                                        <b style={{fontSize: '50px'}}>03.</b>
                                </Col>
                                </Row>
                                <Row className="d-flex justify-content-between font-weight-bold">
                                    <Col className="d-flex justify-content-center" style={{ color: "#ffff" }}>
                                        <div className="d-flex text-center">Επισκέψου ένα <br/>κατάστημα Public </div></Col>
                                    {partner === 'pmmgr' && 
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center" style={{ color: '#ffff' }}>
                                            <FontAwesomeIcon style={{ color: '#ffff' }} icon={faPhoneAlt} />
                                            <a className="ml-2" href="tel:2108181333" target="_blank" style={{ color: "#ffff" }} rel="noreferrer">210 81 81 333</a>
                                        </div>
                                    </Col> }
                                    {partner === 'pmmgr' &&<Col className="d-flex justify-content-center" style={{ color: "#ffff" }}><div className="text-center">Συμπλήρωσε την φόρμα<br /> επικοινωνίας <u><a href="https://www.public.gr/help/contact-us" style={{ color: "#ffff" }} target="_blank" rel="noreferrer"> εδώ</a></u>.</div></Col>}
                                    {partner === 'pmmcy' &&
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon style={{ color: '#ffff' }} icon={faPhoneAlt} />
                                            <a className="ml-2" href="tel:77770300" target="_blank" style={{ color: "#ffff" }} rel="noreferrer">7777 0300</a>
                                        </div>
                                    </Col> }
                                    {partner === 'pmmcy' &&<Col className="d-flex justify-content-center" style={{ color: "#ffff" }}><div className="text-center">Συμπλήρωσε την φόρμα<br /> επικοινωνίας <u><a href="https://www.public.cy/help/contact-us" style={{ color: "#ffff" }} target="_blank" rel="noreferrer"> εδώ</a></u>.</div></Col>}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <Row className="mb-5 pb-5">
                    <Col></Col>
                </Row>
            </div >
        );
}


export default Pmm;