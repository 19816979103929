import { Row, Col, Tab, Nav, Container, Carousel, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CDN } from '../constants'
import { replaceSpecialChars } from '../viewHelperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Offer = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    var partner = props.partner ? props.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    const voucherOffer = state.voucherOffers[0].price;
    const voucherCoupon = state.voucherOffers[0].coupon;
    const voucherPromo = state.voucherOffers[0].promo;
    const cashOffer = state.cashOffers[0].price;
    const cashCoupon = state.cashOffers[0].coupon;
    const cashPromo = state.cashOffers[0].promo;
    const [showXiaomiPopUp, setShowXiaomiPopUp] = useState(false);

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }

    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = cashOffer > voucherOffer ? cashOffer + cashPromo + cashCoupon : voucherOffer + voucherCoupon + voucherPromo;

    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        coupon: device.coupon,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl,
        contact: state.contact
    }

    useEffect(() => {
        console.log(state)
    }, [state])

    useEffect(() => {
        // if(!window.location.href.includes('localhost')){
            window.dataLayer.push({ event: 'Step Offer Page' });
        // }
    }, [])

    const moosendTrackOrder = () => {
        const itemCode = device.brand + '' + device.model;
        const itemName = device.model;
        const itemImage = imageUrl;
        const itemPrice = finalPrice;
        const itemUrl = window.location.href;
        const itemQuantity = 1;
        const itemTotalPrice = finalPrice;
        const itemCategory = state.category === 'ipad' ? 'tablet': 'smartphone';
        // Tracking add to cart events with mandatory arguments + optional
        window.mootrack('trackAddToOrder', itemCode, itemPrice, itemUrl, itemQuantity, itemTotalPrice, itemName, itemImage, itemCategory);
    }
    
    const product1 = {
        itemCode: device.brand + '' + device.model,
        itemName: device.model,
        itemImage: imageUrl,
        itemPrice: finalPrice,
        itemUrl: window.location.href,
        itemQuantity: 1,
        itemTotalPrice: finalPrice,
        itemCategory: state.category === 'ipad' ? 'tablet': 'smartphone',
    };
    const productInfo = [{ product: product1 }];
    window.mootrack('PAGE_VIEWED', productInfo);  

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'offer';
    window.price = finalPrice;
    window.priceVoucher = state.voucherOffers[0].price + state.voucherOffers[0].coupon + state.voucherOffers[0].promo;
    window.priceCash = state.cashOffers[0].price + state.cashOffers[0].coupon + state.cashOffers[0].promo;

const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const [key, setKey] = useState('store');
    const [prepareKey, setPrepareKey] = useState('1')
    const [index, setIndex] = useState(0)
    let modelImage = replaceSpecialChars(model)

    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady && !state.cashOffers) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${state.partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div style={{position: 'relative'}}>
            <section id="pandasOffer">
                <Container>
                    <Row className="justify-content-center m-0 my-5">
                        <Col xs="9" sm="9" className="text-left d-md-none">
                            <Row>
                                <Col className='p-0 text-center text-md-left'>
                                    <h5 className="mb-2"><b><span style={{fontSize:'12px'}} className='d-block d-md-inline'>{device.brand} </span>{device.model}</b>{device.brand === 'Apple' && ', ' + capacity}</h5>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="5" sm="5" md="4" lg="3" xl="3" className='text-center text-md-right px-0 pr-md-4 pb-2 my-3 my-md-0'>
                            {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand + "/" + modelImage + ".jpg"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                            {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand + "/" + modelImage + ".png"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                        </Col>
                        <Col xs="11" sm="9" md="8" lg="9" xl="9" className="text-center text-md-left pl-md-3">
                            <Row>
                                <Col className='p-0 text-center text-md-left d-none d-md-block'>
                                    <h5 className="mb-2" style={{fontSize:'30px'}}><b><span className='d-block d-lg-inline'>{device.brand} </span>{device.model}</b>{device.brand === 'Apple' && ', ' + capacity}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="11" xl="11" className='p-0'>
                                    <div className='pt-2 px-4 mx-auto mx-md-0 my-3' style={{background: '#F7F7F7', borderRadius: '8px', width: 'fit-content'}}>
                                        <p style={{ fontSize: '20px' }} className='m-0 p-0'>{t('offer.Get paid up to')}</p>
                                        <h1 style={{ fontSize: '72px', lineHeight: '52px' }}><b className='thicker'>{finalPrice}€*</b></h1>
                                    </div>
                                    <div style={{ backgroundColor: '#BBFFCC80', padding: '5px 35px 5px 15px', borderRadius: '10px', width: 'fit-content' }} className="d-flex align-items-center m-auto mt-4 mb-3 m-md-0">
                                        <div className="mr-3 mb-1"><img src={CDN + "/pricing-app/icons/black_check.png"} alt="warning" width="15" /></div>
                                        <div style={{ fontSize: '12px' }}><b>{t('offer.Same-day Payment')}</b></div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="11" sm="9" md="12" lg="0" xl="9" className="text-center text-lg-left justify-content-center d-lg-none">
                            <Row className='m-2 my-4 d-flex d-lg-none justify-content-center'>
                                <Col onClick={moosendTrackOrder} xs="12"  md="6" className='pandasOfferButton blackhover p-0 text-center m-2' style={{maxWidth:'305px'}}>
                                    <Link  id='quote-voucher' to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/offerLocations?type=voucher`} style={{ display: 'block', background: '#1A1A1A', color: 'white !important' }}><span>{t('offer.Up to')}</span> <b>{voucherOffer + voucherCoupon + voucherPromo}€</b></Link>
                                </Col>
                                <Col onClick={moosendTrackOrder} xs="12" md="6"  className='pandasOfferButton p-0 text-center m-2' style={{maxWidth:'305px'}}>
                                    <Link  id='quote-cash' to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/offerLocations?type=cash`} style={{ display: 'block', background: 'white', color: '#1A1A1A' }}><span>{t('offer.Up to')}</span> <b>{cashOffer + cashPromo + cashCoupon}€</b></Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg='3'></Col>
                        <Col xs="12" sm="8" md="11" lg="9" xl="9" className="text-center text-lg-left px-0">
                            <Row className='my-2'>
                                <Col className='px-1'>
                                    <span style={{ fontSize: '12px' }}>{t('offer.*Pandas service fees included (avg. 10%)')}</span>
                                    <OverlayTrigger
                                        key={'bottom'}
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip id={`tooltip-bottom`}>
                                                <b>Τρόπος υπολογισμού των τιμών μας :</b><br /><br />
                                                <p>Οι αξίες που προσφέρουμε, υπολογίζονται βάσει της κατάστασης της συσκευής, αλλά και των χαρακτηριστικών της όπως είναι η μάρκα, το μοντέλο και τα GB. <br />
                                                    Παράλληλα, σε συνεργασία με τα μεγαλύτερα marketplaces μεταχειρισμένων κινητών, ελέγχουμε τις τιμές και τη ζήτηση της συσκευής σου στην αγορά μεταχειρισμένων. <br />
                                                    Ως εταιρία κρατάμε ένα fee (avg. 10% της τιμής που σου προσφέρουμε), για τη μεταφορά και τον έλεγχο της συσκευής σου και εσύ πληρώνεσαι σε λιγότερο από 3’.</p>
                                            </Tooltip>
                                        }
                                    >
                                        <img style={{ display: 'inline-block' }} className="ml-1 mb-1" src={CDN + "/pricing-app/icons/Info_message_icon.svg"} alt="info" width="20" />
                                    </OverlayTrigger>
                                    <p style={{ fontSize: '12px', color: '#808080' }}>{t('offer.The final value is determined at the moment of evaluation, based on your device’s condition.')}</p>
                                </Col>
                            </Row>
                            <Row className='d-block d-md-none my-2'>
                                <Col style={{ fontSize: '16px' }} className='p-0'>
                                    <b>{t('offer.By trading in this device:')}</b>
                                </Col>
                            </Row>
                            <Row className='d-block d-md-none mx-auto mb-5 text px-3' style={{ marginRight: '-25px', maxWidth: '365px' }}>
                                <Col xs="12" sm="" style={{ background: 'rgba(237, 218, 204, 0.5)', borderRadius: '8px', position: 'relative' }}>
                                    <img style={{ position: 'absolute', top: '0', left: '0', transform: 'translate(-50%, 25%)' }} src={CDN + "/pricing-app/icons/leaf-icon-noborder.svg"} alt="info" width="55" />
                                    <Row className='align-items-center pt-3'>
                                        <Col xs="5" className='ml-auto pr-0'>
                                            <p style={{ fontSize: '12px' }}><b>{t('offer.60kg C02')} <br /></b><span style={{ color: '#888380' }}>{t('offer.emissions avoided')}</span></p>
                                        </Col>
                                        <Col xs="0" className="text-center">
                                            +
                                        </Col>
                                        <Col xs="6" className='pr-0'>
                                            <p style={{ fontSize: '12px' }}><b>{t('offer.21kg CO2 / year')} <br /></b><span style={{ color: '#888380' }}>{t('offer.offset by planting one tree')}</span></p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='m-0 mt-3 d-none d-lg-block'>
                                <Col xs="12" xl="10" className='pl-0'>
                                    <Row style={{ borderBottom: "2px solid #F2F2F2", paddingBlock: '16px'}} className='align-items-center'>
                                        <Col xs="12" md="6" lg="7" xl="8" className='p-0'>
                                            <h6>{t('offer.Voucher')}</h6>
                                            <p style={{ fontSize: '12px', color: '#808080', marginBottom: '0' }}>{t('offer.See all the stores where you can trade-in and get a voucher.')}</p>
                                        </Col>
                                        <Col onClick={moosendTrackOrder} xs="12" md="6" lg="5" xl="4" className='pandasOfferButton blackhover'>
                                            <Link  id='quote-voucher' to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/offerLocations?type=voucher`} style={{ background: '#1A1A1A', color: 'white' }}><span className='pl-2'>{t('offer.Up to')}</span> <b style={{ fontSize: '24px' }}>{state.voucherOffers[0].price + state.voucherOffers[0].coupon + state.voucherOffers[0].promo}€</b><img style={{ margin: '0 0 5px 10px' }} src={CDN + "/pricing-app/icons/next_arrow_icon_white.svg"} alt="info" width="23" /></Link>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderBottom: "2px solid #F2F2F2", paddingBlock: '16px' }} className='align-items-center'>
                                        <Col xs="12" md="6" lg="7" xl="8" className='p-0'>
                                            <h6>{t('offer.Get cash deposit')}</h6>
                                            <p style={{ fontSize: '12px', color: '#808080', marginBottom: '0' }}>{t('offer.Discover the ways you can trade-in and receive the payment.')}</p>
                                        </Col>
                                        <Col onClick={moosendTrackOrder} xs="12" md="6" lg="5" xl="4" className='pandasOfferButton'>
                                            <Link id='quote-cash' to={`${process.env.PUBLIC_URL}/pandas/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/offerLocations?type=cash`} style={{ background: 'white', color: '#1A1A1A' }}><span>{t('offer.Up to')}</span> <b style={{ fontSize: '24px' }}>{state.cashOffers[0].price}€</b><img style={{ margin: '0 0 5px 10px' }} src={CDN + "/pricing-app/icons/next_arrow_icon_black.svg"} alt="info" width="23" /></Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="3"></Col>
                        <Col md="12" lg="9" xl="9" className="text-left justify-content-center">
                            <Row className=' my-4 d-none d-md-block'>
                                <Col className='p-0'>
                                    <p style={{ fontSize: '20px', color: '#1A1A1A' }}><b>{t('offer.By trading in this device:')}</b></p>
                                    <Row style={{ background: '#EDDACC', borderRadius: '8px', width: 'fit-content' }}>
                                        <Col className='p-0' style={{ width: 'fit-content' }}>
                                            <div style={{ position: 'relative', background: '#FFFFFF80', padding: '65px 23px 32px', margin: '72px 11px 22px 11px', borderRadius: '8px', textAlign: 'center', width: '204px' }}>
                                                <p className='m-0' style={{ fontSize: '16px' }}><b>{t('offer.60kg C02')}</b></p>
                                                <p className='m-0' style={{ fontSize: '12px', color: '#888380' }}>{t('offer.emissions avoided')}</p>
                                                <img style={{ position: 'absolute', top: '0', left: '0', transform: 'translate(80%, -50%)' }} src={CDN + "/pricing-app/icons/device-noCO2.svg"} alt="info" width="70" />
                                            </div>
                                        </Col>
                                        <Col className='p-0' style={{ width: 'fit-content' }}>
                                            <div style={{ position: 'relative', background: '#FFFFFF80', padding: '65px 23px 14px', margin: '72px 11px 22px', borderRadius: '8px', textAlign: 'center', width: '204px' }}>
                                                <p className='m-0' style={{ fontSize: '16px' }}><b>{t('offer.We plant 1 tree')}</b></p>
                                                <p className='m-0' style={{ fontSize: '12px', color: '#888380' }}>{t('offer.that offsets 21kg of CO2 emissions a year.')}</p>
                                                <img style={{ position: 'absolute', top: '0', left: '0', transform: 'translate(80%, -50%)' }} src={CDN + "/pricing-app/icons/device-tree.svg"} alt="info" width="70" />
                                            </div>
                                        </Col>
                                        <Col className='p-0' style={{ width: 'fit-content' }}>
                                            <div style={{ position: 'relative', background: '#FFFFFF80', padding: '65px 23px 14px', margin: '72px 22px 22px 11px', borderRadius: '8px', textAlign: 'center', width: '204px' }}>
                                                <p className='m-0' style={{ fontSize: '16px' }}><b>{t('offer.1 Device')}</b></p>
                                                <p className='m-0' style={{ fontSize: '12px', color: '#888380' }}>{t('offer.will be reused')}</p>
                                                <img style={{ position: 'absolute', top: '0', left: '0', transform: 'translate(80%, -50%)' }} src={CDN + "/pricing-app/icons/device-reuse.svg"} alt="info" width="70" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>

            </section>
            <section className="py-4" style={{ background: '#F7F7F7' }}>
                <Container>
                    <Row className='m-0 my-4 py-4 text-center text-md-left p-md'>
                        <Col>
                            <b className='thicker' style={{ fontSize: '30px' }}>{t('offer.How trade-in works')}</b>
                        </Col>
                    </Row>
                    <Row className='m-0'>
                        <Col className='p-0'>
                            <Tab.Container id="PandasOfferTabs" defaultActiveKey="store"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row className='m-0'>
                                    <Col md="7" lg="7" className='p-0'>
                                        <Row className='m-0 my-2 justify-content-center justify-content-md-start'>
                                            <Nav className='PandasOfferTabs text-center' variant="tabs">
                                                <Col xs="auto" className='pl-0 pr-0 pr-md-2'>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="store">{t('offer.Store')}</Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                                <Col xs="auto" className='pr-0'>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="courier">{t('offer.Courier')}</Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                            </Nav>
                                        </Row>
                                        <Row className='m-0'>
                                            <Tab.Content className='PandasOfferTabContent' >
                                                <Tab.Pane eventKey="store">
                                                    <Row className='mx-0 my-4 d-md-none justify-content-center'>
                                                        <img src={CDN + "/pricing-app/icons/Mobile-illustration-store.png"} alt="info" height="200px" />
                                                    </Row>
                                                    <Row className='mx-0 my-4'>
                                                        <Col xs='2' className='text-center pr-0'>
                                                            <img src={CDN + "/pricing-app/icons/mappin_icon.svg"} alt="info" width="30px" />
                                                        </Col>
                                                        <Col>
                                                            <b style={{ fontSize: '20px', margin: '0' }}>{t('offer.Find a store near you')}</b>
                                                            <p style={{ fontSize: '16px', color: '#1A1A1A80' }}>{t('offer.Check the map and see all the available stores.')}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mx-0 mt-4 mb-2'>
                                                        <Col xs='2' className='text-center pr-0'>
                                                            <img src={CDN + "/pricing-app/icons/fullcheck_icon.svg"} alt="info" width="30px" />
                                                        </Col>
                                                        <Col>
                                                            <b style={{ fontSize: '20px', margin: '0' }}>{t('offer.Bring in your device for a full check')}</b>
                                                            <p style={{ fontSize: '16px', color: '#1A1A1A80' }}>{t('offer.We will evaluate your smartphone and make you an offer based on its condition.')}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mx-0 my-4'>
                                                        <Col xs='2' className='text-center pr-0'>
                                                            <img src={CDN + "/pricing-app/icons/voucher_icon.svg"} alt="info" width="30px" />
                                                        </Col>
                                                        <Col>
                                                            <b style={{ fontSize: '20px', margin: '0' }}>{t('offer.Receive a voucher to spend in-store')}</b>
                                                            <p style={{ fontSize: '16px', color: '#1A1A1A80' }}>{t('offer.And enjoy your new purchase!')}</p>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="courier">
                                                    <Row className='mx-0 my-4 d-md-none justify-content-center'>
                                                        <img src={CDN + "/pricing-app/icons/Mobile-illustration-courier.png"} alt="info" height="200px" />
                                                    </Row>
                                                    <Row className='mx-0 my-4'>
                                                        <Col xs='2' className='text-center pr-0'>
                                                            <img src={CDN + "/pricing-app/icons/mappin_icon.svg"} alt="info" width="30px" />
                                                        </Col>
                                                        <Col>
                                                            <b style={{ fontSize: '20px', margin: '0' }}>{t('offer.Find a courier such as ACS, ELTA, etc')}</b>
                                                            <p style={{ fontSize: '16px', color: '#1A1A1A80' }}>{t('offer.Choose the service that you prefer.')}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mx-0 mt-4 mb-2'>
                                                        <Col xs='2' className='text-center pr-0'>
                                                            <img src={CDN + "/pricing-app/icons/package_icon.svg"} alt="info" width="30px" />
                                                        </Col>
                                                        <Col>
                                                            <b style={{ fontSize: '20px', margin: '0' }}>{t('offer.Send us your device along with your personal Pandas card')}</b>
                                                            <p style={{ fontSize: '16px', color: '#1A1A1A80' }}>{t('offer.Pandas address: 20 Charitos, Athens 10675')}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mx-0 my-4'>
                                                        <Col xs='2' className='text-center pr-0'>
                                                            <img src={CDN + "/pricing-app/icons/currencydollar_icon.svg"} alt="info" width="30px" />
                                                        </Col>
                                                        <Col>
                                                            <b style={{ fontSize: '20px', margin: '0' }}>{t('offer.After evaluation, get and offer and payment immediately')}</b>
                                                            <p style={{ fontSize: '16px', color: '#1A1A1A80' }}>{t('offer.In case you are not satisfied, we will send your smartphone back. The return cost is on us.')}</p>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Row>
                                        <Row className='m-1 my-4 py-4 d-none d-md-block'>
                                            <Col className='pandasOfferButton blackhover p-0 text-left'>
                                                <a onClick={(e) => {window.scrollTo({ top: 0, behavior: 'smooth' })}} style={{ paddingInline: "26px", background: '#1A1A1A', color: 'white', cursor: 'pointer' }}><b>{t('offer.Trade in your device')}</b></a>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="5" lg="5" className='d-none d-lg-block p-0'>
                                        <Tab.Content className='PandasOfferTabContent' >
                                            <Tab.Pane eventKey="store">
                                                <img src={CDN + "/pricing-app/icons/Desktop-illustration-store.png"} alt="info" width="100%" />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="home">
                                                <img src={CDN + "/pricing-app/icons/Desktop-illustration-scooter.png"} alt="info" width="100%" />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="courier">
                                                <img src={CDN + "/pricing-app/icons/Desktop-illustration-courier.png"} alt="info" width="100%" />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                    <Col md="5" lg="5" className='d-none d-md-block d-lg-none p-0'>
                                        <Tab.Content className='PandasOfferTabContent' >
                                            <Tab.Pane eventKey="store">
                                                <img src={CDN + "/pricing-app/icons/tablet-illustration-store.png"} alt="info" width="100%" />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="home">
                                                <img src={CDN + "/pricing-app/icons/tablet-illustration-scooter.png"} alt="info" width="100%" />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="courier">
                                                <img src={CDN + "/pricing-app/icons/tablet-illustration-courier.png"} alt="info" width="100%" />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                    <Row className='m-1 my-4 d-md-none'>
                        <Col xs="12" className='pandasOfferButton blackhover p-0 text-center'>
                            <a onClick={(e) => {window.scrollTo({ top: 0, behavior: 'smooth' })}} style={{ display: 'block', background: '#1A1A1A', color: 'white', cursor: 'pointer' }}><b>{t('offer.Trade in your device')}</b></a>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ background: '#262626' }}>
                <Container>
                    <Row className='py-4 align-items-center m-auto my-4'>
                        <Col xs="12" md="7" lg="6">
                            <Row className='align-items-center py-4'>
                                <Col xs="4">
                                    <img src={CDN + '/pandas-website/common/icons/heart.png'} alt="+1" width="100%" />
                                </Col>
                                <Col xs="8" sm="8">
                                    <p className="my-0" style={{ color: '#BBFFCC', fontSize: '40px' }}><b className='thicker'>15,000+</b></p>
                                    <p style={{ color: 'white', fontSize: '24px' }}><b className='thicker'>{t('offer.devices sold so far...')}</b></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="5" lg="6">
                            <div style={{ maxHeight: '290px', maxWidth: '365px', margin: 'auto' }} >
                                <img src={CDN + "/pricing-app/icons/google-ratings-gr.png"} alt="rating" style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="py-4" style={{ background: '#040404', color: 'white' }}>
                <Container className='d-none d-md-block'>
                    <Tab.Container
                        id="PandasPrepare"
                        defaultActiveKey=""
                        activeKey={prepareKey}
                        onSelect={(k) => { setPrepareKey(k); }}>
                        <Row className='align-items-center py-4'>
                            <Col md="5">
                                <Tab.Content className='PandasOfferTabContent' >
                                    <Tab.Pane eventKey="1">
                                        <img src={CDN + `/pricing-app/videos/${brand == 'apple' ? 'apple' : 'android'}-deactivate.gif`} alt="info" height="450" />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2">
                                        <img src={CDN + `/pricing-app/videos/${brand == 'apple' ? 'apple' : 'android'}-reset.gif`} alt="info" height="450" />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="3">
                                        <img src={CDN + `/pricing-app/videos/${brand == 'apple' ? 'apple' : 'android'}-ready.gif`} alt="info" height="450" />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                            <Col md="7" className='p-0'>
                                <p style={{ fontSize: '30px', color: 'white' }}><b>{t('offer.Prepare your device for trading in')}</b></p>
                                <Nav className='PrepareTabs' variant="tabs">
                                    <Nav.Item onMouseOver={() => { setPrepareKey('1') }} style={{ width: '100%' }}>
                                        <Nav.Link eventKey="1">
                                            <Row className="py-2">
                                                <Col md="1">
                                                    {prepareKey == "1" ?
                                                        <img className='pt-1' src={CDN + "/pricing-app/icons/1-filled.svg"} alt="info" width="35" /> :
                                                        <img className='pt-2 pl-1' src={CDN + "/pricing-app/icons/1.svg"} alt="info" width="32" />}
                                                </Col>
                                                {brand == 'apple' && <Col md="11" className="pl-md-4">
                                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Deactivate Find My iPhone')}</p>
                                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('offer.Settings > Your name > Find My > Find My iPhone > Off')}</p>
                                                </Col>}
                                                {brand != 'apple' && <Col md="11" className="pl-md-4">
                                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Reset your iPhone')}</p>
                                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('offer.Settings > General > Transfer or Reset iPhone > Erase All Content and Settings.')}</p>
                                                </Col>}
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onMouseOver={() => { setPrepareKey('2') }} style={{ width: '100%' }}>
                                        <Nav.Link eventKey="2">
                                            <Row className="py-2">
                                                <Col md="1">
                                                    {prepareKey == "2" ?
                                                        <img className='pt-1' src={CDN + "/pricing-app/icons/2-filled.svg"} alt="info" width="35" /> :
                                                        <img className='pt-2 pl-1' src={CDN + "/pricing-app/icons/2.svg"} alt="info" width="32" />}
                                                </Col>
                                                {brand == 'apple' && <Col className="pl-md-4" md="11">
                                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Deactivate Find My iPhone')}</p>
                                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('Settings > Your name > Find My > Find My iPhone > Off')}</p>
                                                </Col>}
                                                {brand != 'apple' && <Col className="pl-md-4" md="11">
                                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Reset your iPhone')}</p>
                                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('offer.Settings > General > Transfer or Reset iPhone > Erase All Content and Settings.')}</p>
                                                </Col>}
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onMouseOver={() => { setPrepareKey('3') }} style={{ width: '100%' }}>
                                        <Nav.Link eventKey="3">
                                            <Row className="py-2">
                                                <Col md="1">
                                                    {prepareKey == "3" ?
                                                        <img className='' src={CDN + "/pricing-app/icons/3-filled.svg"} alt="info" width="35" /> :
                                                        <img className='pt-1 pl-1' src={CDN + "/pricing-app/icons/3.svg"} alt="info" width="32" />}
                                                </Col>
                                                <Col className="pl-md-4 pb-2" md="11">
                                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Ready to trade-in')}</p>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
                <Container className='d-md-none text-center'>
                    <p className="py-4" style={{ fontSize: '30px' }}><b>{t('offer.Prepare your device for trading in')}</b></p>
                    {<Carousel slide id="PrepareCarousel" activeKey={index} onSelect={(selectedIndex, e) => { setIndex(selectedIndex); }} interval={null}>
                        <Carousel.Item className='text-center' interval={null}>
                            <img src={CDN + `/pricing-app/videos/${brand == 'apple' ? 'apple' : 'android'}-deactivate.gif`} alt="info" height="450" />
                            <Row className='justify-content-center my-4 py-2'>
                                {brand == 'apple' && <Col xs="8" style={{ minHeight: "205px" }}>
                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Deactivate Find My iPhone')}</p>
                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('offer.Settings > Your name > Find My > Find My iPhone > Off')}</p>
                                </Col>}
                                {brand != 'apple' && <Col xs="8" style={{ minHeight: "205px" }}>
                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Deactivate Find My Phone')}</p>
                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('offer.Setting > Security > Find My Modile > Off')}</p>
                                </Col>}
                            </Row>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={null}>
                            <img src={CDN + `/pricing-app/videos/${brand == 'apple' ? 'apple' : 'android'}-reset.gif`} alt="info" height="450" />
                            <Row className='justify-content-center my-4 py-2'>
                                {brand == 'apple' && <Col xs="8" style={{ minHeight: "205px" }}>
                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Reset your iPhone')}</p>
                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('offer.Settings > General > Transfer or Reset iPhone > Erase All Content and Settings.')}</p>
                                </Col>}
                                {brand != 'apple' && <Col xs="8" style={{ minHeight: "205px" }}>
                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Reset your Smartphone')}</p>
                                    <p style={{ fontWeight: '500', color: '#FFFFFF80' }} className='m-0'>{t('offer.Settings > Accounts and Backup > Reset > Factory Data Reset')}</p>
                                </Col>}
                            </Row>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={null}>
                            <img src={CDN + `/pricing-app/videos/${brand == 'apple' ? 'apple' : 'android'}-ready.gif`} alt="info" height="450" />
                            <Row className='justify-content-center my-4 py-2'>
                                <Col xs="8" style={{ minHeight: "205px" }}>
                                    <p style={{ fontSize: "20px", fontWeight: "700" }} className='m-0'>{t('offer.Ready to trade-in')}</p>
                                </Col>
                            </Row>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>}
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='m-0 pt-4 align-items-end text-center text-lg-left'>
                        <Col xs="12" lg="7" xl="8">
                            <Row className='m-0 pb-4 pt-4'>
                                <div>
                                    <p className="py-4" style={{ fontSize: "40px", lineHeight: "1.0" }}><b className='thicker'>{t('offer.Trade in your device')} <span className='thicker' style={{ color: '#FF4B55' }}>{t('offer.today!')}</span></b></p>
                                </div><p style={{ fontSize: '20px' }}>{t('offer.Get paid with a bank deposit to your account or with a voucher for purchases in your favourite tech shop.')}</p>
                                <Col xs="12" md="12" lg="7" xl="5" className='pandasOfferButton blackhover p-0 py-4 my-4 text-center'>
                                    <a onClick={(e) => {window.scrollTo({ top: 0, behavior: 'smooth' })}} style={{ display: 'block', background: '#1A1A1A', color: 'white', cursor: 'pointer' }}><b>{t('offer.Sell your device')}</b></a>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="d-none d-lg-block">
                            <img src={CDN + '/pricing-app/icons/phone-in-hands.png'} alt="+1" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}

export default withTranslation()(Offer);