import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from 'react-bootstrap';
import { GPContext } from "../context";
import Footer from './footer';
import Map from './map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { CDN } from '../constants'

const LocatorResults = (props) => {
    const { t, i18n } = props;

    const { state, dispatch } = React.useContext(GPContext);
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    let instore = sessionStorage.getItem('instore');
    const promoDevices = ['Apple iPhone 15 Pro', 'Apple iPhone 15 Pro Max', 'Apple iPhone 15 Plus', 'Apple iPhone 15', 'Apple iPhone 14 Pro', 'Apple iPhone 14 Pro Max', 'Apple iPhone 14 Plus', 'Apple iPhone 14', 'Apple iPhone 13 Pro', 'Apple iPhone 13 Pro Max', 'Apple iPhone 13', 'Apple iPhone 13 mini', 'Apple iPhone 12 Pro Max', 'Apple iPhone 12 Pro', 'Apple iPhone 12', 'Apple iPhone 12 mini', 'Apple iPhone 11 Pro Max', 'Apple iPhone 11 Pro', 'Apple iPhone 11','Apple iPhone SE (2022)', 'Apple iPhone SE (2020)']

    // const { loading, error, data } = state;
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = (lat, lng, code) => {
        dispatch({ type: "CLICK", payload1: lat, payload2: lng, payload3: code })
    }
    var partn;
    if (partner === 'plaisio')
        partn = 'Plaisio';
    if (partner === 'istorm')
        partn = 'iStorm';
    if (partner === 'istormcy')
        partn = 'iStorm Cyprus';
    if (partner === 'mistore')
        partn = 'Xiaomi Store';

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());

    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }

    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    let offer = {};
    if(state.category === 'smartwatch'){
        const partsOffer = state.partsOffer;
        offer = {
            brand: device?.brand,
            model: device?.model,
            name: device?.model.length < 5 ? device?.brand + " " + device?.model : device?.model,
            capacity: device?.capacity,
            coupon: partsOffer?.coupon,
            powerson: powerson,
            condition: changeToGreek(condition),
            finalPrice: partsOffer?.price,
            imageUrl: imageUrl
        }
    }else{
        offer = {
            brand: device.brand,
            model: device.model,
            name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
            capacity: device.capacity,
            coupon: device.coupon,
            powerson: powerson,
            condition: changeToGreek(condition),
            finalPrice: finalPrice,
            imageUrl: imageUrl
        }
    }
    if(partner.includes('pmm') && promoDevices.includes(device.brand + " " + device.model)){offer.finalPrice = offer.finalPrice + 50}
    var user = {}

    useEffect(() => {
        setTimeout(() => {
            setLoad(false);
        }, 500)

        const header =
            { 'Content-Type': 'application/json' };
        axios.post(`https://hook.integromat.com/ursljgq3xm8g9bvelr08iqh71l5lx8cs`, user, { header }).then(res => {
            console.log(res);
            console.log(res.data);
        })
        dispatch({ type: 'partner', payload: state.partner })
    }, [])

    user.email = state.contact.email;
    user.phone = "+30" + state.contact.phone;
    user.location = state.locations.sort((a, b) => a.distance - b.distance).filter(locations => parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38).map((l) => l.title_gr).slice(0, 1).toString();
    user.partner = state.locations.sort((a, b) => a.distance - b.distance).filter(locations => parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38).map((l) => l.partner_name_gr).slice(0, 1).toString();
    user.address = state.locations.sort((a, b) => a.distance - b.distance).filter(locations => parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38).map((l) => l.address_gr).slice(0, 1).toString();
    user.distance = state.locations.sort((a, b) => a.distance - b.distance).filter(locations => parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38).map((l) => l.distance).slice(0, 1).toString();

    return (
        <div className="d-lg-flex justify-content-lg-center pt-4 pt-md-5">
            <div className="fixed">
                {load && <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className="loader">Loading...</div>
                    </Col>
                </Row>}
                {!load && <>
                    <Row className="d-flex flex-column align-items-center align-items-lg-start flex-lg-row-reverse justify-content-lg-center px-xl-5 mx-lg-3 mb-5 pb-5">
                        <Col xs="11" sm="10" md="8" lg="5" xl="4" className="">
                            <Col className="blueCard d-flex flex-column mb-4 py-lg-4 px-0 px-sm-3">
                                <Col className="d-sm-none d-lg-block mt-3 mt-sm-0">
                                    <div className="mb-3 deviceOffer"><b>{device.name}</b></div>
                                    <div style={{ borderBottom: '2px dashed #FFFFFF' }}></div>
                                </Col>
                                <Col className="d-flex justify-content-between align-items-center my-4">
                                    <div className="bgMobile">
                                        <div className="d-flex justify-content-center align-items-center text-center">
                                            {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand + "/" + model.replaceAll('-', '_') + ".jpg"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} className="offerMobile" alt="phone" />}
                                            {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand + "/" + model.replaceAll('-', '_') + ".png"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} className="offerMobile" alt="phone" />}
                                        </div>
                                    </div>
                                    <div xs="" className="text-right pr-0">
                                        <div style={{ fontSize: '16px' }} className="d-none d-sm-block d-lg-none"><b>{device.name}</b></div>
                                        <div className="getOffer mb-1"><b>{t('offer.Get voucher')}</b></div>
                                        <div className="offerPrice"><b>{offer.finalPrice}€</b></div>
                                    </div>
                                </Col>
                                <Col className="d-none d-lg-block">
                                    <div style={{ borderBottom: '2px solid #FFFFFF' }}></div>
                                    <div className="recyclingCard mt-4 p-3">
                                        <div className=""><img className="mr-1" src={CDN + "/pricing-app/icons/tree_icon.svg"} alt="tree icon" height="16px" />{t('offerCard.recycling1')}</div>
                                    </div>
                                </Col>
                            </Col>
                            <a href="https://g.page/r/CfPT3T3OUKm8EB0/review">
                                <Col className="whiteCard d-none d-lg-flex py-2 mb-4">
                                    <img src={CDN + "/pricing-app/logos/google_logo.svg"} alt="" width="80" />
                                    <div style={{ color: '#212529' }}><b style={{ fontSize: '20px' }}>4.7<small><b>/5</b></small></b><span className="ml-2" style={{ fontSize: '10px', opacity: '0.6' }}>{t('offerCard.review')}</span></div>
                                </Col>
                            </a>
                        </Col>
                        <Col xs="11" sm="10" md="8" lg="6" xl="5" className="list mb-5">
                            <h3 className="mb-4">{partner === 'gp' ? <b>{t('locator.nearAtm')}</b> : <b>Πλησιέστερο κατάστημα:</b>}</h3>
                            {state.locations.sort((a, b) => a.distance - b.distance).filter(locations => (partner === 'gp' || partner === 'pandas' || partner === 'verizon' || locations.partner === partner) && parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38).slice(0, 1).map((location, index) =>
                                <Col onClick={() => handleClick(location.latitude, location.longitude, location.id)} className="locationCard d-flex flex-column p-0 mb-5" key={index}>
                                    <Col className={`${partner}Line`}></Col>
                                    <Row className="d-flex flex-column-reverse flex-sm-row align-items-center px-4 my-4 ">
                                        <Col xs="12"className="mt-3 mt-md-0">
                                            <h4 className="mb-1"><b>{i18n.language === 'el' ? location.name_gr : location.name}</b></h4>
                                            <div className=""><b>{i18n.language === 'el' ? location.partner_name_gr : location.partner}</b></div>
                                            {location.partner === 'iStorm' && <div style={{ fontSize: '10px' }}>{t('locator.istorm')}</div>}
                                            {(location.partner === 'Plaisio' || location.partner === 'Xiaomi Store') && <div style={{ fontSize: '9px' }}>{t('locator.plaisio')}</div>}
                                            <div style={{ opacity: '0.8', fontSize: '12px' }} className="">{i18n.language === 'el' ? location.address_gr : location.address}</div>
                                            {location.distance && <div style={{ opacity: '0.6', fontSize: '12px' }}>{location.distance} {t('locator.distance')}</div>}
                                            <a href={location.location} target="_blank" rel="noreferrer"><button className={`${partner}Btn mapsBtn py-1 px-2 mt-3`}>{t('locator.gm')}</button></a>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <h4 className="mb-4"><b>{t('locator.points')}</b></h4>
                            {state.locations.sort((a, b) => a.distance - b.distance).filter(locations => (partner === 'gp'|| partner === 'pandas' || partner === 'verizon' || locations.partner === partner) && parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38).slice(1, state.locations.length).map((location, index) =>
                                <Col onClick={() => handleClick(location.latitude, location.longitude, location.id)} className="locationCard d-flex flex-column p-0 mb-3" key={index}>
                                    <Col className={`${partner}Line`}></Col>
                                    <Row className="d-flex flex-column-reverse flex-sm-row align-items-center px-4 my-4 ">
                                        <Col xs="12"  className="mt-3 mt-md-0">
                                            <h4 className="mb-1"><b>{i18n.language === 'el' ? location.name_gr : location.name}</b></h4>
                                            <div className=""><b>{i18n.language === 'el' ? location.partner_gr : location.partner}</b></div>
                                            {location.partner === 'iStorm' && <div style={{ fontSize: '9px' }}>{t('locator.istorm')}</div>}
                                            {(location.partner === 'Plaisio' || location.partner === 'Xiaomi Store') && <div style={{ fontSize: '9px' }}>{t('locator.plaisio')}</div>}
                                            <div style={{ opacity: '0.8', fontSize: '12px' }} className="">{i18n.language === 'el' ? location.address_gr : location.address}</div>
                                            {location.distance && <div style={{ opacity: '0.6', fontSize: '12px' }}>{location.distance} {t('locator.distance')}</div>}
                                            <a href={location.location} target="_blank" rel="noreferrer"><button className={`${partner}Btn mapsBtn py-1 px-2 mt-3`}>{t('locator.gm')}</button></a>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <div className="mb-5 pb-5"></div>
                            <div className="mb-5 pb-5"></div>
                        </Col>
                    </Row>
                    {(partner !== 'gp' || instore) ?
                        <Footer back={{ text: `${t('footer.back')}`, path: `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator` }}></Footer>
                        :
                        <Footer back={{ text: `${t('footer.back')}`, path: `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator` }}></Footer>
                    }
                    <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body style={{ height: '80vh', borderRadius: '20px', position: 'relative' }}>
                            <div className="closeMap" onClick={handleClose}><div className="exit"><FontAwesomeIcon icon={faTimes} /></div></div>
                            <Map />
                        </Modal.Body>
                    </Modal>
                </>}
            </div>
        </div>
    );
}

export default withTranslation()(LocatorResults);