import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import { CDN } from '../constants'

const ScooterThankYou = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    const partner = sessionStorage.getItem('partner');
    const brand = sessionStorage.getItem('brand');
    const thankYouBrand = localStorage.getItem('thankYouPageBrand');
    const model = sessionStorage.getItem('model');
    const condition = props.model ? props.model : sessionStorage.getItem('condition');
    const capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');
    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        window.dataLayer.push({ event: 'Step Scooter Thank You' });
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'scooter thank you'


        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${state.partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div style={{paddingBottom: '90px', paddingTop: '50px'}}>
            <div className="m-auto" style={{maxWidth: '600px'}}>
                <div className='d-flex justify-content-center'><img className='mr-4' style={{maxWidth: '200px'}} src={`${process.env.PUBLIC_URL}/fluffy.png`} alt="courier girl" /></div>
                <div className='text-center'>
                    <h1 className='pt-4 m-auto' style={{fontWeight: '800', fontSize: '32px', lineHeight: '32px', maxWidth: '340px'}}>Ευχαριστούμε για την κράτησή σου!</h1>
                    <p className='m-auto pt-3 pb-5' style={{fontSize: '14px', color: '#1d1d1d', maxWidth: '410px', lineHeight: '19px'}}>Το Panda Scooter θα σε περιμένει στη διεύθυνση που έχεις ορίσει και την ημερομηνία και ώρα που έχεις επιλέξει.</p>
                </div>
                <div className='m-auto' style={{maxWidth: '420px'}}>
                    <div style={{display: 'flex'}}>
                        <img src={process.env.PUBLIC_URL+'/battery-icon.svg'} style={{paddingRight: '20px', height: '100%', paddingBottom: '40px'}} />
                        <p style={{fontSize: '14px', lineHeight: '18px', color: '#6c6c6c', borderBottom: '1px solid #f2f2f2'}}>Σιγουρέψου ότι το smartphone σου είναι σε λειτουργία και έχει πάνω από <span style={{color: '#1a1a1a', fontWeight: '600'}}>5% μπαταρία.</span></p>
                    </div>
                    <div style={{display: 'flex'}}>
                        <img src={process.env.PUBLIC_URL+'/cloud-icon.svg'} style={{paddingRight: '20px', height: '100%', paddingBottom: '40px'}} />
                        <p style={{fontSize: '14px', lineHeight: '18px', width: '100%', color: '#6c6c6c', marginTop: '8px', borderBottom: '1px solid #f2f2f2'}}>
                            {thankYouBrand.toLowerCase() === 'apple' ? <span>Αποσύνδεσε το Find My iPhone σου από τη συσκευή.</span> : <span>Αποσύνδεσε το Google account σου από τη συσκευή.</span>}
                        </p>
                    </div>
                    <div style={{display: 'flex'}}>
                        <img src={process.env.PUBLIC_URL+'/counter-clockwise-arrow.svg'} style={{paddingRight: '20px', height: '100%', paddingBottom: '40px'}} />
                        <p style={{fontSize: '14px', lineHeight: '18px', color: '#6c6c6c'}}>Κάνε <span style={{color: '#1a1a1a', fontWeight: '600'}}>επαναφορά</span> εργοστασιακών ρυθμίσεων για να <span style={{color: '#1a1a1a', fontWeight: '600'}}>διαγραφούν</span> όλα τα δεδομένα και ρυθμίσεις από κινητό.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ScooterThankYou);