import * as React from 'react';
import { useEffect, useState, useCallback, useContext } from "react";
import ReactMapGL, { Marker, Popup, FlyToInterpolator, GeolocateControl } from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { GPContext } from "../context";
import { easeCubic } from 'd3-ease';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { withTranslation } from 'react-i18next';
import {CDN} from '../constants'
// import bbox from '@turf/bbox';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const Mapbox = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = useContext(GPContext);
    const { loading, error, data } = state;
    const [geolocate, setGeolocate] = useState();
    const [geolocation, setGeolocation] = useState({});
    const TOKEN = 'pk.eyJ1IjoiZ3JlZW5wYW5kYWlvIiwiYSI6ImNrbGZoY2ZnMTBvOWEydnAwaTNxb3ZiZzUifQ.r8zPO79kYM7ronm9v_JVuw';
    const [currentPlaceId, setCurrentPlaceId] = useState((null));
    const mapRef = React.useRef();
    
    const [viewport, setViewport] = useState({
        width: '100%',
        height: 700,
        latitude: state.latitude,
        longitude: state.longitude,
        zoom: 8
    });
    const geolocateControlStyle = {
        right: 10,
        top: 10
    };

    const handleMarkerClick = (id) => {
        setCurrentPlaceId(id)
    }

     useEffect(() => {
        // navigator.geolocation.getCurrentPosition(function (position) {
        //     let code;
        //     if(state.userLocation && state.userLocation.code){
        //         code = state.userLocation.code;
        //     }else{
        //         code = 0;
        //     }
        //     setGeolocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        //     dispatch({ type: "USER_LOCATION", data: { latitude: position.coords.latitude, longitude: position.coords.longitude, code: code } })
        // });
    }, [])

    const zoom = (lat, long) => {
        setViewport({
            ...viewport,
            latitude: lat,
            longitude: long,
            zoom: 16,
            transitionDuration: 3000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: easeCubic
        });
    };

    const handleViewportChange = useCallback(
        (newViewport) => setViewport(newViewport)
        , []);

    useEffect(() => {
        console.log('location', state.userLocation)
    }, [])

    useEffect(() => {
        if (state.latitude && state.longitude) {
            zoom(state.latitude, state.longitude)
            setCurrentPlaceId(state.code)
        }
    }, [state.latitude, state.longitude])

    // useEffect(() => {
    //     if (state.userLocation) {
    //         setGeolocate(true)
    //     }
    // }, [state.userLocation])


    return (
        <div style={{padding: '0 10px', touchAction: 'none'}}>
            <ReactMapGL
                ref={mapRef}
                {...viewport}
                width="100%" // It always override the view(viewport) width state.
                height="90vh"
                mapStyle='mapbox://styles/greenpandaio/ckryv5wfm6o3t17pkyvktadtm'
                onViewportChange={handleViewportChange}
                mapboxApiAccessToken={TOKEN}
                className='mapBox m-auto'
            >
                {geolocate && <GeolocateControl
                    style={geolocateControlStyle}
                    positionOptions={{ enableHighAccuracy: true }}
                    fitBoundsOptions={{ maxZoom: 14 }}
                    trackUserLocation={false}
                    auto = {true}
                />}
                 {state.code === 0 && state.latitude && <Marker onClick={() => { zoom(state.latitude, state.longitude) }}
                            latitude={state.latitude}
                            longitude={state.longitude}
                        >
                            <div onClick={()=>{}} className="marker temporary-marker" ><span><img className="marker-img" src={CDN + "/pricing-app/icons/location-icon.svg"} alt="mySpot" /></span></div>
                        </Marker>}
                
            </ReactMapGL>
        </div>
    );
}
export default withTranslation()(Mapbox);