import { Row, Col } from "react-bootstrap";
import { Link } from "@reach/router";
import React from "react";
import { CDN } from "../../constants";

const IstormOfferItem = ({partner, category, offer, instore, img, text, link}) => {
    return (
        <Row className="justify-content-center align-items-center">
            <Col xs="10" sm="4" className="text-center">
                {img}
            </Col>
            <Col xs="8" sm="8" md="7" className="mt-3 ml-3">
                <h5 className="mb-2 font-weight-bold text-center text-sm-left">
                    {text}
                  </h5>
                  <h2
                    className="text-center text-sm-left"
                    style={{ color: "#3098cb" }}
                  >
                    <b>έως {offer}€ φθηνότερα.</b>
                  </h2>
                <Row className="justify-content-center justify-content-sm-start">
                    <Col xs="10">
                      {instore ? (
                        <Link to={`${process.env.PUBLIC_URL}/${partner}`}>
                          <div className="istormNext next px-3 mt-4">
                            Νέα Αξιολόγηση
                          </div>
                        </Link>
                      ) : (
                        <Link to={link}
                        >
                          <div
                            style={{ maxWidth: "300px" }}
                            className="istormNext next px-3 mt-4"
                          >
                            Βρες ένα κατάστημα iStorm
                          </div>
                        </Link>
                      )}
                      {category === "smartwatch" && (
                        <div
                          className="d-flex align-items-center mt-4"
                          style={{
                            fontSize: "12px",
                            maxWidth: "380px",
                            color: "#0BA5E1",
                            backgroundColor: "#E5F7FF",
                            borderRadius: "10px",
                            padding: "10px 15px",
                          }}
                        >
                          <div className="mr-3">
                            <img
                              src={
                                CDN +
                                "/pricing-app/partners/istorm/info-icon.svg"
                              }
                              width="32"
                              alt="info icon"
                            />
                          </div>
                          <div style={{ lineHeight: "16px" }}>
                            Η συσκευή θα πρέπει να έχει αναβαθμιστεί στο{" "}
                            <span style={{ fontWeight: "600" }}>
                              τελευταίο διαθέσιμο λογισμικό
                            </span>
                          </div>
                        </div>
                      )}
                    </Col>
                </Row>
            </Col>
        </Row> 
    );
};

export default IstormOfferItem;
