function changeToEng(c) {
    if (c === 'very_good') return 'Like New';
    if (c === 'good') return 'Good';
    if (c === 'poor') return 'Acceptable';
    if (c === 'faulty') return 'Faulty';
}
function changeToGreek(c) {
    if (c === 'very_good') return 'Σαν Καινούρια';
    if (c === 'good') return 'Καλή';
    if (c === 'poor') return 'Μέτρια';
    if (c === 'faulty') return 'Κακή';
}

export {changeToEng, changeToGreek}