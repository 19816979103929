import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import { GPContext } from "../context";
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import {CDN} from '../constants'

const Receipt = (props) => {

    const ref = React.createRef();
    const { t } = props;
    const { dispatch } = React.useContext(GPContext);
    const [receipt, setReceipt] = useState([]);
    var uuid = props.uuid;

    useEffect(() => {
        dispatch({ type: "report", payload: true })
        const header =
            { 'Content-Type': 'application/json' };
        axios.get(`https://server.greenpanda.io/reports/tx.php?uuid=${uuid}`, { header }).then(res => {
            setReceipt(res.data)
            console.log(res);
            console.log(res.data);
        })
    }, [])
    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });


    return (
        <>
            <div ref={ref}>
                <Row className="d-flex flex-column align-items-center m-2">
                    <a href="https://greenpanda.io/"><img src={CDN + "/pricing-app/partners/gp/logo.png"} alt="Logo" width="150px" /></a>
                    <Col xs="11" sm="10" md="9" lg="7" xl="6" className="receiptCard mt-4">
                        <Row className="d-flex flex-column align-items-center" >
                            <Col className="receiptHeader mb-5"></Col>
                            <Col xs="10" md="8" className="d-flex flex-column align-items-center text-center">
                                <div className="mb-3" style={{ fontSize: '28px' }}><b>Συγχαρητήρια</b></div>
                                <div className="mb-4" style={{ fontSize: '22px' }}><b style={{ color: '#42D900', fontSize: '26px' }}>{receipt.price}€</b> έχουν κατατεθεί στο λογαριασμό σου</div>
                                <div className="mb-5" style={{ fontSize: '12px' }}>Ευχαριστούμε που μας βοήθησες να υποστηρίξουμε την κυκλική οικονομία για έναν πιο βιώσιμο πλανήτη.</div>
                                <div className="mb-3" style={{ fontSize: '22px' }}>Στοιχεία Συναλλαγής</div>
                            </Col>
                            <Col md="10" lg="11" className="d-flex flex-column align-items-center text-center" style={{ fontSize: '14px' }}>
                                <Col xs="11" style={{ borderBottom: '2px solid #EBEBEB' }}></Col>
                                <Col className="d-flex justify-content-between mt-3 mb-3">
                                    <div className="text-left"><b>Προς: </b></div>
                                    <div>{receipt.fullname}</div>
                                </Col>
                                <Col className="d-flex justify-content-between mb-3">
                                    <div className="text-left"><b>Ημερομηνία: </b></div>
                                    <div>{receipt.date}</div>
                                </Col>
                                <Col className="d-flex justify-content-between mb-3">
                                    <div className="text-left"><b>Κωδικός συναλλαγής: </b></div>
                                    <div>{receipt.imei}</div>
                                </Col>
                                <Col className="d-flex justify-content-between mb-3">
                                    <div className="text-left"><b>Μοντέλο: </b></div>
                                    <div>{receipt.brand} {receipt.model}</div>
                                </Col>
                                <Col className="d-flex justify-content-between mb-3">
                                    <div className="text-left"><b>Κατάσταση συσκευής: </b></div>
                                    {receipt.grade === 'Good' && <div style={{ color: '#23A453' }}><b>Καλή</b></div>}
                                    {receipt.grade === 'Poor' && <div style={{ color: '#FFD200' }}><b>Μέτρια</b></div>}
                                    {receipt.grade === 'Faulty' && <div style={{ color: '#E21A1B' }}><b>Κακή</b></div>}
                                </Col>
                                <Col className="d-flex justify-content-between mb-4">
                                    <div className="mb-5"><b>Χρηματική αξία: </b></div>
                                    <div><b>{receipt.price}€</b></div>
                                </Col>
                                <Col style={{ borderBottom: '2px dashed #EBEBEB' }}></Col>
                            </Col>
                            <Col xs="10" lg="9" className="mt-5 d-flex flex-column align-items-center text-center">
                                <img className="mb-3" src={CDN + "/pricing-app/icons/moto_tree.png"} alt="moto_tree" />
                                <Col md="10" lg="11" className="mb-5" style={{ fontSize: '12px' }}>Με κάθε συσκευή που <b>ανακυκλώνεις</b> σε ένα <b>ΑΤΜ</b>, <b style={{ color: '#42D900' }}>φυτεύουμε</b> και ένα <b style={{ color: '#42D900' }}>δέντρο</b> στο όνομά σου.</Col>
                                <Col style={{ borderBottom: '2px solid #EBEBEB' }}></Col>
                                <div className="d-flex align-items-center my-5">
                                    <img src={CDN + "/pricing-app/icons/chatting.png"} alt="moto_tree" width="40" />
                                    <div className="text-left">
                                        <div style={{ fontSize: '10px' }}><b>Έχεις απορίες; </b> Ο ειδικός της <a style={{ color: '#262626' }} href="https://greenpanda.io/syxnes-erotiseis/"><u>Pandas</u></a> είναι κοντά σου!</div>
                                        <div style={{ fontSize: '10px' }}>Κάλεσέ μας στο 2111982521 ή συμπλήρωσε τη <a style={{ color: '#262626' }} href="https://greenpanda.io/epikoinonia/"><u>φόρμα επικοινωνίας</u></a></div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="receiptFooter">
                                <div className="p-2">Εστάλη στο {receipt.email}, με ♥ και bamboos από την Pandas, Χάρητος 20.</div>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </div >
            <Row className="justify-content-center mb-5">
                <Col className="d-flex justify-content-center">
                    <button style={{ border: 'none', background: '#FFFFFF' }} onClick={handlePrint}>Αποθήκευση <FontAwesomeIcon icon={faArrowAltCircleDown} /></button>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center text-center">
                <Col xs="11" className="">
                    <h4 className="font-weight-bold mb-3">Θέλουμε να σε ακούσουμε!</h4>
                    <div>Στην Pandas η γνώμη σου μετράει!</div>
                    <div>Πες μας την <b>εμπειρία σου</b> μαζί μας και <b>κέρδισε δώρα.</b></div>
                </Col>
            </Row>
            <Row className="justify-content-center text-center">
                <Col xs="7" sm="5" md="3" lg="2">
                    <a href="https://greenpandaio.typeform.com/to/p5CQ72NN?typeform-source=app.clickup.com" target="_blank" rel="noreferrer"><div className="gpNext next nextSmall my-4">Ξεκίνησε εδώ <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} /></div></a>
                </Col>
            </Row>
            <Row className="justify-content-center pb-5">
                <Col xs="10" sm="8" md="6" className="mt-3 whiteCard" style={{ padding: '12px 0px' }}>
                    <img src={CDN + "/pricing-app/logos/google_logo.svg"} alt="" width="100" />
                    <div><b style={{ fontSize: '20px' }}>4.7<small><b>/5</b></small></b><span className="ml-2" style={{ fontSize: '10px', opacity: '0.6' }}>{t('offerCard.review')}</span></div>
                </Col>
            </Row>
        </>
    );
}

export default withTranslation()(Receipt);