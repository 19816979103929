import { Link, navigate } from "@reach/router";
import { Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import { GPContext } from "../context";
import { CDN } from '../constants'

const Brands = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    const instore = sessionStorage.getItem('instore');

    var partner = state.partner ? state.partner.toLowerCase() : '';
    sessionStorage.setItem('partner', partner)
    sessionStorage.setItem('model', 'unknown');
    sessionStorage.setItem('brand', 'unknown');
    sessionStorage.setItem('condition', 'unknown');
    sessionStorage.setItem('finalPrice', 0);
    sessionStorage.setItem('promoPrice', 0);
    sessionStorage.setItem('promoCode', '');
    sessionStorage.setItem('capacity', '');
    sessionStorage.setItem('answer', '');
    sessionStorage.setItem('modelImage', '');
    var devicesBrands = [...new Set(state.devices.map(d => d.brand))];;
    var brands = state.brands.filter(a => devicesBrands.includes(a.brand)).sort((a, b) => a.order > b.order).map(a => a.brand);
    let link;
    if(props.partner==='xiaomigr'){
        link='email';
    }else{
        link='samsung-voucher';
    };

    if (partner === 'mistore' || partner==='xiaomigr')
        brands = ['Xiaomi'].concat(brands.filter(a => a !== 'Xiaomi'));
        
    if (partner === 'samsunggr')
    brands = ['Samsung'].concat(brands.filter(a => a !== 'Samsung'));


    if (partner === 'istorm' || partner === 'istormcy')
        navigate(`${process.env.PUBLIC_URL}/${props.partner}/categories`)
    console.log(brands)


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Brand' });
    }, []);

    window.partner = partner;
    window.language = i18n.language;
    window.step = 'brands';
    window.source = 'pricing-flow';

    return (
        <div id="brands">
            <Row className="justify-content-md-center mb-4">
                <Col lg="12" className="text-center"><h1 className="title">{t('brands.title')}</h1></Col>
            </Row>
            <Row className="justify-content-center mb-5">
                {
                    //   {state.locations.sort((a, b) => a.distance - b.distance).filter(locations => (partner === 'gp' || partner === 'verizon' || locations.partner === partn) && locations.code !== 4 && locations.code !== 38).slice(1, state.locations.length).map((location, index) =>
                    brands.map((brand) => {
                        return (
                            <Col lg={3} md={4} xs={11} className="mb-3 " key={brand}>
                                <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand.toLowerCase()}`} onClick={() => {dispatch({ type: "SAVE", data: { brand } }); sessionStorage.setItem('path', `${process.env.PUBLIC_URL}/${props.partner}/`)}}>
                                    <div className={`${partner}Item item brandItem text-center`} style={{position: 'relative'}}>
                                        <Row style={{ height: "100%" }} onClick={() => {dispatch({ type: "SAVE", data: { brand } }); sessionStorage.setItem("brand", brand);}}>
                                            <Col lg={12} md={12} xs={4} className="pt-md-4 pt-2">
                                                {brand.toLowerCase()!=='samsunggr' ?
                                                <img src={CDN + "/logos/brand-logos/" + brand.toLowerCase() + ".png"} alt={brand} height="40px" className="brandLogo" />
                                                :
                                                <>
                                                    <div className="d-md-none"><img style={{position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', left: '50%'}} src={CDN + "/logos/brand-logos/" + brand.toLowerCase() + ".png"} alt={brand} height="60px" className="brandLogo" /></div>
                                                    <div className='d-none d-md-block'><img src={CDN + "/logos/brand-logos/" + brand.toLowerCase() + ".png"} alt={brand} height="40px" className="brandLogo" /></div>
                                                </>
                                                }
                                            </Col>
                                            <Col lg={12} md={12} xs={8} className="pt-3 text-left text-md-center">
                                                {brand}
                                            </Col>
                                        </Row>
                                    </div>
                                </Link>
                            </Col>
                        );
                    })
                }
                {partner!=='samsunggr' && partner!=='xiaomigr' ? <Col lg={3} md={4} xs={11} className="mb-5 pb-3" key="error">
                    <div onClick={handleShow}>
                        <div className={`${partner}Item item brandItem text-center`}>
                            <Row style={{ height: "100%" }}>
                                <Col lg={12} md={12} xs={4} className="pt-md-4 pt-2">
                                    <img src={CDN + "/devices/not_found_icon.svg"} alt="notFound" height="40px" className="brandLogo" />
                                </Col>
                                <Col lg={12} md={12} xs={8} className="pt-3 text-left text-md-center">
                                    <small><b>{t('brands.error')}</b></small>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                :
                <Col lg={3} md={4} xs={11} className="mb-3 " key='unknown'>
                    <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${link}`} onClick={() => {dispatch({ type: "SAVE", data: 'unknown' }); sessionStorage.setItem("brand", 'unknown'); sessionStorage.setItem('path', `${process.env.PUBLIC_URL}/${props.partner}/`)}}>
                        <div className={`${partner}Item item brandItem text-center`}>
                            <Row style={{ height: "100%" }} onClick={() => {dispatch({ type: "SAVE", data: 'unknown' }); sessionStorage.setItem("brand", 'unknown');}}>
                                <Col lg={12} md={12} xs={4} className="pt-md-4 pt-2">
                                    <img src={CDN + "/devices/not-found.svg"} alt='unknown' height="40px" className="brandLogo" />
                                </Col>
                                <Col lg={12} md={12} xs={8} className="pt-3 text-left text-md-center">
                                    Άλλη μάρκα
                                </Col>
                            </Row>
                        </div>
                    </Link>
                </Col>
                }
            </Row>
            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h3 className="float-right" style={{ cursor: "pointer" }} onClick={handleClose}><FontAwesomeIcon icon={faTimes} /></h3>
                    <Row className="d-flex flex-column" style={{ height: "100%" }}>
                        <Col className="text-center text-lg-left my-3">
                            <Row className="justify-content-center align-items-center mb-4">
                                <Col xs="5" lg="3" className="text-center"><img src={CDN + "/devices/not_found_icon.svg"} alt="notFound" width="100%" /></Col>
                            </Row>
                            <Row className="justify-content-center align-items-center">
                                <Col xs="12" lg="8" style={{ fontSize: '16px' }}>{t('brands.errorModal1')}<b>{t('brands.errorModal2')}</b>{t('brands.errorModal3')}<b className={`${partner}Text`}>{t('brands.errorModal4')}</b>{t('brands.errorModal5')}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default withTranslation()(Brands);