import * as React from 'react';
import { useEffect, useState, useCallback, useContext } from "react";
import ReactMapGL, { Marker, Popup, FlyToInterpolator, GeolocateControl } from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { easeCubic } from 'd3-ease';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { GPContext } from "../context";
import { withTranslation } from 'react-i18next';
import { CDN } from '../constants'
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Mapbox = (props) => {
    const { t, i18n } = props;
    const [geolocate, setGeolocate] = useState();
    const TOKEN = 'pk.eyJ1IjoiZ3JlZW5wYW5kYWlvIiwiYSI6ImNrbGZoY2ZnMTBvOWEydnAwaTNxb3ZiZzUifQ.r8zPO79kYM7ronm9v_JVuw';
    const [currentPlaceId, setCurrentPlaceId] = useState((null));
    const mapRef = React.useRef()
    const { state, dispatch } = useContext(GPContext);

    const queryString=window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const type = urlParams.get('type')
    // console.log(type)
    // console.log(state.locations)
    let offers = (state[`${type}Offers`])

    let partners = [];
    if(offers){
        offers.forEach(offer => {
            partners.push(offer.partner)
        });
    }
    

    if (type == "cash") {
        for (let i in offers) {
            // console.log(i, offers, partners)
            if (offers[i].partner === 'greenpanda' && !partners.includes('abvasilopoulos')) {
                console.log(offers)
                let b = Object.assign({}, offers[i])
                b.partner = 'abvasilopoulos';
                offers.push(b)
                partners.push('abvasilopoulos')
            } 
            if (offers[i].partner === 'greenpanda' && !partners.includes('wind')){
                console.log(offers)
                let b = Object.assign({}, offers[i])
                b.partner = 'wind';
                offers.push(b)
                partners.push('wind')
            }
        }
    }

    
    // console.log(document.body.offsetWidth)
    const [viewport, setViewport] = useState({
        width: '100%',
        height: document.body.offsetWidth < 766 ? '70vh': '86vh',
        latitude: 37.9838,
        longitude: 23.7618,
        zoom: 8
    });
    const geolocateControlStyle = {
        right: 10,
        top: 10
    };

    const handleMarkerClick = (id) => {
        setCurrentPlaceId(id)
    }

    const zoom = (lat, long) => {
        setViewport({
            ...viewport,
            latitude: lat,
            longitude: long,
            zoom: 14,
            transitionDuration: 3000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: easeCubic
        });
    };
    const zoomOut = (lat, long) => {
        setViewport({
            ...viewport,
            latitude: lat,
            longitude: long,
            zoom: 5,
            transitionDuration: 6000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: easeCubic
        });
    };

    const handleViewportChange = useCallback(
        (newViewport) => setViewport(newViewport)
        , []);

    useEffect(() => {
        console.log('location',state.userLocation)
    }, [])
    useEffect(() => {
        if (state.userLocation && state.userLocation.latitude && state.userLocation.longitude) {
            zoom(state.userLocation.latitude, state.userLocation.longitude)
            setCurrentPlaceId(state.userLocation.code)
            setGeolocate(true)
        }
    }, [state.userLocation])

    useEffect(() => {
            setTimeout(() => {
                zoomOut(38, 23.8)
            }, 1000);
    }, []);
    const reversePartnerMapping = {
        'Pandas':'greenpanda',
        'AB Vasilopoulos': 'abvasilopoulos',
        'iStorm': 'istorm',
        'Wind':'wind',
        'Plaisio':'plaisio',
        'iStorm Cyprus':'istormcy',
        'Xiaomi Store': 'mistore' 
    }

    return (
        <>
            <div className="pandasmapContainer" >
                <ReactMapGL
                    ref={mapRef}
                    {...viewport}
                    width="100%" // It always override the view(viewport) width state.
                    height= {document.body.offsetWidth < 766 ? '58vh': '86vh'}
                    mapStyle="mapbox://styles/greenpandaio/ckryv5wfm6o3t17pkyvktadtm"
                    onViewportChange={handleViewportChange}
                    mapboxApiAccessToken={TOKEN}
                >
                    {geolocate && <GeolocateControl
                        style={geolocateControlStyle}
                        positionOptions={{ enableHighAccuracy: true }}
                        fitBoundsOptions={{ maxZoom: 14 }}
                        trackUserLocation={false}
                        auto
                    />}
                    {state.code === 0 && state.userLocation && state.userLocation.latitude && <Marker onClick={() => { zoom(state.userLocation.latitude, state.userLocation.longitude) }}
                        latitude={state.userLocation.latitude}
                        longitude={state.userLocation.longitude}
                    >
                        <div onClick={() => { }} className="pandasmarker" ><span><img className="pandasmarkerImg" src={CDN + "/website/map/location-pin-2965.png"} alt="mySpot" /></span></div>
                    </Marker>}
                    {state.locations.filter(locations => parseInt(locations.store) !== 4 && parseInt(locations.store) !== 38 && partners.includes(locations.partner) && (!locations.distance || locations.distance < 30 )).map((l, index) => (
                        <div key={index}>
                            <Marker onClick={() => { zoom(l.lat, l.lng) }}
                                latitude={l.lat}
                                longitude={l.lng}
                            >
                                <div onClick={() => handleMarkerClick(parseInt(l.store))} className="pandasmarker" ><span><img className="pandasmarkerImg" src={`${CDN}/pricing-app/icons/location-icon.svg`} alt="gpSpot" /></span></div>
                            </Marker>
                            {parseInt(l.store) === currentPlaceId &&
                                <Popup
                                    mapref={mapRef}
                                    latitude={l.lat}
                                    longitude={l.lng}
                                    closeButton={true}
                                    closeOnClick={false}
                                    onClose={() => setCurrentPlaceId(null)}
                                    anchor="bottom"
                                >
                                    <div>{l.name_gr}</div>
                                    <div style={{ fontSize: '12px', opacity: '0.8' }}>{l.partner_name_gr} {l.distance && <small>({l.distance} {t('sectionOne.distance')})</small>}</div>
                                    <div style={{ fontSize: '12px', opacity: '0.8', maxWidth: '200px' }}>{l.address_gr}</div>
                                    <a className="directions" href={l.location} >Οδηγίες Google Maps</a>
                                </Popup>}
                        </div>
                    ))}
                </ReactMapGL>
            </div >
        </>);
}
export default withTranslation()(Mapbox);