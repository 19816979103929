import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState, useRef, useCallback } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import { CDN } from '../constants'
import Autocomplete from "react-google-autocomplete";
import Map from './map';
import ReactMapGL, { Marker, Popup, FlyToInterpolator, GeolocateControl } from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { easeCubic } from 'd3-ease';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import * as turf from "@turf/turf";
import Maplist from './mapList';
import Mapbox from './mapBox';
import Footer from './footer';

const CourierMap = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    var partner = props.partner ? props.partner : sessionStorage.getItem('partner');
    var brand = props.brand ? props.brand : sessionStorage.getItem('brand');
    var model = props.model ? props.model : sessionStorage.getItem('model')
    var condition = props.model ? props.model : sessionStorage.getItem('condition');
    var capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');

    sessionStorage.setItem('address', state.address)
    sessionStorage.setItem('zipcode', state.zipcode)

    const checkUserPosition = () => {
        // if(state.userLocation.code === 0){
        //     dispatch({ type: "USER_LOCATION", data: { latitude: state.latitude, longitude: state.longitude} })
        // }
    }

    const [geolocation, setGeolocation] = useState({});
    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         console.log({ lat: position.coords.latitude, lng: position.coords.longitude });
    //         setGeolocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    //     });
    // }, [])

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        window.dataLayer.push({ event: 'Step Location Confirmation' });
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'location confirmation';

    if (!state.address){ 
        return (
            <>
            {props.partner === 'samsunggr' || props.partner==='xiaomigr' ? <div style={{display: 'none'}}>{window.location = process.env.PUBLIC_URL+`/${props.partner}/location/`} </div>
            :
            <div style={{display: 'none'}}>{window.location = process.env.PUBLIC_URL+`/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/location`} </div>
            }
            </>
            
        );
    }

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${state.partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div className='justify-content-center text-center'>
            {/* <Map /> */}
            
                <div style={{position: 'relative', borderRadius: '10px', height: '100%'}}>
            {/* <Col xs="11" className={"order-2 order-md-1 ps-md-5 "} > */}
                    <div className='mapBoxContainer samsung' style={{position: 'absolute', top: '0.2rem', left: '0.2rem', zIndex: '4'}}><Maplist props={props} /></div>
                  {/* </Col> */}

                  {/* <Col xs> */}
                    <div className='pt-5 pt-md-3'><Mapbox props={props} /></div>
                  {/* </Col> */}
                  </div>
                  {props.partner==='samsunggr' || props.partner==='xiaomigr' ?
                        <>
                            <div className='d-none d-md-flex'><Footer back={{ text: `Πίσω`, path: `${process.env.PUBLIC_URL}/${props.partner}/location` }} next={{ text: `Επιβεβαίωση διεύθυνσης`, path: `${process.env.PUBLIC_URL}/${props.partner}/tradein` }}></Footer></div>
                            <div className='d-md-none'><Footer onClick={{checkUserPosition}}  next={{ text: `Επιβεβαίωση διεύθυνσης`, path: `${process.env.PUBLIC_URL}/${props.partner}/tradein` }}></Footer></div>
                        </>
                    :
                    <>
                            <div className='d-none d-md-flex'><Footer back={{ text: `Πίσω`, path: `${process.env.PUBLIC_URL}/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/location` }} next={{ text: `Επιβεβαίωση διεύθυνσης`, path: `${process.env.PUBLIC_URL}/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/cash` }}></Footer></div>
                            <div className='d-md-none'><Footer  next={{ text: `Επιβεβαίωση διεύθυνσης`, path: `${process.env.PUBLIC_URL}/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/cash` }}></Footer></div>
                    </>
                    }
                  {/* <div className='d-none d-md-flex'><Footer back={{ text: `Πίσω`, path: `${process.env.PUBLIC_URL}/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/location` }} next={{ text: `Επιβεβαίωση διεύθυνσης`, path: `${process.env.PUBLIC_URL}/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/cash` }}></Footer></div>
                  <div className='d-md-none'><Footer  next={{ text: `Επιβεβαίωση διεύθυνσης`, path: `${process.env.PUBLIC_URL}/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/cash` }}></Footer></div> */}

        </div>
    );
}

export default withTranslation()(CourierMap);