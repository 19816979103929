import { Container, Navbar, Nav, Row, Col, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CDN } from '../constants'



const Partner = (props) => {
    const { t, i18n, children } = props;
    const { state, dispatch } = React.useContext(GPContext);
    var partner = props.partner ? props.partner.toLowerCase() : '';
    const [hasLocations, setHasLocations] = useState(false);
    let instore = sessionStorage.getItem('instore');

    var temp;

    const partner_link = {
        'gp': 'getDevices.php',
        'pandas': 'getDevices.php',
        'plaisio': 'getDevicesPlaisio.php',
        'verizon': 'getDevices.php',
        'istorm': 'getDevicesIstorm.php',
        'istormcy': 'getDevicesIstormCy.php',
        'mistore': 'getDevicesMi.php',
        'germanos': 'getDevices.php',
        'publiccy': 'getDevices.php',
        'yougr': 'getDevices.php',
        'samsunggr': 'getDevices.php',
        'xiaomigr': 'getDevices.php',
        'pmmgr': 'getDevices.php',
        'pmmcy': 'getDevices.php',
        'mediamarktgr': 'getDevices.php'
    }

    const powered_by = {
        'gp': '',
        'pandas': '',
        'plaisio': '',
        'verizon': '#FFFFFF',
        'istorm': '#1a1a1a',
        'istormcy': '#FFFFFF',
        'mistore': '',
        'germanos': '',
        'publiccy': '#FFFFFF',
        'yougr': '',
        'samsunggr': '#FFFFFF',
        'xiaomigr': '#FFFFFF',
        'pmmgr' : '',
        'pmmcy' : '',
        'mediamarktgr': ''
    }

    const partner_url = {
        'gp': 'https://www.greenpanda.io/',
        'pandas': 'https://www.pandas.io/el-GR',
        'plaisio': 'https://www.plaisio.gr/',
        'verizon': 'https://www.verizon.com/',
        'istorm': 'https://www.istorm.gr/',
        'istormcy': 'https://istorm.com.cy/',
        'mistore': 'https://www.mistore-greece.gr/',
        'germanos': 'https://www.germanos.gr',
        'publiccy': 'https://www.public.cy/',
        'yougr': 'https://www.you.gr/',
        'samsunggr': 'https://www.samsung.com/gr',
        'xiaomigr': 'https://www.mi.com/gr/',
        'pmmgr' : '#',
        'pmmcy': '#',
        'mediamarktgr': 'https://www.mediamarkt.gr/'
    }

    const partner_title = {
        'gp': 'Green Panda',
        'pandas': 'Pandas',
        'plaisio': 'Plaisio',
        'verizon': 'Verizon',
        'istorm': 'iStorm',
        'istormcy': 'iStorm',
        'mistore': 'MiStore',
        'germanos': 'Germanos',
        'publiccy': 'Public',
        'yougr': 'you.gr',
        'samsunggr': 'Samsunggr',
        'xiaomigr': 'Xiaomi',
        'pmmgr': 'PMM',
        'pmmcy': 'PMM',
        'mediamarktgr': 'MediaMarkt'
    }
    const partner_slogan = {
        'gp': '',
        'pandas': '',
        'plaisio': '| Γεια Με γεια',
        'verizon': '',
        'istorm': '| Trade n Upgrade',
        'istormcy': '| Trade n Upgrade',
        'mistore': '| Mi Exchange',
        'germanos': '',
        'publiccy': 'με trade-in',
        'yougr': '',
        'xiaomigr': '',
        'pmmgr': '| Trade&save',
        'pmmcy': '| Trade&save',
        'mediamarktgr': '| Trade&save'
    }
    var cashOffers, voucherOffers, offers;
    useEffect(() => {
        let [ x, z, partner, brand, model, capacity, powerson, condition, contact, y] = window.location.pathname.split('/')
        console.log('here', model)
        if (partner === 'pandas' && condition !== undefined && state.cashOffers == undefined) {
            axios.get(`https://imei-service.greenpanda.io/v2/offers/webquote`, 
                    { params : {brand: brand, model: model.replaceAll('-', ' ').replaceAll('_', '/'), condition: condition, capacity: capacity, flow: 'pandas', smartphone:1}}).then(res => {
                console.log("my res", res)
                offers = res.data.offers;
                cashOffers = offers ? offers.filter((offer) => offer.type === 'cash') : [];
                cashOffers.sort((a, b) => (a.price > b.price) ? -1 : 1)
                voucherOffers = offers ? offers.filter((offer) => offer.type === 'voucher') : [];
                voucherOffers.sort((a, b) => (a.price > b.price) ? -1 : 1)
                console.log("cash offers", cashOffers[0].price)
                console.log("voucher offers", voucherOffers)
                dispatch({
                    type: "SET_OFFERS",
                    payload1: cashOffers,
                    payload2: voucherOffers
                });
                sessionStorage.setItem('cashOffers', JSON.stringify(cashOffers))
                sessionStorage.setItem('voucherOffers', JSON.stringify(voucherOffers))
            }).catch((error) => {
                console.log(error)
                navigate(process.env.PUBLIC_URL+'/')
            })
        }
    }, [])

    useEffect(() => {

        if (partner.includes('instore') && !(partner.includes('gp') || partner.includes('pandas'))) {
            sessionStorage.setItem('instore', props.partner)
            partner = partner.split('-')
            partner = partner[0]
        } else {
            sessionStorage.removeItem('instore')
        }
        if (!partner_url[partner]) {
            window.location = process.env.PUBLIC_URL+'/pandas';
        }
        dispatch({ type: 'partner', payload: partner })
        console.log(window.location.pathname)
    }, [])

    useEffect(() => {
        var category = sessionStorage.getItem('device_category');
        var url;
        if (category === 'ipad' && (state.partner === 'istorm' || state.partner === 'istormcy')) {
            dispatch({ type: "SET_CATEGORY", payload: category })
            url = `https://server.greenpanda.io/scripts/getDevicesIstormIpad.php?partner=${partner}`;
        }
        else {
            url = `https://server.greenpanda.io/scripts/${partner_link[partner]}`;
        }

        axios.get(url).then(res => {
            dispatch({
                type: "SET_DEVICES",
                payload: res.data
            });
        })

        axios.get(`https://server.greenpanda.io/scripts/getBrands.php`).then(res => {
            dispatch({
                type: "SET_BRANDS",
                payload: res.data
            });
        })
    }, [])

    useEffect(() => {
        console.log(sessionStorage.getItem('brand'))
        setTimeout(() => {
            axios.get(`https://session-service.greenpanda.io/locations${(props.partner === 'pandas')? '?flow=pandas' : '?partner=' + props.partner ?? state.partner}`)
                .then((response) => {
                    setHasLocations(true);
                    dispatch({
                        type: "SET_LOCATIONS",
                        payload: response.data
                    });
                }).catch((err) => {
                    dispatch({ type: "FETCH_FAILURE" });
                });
        }, 300)
    }, [])

    useEffect(() => {
        document.title = ((partner === 'gp' || partner === 'pandas') ? (t('gpTabTitle') + (sessionStorage.getItem('brand') !== 'unknown'?sessionStorage.getItem('brand'):'')) + (sessionStorage.getItem('model') !== 'unknown'?' '+sessionStorage.getItem('model'):'') + ' smartphone ' : t('partnerTabTitle')) + (i18n.language === 'el' ? ` ${partner_slogan[partner]} ` : '') + `| ${partner_title[partner]}`
    }, [i18n.language, sessionStorage.getItem('brand')])

    useEffect(() => {
        partner = temp
    }, [])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const greek = () => {
        i18n.changeLanguage("el")
        sessionStorage.setItem('lang', 'el')
        setShow(false)
    }
    const english = () => {
        i18n.changeLanguage("en")
        sessionStorage.setItem('lang', 'en')
        setShow(false)
    }

    return (
        <>
            {!(state.devices && state.brands) && <Row className="justify-content-md-center pt-5 mt-5">
                <Col lg="12" className="text-center">
                    <h3 className="title mb-4">{t('wait')}</h3>
                    <div className={`${state.partner}Loader`}>Loading...</div>
                </Col>
            </Row>}
            {state.devices && state.brands && <>
                {state.partner !== 'gp' &&
                    <Navbar className={`${state.partner}Nav fixed-top px-0 px-md-2`} expand="xl">
                        <div className='justify-content-between d-flex align-items-center' style={{width: '100%', margin: '0 15px'}}>
                            <div>
                                <a href={partner_url[state.partner]} target="_blank" rel="noreferrer">
                                    <div className='d-flex '>
                                        {(state.partner === 'pandas') ? <>
                                            <img src={`${CDN}/pricing-app/partners/${state.partner}/logo_white.svg`} alt="Logo" height="35px" className={`d-inline d-md-none my-2 ${state.partner}LogoHeight`} />
                                            <img src={`${CDN}/pricing-app/partners/${state.partner}/logo_white.svg`} alt="Logo" height="45px" className={`d-none d-md-inline my-2 ${state.partner}LogoHeight`} />
                                        </> :
                                        state.partner === 'istorm' ? 
                                            <div>
                                                <img src={`${CDN}/pricing-app/partners/${state.partner}/logo.png?`} alt="Logo" height="35px" className={`d-inline d-md-none my-2 ${state.partner}LogoHeight`} />
                                                <img src={`${CDN}/pricing-app/partners/${state.partner}/logo.png?`} alt="Logo" height="45px" className={`d-none d-md-inline my-2 ${state.partner}LogoHeight`} />
                                            </div>
                                            :
                                            <div>
                                                <img src={`${CDN}/pricing-app/partners/${state.partner}/logo.png`} alt="Logo" height="35px" className={`d-inline d-md-none my-2 ${state.partner}LogoHeight`} />
                                                <img src={`${CDN}/pricing-app/partners/${state.partner}/logo.png`} alt="Logo" height="45px" className={`d-none d-md-inline my-2 ${state.partner}LogoHeight`} />
                                            </div>}
                                        {(state.partner === 'yougr' || state.partner === 'plaisio' || state.partner.includes('pmm') || state.partner === 'mediamarktgr') && <div>
                                            <img src={`${CDN}/pricing-app/partners/${state.partner}/tradein_logo.png`} alt="Logo" height="35px" className={`d-inline d-md-none ${state.partner}TradeinLogoHeight my- mx-4 `} />
                                            <img src={`${CDN}/pricing-app/partners/${state.partner}/tradein_logo.png`} alt="Logo" height="45px" className={`d-none d-md-inline ${state.partner}TradeinLogoHeight my- mx-4 `} />
                                        </div>}
                                    </div>
                                </a>
                            </div>
                            <div>
                                {state.partner !== 'pandas' && <span>
                                    <small className="d-none d-md-inline" style={{ color: `${powered_by[state.partner]}` }}>powered by</small>
                                    <a href="https://pandas.io/el-GR" target="_blank" rel="noreferrer">
                                        {(state.partner === 'verizon' || state.partner === 'istormcy' || state.partner === 'publiccy' || state.partner === 'samsunggr' || state.partner === 'xiaomigr') ? <>
                                            <img src={CDN + "/pricing-app/partners/pandas/logo_white.svg"} alt="Logo" height="25px" className="d-md-none my-2 ml-md-3" />
                                            <img src={CDN + "/pricing-app/partners/pandas/logo_white.svg"} alt="Logo" height="35px" className="d-none d-md-inline ml-md-3" /> </> : <>
                                            <img src={CDN + "/pricing-app/partners/pandas/logo.svg"} alt="Logo" height="25px" className="d-md-none my-2 ml-md-3" />
                                            <img src={CDN + "/pricing-app/partners/pandas/logo.svg"} alt="Logo" height="35px" className="d-none d-md-inline my-2 ml-md-3" /></>}
                                    </a>
                                </span>}
                            </div>
                        </div>
                    </Navbar>}
                {state.partner === 'gp' && <Navbar bg="white" expand="xl" className="fixed-top">
                    <Container fluid className="navWidth">
                        <Navbar.Brand href="https://greenpanda.io">
                            <img src={CDN + "/pricing-app/partners/gp/logo.png"} alt="Logo" height="35px" className="d-md-none" />
                            <img src={CDN + "/pricing-app/partners/gp/logo.png"} alt="Logo" height="45px" className="d-none d-md-inline" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"><FaBars aria-controls="basic-navbar-nav" /></Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                            </Nav>
                            <Nav className="mr-2">
                                <Nav.Link target="_blank" href="https://greenpanda.io/syxnes-erotiseis/" >{t('navbar.faq')}</Nav.Link>
                                <Nav.Link target="_blank" href="https://greenpanda.io/proetimasia-syskevis/">{t('navbar.prep')}</Nav.Link>
                                <Nav.Link target="_blank" href="https://greenpanda.io/scooter/">Scooter</Nav.Link>
                                <Nav.Link target="_blank" href="https://greenpanda.io/epikoinonia/">{t('navbar.contact')}</Nav.Link>
                            </Nav>
                            <Nav className="mr-2 flex-md-row">
                                <a href="https://greenpanda.io/atm" className="mr-md-2 mybutton mt-2 mt-xl-0">{t('navbar.atm')}</a>
                                <a href="/" className="mybutton mt-2 mt-xl-0">{t('navbar.pricing')}</a>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                    <div className="language" onClick={handleShow}>{i18n.language === 'el' ? <img src={CDN + "/language-icons/gr.svg"} alt="gr" width="30" /> : <img src={CDN + "/language-icons/en.svg"} alt="gr" width="30" />}</div>
                </Navbar>}
                {!hasLocations &&
                    <Row className="justify-content-md-center pt-5 mt-5">
                        <Col lg="12" className="text-center">
                            <h3 className="title mb-4">{t('wait')}</h3>
                            <div className={`${state.partner}Loader`}>Loading...</div>
                        </Col>
                    </Row>
                }
                {hasLocations && !((window.location.href.includes('location/map') 
                    && window.location.href.includes('samsunggr/')) )&& <div className="mt-5 pt-3 pt-md-4">
                    {children}
                </div>}
                {hasLocations && (window.location.href.includes('location/map') 
                    && window.location.href.includes('samsunggr/')) && <div className="mt-5 samsung-map">
                        {children}
                    </div>}
            </>}
            <Modal id="langModal" show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body >
                    <div className="d-flex justify-content-between">
                        <div></div>
                        <h3 className="fs20 fw700">{t('lang')}</h3>
                        <h3 className="" onClick={handleClose} style={{ cursor: "pointer", fontSize: '15px' }} ><FontAwesomeIcon icon={faTimes} /></h3>
                    </div>
                    <Row className="d-flex justify-content-center py-4 px-3" style={{ height: "100%" }}>
                        <Col xs="12" className="text-center mb-3">
                            <img src={CDN + "/language-icons/gr.svg"} alt="gr" width="30" />
                            <span onClick={greek} className="ms-3 langText">{t('el')} (EL)</span>
                        </Col>
                        <div style={{ width: '100%', borderBottom: '1px solid #DDDDDD' }}></div>
                        <Col xs="12" className="text-center  mt-3">
                            <img src={CDN + "/language-icons/en.svg"} alt="en" width="30" />
                            <span onClick={english} className="ms-3 langText">{t('en')} (EN)</span>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>

    );
}

export default withTranslation()(Partner);