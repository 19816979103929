import { Row, Col } from "react-bootstrap";
import { GPContext } from "../context";
import { Link } from "@reach/router";
import React, { useState, useEffect } from "react";
import { redirectAfterTimeout } from "../viewHelperFunctions";
import { CDN } from "../constants";
import IstormOfferGreyItem from "./ui-components/istormOfferGreyItem";
import IstormOfferItem from "./ui-components/istormOfferItem";

const SHOULD_DISPLAY_PROMO_BOXES = false;

const Istorm = (props) => {
  const { state, dispatch } = React.useContext(GPContext);
  const { t, i18n } = props;
  var brand = props.brand ? props.brand.toLowerCase() : "";
  var model = props.model ? props.model.toLowerCase() : "";
  var capacity =
    props.capacity && props.capacity !== "stock"
      ? props.capacity.toLowerCase()
      : "";
  var powerson = props.powerson ? props.powerson.toLowerCase() : "";
  var condition = props.condition ? props.condition.toLowerCase() : "";
  var partner = state.partner ? state.partner.toLowerCase() : "";
  var contact = props.contact ? props.contact.toLowerCase() : "";
  let instore = sessionStorage.getItem("instore");

  var devices = state.devices.filter(
    (a) =>
      a.brand.toLowerCase() === brand.toLowerCase() &&
      a.model.toLowerCase() ===
        model.replaceAll("-", " ").replaceAll("_", "/").toLowerCase()
  );

  var variants = devices.reduce((map, obj) => {
    map[obj.capacity] = obj;
    return map;
  }, {});

  function addCoupon(price, coupon) {
    let sum = 0;
    if (price) sum += price;
    if (coupon) sum += coupon;
    return sum;
  }

  function changeToGreek(c) {
    if (c === "very_good") return "Σαν Καινούρια";
    if (c === "good") return "Καλή";
    if (c === "poor") return "Μέτρια";
    if (c === "faulty") return "Κακή";
  }
  capacity = capacity.toUpperCase();
  var device = variants[capacity.toUpperCase()];
  if (capacity === "") capacity = "stock";
  var finalPrice = device
    ? powerson === "ber"
      ? 1
      : device[condition + "_price"]
    : 0;
  var coupon = device ? device[condition + "_coupon"] : 0;
  var imageUrl = `https://pricing.greenpanda.io/phone.png`;
  if (brand === "apple")
    imageUrl = `https://pricing.greenpanda.io/devices/${device?.brand.toLowerCase()}/${device?.model
      .replaceAll(" ", "_")
      .toLowerCase()}.jpg`;

  var offer;
  if (state.category === "smartwatch") {
    const partsOffer = state.partsOffer;
    offer = {
      brand: device?.brand,
      model: device?.model,
      name:
        device?.model.length < 5
          ? device?.brand + " " + device?.model
          : device?.model,
      capacity: device?.capacity,
      coupon: partsOffer?.coupon,
      powerson: powerson,
      condition: changeToGreek(condition),
      finalPrice: addCoupon(partsOffer?.price, partsOffer?.coupon),
      imageUrl: imageUrl,
    };
  } else {
    offer = {
      brand: device?.brand,
      model: device?.model,
      name:
        device?.model.length < 5
          ? device?.brand + " " + device?.model
          : device?.model,
      capacity: device?.capacity,
      coupon: coupon,
      powerson: powerson,
      condition: changeToGreek(condition),
      finalPrice: finalPrice,
      imageUrl: imageUrl,
    };
  }

  const [crossout, setCrossout] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      window.dataLayer.push({ event: "Step Offer Page" });
    }
    dispatch({ type: "partner", payload: state.partner });
    if (
      device.old_price !== 0 &&
      finalPrice - device.old_price >= 0.1 * device.old_price &&
      finalPrice > 50
    ) {
      setCrossout(true);
    }
    if (instore) {
      redirectAfterTimeout("/" + props.partner);
    }
  }, []);

  window.brand = brand;
  window.partner = partner;
  window.model = model;
  window.language = sessionStorage.getItem("lang");
  window.capacity = capacity.toUpperCase();
  window.condition = condition;
  window.step = "offer";
  window.price = finalPrice;

  if (!device) return <>{(window.location = process.env.PUBLIC_URL + "/")} </>;
  else
    return (
      <div>
        <Row className="justify-content-center pt-2 p-1">
          <Col xs="12" md="12" className="d-none d-sm-block text-center mb-5">
            <h2 id="offerModel">
              <img
                src={CDN + "/pricing-app/photos/trade.png"}
                alt="trade"
                width="380"
                style={{ maxWidth: "100%" }}
              />
            </h2>
            <h4 className="font-weight-bold mb-3">
              Αποχαιρέτησε το παλιό σου{" "}
              {state.category === "ipad"
                ? "iPad"
                : state.category === "iphone"
                ? "iPhone"
                : "Apple Watch"}{" "}
              και <span style={{ color: "#3098cb" }}>κέρδισε!</span>
            </h4>
          </Col>
          <Col xs="12" md="12" className="d-sm-none text-center mb-2">
            <h3 id="offerModel">
              <img
                src={CDN + "/pricing-app/photos/trade.png"}
                alt="trade"
                width="320"
                style={{ maxWidth: "100%" }}
              />
            </h3>
            <h4 className="font-weight-bold mb-3">
              Αποχαιρέτησε το παλιό σου{" "}
              {state.category === "ipad" ? "iPad" : "iPhone"} και{" "}
              <span style={{ color: "#3098cb" }}>κέρδισε!</span>
            </h4>
          </Col>
          {state.category === "ipad" && (
            <IstormOfferItem
              offer={offer.finalPrice}
              category={state.category}
              text="Απόκτησε το νέο σου iPad"
              img={
                <img
                  src={`${CDN}/pricing-app/photos/newipad.png`}
                  alt="iPad"
                  width="95%"
                  style={{ maxWidth: "300px" }}
                />
              }
              partner={props.partner}
              link={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`}
            />
          )}
          {state.category === "smartwatch" && (
            <IstormOfferItem
              offer={offer.finalPrice}
              category={state.category}
              text="Απόκτησε το νέο σου Apple Watch"
              img={
                <img
                  src={`${CDN}/pricing-app/photos/apple-watches.png`}
                  alt="Apple Watch"
                  width="95%"
                  style={{ maxWidth: "300px" }}
                />
              }
              partner={props.partner}
              link={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`}
            />
          )}
          {state.category === "iphone" && offer.finalPrice <= 50 && (
            <IstormOfferItem
              offer={offer.finalPrice}
              category={state.category}
              text="Απόκτησε το νέο σου iPhone 14 και 14 Plus ή iPhone 13"
              img={
                <img
                  src={`${CDN}/devices/${device.brand.toLowerCase()}/${device.model
                    .replaceAll(" ", "_")
                    .toLowerCase()}.jpg`}
                  alt="Phone"
                  width="100%"
                  style={{ maxWidth: "160px" }}
                />
              }
              partner={props.partner}
              link={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`}
            />
          )}

          {state.category === "iphone" && offer.finalPrice > 50 && (
            <IstormOfferItem
              offer={offer.finalPrice}
              category={state.category}
              text="Απόκτησε το νέο σου iPhone 15 series"
              img={
                <img
                  src={`${CDN}/pricing-app/photos/iphone-14-promo.png`}
                  alt="Phone"
                  width="200%"
                  style={{ maxWidth: "300px", marginLeft: "-40px" }}
                />
              }
              partner={props.partner}
              link={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`}
            />
          )}
          {state?.category !== "smartwatch" && (
            <Row
              className="pt-4 justify-content-center"
              style={{ width: "100%" }}
            >
              <Col
                xs="10"
                sm="8"
                md="6"
                lg="4"
                className="text-center mb-5"
                style={{ fontSize: "10px" }}
              >
                *Η τελική τιμή καθορίζεται αφού αξιολογηθεί η κατάσταση της
                συσκευής και η υγεία της μπαταρίας.
              </Col>
            </Row>
          )}
          {SHOULD_DISPLAY_PROMO_BOXES &&
            state.category === "iphone" &&
            offer.finalPrice > 50 && (
              <Row className="justify-content-center mb-4">
                <Col xs="10" lg="6">
                  <IstormOfferGreyItem
                    offer={offer.finalPrice + 50}
                    text="Απόκτησε το νέο σου iPhone 13, 14 & 14 Plus"
                    partner={props.partner}
                    instore={instore}
                    imgSrc={`${CDN}/pricing-app/photos/iphone-14-plus.png`}
                    link={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`}
                  />
                </Col>
                <Col xs="10" lg="6">
                  <IstormOfferGreyItem
                    offer={offer.finalPrice}
                    text="Απόκτησε το νέο σου iPhone 16 / 12 / 11 / SE"
                    partner={props.partner}
                    instore={instore}
                    imgSrc={`${CDN}/pricing-app/photos/iphone-14-pro.png`}
                    link={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`}
                  />
                </Col>
              </Row>
            )}
        </Row>
        <Row className="pt-4 justify-content-center">
          <Col xs="12" md="12" className="text-center mb-5">
            <h2 className="d-none d-md-block">
              <b>Είναι τόσο απλό!</b>
            </h2>
            <h5 className="d-md-none">
              <b>Είναι τόσο απλό!</b>
            </h5>
          </Col>
          <Col
            xs="12"
            md="4"
            className="d-flex flex-column align-items-center text-center mb-5"
          >
            <div className="smallStep mb-2">1</div>
            <img
              src={
                state.category === "ipad"
                  ? `${CDN}/pricing-app/photos/step1ipad.png`
                  : state.category === "iphone"
                  ? `${CDN}/pricing-app/photos/step1.png`
                  : `${CDN}/categories/smartwatch.png`
              }
              style={{ height: "80px", width: "auto" }}
              alt="store"
              className="mb-1"
            />
            <h5 style={{ maxWidth: "300px" }}>
              <b>
                Αποχαιρέτησε το παλιό σου{" "}
                {state.category === "ipad"
                  ? "iPad"
                  : state.category === "iphone"
                  ? "iPhone"
                  : "Apple Watch"}
                .
              </b>
            </h5>
          </Col>
          <Col
            xs="12"
            md="4"
            className="d-flex flex-column align-items-center text-center mb-5"
          >
            <div className="smallStep mb-2">2</div>
            <img
              src={CDN + "/pricing-app/icons/step2.png"}
              style={{ height: "80px", width: "auto" }}
              alt="store"
              className="mb-1"
            />
            <h5 style={{ maxWidth: "300px" }}>
              <b>Φέρτο σε ένα κατάστημα iStorm για να το αξιολογήσουμε.</b>
            </h5>
          </Col>
          <Col
            xs="12"
            md="4"
            className="d-flex flex-column align-items-center text-center mb-5"
          >
            <div className="smallStep mb-2">3</div>
            <img
              src={
                state.category === "ipad"
                  ? `${CDN}/pricing-app/photos/step3ipad.png`
                  : state.category === "iphone"
                  ? `${CDN}/pricing-app/photos/step3_d.png`
                  : `${CDN}/pricing-app/photos/apple-watch.png`
              }
              style={{ height: "80px", width: "auto" }}
              alt="store"
              className=" mb-1"
            />
            <h5 style={{ maxWidth: "300px" }}>
              <b>
                Καλωσόρισε το νέο σου{" "}
                {state.category === "ipad"
                  ? "iPad"
                  : state.category === "iphone"
                  ? "iPhone"
                  : "Apple Watch"}
                !
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col xs="12" md="12" className="text-center mb-5">
            <img
              src={CDN + "/pricing-app/icons/guy.png"}
              width="150"
              alt="Guy"
              style={{ marginBottom: "-70px" }}
            />
            <div
              className="p-5"
              style={{ background: "#f1f3f5", borderRadius: "50px" }}
            >
              <h4 className="title mt-5 mb-2">
                <span style={{ color: "#3098cb" }}>Έχεις απορίες;</span>
              </h4>
              <h4 className="title mb-3">
                {" "}
                Ο ειδικός της Apple είναι κοντά σου!
              </h4>
              <Row
                className="pt-4 text-center d-flex justify-content-around"
                style={{ color: "#3098cb" }}
              >
                <Col xs="12" md="4" className="text-center mb-5">
                  <Link
                    to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`}
                    style={{ color: "#3098cb" }}
                  >
                    <img
                      src={CDN + "/pricing-app/icons/store_icon.png"}
                      width="80"
                      alt="store"
                      className="mb-3"
                    />
                    <h6>
                      <b>
                        Επισκέψου <br />
                        ένα κατάστημα iStorm
                      </b>
                    </h6>
                  </Link>
                </Col>
                {partner === "istorm" && (
                  <Col xs="12" md="4" className="text-center mb-5">
                    <a
                      href="tel:2119997590"
                      target="_blank"
                      style={{ color: "#3098cb" }}
                      rel="noreferrer"
                    >
                      <img
                        src={CDN + "/pricing-app/icons/iphone.png"}
                        width="80"
                        alt="store"
                        className="mb-3"
                      />
                    </a>
                    <a
                      href="tel:2119997590"
                      target="_blank"
                      style={{ color: "#3098cb" }}
                      rel="noreferrer"
                    >
                      <h6 className="font-weight-bold">
                        Τηλεφώνησε στο <br /> 2119997590<sup>*</sup>
                      </h6>
                    </a>
                  </Col>
                )}
                <Col xs="12" md="4" className="text-center mb-5">
                  {partner === "istorm" && (
                    <a
                      href="https://www.istorm.gr/contact/"
                      style={{ color: "#3098cb" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={CDN + "/pricing-app/icons/mail_icon.png"}
                        width="80"
                        alt="store"
                        className="mb-3"
                      />
                    </a>
                  )}
                  {partner === "istorm" && (
                    <a
                      href="https://www.istorm.gr/contact/"
                      style={{ color: "#3098cb" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h6 className="font-weight-bold">
                        Συμπλήρωσε <br />
                        τη φόρμα επικοινωνίας
                      </h6>
                    </a>
                  )}
                  {partner === "istormcy" && (
                    <a
                      href="https://www.istorm.com.cy/contact/"
                      style={{ color: "#3098cb" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={CDN + "/pricing-app/icons/mail_icon.png"}
                        width="80"
                        alt="store"
                        className="mb-3"
                      />
                    </a>
                  )}
                  {partner === "istormcy" && (
                    <a
                      href="https://www.istorm.com.cy/contact/"
                      style={{ color: "#3098cb" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h6 className="font-weight-bold">
                        Συμπλήρωσε <br />
                        τη φόρμα επικοινωνίας
                      </h6>
                    </a>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="mb-5 pb-5">
          <Col>
            {partner === "istorm" && (
              <div className="istormFooter text-center p-5">
                * Το τηλεφωνικό μας κέντρο λειτουργεί Δευτέρα - Παρασκευή, 10:00
                - 18:00
              </div>
            )}
          </Col>
        </Row>
        {/* <div className="footer row" style={{ background: 'rgba(0, 0, 0, 0)', boxShadow: 'none', marginBottom: '15px', height: '60px' }}>
                <Link to={`${process.env.PUBLIC_URL}/${partner}/apple`} className={`istormNext next col-8 offset-2 col-md-4 offset-md-4 `}> Νέα αξιολόγηση </Link>
            </div> */}
      </div>
    );
};

export default Istorm;
