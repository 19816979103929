import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link,  navigate } from "@reach/router"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {CDN} from '../constants'

const Germanos = (props) => {
    const { t, i18n} = props;
    const { state, dispatch } = React.useContext(GPContext);

    var partner = props.partner ? props.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    let instore = sessionStorage.getItem('instore');


    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }
    const [open1, setOpen1] = useState(false);
    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    console.log('------> ' + device)
    console.log(finalPrice)
    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        coupon: device.coupon,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl,
        contact: state.contact
    }

    useEffect(() => {
        console.log("State: ", state);
        console.log(props)
        window.dataLayer.push({ event: 'Step Offer Page' });
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = sessionStorage.getItem('lang');
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'offer';
    window.price = finalPrice;

    console.log(brand)
    console.log(offer.brand)

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div>
            <Row className="justify-content-center pb-5">
                <Col xs="12" md="11" lg="8" className="text-center my-3">
                        <div className="title">Αποχαιρέτησε το {device.name} και καλωσόρισε <span style={{ color: "#1f407a" }}> νέα προϊόντα </span> με μεγάλο <span style={{ color: "#1f407a" }}> όφελος</span>!</div>
                </Col>
                <Col xs="10" sm="8" md="12" lg="10" xl="9">
                    <Row style={{ border: '2px solid #E5E4E5', padding: '20px 0px', borderRadius: '10px' }} className="justify-content-center align-items-center">
                        <Col md="5" className="text-center">
                            <h3 className="d-md-none mb-0">{t('offer.get')} <b style={{ fontSize: '34px', color: '#41D900' }}><br />{offer.finalPrice}</b><b style={{ fontSize: '24px', color: '#41D900' }}>€</b></h3>
                            {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand + "/" + model.replaceAll('-', '_') + ".jpg"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                            {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand + "/" + model.replaceAll('-', '_') + ".png"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                        </Col>
                        <Col md="7" lg="6" className="text-left mt-3">
                            <div className="d-md-none text-center mx-3" style={{ fontSize: '8px', opacity: '0.5' }}>{t('offer.offerText')}</div>
                            <div className="d-md-none my-3" style={{ borderBottom: '2px dashed #E5E4E5' }}></div>
                            <h5 className="d-none d-md-block title text-left mb-4">{device.name}{device.brand === 'Apple' && ', ' + capacity}</h5>
                            <h6 className="title d-md-none text-center mb-1">{device.name}{device.brand === 'Apple' && ', ' + capacity}</h6>
                            <div className="d-flex flex-column flex-md-row align-items-center mb-md-4">
                                {condition === 'very_good' && <div style={{ backgroundColor: '#E7FAF5', padding: '15px 20px', borderRadius: '10px' }} className="d-flex align-items-center">
                                    <div className="mr-3"><img src={CDN + "/pricing-app/condition/very_good_filled_green_icon.png"} alt="warning" width="27" /></div>
                                    <div style={{ fontSize: '14px' }}><b>{t('offer.veryGood')}</b></div>
                                </div>}
                                {condition === 'good' && <div style={{ backgroundColor: '#E4F3FF', padding: '15px 20px', borderRadius: '10px' }} className="d-flex align-items-center">
                                    <div className="mr-3"><img src={CDN + "/pricing-app/condition/good_filled_blue_icon.png"} alt="warning" width="27" /></div>
                                    <div style={{ fontSize: '14px' }}><b>{t('offer.good')}</b></div>
                                </div>}
                                {condition === 'poor' && <div style={{ backgroundColor: '#FFF5E8', padding: '15px 20px', borderRadius: '10px' }} className="d-flex align-items-center">
                                    <div className="mr-3"><img src={CDN + "/pricing-app/condition/poor_filled_icon.svg"} alt="warning" width="27" /></div>
                                    <div style={{ fontSize: '14px' }}><b>{t('offer.poor')}</b></div>
                                </div>}
                                {condition === 'faulty' && <div style={{ backgroundColor: '#FFE6E2', padding: '15px 20px', borderRadius: '10px' }} className="d-flex align-items-center">
                                    <div className="mr-3"><img src={CDN + "/pricing-app/condition/faulty_filled_icon.svg"} alt="warning" width="27" /></div>
                                    <div style={{ fontSize: '14px' }}><b>{t('offer.faulty')}</b></div>
                                </div>}
                            </div>
                            <div className="d-none d-md-block" style={{ borderBottom: '2px dashed #E5E4E5' }}></div>
                            <div className="mt-3 d-none d-md-block" style={{ fontSize: '34px', lineHeight: '25px' }}>{t('offer.get')} <b style={{ fontSize: '38px', color: '#24447c' }}>{offer.finalPrice}</b><b style={{ fontSize: '24px', color: '#24447c' }}>€</b></div>
                            <div className="d-none d-md-block mt-3" style={{ fontSize: '9px', opacity: '0.5' }}>*Η τελική τιμή καθορίζεται αφού αξιολογηθεί η κατάσταση της συσκευής και η υγεία της μπαταρίας.</div>
                        </Col>
                    </Row>
                    <Row className="pt-5">
                    <Col xs="12" md="12" className="text-center mb-md-4">
                        <h3 className="d-none d-md-block title">Είναι τόσο απλό!</h3>
                        <h5 className="d-md-none title">Είναι τόσο απλό!</h5>
                    </Col>
                </Row>
                <Row style={{ background: '#F0F3F5' }} className="d-none d-md-block mb-5 py-4">
                    <Col className="d-flex justify-content-center">
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/germanos/map_icon.svg"} width="70" alt="map" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα  <b> Γερμανός</b></div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/germanos/smartphone_icon.svg"} width="70" alt="smartphone" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b>, αφού το αξιολογήσουμε</div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/germanos/wallet_icon.svg"} width="70" alt="wallet" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                        </Col>
                    </Col>
                </Row>
                <Slider className="d-md-none mt-2 mb-5" {...settings}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/germanos/map_icon.svg"} alt="map" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα  <b> Γερμανός</b></div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/germanos/smartphone_icon.svg"} alt="smartphone" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b> του αφού το αξιολογήσουμε</div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/germanos/wallet_icon.svg"} alt="wallet" height="50px" />
                        <div style={{ maxWidth: '180px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                    </div>
                </Slider>
                <Row className="my-5 py-2 justify-content-center">
                    <Col xs="9" sm="7" md="5" lg="5" xl="5">
                        {instore ? <Link to={`${process.env.PUBLIC_URL}/${instore}`} className="">
                            <div className="germanosNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                        </Link>
                        :
                        <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`} className="">
                            <div className="germanosNext next px-5 mt-4">Βρες ένα κατάστημα Γερμανός</div>
                        </Link>
                        }
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xs="11" md="10" className="text-center">
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι πρέπει να κάνω; </div>
                                            <div><img src={CDN + "/pricing-app/partners/germanos/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Το μόνο που χρειάζεται να κάνεις είναι να φέρεις το παλιό σου smartphone σε ένα κατάστημα Γερμανος.  Θα αξιολογήσουμε άμεσα τη συσκευή σου και θα σε ενημερώσουμε για το όφελος που μπορείς να λάβεις. Στη συνέχεια, μπορείς να αποκτήσεις το νέο σου smartphone σε χαμηλότερη τιμή.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Ποιες συσκευές συμμετέχουν στο πρόγραμμα;   </div>
                                            <div><img src={CDN + "/pricing-app/partners/germanos/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Μπορείς να δεις τα brands αλλά και τις συσκευές που συμμετέχουν στον μηχανισμό απόσυρσης <a href="https://pricing.greenpanda.io/germanos/">εδώ</a>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Πρέπει η συσκευή να είναι λειτουργική προκειμένου να μπορεί να μπει στο πρόγραμμα;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/germanos/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Ναι, η συσκευή σου θα πρέπει να είναι λειτουργική και ενεργή, προκειμένου να λάβεις την αξία που της αντιστοιχεί με βάση την κατάστασή της. Εάν το smartphone σου δεν είναι λειτουργικό μπορείς και πάλι να μας το παραδώσεις, χωρίς να λάβεις χρηματικό ποσό, ώστε να το ανακυκλώσουμε υπεύθυνα.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Με ποια κριτήρια καθορίζεται η αξία της συσκευής μου;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/germanos/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η αξία της συσκευής καθορίζεται από τη μάρκα, το μοντέλο, τη χρονολογία, την κατάσταση, και την τρέχουσα τιμή της στην αγορά ως μεταχειρισμένη συσκευή.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι θα πρέπει να κάνω πριν παραδώσω το smartphone μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/germanos/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Θα πρέπει αρχικά να κάνεις αντιγραφή όλων των δεδομένων σου σε κάποιο ασφαλές αποθηκευτικό χώρο και να επαναφέρεις τη συσκευή στις αρχικές της ρυθμίσεις . Σε κάθε περίπτωση τα δεδομένα θα διαγραφούν για τη δική σου ασφάλεια μόλις παραλάβουμε τη συσκευή. Φυσικά με το back up Μπορούμε να σε βοηθήσουμε και εμείς οπότε μην αγχώνεσαι, αρκεί να έχεις μαζί σου τους κατάλληλους κωδικούς !
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι γίνεται με τα δεδομένα μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/germanos/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Πριν παραδώσεις το παλιό σου smartphone θα πρέπει να έχεις κάνει backup σε κάποια άλλη συσκευή σου και να έχεις κάνει επαναφορά δεδομένων και ρυθμίσεων.  Ωστόσο, όλες οι συσκευές περνούν από μια συγκεκριμένη διαδικασία διαλογής μετά την παραλαβή. Στην περίπτωση όπου δεν έχει γίνει επαναφορά, το εξειδικευμένο μας προσωπικό πραγματοποιεί μια αυτοματοποιημένη διαδικασία επαναφοράς για να διασφαλίσει την προστασία και την οριστική διαγραφή των προσωπικών σου δεδομένων.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="d-none d-md-block mb-5 py-4">
                    <Col>
                        <Row className="py-4" style={{ background: '#162d55', borderRadius: '8px' }}>
                            <Col className="text-center mb-4">
                                <div className="">
                                    <h3 className="font-weight-bold"><span style={{ color: "#ffff" }}>Έχεις απορίες;</span></h3>
                                    <h6 style={{ color: "#77818E" }} className="font-weight-bold">Είμαστε εδώ για να σου τις λύσουμε.</h6>
                                </div>
                            </Col>
                            <Col xs="12">
                                <Row className="d-flex justify-content-between font-weight-bold">
                                    <Col className="d-flex justify-content-center" style={{ color: "#77818E", textAlign: "center" }}><div>Επισκέψου ένα <br /> κατάστημα <a href="https://www.germanos.gr/store-locator/" style={{ color: "#ffff" }} target="_blank" rel="noreferrer">Γερμανός </a></div></Col>
                                    <Col className="d-flex flex-column align-items-center">
                                        <div style={{ color: "#77818E"}}> Κάλεσε στο</div>
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon style={{ color: '#ffff' }} icon={faMobileAlt} />
                                            <a className="ml-2" href="tel:210 800 6000" target="_blank" style={{ color: "#ffff" }} rel="noreferrer">210 800 6000</a>
                                        </div>
                                        
                                    </Col>
                                    <Col className="d-flex justify-content-center" style={{ color: "#77818E", textAlign: "center" }}><div>Συμπλήρωσε την φόρμα<br /> επικοινωνίας <a href="https://www.germanos.gr/communication/contact-us/" style={{ color: "#ffff" }} target="_blank" rel="noreferrer"> εδώ</a>.</div></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                  </Row>
                </Col>
            </Row>
            {/* <Footer back={{ text: `${t('footer.back')}`, path: `/${partner}/${brand}/${model}/${capacity}/${powerson}/${condition}` }}></Footer> */}
        </div>
    );
}

export default withTranslation()(Germanos);