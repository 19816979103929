import { Link, navigate } from "@reach/router";
import store from "store";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { iPadTextfix, replaceSpecialChars } from "../viewHelperFunctions";
import { CDN } from "../constants";

var currentDevice = null;
const Device = (props) => {
  const { t, i18n } = props;
  const { state, dispatch } = React.useContext(GPContext);
  const [parts, setParts] = useState([]);
  const [activeCondition, setActiveCondition] = useState();
  const instore = sessionStorage.getItem("instore");
  sessionStorage.setItem("condition", "unknown");
  sessionStorage.setItem("capacity", "");
  var partner = state.partner ? state.partner.toLowerCase() : "";
  var brand = props.brand.toLowerCase();
  var model = props.model.toLowerCase();
  const category = state.category;
  sessionStorage.setItem("modelImage", replaceSpecialChars(model));
  var devices = state.devices.filter(
    (a) =>
      a.brand.toLowerCase() === props.brand.toLowerCase() &&
      a.model.toLowerCase() ===
        props.model.replaceAll("-", " ").replaceAll("_", "/").toLowerCase()
  );
  var variants = devices.reduce((map, obj) => {
    map[obj.capacity] = obj;
    return map;
  }, {});
  var displayConnectivity = false;
  if (state.category === "ipad") {
    if (!model.includes("cellular")) {
      let array = model.split("-");
      array[array.length - 1] === "wifi"
        ? array.splice(array.length, 0, "+", "cellular")
        : array.splice(array.length - 1, 0, "+", "cellular");
      var cellularModel = array.join("-");
      var wifiModel = model;
      var connectivity = "wifi";
    } else {
      wifiModel = model.replace("-+-cellular", "");
      cellularModel = model;
      connectivity = "cellular";
    }
    let splited = props.model
      .replaceAll("-", " ")
      .replaceAll("_", "/")
      .toLowerCase()
      .replace("+ cellular", "")
      .split(" ");
    var ipads = state.devices.filter(
      (a) =>
        a.brand.toLowerCase() === props.brand.toLowerCase() &&
        splited
          .map((eachWord) => a.model.toLowerCase().includes(eachWord))
          .reduce((a, b) => a && b)
    );
    displayConnectivity =
      ipads.filter(
        (ipad) => ipad.model.replaceAll(" ", "-").toLowerCase() === wifiModel
      ).length > 0 &&
      ipads.filter(
        (ipad) =>
          ipad.model.replaceAll(" ", "-").toLowerCase() === cellularModel
      ).length > 0;
  }

  var capacities = devices.map((a) => {
    return a.capacity;
  });
  capacities = capacities.filter(function (item, pos) {
    return capacities.indexOf(item) === pos;
  });
  var defaultAnswer = props.powerson ? props.powerson : "";
  var answer = defaultAnswer;
  var canNext = false;

  var capacity = null;
  var device = null;
  let link;
  if (props.partner === "xiaomigr") {
    link = "email";
  } else {
    link = "samsung-voucher";
  }

  if (capacities.length > 1) {
    capacity =
      props.capacity && props.capacity !== "stock"
        ? props.capacity.toUpperCase()
        : "stock";
    device =
      capacity !== "stock" ? variants[capacity] : variants[capacities[0]];
    canNext = capacity !== "stock" && answer;
  } else if (capacities.length === 1 && capacities[0] !== "") {
    capacity = capacities[0];
    device = variants[capacities[0]];
    canNext = answer;
  } else {
    capacity = "stock";
    device = variants[""];
    canNext = answer;
  }

  if (state.category === "smartwatch") {
    let found = false;
    for (let key in activeCondition) {
      if (activeCondition[key] === "") {
        found = true;
        break;
      }
    }
    if (found) {
      canNext = false;
    } else {
      canNext = true;
    }
  }

  const update = (index, updatedValue, part) => {
    let newState = [...activeCondition];
    let obj = { code: part.code, condition: updatedValue };
    newState[index] = obj;
    setActiveCondition(newState);
  };

  useEffect(() => {
    if (category === "smartwatch") {
      // fetch(`http://localhost:8080/v1/parts/definitions?category=${state.category}`
      fetch(
        `https://imei-service.greenpanda.io/v1/parts/definitions?category=smartwatch`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setParts(data.parts);
          dispatch({
            type: "SET_PARTCONDITIONS",
            payload: data.parts,
          });
        })
        .catch((e) => {
          console.log("eskase", e);
        });
    }

    window.dataLayer.push({ event: "Step Device Specs" });
  }, []);

  useEffect(() => {
    let arr = [];
    for (let key in parts) {
      let value;
      if (parts[key].code === "watch_scratch_cosmetics") {
        value = "default";
      } else {
        value = "";
      }
      arr.push(value);
    }
    setActiveCondition(arr);
  }, [parts]);

  window.brand = brand;
  window.partner = partner;
  window.model = model;
  window.language = i18n.language;
  window.step = 'device specs';

  // sessionStorage.setItem('path', window.location.href)

  const [showError, setShowError] = useState(false);

  const handleClose = () => setShowError(false);
  const handleShow = () => setShowError(true);

  const next = () => {
    if (state.category === "smartwatch") {
      dispatch({
        type: "SET_ACTIVECONDITIONS",
        payload: activeCondition,
      });
      navigate(
        `${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/stock/working/condition`
      );
    } else {
      if (canNext && answer === "ber") handleShow();

      if (
        (partner === "samsunggr" || partner === "xiaomigr") &&
        canNext &&
        answer === "ber"
      ) {
        sessionStorage.setItem(
          "path",
          `${process.env.PUBLIC_URL}/${props.partner}/${sessionStorage
            .getItem("brand")
            .toLowerCase()}/${model}`
        );
        console.log(sessionStorage.getItem("path"));
        navigate(`${process.env.PUBLIC_URL}/${props.partner}/${link}`);
      }

      if (canNext && answer === "working") {
        navigate(
          `${process.env.PUBLIC_URL}/${
            props.partner
          }/${brand}/${model}/${capacity.toLowerCase()}/${answer}/condition`
        );
      }
    }
  };

  const back = () => {
    navigate(`${process.env.PUBLIC_URL}/${props.partner}/${brand}`);
  };

  if (currentDevice !== model) {
    currentDevice = model;
    store.set("device", { brand: device.brand, model: device.model });
  }
  let displayedModel;
  if (state.category == "ipad") displayedModel = iPadTextfix(device?.model);
  else displayedModel = device?.model;

  let modelImage = replaceSpecialChars(model);
  console.log(`${CDN}/devices/` + brand + "/" + modelImage + ".jpg");

  if (!device) return <>{(window.location = process.env.PUBLIC_URL + "/")} </>;
  else
    return (
      <>
        <Container id="device" className="steps">
          <Row className="justify-content-center">
            <Col xs="12" md="11" lg="10">
              {state.category !== "smartwatch" && (
                <div>
                  {displayConnectivity && (
                    <Col className="pb-0">
                      <Row className="justify-content-center justify-content-sm-start mb-5 pb-2">
                        <Col
                          xs="12"
                          className="text-center text-sm-left mb-4 p-0"
                        >
                          <h1 className="title">{t("device.connectivity")}</h1>
                        </Col>
                        <Col
                          xs="10"
                          sm="5"
                          md="4"
                          lg="3"
                          className="p-0 mr-0 mr-sm-4 mb-sm-0 mb-3"
                        >
                          <label className="answer">
                            <Link
                              className="answerButton"
                              to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${wifiModel}`}
                              onClick={() => {
                                dispatch({
                                  type: "SAVE",
                                  data: { connectivity },
                                });
                                sessionStorage.setItem(
                                  "connectivity",
                                  connectivity
                                );
                              }}
                            >
                              <input
                                type="radio"
                                className="btn-check d-none"
                                name="connectivity"
                                value="wifi"
                                checked={connectivity === "wifi" ? true : false}
                              />
                              <div className=" d-flex align-items-center">
                                {!(connectivity === "wifi") && (
                                  <img
                                    src={
                                      CDN + "/pricing-app/icons/checkbox_up.svg"
                                    }
                                    alt="checkbox"
                                    width="20"
                                  />
                                )}
                                {connectivity === "wifi" && (
                                  <img
                                    src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                    alt="checkbox"
                                    width="20"
                                  />
                                )}
                                <div className="ml-3">
                                  <b>{t("device.wifi")}</b>
                                  {/* <div style={{ fontSize: '10px', opacity: '0.5' }}>{t('device.working1')}</div> */}
                                </div>
                              </div>
                            </Link>
                          </label>
                        </Col>
                        <Col
                          xs="10"
                          sm="5"
                          md="4"
                          lg="3"
                          className="p-0 mr-0 mr-sm-4 mb-3 mb-sm-0"
                        >
                          <label className="answer">
                            <Link
                              className="answerButton"
                              to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${cellularModel}`}
                              onClick={() => {
                                dispatch({
                                  type: "SAVE",
                                  data: { connectivity },
                                });
                                sessionStorage.setItem(
                                  "connectivity",
                                  connectivity
                                );
                              }}
                            >
                              <input
                                type="radio"
                                className="btn-check d-none"
                                name="connectivity"
                                value="cellular"
                                checked={
                                  connectivity === "cellular" ? true : false
                                }
                              />
                              <div className="d-flex align-items-center">
                                {!(connectivity === "cellular") && (
                                  <img
                                    src={
                                      CDN + "/pricing-app/icons/checkbox_up.svg"
                                    }
                                    alt="checkbox"
                                    width="20"
                                  />
                                )}
                                {connectivity === "cellular" && (
                                  <img
                                    src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                    alt="checkbox"
                                    width="20"
                                  />
                                )}
                                <div className="ml-3">
                                  <b>{t("device.cellular")}</b>
                                  {/* <div style={{ fontSize: '10px', opacity: '0.5' }}>{t('device.ber1')}</div> */}
                                </div>
                              </div>
                            </Link>
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Row>
                    {capacities.length > 1 && (
                      <Col xs="12" className="mb-2 capacities">
                        <Col
                          xs="12"
                          className="text-center text-sm-left mb-4 p-0"
                        >
                          <h1 className="title">{t("device.capacity")}</h1>
                        </Col>
                        <div
                          id="deviceCapacities"
                          className="deviceLayout d-flex flex-column align-items-center flex-sm-row mb-5"
                        >
                          {capacities
                            .sort((a, b) => {
                              if (
                                a.substr(a.length - 2) === "TB" &&
                                b.substr(b.length - 2) === "GB"
                              )
                                return -1;
                              if (
                                a.substr(a.length - 2) === "GB" &&
                                b.substr(b.length - 2) === "TB"
                              )
                                return 1;
                              return parseInt(b) - parseInt(a);
                            })
                            .map((cap) => {
                              return (
                                <Col
                                  xs="10"
                                  sm="3"
                                  className="p-0 mr-0 mr-sm-4 mb-3 mb-sm-0"
                                >
                                  <label className="answer" key={cap}>
                                    {answer && (
                                      <Link
                                        className="answerButton"
                                        to={`${process.env.PUBLIC_URL}/${
                                          props.partner
                                        }/${brand}/${model}/${cap.toLowerCase()}/${answer}`}
                                        onClick={() => {
                                          dispatch({
                                            type: "SAVE",
                                            data: { cap },
                                          });
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          className="btn-check d-none"
                                          name="capacity"
                                          onChange={() => {}}
                                          value={cap}
                                          checked={
                                            cap === capacity ? true : false
                                          }
                                        />
                                        <div className="d-flex align-items-center">
                                          {!(cap === capacity) && (
                                            <img
                                              src={
                                                CDN +
                                                "/pricing-app/icons/checkbox_up.svg"
                                              }
                                              alt="checkbox"
                                              width="20"
                                            />
                                          )}
                                          {cap === capacity && (
                                            <img
                                              src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                              alt="checkbox"
                                              width="20"
                                            />
                                          )}
                                          <b className="ml-3">{cap}</b>
                                        </div>
                                      </Link>
                                    )}
                                    {!answer && (
                                      <Link
                                        className="answerButton"
                                        to={`${process.env.PUBLIC_URL}/${
                                          props.partner
                                        }/${brand}/${model}/${cap.toLowerCase()}`}
                                        onClick={() => {
                                          dispatch({
                                            type: "SAVE",
                                            data: { cap },
                                          });
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          className="btn-check d-none"
                                          name="capacity"
                                          onChange={() => {}}
                                          value={cap}
                                          checked={
                                            cap === capacity ? true : false
                                          }
                                        />
                                        <div className="d-flex align-items-center">
                                          {!(cap === capacity) && (
                                            <img
                                              src={
                                                CDN +
                                                "/pricing-app/icons/checkbox_up.svg"
                                              }
                                              alt="checkbox"
                                              width="20"
                                            />
                                          )}
                                          {cap === capacity && (
                                            <img
                                              src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                              alt="checkbox"
                                              width="20"
                                            />
                                          )}
                                          <b className="ml-3">{cap}</b>
                                        </div>
                                      </Link>
                                    )}
                                  </label>
                                </Col>
                              );
                            })}
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Col className="pb-5">
                    <Row className="justify-content-center justify-content-sm-start mb-5 pb-5">
                      <Col
                        xs="12"
                        className="text-center text-sm-left mb-4 p-0"
                      >
                        <div className="title">{t("device.question")}</div>
                      </Col>
                      <Col
                        xs="10"
                        sm="5"
                        md="4"
                        lg="3"
                        className="p-0 mr-0 mr-sm-4 mb-sm-0 mb-3"
                      >
                        <label className="answer">
                          <Link
                            className="answerButton"
                            to={`${process.env.PUBLIC_URL}/${
                              props.partner
                            }/${brand}/${model}/${capacity.toLowerCase()}/working`}
                            onClick={() => {
                              dispatch({ type: "SAVE", data: { answer } });
                              sessionStorage.setItem("answer", "working");
                            }}
                          >
                            <input
                              type="radio"
                              className="btn-check d-none"
                              name="powerson"
                              value="working"
                              checked={answer === "working" ? true : false}
                            />
                            <div className=" d-flex align-items-center">
                              {!(answer === "working") && (
                                <img
                                  src={
                                    CDN + "/pricing-app/icons/checkbox_up.svg"
                                  }
                                  alt="checkbox"
                                  width="20"
                                />
                              )}
                              {answer === "working" && (
                                <img
                                  src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                  alt="checkbox"
                                  width="20"
                                />
                              )}
                              <div className="ml-3">
                                <b>{t("device.working")}</b>
                                <div
                                  style={{ fontSize: "10px", opacity: "0.5" }}
                                >
                                  {t("device.working1")}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </label>
                      </Col>
                      <Col
                        xs="10"
                        sm="5"
                        md="4"
                        lg="3"
                        className="p-0 mr-0 mr-sm-4 mb-3 mb-sm-0"
                      >
                        <label className="answer">
                          <Link
                            className="answerButton"
                            to={`${process.env.PUBLIC_URL}/${
                              props.partner
                            }/${brand}/${model}/${capacity.toLowerCase()}/ber`}
                            onClick={() => {
                              dispatch({ type: "SAVE", data: { answer } });
                              sessionStorage.setItem("answer", "ber");
                            }}
                          >
                            <input
                              type="radio"
                              className="btn-check d-none"
                              name="powerson"
                              value="faulty"
                              checked={answer === "ber" ? true : false}
                            />
                            <div className="d-flex align-items-center">
                              {!(answer === "ber") && (
                                <img
                                  src={
                                    CDN + "/pricing-app/icons/checkbox_up.svg"
                                  }
                                  alt="checkbox"
                                  width="20"
                                />
                              )}
                              {answer === "ber" && (
                                <img
                                  src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                  alt="checkbox"
                                  width="20"
                                />
                              )}
                              <div className="ml-3">
                                <b>{t("device.ber")}</b>
                                <div
                                  style={{ fontSize: "10px", opacity: "0.5" }}
                                >
                                  {t("device.ber1")}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </label>
                      </Col>
                    </Row>
                  </Col>
                </div>
              )}
              <div style={{ marginBottom: "140px" }}>
                {state.category === "smartwatch" &&
                  parts.map((part, index) => {
                    return (
                      <div key={part.code}>
                        {part.code !== "watch_scratch_cosmetics" && (
                          <div>
                            <Col
                              xs="12"
                              className="text-center text-sm-left mb-4 p-0"
                            >
                              <h1 className="title">
                                {t(`device.${part.code}`)}
                              </h1>
                            </Col>
                            <div
                              id="deviceCapacities"
                              className="deviceLayout d-flex flex-column align-items-center flex-sm-row mb-5"
                            >
                              {part.conditions.map((condition) => {
                                return (
                                  <>
                                    {condition !== "default" && (
                                      <Col
                                        xs="10"
                                        sm="3"
                                        className="p-0 mr-0 mr-sm-4 mb-3 mb-sm-0"
                                      >
                                        <label
                                          className="answer"
                                          key={condition}
                                        >
                                          <div
                                            className="answerButton"
                                            onClick={() => {
                                              dispatch({
                                                type: "SAVE",
                                                data: { condition },
                                              });
                                              update(index, condition, part);
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              className="btn-check d-none"
                                              name="capacity"
                                              onChange={() => {}}
                                              value={condition}
                                              checked={
                                                activeCondition[index] ===
                                                condition
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <div className="d-flex align-items-center">
                                              {!(
                                                activeCondition[index]
                                                  ?.condition === condition
                                              ) && (
                                                <img
                                                  src={
                                                    CDN +
                                                    "/pricing-app/icons/checkbox_up.svg"
                                                  }
                                                  alt="checkbox"
                                                  width="20"
                                                />
                                              )}
                                              {activeCondition[index]
                                                ?.condition === condition && (
                                                <img
                                                  src={`${CDN}/pricing-app/partners/${partner}/checkbox_down.svg`}
                                                  alt="checkbox"
                                                  width="20"
                                                />
                                              )}
                                              <b className="ml-3">
                                                {t(`device.${condition}`)}
                                              </b>
                                            </div>
                                          </div>
                                        </label>
                                      </Col>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              <div className="footer row justify-content-end justify-content-md-between align-items-center px-3 px-md-5">
                <div className="d-none d-md-flex align-items-center">
                  <div>
                    <b>{t("footer.descr")}</b>
                  </div>
                  <div
                    className="mx-4"
                    style={{ borderRight: "1px solid #E5E4E5", height: "60px" }}
                  ></div>
                  <div className="d-flex align-items-center">
                    <div className="mr-3">
                      {device.brand === "Apple" && (
                        <img
                          src={
                            `${CDN}/devices/` +
                            brand +
                            "/" +
                            modelImage +
                            ".jpg"
                          }
                          onError={(e) => {
                            e.target.src =
                              process.env.PUBLIC_URL + "/phone.png";
                            e.target.onError = null;
                          }}
                          width="40"
                          alt="phone"
                        />
                      )}
                      {device.brand !== "Apple" && (
                        <img
                          src={
                            `${CDN}/devices/` +
                            brand +
                            "/" +
                            modelImage +
                            ".png"
                          }
                          onError={(e) => {
                            e.target.src =
                              process.env.PUBLIC_URL + "/phone.png";
                            e.target.onError = null;
                          }}
                          width="40"
                          alt="phone"
                        />
                      )}
                    </div>
                    <div className="text-left">
                      <div style={{ fontSize: "18px" }}>
                        <b>{displayedModel}</b>
                      </div>
                      <div style={{ opacity: "0.5" }}>{device.brand}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="back mr-3 mr-md-5" onClick={back}>
                    {t("footer.back")}
                  </div>
                  <div
                    className={
                      canNext
                        ? `${partner}Next next foot`
                        : `${partner}Next next foot disabled`
                    }
                    onClick={next}
                  >
                    {t("footer.next")}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          show={showError}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3
              className="float-right"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </h3>
            <Row className="d-flex flex-column" style={{ height: "100%" }}>
              <Col className="text-center text-lg-left my-3">
                <Row className="justify-content-center align-items-center">
                  {partner !== "gp" ? (
                    <Col
                      xs="6"
                      lg="4"
                      xl="4"
                      className={`${partner}Bg text-center mb-4 p-0`}
                    >
                      <img
                        className={`${partner}Image`}
                        src={`${CDN}/pricing-app/partners/${partner}/logo.svg`}
                        alt="logo"
                        width="100%"
                      />
                    </Col>
                  ) : (
                    <Col
                      xs="6"
                      lg="4"
                      xl="4"
                      className={`${partner}Bg text-center mb-4 p-0`}
                    >
                      <img
                        className={`${partner}Image`}
                        src={`${CDN}/pricing-app/partners/gp/image.png`}
                        alt="logo"
                        width="100%"
                      />
                    </Col>
                  )}
                  <Col xs="12" className="mt-3 text-center">
                    <h5>
                      <b>{t("device.popup1")}</b>
                    </h5>
                  </Col>
                  <Col
                    xs="11"
                    lg="8"
                    className="text-center"
                    style={{ fontSize: "15px" }}
                  >
                    <span>
                      {t("device.popup2")}
                      <b className={`${partner}Text`}>{t("device.popup3")}.</b>
                    </span>
                    {partner === "gp" && (
                      <span>
                        {t("device.popup4")}
                        <b className={`${partner}Text`}>{t("device.popup5")}</b>
                        !
                      </span>
                    )}
                  </Col>
                  {partner === "gp" ? (
                    <Col xs="10" lg="6" className="text-center mt-5">
                      <a
                        className={`${partner}Next next`}
                        href="https://greenpanda.io/atm"
                      >
                        {t("atm")}
                      </a>
                    </Col>
                  ) : (
                    <Col xs="10" lg="6" className="text-center mt-5">
                      <a
                        className={`${partner}Next next`}
                        href={`${process.env.PUBLIC_URL}/${props.partner}`}
                      >
                        {t("new")}
                      </a>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
};

export default withTranslation()(Device);
