import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState, useRef } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import { CDN } from '../constants'
import Autocomplete from "react-google-autocomplete";
import axios from 'axios';

const Location = (props) => {
    const { t, i18n } = props;
    const ref = useRef();
    const { state, dispatch } = React.useContext(GPContext);
    const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyDrwWAOVkgtQ1pLDi9ma-Tq7RUtqCqAiDg";
    const TOKEN = 'pk.eyJ1IjoiZ3JlZW5wYW5kYWlvIiwiYSI6ImNrbGZoY2ZnMTBvOWEydnAwaTNxb3ZiZzUifQ.r8zPO79kYM7ronm9v_JVuw';
    let partner = props.partner ? props.partner : sessionStorage.getItem('partner');
    let brand = props.brand ? props.brand : sessionStorage.getItem('brand');
    let model = props.model ? props.model : sessionStorage.getItem('model')
    let condition = props.model ? props.model : sessionStorage.getItem('condition');
    let capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');
    let countries;
    if(props.partner==='samsunggr'){
       countries = ['gr'];
    }else{
        countries = ['gr', 'cy'];
    }

    console.log(countries)

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }

    const currentPosition = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log(position)
                if(position){
                    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${position.coords.longitude},${position.coords.latitude}.json?access_token=${TOKEN}`).then(res => {
                        const address = res.data.features[0].place_name;
                        let country = address.split(',')[2].toLowerCase();
                        sessionStorage.setItem('country', country);
                        console.log('country', country)
                        console.log(res.data)
                        console.log('address', address)
                        const zipcode = res.data.features[0].context[0].text.replace(/\s/g, '');
                        const geolocation = { latitude: position.coords.latitude, longitude: position.coords.longitude, code: 1 }
                        dispatch({ type: "SET_ZIPCODE", payload: zipcode });
                        dispatch({ type: "SET_ADDRESS", payload: address });
                        dispatch({ type: "CLICK", payload1: parseFloat(position.coords.latitude), payload2: parseFloat(position.coords.longitude), payload3: 0 })
                        dispatch({ type: "USER_LOCATION", data: geolocation })
                        if(props.partner==='samsunggr' || props.partner==='xiaomigr'){
                            navigate(process.env.PUBLIC_URL+`/${props.partner}/map`);
                        }else{
                            navigate(process.env.PUBLIC_URL+`/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/map`);
                        }
                    })
                }
            }
        );
    }

    useEffect(() => {
        console.log("State: ", state);
        console.log(props)
        window.dataLayer.push({ event: 'Step Location Form' });
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'location form';

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);

    
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${state.partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div className='justify-content-center text-center location'>
            <div className='d-flex justify-content-center'><img style={{maxWidth: '100px', marginTop: '50px'}} src={process.env.PUBLIC_URL+"/heart-icon.png"} alt="heart icon" /></div>
            <h1 style={{fontSize: '32px', fontWeight: '800'}}>Ας ξεκινήσουμε!</h1>
            <p className='m-auto pt-2' style={{fontSize: '14px', lineHeight: '19px', fontWeight: '400', maxWidth: '320px'}}>Συμπλήρωσε την τοποθεσία σου.  Θα σε βοηθήσουμε να ολοκληρώσεις τη διαδικασία trade-in με τον πιο εύκολο και γρήγορο τρόπο.</p>
            <div style={{position: 'relative'}}>
                <img src={process.env.PUBLIC_URL+'/search-icon.svg'} alt="search icon" style={{position: 'absolute', top: '55%', paddingLeft: '15px'}}/>
                <Autocomplete
                    apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                    className='googleAutocomplete mt-4'
                    style={{ width: '340px', height: '40px'}}
                    renderLeftButton={()  => <img src={`${CDN}/pricing-app/icons/pdf-icon.svg`} alt='path icon' />}
                    onPlaceSelected={(place) => {
                        console.log(place)
                        if(place.formatted_address){
                            let address = place.formatted_address
                            // let zipcode = place.address_components.slice(-1)[0].long_name.replace(/\s/g, '')
                            let zipcode;
                            let country;
                            // console.log('length',place.address_components.length)
                            for(let i=0; i<place.address_components.length; i++){
                                if(place.address_components[i].types[0]==='postal_code'){
                                    zipcode = place.address_components[i].long_name.replace(/\s/g, '');
                                    console.log(zipcode)
                                }
                                
                                if(place.address_components[i].types[0] ==='country'){
                                    country = place.address_components[i].long_name.toLowerCase();
                                    sessionStorage.setItem('country', country);
                                    console.log('country',country)
                                }
                            }
                            console.log(zipcode, address)
                            
                            if (address) {
                                var lat = place.geometry.location.lat();
                                var lng = place.geometry.location.lng();
                                dispatch({ type: "SET_ZIPCODE", payload: zipcode })
                                dispatch({ type: "SET_ADDRESS", payload: address })
                                // dispatch({ type: "CLICK", payload: { latitude: lat, longitude: lng, code: 0 } })
                                dispatch({ type: "CLICK", payload1: parseFloat(lat), payload2: parseFloat(lng), payload3: 0 })
                                dispatch({ type: "USER_LOCATION", data: { latitude: parseFloat(lat), longitude: parseFloat(lng), code: 0 } })
                                if(props.partner==='samsunggr' || props.partner==='xiaomigr'){
                                    navigate(process.env.PUBLIC_URL+`/${props.partner}/map`);
                                }else{
                                    navigate(process.env.PUBLIC_URL+`/${props.partner}/${props.brand}/${props.model}/${props.capacity}/${props.powerson}/${props.condition}/${props.contact}/offer/map`);
                                }
                            }
                        }
                       
                    }}
                    options={{
                        types: [],
                        componentRestrictions: { country: countries }
                    }}
                    placeholder="Συμπλήρωσε τη διεύθυνσή σου"
                />
            </div>
            <div className='mt-3' onClick={currentPosition}>
                <img src={process.env.PUBLIC_URL+'/path-icon.svg'} alt='path icon' />
                <span style={{color: '#6c6c6c', fontSize: '14px'}} className='ml-2'>Χρήση τρέχουσας τοποθεσίας</span>
            </div>
        </div>
    );
}

export default withTranslation()(Location);