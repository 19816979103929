import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import Footer from './footer';
import { Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';
import { GPContext } from "../context";
import { iPadSort, iPadTextfix, replaceSpecialChars } from '../viewHelperFunctions';
import { CDN } from '../constants'

const Models = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    sessionStorage.setItem('condition', 'unknown');

    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = state.brand.toLowerCase() || props.brand.toLowerCase();
    sessionStorage.setItem('brand', state.brand)
    // var brand = partner === 'samsung' ? sessionStorage.getItem("brand").toLocaleLowerCase() : state.brand.toLowerCase() || props.brand.toLowerCase();
    let instore = sessionStorage.getItem('instore');

    const [showError, setShowError] = useState(false);
    const [show, setShow] = useState(false);
    const [device, setDevice] = useState(false);
    const [filter, setFilter] = useState("");
    let link;
    if(props.partner==='xiaomigr'){
        link='email';
    }else{
        link='samsung-voucher';
    }

    var models = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase());

    models = models.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);

    if (state.category === 'ipad')
        models = models.filter((v, i, a) => (a.findIndex(t => ((t.name.includes('Cellular') ? t.name.replace('+ Cellular', '') : t.name) === (v.name))) === i) || a.findIndex(t => ((t.name) === v.name.replace(' + Cellular', ''))) < 0);
    let filteredModels = models.filter(model => {
        var name = model.name.toLowerCase();
        var toSearch = filter.toLowerCase();
        return name.includes(toSearch) || toSearch.includes(name)
    });
    console.log(models)
    if (state.category == 'ipad') {
        filteredModels = filteredModels.sort((a, b) => iPadSort(a, b));
    }
    const handleClose = () => setShowError(false);
    const handleShow = () => setShowError(true);

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Model' });
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.language = i18n.language;
    window.step = 'model';
    

    for (let i = 0; i < models.length; i++) {
        let image;
        const index = models[i].name.indexOf(" ");
        models[i].image = models[i].name.substring(index + 1);
        models[i].image = models[i].image.replace(/[&\/\\#,()$~%'"\s:*?<>{}]/g, '_');
    }

    for (let i = 0; i < models.length; i++) {
        models[i].image = replaceSpecialChars(models[i].name);
    }

    if (models.length === 0) return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);
    return (
        <div id="models">
            <Row className="justify-content-center mb-4">
                <Col lg="12" className="text-center">
                    {(partner === 'istorm' || partner === 'istormcy') ?
                        <h1 className="title">{t('models.titleIstorm')}</h1>
                        :
                        <h1 className="title">{t('models.title')}</h1>
                    }
                    <div className="infoLink" onClick={() => setShow(!show)}><FontAwesomeIcon icon={faQuestionCircle} /> {t('models.infoLink')}</div>
                    {brand === 'apple' && show && <div className="infoBox text-left">{state.category === 'ipad' ? t('models.infoBoxIpad1') : t('models.infoBox1')} <b>{t('models.settings')} {state.category === 'ipad' && t('models.gen') + ' >'} {state.category === 'ipad' && t('models.info')}</b>  {state.category === 'iphone' && t('models.infoBox2') + `>` + t('models.infoBox3')}</div>}
                    {brand !== 'apple' && show && <div className="infoBox text-left">{t('models.infoBox4')} <b>{t('models.settings')}</b> <b>{t('models.infoBox5')}</b> {t('models.infoBox6')}</div>}
                </Col>
                <Col xs="10" sm="8" md="6" lg="4">
                    <InputGroup className="mt-3 myInput">
                        <InputGroup.Prepend style={{ borderRadius: '60px' }}>
                            <InputGroup.Text style={{ padding: '0px 15px 0px 0px' }}><img src={`${CDN}/pricing-app/partners/${partner}/search_filled_icon.svg`} alt="search" width="40" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" placeholder={t('models.search')} onChange={e => setFilter(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-5 pb-5 justify-content-center">
                {
                    filteredModels.map((model) => {
                        var modelView = model.model.length < 5 ? model.brand + " " + model.model : model.model;
                        if (state.category == 'ipad') {
                            modelView = modelView.replace('WiFi', '');
                            modelView = iPadTextfix(modelView);
                        }
                        return (
                            <Col xs="11" key={model.name.replaceAll(' ', '-').toLowerCase()} className="mb-3">
                                <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model.model.replaceAll(' ', '-').replaceAll('/', '_').toLowerCase()}`} onClick={() => {dispatch({ type: "SAVE", data: { model: model.model } }); sessionStorage.setItem("model", model.model); sessionStorage.setItem('modelImage', `${model.model.replaceAll(' ', '-').replaceAll('/', '_').toLowerCase()}`)}}>
                                    <Button variant="outline-success" className={`modelItem ${partner}Item item bg-white ${device.model === model.model.replaceAll(' ', '-').toLowerCase() ? 'active' : ''}`} block onClick={() => { setDevice({ brand: brand, model: model.model.replaceAll(' ', '-').toLowerCase() }) }}>
                                        <Row>
                                            <Col xs={3} style={{ textAlign: "right" }}>
                                                {/* {brand === "apple" && <img src={`${CDN}/devices/` + brand + "/" + model.model.replaceAll(' ', '_').toLowerCase() + ".jpg"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} alt="phone" height="60px" />} */}
                                                {brand === "apple" && <img src={`${CDN}/devices/` + brand + "/" + model.image.toLowerCase() + ".jpg"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} alt="phone" height="60px" />}
                                                {brand !== "apple" && <img src={`${CDN}/devices/` + brand + "/" + model.image.replaceAll(' ', '_').toLowerCase() + ".png"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} alt="phone" height="60px" />}
                                            </Col>
                                            <Col xs={9} className="pt-2">
                                                <h2>{modelView}</h2>
                                                <span>{model.brand}</span>
                                            </Col>
                                        </Row>
                                    </Button>
                                </Link>
                            </Col>
                        );
                    })
                }
                {partner!=='samsunggr' && partner!=='xiaomigr' ?<Col xs="11" key="modelError" className="mb-5 pb-5">
                    <Button variant="outline-success" className={`modelItem ${partner}Item item bg-white`} block onClick={handleShow}>
                        <Row className="align-items-center">
                            <Col xs={3} style={{ textAlign: "right" }}>
                                <img src={CDN + "/devices/not_found_icon.svg"} alt="notFound" width="50" className="brandLogo" />
                            </Col>
                            <Col xs={9} className="pt-2">
                                <div><b>{t('models.error')}</b></div>
                            </Col>
                        </Row>
                    </Button>
                </Col>
                :
                <Col xs="11" key='unknown' className="mb-3">
                    <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${link}`} onClick={() => {dispatch({ type: "SAVE", data: { model: 'unknown' } }); sessionStorage.setItem("model", 'unknown'); sessionStorage.setItem('path', `${process.env.PUBLIC_URL}/${props.partner}/${sessionStorage.getItem('brand').toLowerCase()}`)}}>
                        <Button variant="outline-success" className={`modelItem ${partner}Item item bg-white`} block>
                            <Row className="align-items-center">
                                <Col xs={3} style={{ textAlign: "right" }}>
                                    <img src={CDN + "/devices/not-found.svg"} alt="notFound" height="60" width='45' className="brandLogo" />
                                </Col>
                                <Col xs={9} className="pt-2">
                                    <h2>Άλλο μοντέλο</h2>
                                    <span style={{textTransform: 'capitalize'}}>{brand}</span>
                                </Col>
                            </Row>
                        </Button>
                    </Link>
                </Col>
                }
            </Row>
            <Footer back={{ text: `${t('footer.back')}`, path: `${process.env.PUBLIC_URL}/${props.partner}` }} next={device ? { text: `${t('footer.next')}`, path: `${process.env.PUBLIC_URL}/${instore}/${device.brand}/${device.model}` } : { text: `${t('footer.next')}` }}></Footer>
            <Modal show={showError} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <h3 className="float-right" style={{ cursor: "pointer" }} onClick={handleClose}><FontAwesomeIcon icon={faTimes} /></h3>
                    <Row className="d-flex flex-column" style={{ height: "100%" }}>
                        <Col className="text-center text-lg-left my-3">
                            <Row className="justify-content-center align-items-center mb-4">
                                <Col xs="5" lg="3" className="text-center"><img src={CDN + "/devices/not_found_icon.svg"} alt="notFound" width="100%" /></Col>
                            </Row>
                            <Row className="justify-content-center align-items-center">
                                <Col xs="12" lg="8" style={{ fontSize: '16px' }}>{t('models.errorModal1')}<b>{t('models.errorModal2')}</b>{t('models.errorModal3')}<b className={`${partner}Text`}>{t('models.errorModal4')}</b>{t('models.errorModal5')}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default withTranslation()(Models);