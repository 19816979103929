import { Row, Col, InputGroup, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext, Suspense } from "react";
import { navigate } from '@reach/router';
import { GPContext } from "../context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CDN } from '../constants'

let Terms

const XiaomiEmail = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = useContext(GPContext);
    sessionStorage.setItem('promoPrice', 100);
    let promoPrice = sessionStorage.getItem('promoPrice');
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = sessionStorage.getItem('brand');
    var capacity = sessionStorage.getItem('capacity') ? sessionStorage.getItem('capacity') : state.cap;
    var powerson = sessionStorage.getItem('answer');
    var condition = sessionStorage.getItem('condition')
    var model = sessionStorage.getItem('model');

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    capacity = capacity ? capacity.toUpperCase() : '';
    var device = variants[capacity.toUpperCase()];
    let finalPrice
    if(device && powerson!=='ber' && powerson!=='' && condition !== 'unknown'){
        console.log('')
        finalPrice = device[condition + '_price'];
    }else{
        finalPrice = 0;
        device = {
            brand: brand,
            model: model,
            name: model,
            capacity: capacity
        }
    }

    function changeToEng(c) {
        if (c === 'very_good') return 'Like New';
        if (c === 'good') return 'Good';
        if (c === 'poor') return 'Acceptable';
        if (c === 'faulty') return 'Faulty';
    }
    function changeToGreek(c) {
        if (c === 'very_good') return 'Σαν Καινούρια';
        if (c === 'good') return 'Καλή';
        if (c === 'poor') return 'Μέτρια';
        if (c === 'faulty') return 'Κακή';
    }

    const [email, setEmail] = useState("");

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Email Form' });
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'email form';

    useEffect(() => {
        Terms = React.lazy(() => import('../terms/samsung-terms_' + i18n.language));
    }, [i18n.language])


    function validateEmail(email) {
        if (/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)) return true;
        return false;
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [promoCode, setPromoCode] = useState('')
    const handlePromo = (e) => {
        setPromoCode(e.target.value);
        setPromoError('')
    }


    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${brand.toLowerCase()}/${model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    
    function sendOffer() {
        var offer = {
            brand: brand,
            model: model,
            name: model.length < 5 ? brand + " " + model : model,
            capacity: capacity,
            powerson: powerson,
            condition: changeToGreek(condition),
            condition_en: changeToEng(condition),
            finalPrice: finalPrice,
            promoPrice: sessionStorage.getItem('promoPrice') ? sessionStorage.getItem('promoPrice') : 0,
            imageUrl: imageUrl,
            fbclid: localStorage.getItem('fbclid'),
            ttclid: localStorage.getItem('ttclid'),
            gclid: localStorage.getItem('gclid'),
            lang: i18n.language
        }
    
        localStorage.setItem("email", email);
        offer.email = email;

        dispatch({ type: "contact", payload: { email: email } })
        // setSubmit(true);

        const headers2 =
            { 'Content-Type': 'application/json' };

        var category;
        if (state.category === 'ipad') {
            category = 'tablet'
        } else {
            category = 'smartphone'
        }

        axios.post(`https://communications.greenpanda.io/webquote/xiaomigr`, {
            email: offer.email,
            brand: offer.brand,
            model: offer.model,
            price: offer.finalPrice,
            promo: promoPrice,
            condition: offer.condition_en,
            capacity: offer.capacity,
            lang: i18n.language,
            partner: partner,
            category: category
        }, { headers2 }).then(res => {
            console.log(res);
            console.log(res.data);
        })

        let hookPayload = {};
        Object.assign(hookPayload, offer);
        hookPayload.partner = props.partner;
        hookPayload.category = category;
        axios.post(`https://hook.integromat.com/mt2kbea6lmbpka30lxewowg8lq969zdv`, hookPayload, { headers2 }).then(res => {
            console.log(res);
            console.log(res.data);
            // navigate(`${process.env.PUBLIC_URL}/${partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer`);
        })
    }

    const [promoError, setPromoError] = useState('')
    const [emailError, setEmailError] = useState('')

    
    const validation = (e) => {
        e.preventDefault();
        let validEmail = validateEmail(email);
        if(!validEmail){
            setEmailError('Invalid email')
        }else{
            setEmailError('')
            sessionStorage.setItem("email", email);
        }
        
        if(validEmail){
            sendOffer();
            navigate(process.env.PUBLIC_URL+`/${props.partner}/offer/`);
                   
        }
    }

    const back = () => {
        navigate(sessionStorage.getItem('path'));
    }

    return(
        <div>
            <div className='text-center'>
                <h1 style={{fontWeight: '700', fontSize: '32px', lineHeight: '30px', marginTop: '50px', marginBottom: '20px'}}>Η προσφορά σου είναι έτοιμη</h1>
                <p className='m-auto' style={{fontSize: '14px', fontWeight: '500'}}>Συμπλήρωσε το email σου για να λάβεις την προσφορά.</p>
            </div>
           
            <Row className='justify-content-center text-center samsungInput mt-2' style={{marginBottom: '180px'}}>
                <div>
                    <form onSubmit={validation} className='floatingForm mt-4' style={{maxWidth: '320px'}}>
                        <label for="email" style={{marginBottom: '0'}}>
                            <input type="text" id="email" placeholder="" onChange={e => {setEmail(e.target.value); setEmailError('')}}/>
                            <span>Email</span>
                        </label>
                        <p style={{height: '20px', margin: '3px 0 5px 15px'}}>{emailError}</p>
                    </form>
                    <p className='m-auto' style={{fontSize: '11px', color: '#747676'}}>Σεβόμαστε τα προσωπικά σας δεδομένα. <u onClick={handleShow} style={{color: '#263847', fontWeight: '600', maxWidth: '320px'}}> Πολιτική Απορρήτου</u></p>
                </div>
            </Row>
            
            
            <div className="footer row justify-content-end align-items-center px-3 px-md-5">
                <div className="d-flex">
                    <div className="back backEmail mr-3 mr-md-5" onClick={back}>
                        {t('footer.back')}
                    </div>
                    <div className={`${partner}Next next px-3  foot`} onClick={validation}>{t('email.getOffer')}</div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <h3 className="text-right mt-3 mr-3 mb-0" onClick={handleClose} style={{ cursor: "pointer", overflow: 'st' }} ><FontAwesomeIcon icon={faTimes} /></h3>
                <Modal.Body style={{ height: '75vh', overflow: 'scroll' }} className="pt-0">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Terms />
                    </Suspense>
                </Modal.Body>
            </Modal>
        </div>
    );
}


export default withTranslation()(XiaomiEmail);