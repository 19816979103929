import { navigate } from "@reach/router";

function classifyIpads (model) {
    let modelClass;
    if (model.includes('Pro')) modelClass = 1;
    else if (model.includes('Air')) modelClass = 2;
    else if (model.includes('Mini')) modelClass = 3;
    else modelClass = 4;
    return modelClass;
}

function iPadSort (a,b) {
    let classOfA, classOfB;
    classOfA = classifyIpads(a.model);
    classOfB = classifyIpads(b.model);
    let modelOfA, modelOfB;
    if (!(/\d/.test(a.model))) 
        modelOfA = a.model + ' 1'
    else
        modelOfA = a.model
    if (!(/\d/.test(b.model))) 
        modelOfB = b.model + ' 1'
    else
        modelOfB = b.model
    if (classOfA < classOfB) 
        return -1
    else if (classOfA > classOfB) 
        return 1
    else {
        let na = modelOfA.match(/[+-]?\d+(\.\d+)?/g) //gets number of model
        let nb = modelOfB.match(/[+-]?\d+(\.\d+)?/g)
        if (na === null || nb === null || na[na.length-1] === nb[nb.length-1]) {
            if (a.model < b.model){
                return 1 
            }
            else{
                return -1;
            }
        }
        else{
            if (Number(na[na.length-1]) > Number(nb[nb.length-1]))
                return -1
            else 
                return 1
        }
    }
}
function iPadTextfix(model) {
    model = model.replace('Mini', 'mini');
    //model = model.replace('4G', '');
    let num = model.match(/[+-]?\d+(\.\d+)?/g)
    if (num != null){
        num = num[0] 
        if ( Number(num) > 8 && Number(num) < 2000) {
            let n2 = num
            if (num[num.length-1] === 0){
                n2 = num.substr(0,num.length-2)
            }
            model = model.replace(num,n2+'"')
        }
    }
    return model
}

function redirectAfterTimeout(path) {
    var timeout = 120000;
    console.log('Redirect to home timer set for '+timeout)
    setTimeout(() => {
      navigate(path);
    }, timeout);
}

function replaceSpecialChars(str){
        const index = str.indexOf(" ");
        str = str.substring(index + 1);
        str = str.replace(/[-&\/\\#,+()$~%'"\s:*?<>{}]/g, '_');
        return str
}

export {classifyIpads, iPadSort, iPadTextfix, redirectAfterTimeout, replaceSpecialChars}