
import React from "react";

export const GPContext = React.createContext();
export const initialState = {
    loading: true,
    error: "",
    locations: [],
    location_id: [],
    search: null,
    receipt: false,
    contact: {},
    brand: '',
    model: '',
    category: 'iphone',
    charger: '',
    promoCode: '',
    zipcode: '',
    address: '',
    cashOffers: sessionStorage.getItem('cashOffers')? JSON.parse(sessionStorage.getItem('cashOffers')): undefined,
    voucherOffers: sessionStorage.getItem('voucherOffers')? JSON.parse(sessionStorage.getItem('voucherOffers')): undefined,
};
export const reducer = (state, action) => {

    switch (action.type) {
        case "SAVE":
            console.log('Tha swsw ta: ', action.data)
            return {
                ...state,
                ...action.data //extra
            };
        case "SET_LOCATIONS":
            return {
                ...state,
                loading: false,
                error: "",
                locations: action.payload
            };
        case "SET_DEVICES":
            return {
                ...state,
                devices: action.payload
            };
        case "SET_OFFERS":
            return {
                ...state,
                cashOffers: action.payload1,
                voucherOffers: action.payload2
            };
        case "SET_CATEGORY":
            return {
                ...state,
                category: action.payload
            };
        case "SET_PARTCONDITIONS":
            return {
                ...state,
                partConditions: action.payload
            };
        case "SET_ACTIVECONDITIONS":
            return {
                ...state,
                activeConditions: action.payload
            };
        case "SET_PARTSOFFER":
            return {
                ...state,
                partsOffer: action.payload
            };

        case "SET_BRANDS":
            return {
                ...state,
                brands: action.payload
            };

        case "SET_PROMO":
            return {
                ...state,
                promoCode: action.payload
            };

        case "SET_ZIPCODE":
            return {
                ...state,
                zipcode: action.payload
            };
        case "SET_ADDRESS":
            return{
                ...state,
                address: action.payload
            }
        
        case "CLICK":
            console.log('click sto: ', action.payload1, action.payload2, action.payload3)
            return {
                ...state,
                userLocation: action.payload,   
                latitude: action.payload1,
                longitude: action.payload2,
                code: action.payload3
            };
        case "USER_LOCATION":
            console.log('click sto: ', action.data)
            return {
                ...state,
                userLocation: action.data
            }
        case "SEARCH":
            console.log('search: ', action.payload)
            return {
                ...state,
                search: action.payload
            };
        case "report":
            console.log('rec: ', action.payload)
            return {
                ...state,
                report: action.payload
            };
        case "contact":
            console.log('contact: ', action.payload)
            return {
                ...state,
                contact: action.payload
            };
        case "partner":
            console.log('partner: ', action.payload)
            return {
                ...state,
                partner: action.payload
            };
        case "price":
            console.log('price: ', action.payload)
            return {
                ...state,
                price: action.payload
            };
        case "PATH":
        return {
            ...state,
            pathUrl: action.data
        }
        case "FETCH_FAILURE":
            return {
                ...state,
                loading: false,
                error: "Something went wrong",
                locations: []
            };
        default:
            return state;
    }
};
