import { withTranslation } from 'react-i18next';
import React, { useState, useContext, useEffect, Suspense } from "react";
import { Row, Col, FormControl, InputGroup, Form, Modal } from 'react-bootstrap';
import { GPContext } from "../context";
import { InlineWidget } from "react-calendly";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderNone, faTimes } from '@fortawesome/free-solid-svg-icons'
import {CDN} from '../constants'
import { Link, navigate  } from "@reach/router";
let Terms;

const ScooterCalendly = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    const partner = sessionStorage.getItem('partner');
    const brand = sessionStorage.getItem('brand');
    const model = sessionStorage.getItem('model');
    localStorage.setItem('thankYouPageBrand', brand);
    const condition = props.model ? props.model : sessionStorage.getItem('condition');
    const capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');
    let url;
    useEffect(() => {
        Terms = React.lazy(() => import('../terms/scooter-terms_' + i18n.language));
    }, [i18n.language])

    if(props.partner === 'pandas'){
        url = "https://calendly.com/pandas-appointment/scooter";
    }else if(props.partner === 'yougr'){
        url = `https://calendly.com/pandas-appointment/yougr-trade-in-at-home`
    }else {
        url = `https://calendly.com/pandas-appointment/${props.partner.slice(0,-2)}-gr-trade-in-at-home`
    }
    let customAnswers
    if(partner === 'pandas'){
        customAnswers =  {
            a2: sessionStorage.getItem('address'),
            a6: model,
            a4: sessionStorage.getItem('promoCode')
        }
    }else{
        customAnswers =  {
            a2: sessionStorage.getItem('address'),
            a3: model,
            a4: sessionStorage.getItem('promoCode')
        }
    }
    let price;
    if(props.partner==='pandas'){
        price = state.cashOffers[0].price;
    }else if(props.partner === 'yougr'){
        price = state.price
    }else{
        price = sessionStorage.getItem('finalPrice');
    }
    const promoPrice = sessionStorage.getItem('promoPrice');
    const email = sessionStorage.getItem('email');
    const modelImage = sessionStorage.getItem('modelImage');
    // const condition = sessionStorage.getItem('condition');
    console.log(`${CDN}/devices/` + brand.toLowerCase() + "/" + model.replaceAll(' ', '_').toLowerCase() + ".jpg")
    let color;
    if(props.partner==='samsunggr'){
        color = '39b6ff';
    }else if(props.partner === 'xiaomigr'){
        color = 'f26a21'
    }else{
        color = '39b6ff'
    }

    const [loading, setLoading] = useState(true);
    const [getTK, setGetTK] = useState([]);
    const getData = () => {
        fetch(`${process.env.PUBLIC_URL}/scooter/postcodes.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function (response) {
                console.log(response)
                return response.json();
            })
            .then(function (myJson) {
                setGetTK(myJson)
                setLoading(false)
            });
    }

    const wordingToResult = (result) => {
        if(result === "like_new") return "very_good";
        if(result === "good") return "good";
        if(result === "acceptable") return "poor";
        if(result === "broken") return "faulty";
    }

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Scooter Form' });
        getData()
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'scooter form'

    useEffect(() => {
        if (window.location.pathname === '/scooter-form/') {
            console.log('User came directly')
        } else {
            console.log("User did not came directly");
        }
    })

    const [inputTK, setInputTK] = useState('');
    var checkTK = getTK.find(v => v === inputTK)


    function checkEnterKey(e){
        if(e.key === 'Enter'){
            e.preventDefault();
            handleTK();
        }
    }

    const handleTK = () => {
        if (checkTK === inputTK) {
            setStep2(true);
            setStep1(false);
        } else {
            setShow(true);
        }
    }
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (loading) {
        return (
            <Row className="justify-content-md-center mb-4">
                <Col lg="12" className="text-center">
                    <div className="loader">Loading...</div>
                </Col>
            </Row>)
    }
    else {
        return (
            <div className='justify-content-center d-flex'> 
                <Row className="justify-content-between mt-2 mt-lg-5" style={{maxWidth: '90%'}}>
                    <Col className='mb-5'>
                        <Row className='pt-3 pb-2 pb-lg-5 mb-3 mb-lg-0 d-flex flex-column  justify-content-center align-items-center '>
                            <Col xs="12" xl="1" style={{padding: '0'}} className="d-flex justify-content-center align-items-center ">      
                                <div><img src={CDN + "/pricing-app/partners/"+props.partner+"/poi_icon.svg"} alt="ping_icon" width="40" /></div>  
                            </Col>
                            <Col xs='11' sm='10' md="9" lg="11" className='align-self-center' style={{padding: '0'}}>
                                <div className="text-center d-md-flex justify-content-center align-items-center">
                                    <h6 className="mt-2 fw600 fs22" style={{fontSize: '28px'}}>Πότε θα ήθελες να έρθει το Pandas scooter;</h6>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row style={{justifyContent: "space-around"}} className="mb-5 pb-5">                          
                            <Col xs="12" sm="10" md="8" lg="5" xl="5" style={{ maxWidth: '470px'}}>   
                                <div style={{position: 'sticky', top: '70px'}}>
                                    <Row className={`${props.partner}Card justify-content-around pt-2 pt-3 pb-3 pl-1 pr-1`}>
                                        <Col xs="12" className='mb-3 mb-md-2'>
                                            {model!=='unknown' ? <div className="d-md-block fs20 fw700 text-start" style={{fontSize: '23px'}}>{model}</div>
                                            :
                                                <div className="d-md-block fs20 fw700 text-start" style={{fontSize: '23px'}}>Smartphone</div>
                                            }
                                            <div className="d-none d-md-block mt-2 mb-3" style={{ borderBottom: '2px dashed #FFFFFF' }}></div>
                                        </Col>
                                        {brand.toLowerCase() === 'apple' && <Col xs="3" sm="3" md="4" className="whiteBox mb-3 ml-2"><img className='fullWidth ' src={`${CDN}/devices/` + brand.toLowerCase() + "/" + modelImage + ".jpg"} onError={(e) => { e.target.src =`${CDN}/pricing-app/condition/like_new.png`; e.target.onError = null; }} alt="phone"/></Col>}
                                        {brand.toLowerCase() !== 'apple' && <Col xs="3" sm="3" md="4" className="whiteBox mb-3 ml-2"><img className='fullWidth calendarImage' src={`${CDN}/devices/` + brand.toLowerCase() + "/" + modelImage + ".png"} onError={(e) => { e.target.src = `${CDN}/pricing-app/condition/like_new.png`; e.target.onError = null; }} alt="phone"/></Col>}
                                        <Col xs="7" className="text-start pl-0" style={{ color: '#FFFFFF' }}>
                                            <div style={{fontSize: '20px'}} className="fw500 fs16 widthXs pr-xl-4">Πάρε σήμερα έκπτωση έως</div>
                                            <h3 className="fw800 fs22">{parseInt(price) + parseInt(promoPrice)}€<span className="ms-1 fw500"> {t('scooter.appointmentGetToday3')}</span></h3>
                                        </Col>
                                        <Col xs="12" className="d-md-block my-lg-3 pl-3 pr-3 pl-md-3 pr-md-3">
                                            <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: '10px' }}>
                                                <div className="d-flex pl-3 pt-2 pr-2">
                                                    <div className="ms-2 fs13 text-start">Επιστρέφοντας αυτό το smartphone:</div>
                                                </div>
                                                {partner === 'pandas' && <div className="d-flex pl-3 pb-3 pt-3 pr-2">
                                                    <img className='mr-3' src={CDN + "/website/scooter/tree.svg"} alt="mobile_green_blue" width="20" />
                                                    <div className="ms-2 fs13 text-start">+ 1 δέντρο θα φυτευτεί</div>
                                                </div>}
                                                <div className="d-flex pl-3 pb-3 pr-2">
                                                    <img className='mr-3' src={CDN + "/pricing-app/icons/car_icon.svg"} alt="car-icon" width="20" />
                                                    <div className="ms-2 fs13 text-start">- 60kg CO2 από το περιβάλλον</div>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                    <p className='m-auto text-center pt-3' style={{fontSize: '11px', color: '#747676'}}>Σεβόμαστε τα προσωπικά σας δεδομένα. <u onClick={handleShow} style={{color: '#263847', fontWeight: '600', maxWidth: '320px', cursor: 'pointer'}}> Πολιτική Απορρήτου</u></p>
                                </div>
                            </Col>
                            {step1 && 
                             <Col xs="12" sm="12" md="8" lg="6" xl="6" style={{ width: '550px'}} className="mt-3 mt-lg-4 mt-lg-0 calendlyTop">
                                <InlineWidget url={url}
                                    pageSettings={{
                                        backgroundColor: 'ffffff',
                                        hideEventTypeDetails: true,
                                        hideLandingPageDetails: true,
                                        primaryColor: color,
                                        textColor: 263746,
                                        hideGdprBanner: true,
                                    }}
                                    styles={{
                                        width: '100%',
                                        height: '1500px',
                                        border: 'none',
                                    }}
                                    prefill={{
                                        email: email ? email : '',
                                        customAnswers: customAnswers
                                    }}
                                />
                            </Col>
                            }
                        </Row>
                    </Col>
                </Row>
                <div className="footer row justify-content-start align-items-center px-3 px-md-5">
                <Link to={`${process.env.PUBLIC_URL}/${props.partner}/tradein`} className={`back`}>Ακύρωση</Link>
                </div>
                <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <h3 className="text-right mt-3 mr-3 mb-0" onClick={handleClose} style={{ cursor: "pointer", overflow: 'st' }} ><FontAwesomeIcon icon={faTimes} /></h3>
                    <Modal.Body style={{ height: '75vh', overflow: 'scroll' }} className="pt-0">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Terms />
                        </Suspense>
                    </Modal.Body>
                </Modal>
            </div >
        );
    }

}

export default withTranslation()(ScooterCalendly);