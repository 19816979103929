import { withTranslation } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import { GPContext } from "../context";
import PrepContent from './prepContent';
import {CDN} from '../constants'
import { Link } from '@reach/router';

const Thankyou = (props) => {

    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    const partner = sessionStorage.getItem('partner');
    const brand = sessionStorage.getItem('brand');
    const model = sessionStorage.getItem('model');
    const condition = props.model ? props.model : sessionStorage.getItem('condition');
    const capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');
    useEffect(() => {
        window.dataLayer.push({ event: 'Step Scooter Thank You' });
        console.log(window.location.pathname)
        dispatch({ type: "PATH", data: window.location.pathname })
        document.title = "Scooter Thank you - Pandas"
    }, [i18n.language])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'scooter thank you'

    return (
        <div>
            <Row className="justify-content-center text-center  pb-5 mb-5">
                <Col>
                    <Row className="align-items-center justify-content-center text-center mb-2" >
                        <Col xs="12" className="mb-2 mt-5"><img src={CDN + "/pricing-app/partners/yougr/caledar_icon.png"} alt="calendar" width="80" /></Col>
                    </Row>
                    <Row className="align-items-center justify-content-center text-center mb-2">
                        <Col xs="10" lg="6" className="mb-2 mw2 fw"><div  style={{fontSize: '38px', fontWeight: '700'}} className="fs32">Ευχαριστούμε για την κράτησή σου!</div></Col>
                    </Row>
                    <Row className="align-items-center justify-content-center text-center mb-2">
                        <Col xs="12" sm="10" md="9" lg="9" xl="7" className="mb-4 mw2"><div className="fs16 fw300 me-2">Το Pandas Scooter θα σε περιμένει στη διεύθυνση που έχεις ορίσει και την ημερομηνία και ώρα που έχεις επιλέξει.</div></Col>
                    </Row>
                    <Row className="align-items-center justify-content-center text-center mb-2">
                        <Col xs="12" sm="10" md="9" lg="7" xl="5" className="mb-5 mw3"><span className="fs16 fw300 me-2">Δες εδώ πως πρέπει να προετοιμάσεις τη συσκευή σου για το Pandas Scooter.</span><img src="https://s.w.org/images/core/emoji/13.1.0/svg/1f447.svg" alt="down_icon" width="20" /></Col>
                    </Row>
                </Col>
                <PrepContent />
            </Row >
            <div className="footer row justify-content-start align-items-center px-3 px-md-5">
                <a href='https://www.you.gr' className={`next ${state.partner}Next foot mr-3 mr-md-5`}>Επιστροφή στο you.gr</a>
                <Link to={`${process.env.PUBLIC_URL}/yougr`} className={`yougrNextFooterBtn back foot`}>Νέα Προσφορά</Link>
            </div>
        </div >
    );
}

export default withTranslation()(Thankyou);