import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect } from "react";
import { GPContext } from "../context";
import { Link } from "@reach/router"
import Footer from './footer';
import {CDN} from '../constants'

const Verizon = (props) => {
    const { t } = props;

    const { state, dispatch } = React.useContext(GPContext);
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    let instore = sessionStorage.getItem('instore');

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());

    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }

    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        coupon: device.coupon,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl
    }

    useEffect(() => {
        console.log("State: ", state);
        console.log(props)
        window.dataLayer.push({ event: 'offer' });
        dispatch({ type: 'partner', payload: state.partner })
    }, [])

    console.log(brand)
    console.log(offer.brand)

    window.step = 'offer';
    window.brand = brand;
    window.model = model;
    window.capacity = capacity;
    window.working = powerson;
    window.condition = condition;
    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 1000);
    }, []);


    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div>
            <Row className="justify-content-center pb-5">
                <Col xs="10" sm="8" md="12" lg="10" xl="9">
                    <Row style={{ border: '2px solid #E5E4E5', padding: '20px 0px', borderRadius: '10px' }} className="justify-content-center align-items-center">
                        <Col md="5" className="text-center">
                            <h3 className="d-md-none">You can get <b style={{ fontSize: '34px', color: '#000000' }}><br /><b style={{ fontSize: '24px', color: '#000000' }}>$</b>{offer.finalPrice}</b></h3>
                            {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand + "/" + model.replaceAll('-', '_') + ".jpg"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                            {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand + "/" + model.replaceAll('-', '_') + ".png"} onError={(e) => { e.target.src = '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="100%" alt="phone" />}
                        </Col>
                        <Col md="6" className="text-left mt-3 mt-md-0">
                            <div className="d-md-none text-center mx-3" style={{ fontSize: '8px', opacity: '0.5' }}>The offer is finalized at the ATM, depending on the complete evaluation of your smartphone.</div>
                            <div className="d-md-none my-3" style={{ borderBottom: '2px dashed #E5E4E5' }}></div>
                            <h5 className="d-none d-md-block title text-left mb-4">{device.name}</h5>
                            <h6 className="title d-md-none text-center mb-1">{device.name}</h6>
                            <div className="d-flex flex-column flex-md-row align-items-center mb-md-4">
                                <div className="mr-md-4" style={{ fontSize: '14px', opacity: '0.5' }}>{capacity}</div>
                                {condition === 'good' && <div style={{ backgroundColor: '#E7FAF5', padding: '10px', borderRadius: '10px' }} className="d-flex align-items-center">
                                    <div className="mr-3"><img src={CDN + "/pricing-app/condition/good_tag.png"} alt="warning" width="20" /></div>
                                    <div style={{ fontSize: '12px' }}><b>Good device condition</b></div>
                                </div>}
                                {condition === 'poor' && <div style={{ backgroundColor: '#FFF5E8', padding: '10px', borderRadius: '10px' }} className="d-flex align-items-center">
                                    <div className="mr-3"><img src={CDN + "/pricing-app/condition/ok_tag.svg"} alt="warning" width="20" /></div>
                                    <div style={{ fontSize: '12px' }}><b>Poor device condition</b></div>
                                </div>}
                                {condition === 'faulty' && <div style={{ backgroundColor: '#FFE6E2', padding: '10px', borderRadius: '10px' }} className="d-flex align-items-center">
                                    <div className="mr-3"><img src={CDN + "/pricing-app/condition/faulty_col_icon.svg"} alt="warning" width="20" /></div>
                                    <div style={{ fontSize: '12px' }}><b>Faulty device condition</b></div>
                                </div>}
                            </div>
                            <div className="d-none d-md-block" style={{ borderBottom: '2px dashed #E5E4E5' }}></div>
                            <div className="mt-3 d-none d-md-block" style={{ fontSize: '34px' }}>You can get <b style={{ fontSize: '38px', color: '#000000' }}><b style={{ fontSize: '24px', color: '#000000' }}>$</b>{offer.finalPrice}</b></div>
                            <div className="d-none d-md-block" style={{ fontSize: '8px', opacity: '0.5' }}>The offer is finalized at the ATM, depending on the complete evaluation of your smartphone.</div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-5">
                        {offer.finalPrice >= 70 && <Col xs="10" md="5" lg="5" xl="5" className="mb-3 mb-md-0">
                            <a href={`https://greenpanda.io/scooter-form/?brand=${offer.brand}&model=${offer.model}&smartphone=${offer.brand} ${offer.model}&price=${offer.finalPrice}`} id="offer-page_scooter" className="courier">Ask for a courier</a>
                        </Col>}
                        <Col xs="10" md="5" lg="5" xl="5">
                            {instore ? <Link to={`${process.env.PUBLIC_URL}/${props.partner}`} className="">
                                <div className="verizonNext next">New Assessment</div>
                            </Link>
                            :
                            <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator`} className="">
                                <div className="verizonNext next">Find a nearby store locator</div>
                            </Link>
                            }
                        </Col>
                        <Col xs="12" className="my-5">
                            <div style={{ borderBottom: '2px solid #E5E4E5' }}></div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-center mb-5 pb-5">
                        <Col md="4" className="text-center">
                            <div className="d-md-none title text-center mb-4">Scan and Download the Verizon App </div>
                            <img src={CDN + "/pricing-app/photos/qrcode.png"} alt="Qrcode" width="140" />
                        </Col>
                        <Col md="7" className="text-left mt-3 mt-md-0">
                            <div className="d-md-none my-3" style={{ borderBottom: '2px dashed #E5E4E5' }}></div>
                            <div className="d-none d-md-block title text-left mb-4">Scan and Download the Verizon App </div>
                            <div className="d-flex flex-column flex-md-row align-items-center">
                                <div className="mr-md-4" style={{ fontSize: '14px' }}>Add it to your</div>
                                <div className="mx-3 my-3 my-md-0"><img src={CDN + "/pricing-app/logos/applewallet_logo.svg"} alt="warning" width="140" /></div>
                                <div className=""><img src={CDN + "/pricing-app/logos/gpay_logo.svg"} alt="warning" width="150" /></div>
                            </div>
                        </Col>
                        <Col xs="12" className="my-5">
                            <div style={{ borderBottom: '2px solid #E5E4E5' }}></div>
                        </Col>
                        <Col xs="12">
                            <div className="title mb-3">Why do I need a QR code?</div>
                            <div>Your QR code ensures that you get the best price possible, locking the current offer for seven days, as long as your device’s condition doesn’t deteriorate.</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Footer back={{ text: `Back`, path: `/${partner}/${brand}/${model}/${capacity}/${powerson}/${condition}` }}></Footer>
        </div>
    );
}

export default withTranslation()(Verizon);