import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CDN } from '../constants'
import Mapbox from "../components/pandasmapbox";
import Maplist from "../components/pandasmaplist";

const OfferLocations = (props) => {
    const { t, i18n } = props;
    console.log('url',window.location.href.includes('voucher'))
    const { state, dispatch } = React.useContext(GPContext);
    let partner = props.partner ? props.partner.toLowerCase() : '';
    let brand = props.brand ? props.brand.toLowerCase() : '';
    let model = props.model ? props.model.toLowerCase() : '';
    let capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    let condition = props.condition ? props.condition.toLowerCase() : '';
    const [openMap, setOpenMap] = useState(false);
    const [showScroll, setShowScroll] = useState(false);
    
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
        }
    };
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // window.addEventListener('scroll', checkScrollTop)

    useEffect(() => {
      let event;
      if(window.location.href.includes('voucher')){
        event = 'Step Voucher Map Locations';
      }else if(window.location.href.includes('cash')){
        event = 'Step Cash Map Locations';
      }
      window.dataLayer.push({ event: event });
      window.step = event;
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;

    const handleMapButton = () => {
        setOpenMap(!openMap);
        window.scroll(0, 0);
    }

    console.log(props)
  return (
      <>
      {state.cashOffers === undefined?<div></div>:
    <main className='' id="pandasmap">
    <section className=''>
      <div>
        <div style={{ width: '100%' }}>
          <Row className="d-flex justify-content-between mt-4 m-1">
            {false ?
              <Row className="justify-content-md-center mb-4">
                <Col lg="12" className="text-center">
                  <h3 className="title mb-4">{t('sectionOne.wait')}</h3>
                  <div className="loader">Loading...</div>
                </Col>
              </Row> : <>

                <Col xs="12" md="5" xl="4" className={"order-1 order-md-1  p-0"} >
                  <Maplist openMap={openMap}/>
                </Col>

                <Col md="7" xl="8" className={"order-2 order-md-2 " + (openMap ? 'd-block' : 'd-none d-md-block')}>
                  <Mapbox />
                </Col>

                <div className={ openMap ? 'd-none' : 'pandasshowMap d-md-none'} style={{padding: '0 0 20px' }}>
                  <button onClick={handleMapButton} className="mx-auto d-block" >
                    <img src={CDN + "/pricing-app/icons/MapTrifold_white.svg"} alt="info" height="25px" />
                    <span>Δες το χάρτη</span>
                  </button>
                </div>
                <div className={openMap ? `pandascloseMap d-md-none` : 'd-none'} style={{padding: '0 0 20px' }}>
                  <button onClick={handleMapButton} className="d-block mx-auto">   
                    <p className="m-0" style={{fontSize:'12px'}}>Πίσω</p>
                  </button>
                </div>
              </>}
          </Row>
        </div>

      </div >
    </section>
  </main>}
  </>
  )
}

export default withTranslation()(OfferLocations);