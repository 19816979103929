import { Row, Col, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import { CDN } from '../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Holidays from 'date-holidays';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { changeToEng, changeToGreek } from '../helperFunctions';
import axios from 'axios';

const Courier = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()+1);
    var endDate = new Date();
    endDate.setDate(endDate.getDate()+31);
    const [startDate, setStartDate] = useState('');
    const [courierDate, setCourierDate] = useState('')
    const [courierTime, setCourierTime] = useState('11:00-15:00');
    let termsUrl, privacyUrl;
    const samsungNewPromoFlow = sessionStorage.getItem('promoCode')?.includes('SMM2023');
    if(samsungNewPromoFlow){
        termsUrl = `https://www.pandas.io/el-GR/samsung-newpromo/terms`;
        privacyUrl = `https://www.pandas.io/el-GR/samsung-newpromo/privacy-policy`;
    }else{
        termsUrl = `https://www.pandas.io/el-GR/${props.partner.slice(0,-2)}/terms`;
        privacyUrl = `https://www.pandas.io/el-GR/${props.partner.slice(0,-2)}/privacy-policy-courier`;
    }
    const URL = 'https://hook.eu1.make.com/q32rvg1yh77dmlbqv4oigca5z541wjko';


    var partner = sessionStorage.getItem('partner') ?? props.partner.toLowerCase();
    var brand = sessionStorage.getItem('brand') ?? props.brand.toLowerCase();
    const promoPrice = sessionStorage.getItem('promoPrice') ?? 0;
    var model = sessionStorage.getItem('model') ?? props.model.toLowerCase();
    var condition = sessionStorage.getItem('condition') ?? props.condition.toLowerCase();
    var capacity = sessionStorage.getItem('capacity') ?? props.capacity.toLowerCase();
    const [error, setError] = useState("");
    const [show, setShow] = useState(false);
    const [showImei, setShowImei] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseImei = () => setShowImei(false);
    const handleShow = () => setShow(true);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);

    const [isReady, setIsReady] = React.useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState(sessionStorage.getItem('email'));
    const [phoneNumber, setPhoneNumber] = useState('');
    const [imei, setImei] = useState('');
    const [imeiErrorMsg, setImeiErrorMsg] = useState('');
    const [edit, setEdit] = useState(false);
    const [address, setAddress] = useState('')
    const [addressNumber, setAddressNumber] = useState('')
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [check3, setCheck3] = useState(false);
    const [style1, setStyle1] = useState('greyBorder');
    const [style2, setStyle2] = useState('greyBorder');
    const [style3, setStyle3] = useState('greyBorder');
    const [click,setClick] = useState(0)
    const [loader, setLoader] = useState(false)
    const [submitButton, setSubmitButton] = useState(true)
    const modelImage = sessionStorage.getItem('modelImage')
    capacity = capacity.toUpperCase()
    let imageUrl;
    if (brand.toLowerCase() === 'apple') {
        imageUrl = `${CDN}/devices/${brand.toLowerCase()}/${modelImage}.jpg`
    }else{
        imageUrl = `${CDN}/devices/${brand.toLowerCase()}/${modelImage}.png`
    }
    const hd = new Holidays('GR');

    let finalPrice;
    if(props.partner==='pandas'){
        finalPrice = state.cashOffers[0].price;
    }else if (props.partner === 'yougr'){
        finalPrice = sessionStorage.getItem('price');
    }else{
        finalPrice = sessionStorage.getItem('finalPrice');
    }

    if(props.partner === 'yougr' && !finalPrice){
        const queryParameters = new URLSearchParams(window.location.search)
        capacity = queryParameters.get("capacity")
        brand = queryParameters.get("brand")
        model = queryParameters.get("model")
        condition = queryParameters.get("condition").toLowerCase().replace('-', '-');
        if(condition === 'like new'){
            condition = 'very_good';
        }
        capacity = (capacity && capacity !== 'stock') ? capacity.toLowerCase() : '';
        var devices = state.devices.filter(
            (a) =>    
            a.brand.toLowerCase() === brand.toLowerCase() &&
              a.model.toLowerCase() ===
                model.replaceAll("-", " ").replaceAll("_", "/").toLowerCase()
            
        );
        
          var variants = devices.reduce((map, obj) => {
            map[obj.capacity] = obj;
            return map;
          }, {});
         var device = variants[capacity.toUpperCase()];
         model = device.model
         brand = device.brand
         finalPrice = device[condition + '_price'];
         sessionStorage.setItem('partner', partner);
         sessionStorage.setItem('brand', brand);
         sessionStorage.setItem('model', model)
         sessionStorage.setItem('condition', condition);
         sessionStorage.setItem('capacity', capacity);
    }
    async function requestAcsDelivery(courierData) {
        return axios.post(
          `https://acs-courier-production.up.railway.app/api/deliveries`,
          courierData,
          { headers: { "x-api-key": `${process.env.REACT_APP_COURIER_SERVICE_API_KEY}` }, responseType: 'json' }
        );
    }

    async function cancelAcsDelivery(deliveryId) {
        return axios.post(
          `https://acs-courier-production.up.railway.app/api/deliveries/${deliveryId}/cancel`,
          {},
          { headers: { "x-api-key": `${process.env.REACT_APP_COURIER_SERVICE_API_KEY}` }, responseType: 'json' }
        );
    }

    async function log(level, message, data) {
        return axios.post(
          `https://api.axiom.co/v1/datasets/pandas-prod/ingest`,
          [{level: level ,message: `[pricing-app] ${message}`, metadata: [data], project: 'pricing-app'}],
          { headers: { "Authorization": `Bearer ${process.env.REACT_APP_AXIOM_TOKEN}`, 'Content-Type': 'application/json' } }
        );
    }

    useEffect(()=>{
        window.dataLayer.push({ event: 'Step Courier Form' });
        if(props.partner==='samsunggr'){
            setImei('000000000000000');
        }else if(props.partner==='xiaomigr'){
            setImei(sessionStorage.getItem('promoCode'));
        }
    },[]);

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'courier form';


    const handleClick = (e) => {
        if(e.target.id !== 'address'){
            setEdit(false);
        }
    }

    const isWeekday_Holiday = (date) => {
        
        const day = date.getDay(date);
        if(hd.isHoliday(date)){
            return false;
        }else{
            return date < endDate && day !== 0 && day !== 6 ;
        };
        
    };

    const handleName = (event) => {
        if (/^\p{L}+\s*\p{L}*$/u.test(event.target.value) || event.target.value === '') {
            setName(event.target.value)
            sessionStorage.setItem('name', event.target.value)
            setClick(click+1);
        } 
    }

    const handleSurname = (event) => {
        if (/^\p{L}+\s*\p{L}*$/u.test(event.target.value) || event.target.value === '') {
            setSurname(event.target.value)
            sessionStorage.setItem('surname', event.target.value)
            setClick(click+1);
        } 
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
        setClick(click+1);
    }

    const handleZipcode = (event) => {
        setZipcode(event.target.value)
        setClick(click+1);
    }

    const handleAddress = (event) => {
        if (/^\p{L}+\s*\p{L}*$/u.test(event.target.value) || event.target.value === '') {
            setAddress(event.target.value)
            setClick(click+1);
        } 
    }

    const handleCity = (event) => {
        if (/^\p{L}+\s*\p{L}*$/u.test(event.target.value) || event.target.value === '') {
            setCity(event.target.value);
            setClick(click+1);
        }
    }

    const handleAddressNumber = (event) => {
        if (/^\d+$/.test(event.target.value) || event.target.value === '') {
            setAddressNumber(event.target.value);
            setClick(click+1);
        }
    }

    const handleImei = (event) => {
        setImeiErrorMsg('');
        if (event.target.value!==''){
            let isNum = /^\d+$/.test(event.target.value);
            if(!isNum){
                setImeiErrorMsg('Υποστηρίζονται μόνο αριθμοί!')
            }
        }
        
        setImei(event.target.value)
        setClick(click+1);
    }

    const handlePhoneNumber = (phone) => {
        setPhoneNumber('+'+phone)
        setClick(click+1);
    }

    useEffect( () => {
        submit()
    }, [click])

    useEffect( () => {
        if(startDate!==''){
            setCourierDate(startDate.toLocaleDateString())
        }
    }, [startDate])

    const validation = async (event) => {
        setError("")
        event.preventDefault();
        if(phoneNumber.length < 7){
            setError('Ο αριθμός τηλεφώνου δεν είναι έγκυρος');
            return;
        }
        try{
            setSubmitButton(true)
            setLoader(true);
            let promoCode;
            if(partner==='samsunggr' || partner==='xiaomigr'){
                promoCode = sessionStorage.getItem('promoCode');
            }else{
                promoCode = imei;
            }
            sessionStorage.setItem('courierDate', courierDate)
            sessionStorage.setItem('courierTime', courierTime)
            event.preventDefault();
            var today = new Date();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var date = today.getDate() + '/' + (today.getMonth()+1)+'/'+ today.getFullYear();
            const fullAddress = address + ' ' + addressNumber + ', ' + zipcode;

            const bodyData = {
                promoCode: promoCode,
                partner: partner,
                firstName: name,
                lastName: surname,
                email: email,
                phoneNumber: phoneNumber,
                capacity: capacity,
                condition_gr: changeToGreek(condition),
                condition: changeToEng(condition),
                brand: brand,
                model: model,
                address: fullAddress,
                courierDate: courierDate,
                courierTime: courierTime,
                currentTime: time,
                currentDate: date,
                date: new Date().toISOString().slice(0, 10),
                device_category: state.category === 'ipad' ? 'tablet': 'smartphone',
                device_image_url: imageUrl,
                buy_price: finalPrice,
                courier_scheduled_date: courierDate.replaceAll('/', '-').split('-').reverse().join('-'), //format to YYYY-MM-DD
                courier_page_url: window.location.href
            };
            const formattedDate = `${startDate.getFullYear()}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getDate().toString().padStart(2, '0')}`;

            const acsData = {
                pick_up_date: formattedDate,
                pick_up_interval_from: courierTime.split("-")[0],
                pick_up_interval_to: courierTime.split("-")[1],
                pick_up_details: "Please handle with care",
                sender: {
                    name: name + " " + surname,
                    email: email,
                    phone: phoneNumber,
                    address: {
                    city: city,
                    number: addressNumber,
                    address: address + " " + addressNumber,
                    zipcode: zipcode,
                    },
                },
                recipient: {
                    name: "Greenpanda",
                    email: "hello@pandas.io",
                    phone: "2111982521",
                    address: {
                    city: "Athens",
                    number: "2",
                    address: "Merlin 2",
                    zipcode: "10671",
                    },
                },
                package: {
                    length: 1,
                    width: 1,
                    height: 1,
                    weight: 1,
                    items_count: 1,
                },
            }
            const acsResponse = await requestAcsDelivery(acsData);
            const logData = {
                id: acsResponse?.data?.data?.id,
                status: acsResponse?.data?.data?.status,
                provider_status: acsResponse?.data?.data?.provider_status,
                details: acsResponse?.data?.data?.details,
                raw_data: JSON.stringify(acsResponse)
            }
            const infoLevel = acsResponse?.data?.data?.id > 0 ? 'info' : 'error';
            await log(infoLevel, '[INFO] Courier data send', logData);
            if(acsResponse?.data?.data?.id > 0){
                bodyData.id = acsResponse?.data?.data?.id
                bodyData.status = acsResponse?.data?.data?.status
                bodyData.provider_status = acsResponse?.data?.data?.provider_status
                bodyData.details = acsResponse?.data?.data?.details
                fetch(URL, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'deflate, gzip, br'
                    },
                    body: JSON.stringify(bodyData)
                })
                .then((response) => {
                    response.blob().then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),{ type: 'application/pdf' }
                    );
                    sessionStorage.setItem('pdfUrl', url);
                    navigate(process.env.PUBLIC_URL+`/${props.partner}/courier-thankyou`);
                }).catch((error)=>{
                    log('error', '[INFO] Webhook error', {logData, error: error});
                    cancelAcsDelivery(acsResponse?.data?.data?.id)
                    setSubmitButton(false);
                    setLoader(false);
                    setError("Κάτι πήγε στραβά");
                })})
            }else{
                setSubmitButton(false);
                setLoader(false);
            }
        }catch(e){
            log('error', '[INFO] Something went wrong with ACS request', {error: e});
            setSubmitButton(false);
            setLoader(false);
            setError(e.response.data.error)
        }
    }

    const submit = () => {
        if(name!=='' && surname!=='' && email!=='' && phoneNumber!=='' && imei && /^\d+$/.test(imei) && address!=='' && addressNumber !=='' && city !=='' && zipcode !=='' && courierDate && check1 && check2 && check3){
            setSubmitButton(false);
        }else{
            setSubmitButton(true)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);


        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">{t('wait')}</h3>
                        <div className={`${state.partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        }
    return (
        <div onClick={handleClick} style={{position: 'relative'}}>
            
            <Row className='justify-content-center justify-content-md-between m-auto pt-5' style={{marginTop: '50px', maxWidth: '930px'}}>
                <Col xs="12" md="6" lg="6" className='text-center' style={{ fontSize: '20px', maxWidth: '400px'}}>
                    <div className='d-md-none'>
                        <h1 style={{fontWeight: '800'}}>Στείλε με Courier</h1>
                        <h6 style={{fontWeight: '500', fontSize: '16px'}} className='m-md-auto mx-0 mt-md-3 mt-3 mb-5'>Θα επικοινωνήσουμε μαζί σου
                            με το που λάβουμε τη συσκευή σου.
                        </h6>
                    </div>
                    <img className='mb-5 mb-md-0 d-none d-md-block' style={{maxWidth: '280px', paddingRight: '20px'}} src={`${process.env.PUBLIC_URL}/courier.png`} alt="courier girl" />
                    <img className='mb-4 mb-md-0 d-md-none' style={{maxWidth: '280px'}} src={`${CDN}/pricing-app/icons/courier-girl-mobile.png`} alt="courier girl" />
                    <div className='ml-md-4' style={{paddingRight: '100px'}}>
                        {brand!=='unknown' ? <div className='mt-3 d-none d-md-block' style={{fontWeight: '600', lineHeight: '20px'}}>{brand}</div>
                        :
                        <div className='mt-3 d-none d-md-block' style={{fontWeight: '600', lineHeight: '20px'}}>Η συσκευή σου</div>
                        }
                            {model!=='unknown' ? <div className='d-none d-md-block'>{model}</div>
                        :
                            <div className='d-none d-md-block'>Smartphone</div>
                        }
                        <div className='d-none d-md-block' style={{ fontSize: '40px', fontWeight: '800', lineHeight: '40px' }}>€{parseInt(finalPrice) + parseInt(promoPrice)}</div>
                    </div>
                    <div className='text-left mt-5 mb-4 d-none d-md-block' style={{fontSize: '16px'}}>
                        <div className='d-flex align-items-center mb-3'><img src={CDN + "/pricing-app/icons/check-icon.svg"} alt='check icon' /><p className='m-0 ml-3 '>Άμεση πληρωμή, αυθημερόν</p></div>
                        <div className='d-flex align-items-center mb-5'><img src={CDN + "/pricing-app/icons/check-icon.svg"} alt='check icon' /><p className='m-0 ml-3 '>100% ασφαλές</p></div>
                        
                    </div>
                    <div className='text-left' style={{fontSize: '16px', fontWeight: '600'}}>
                        <h6 className='mb-3 d-none d-md-block' style={{fontSize: '20px', fontWeight: '600'}}>Πώς δουλεύει:</h6>
                        <div className='d-flex align-items-center mb-3'><img src={CDN + "/pricing-app/icons/note-pencil-icon.svg"} alt="pencil icon" width="30px" /><p className='mb-0 py-3 ml-2' style={{borderBottom: '2px solid #f2f2f2', lineHeight: '20px', width: '100%'}}>Συμπληρώνεις τα στοιχεία σου</p></div>
                        <div className='d-flex align-items-center mb-3'><img src={CDN + "/pricing-app/icons/package-icon.svg"} alt="package icon" width="30px" /><p className='mb-0 py-3 ml-2' style={{borderBottom: '2px solid #f2f2f2',lineHeight: '18px', width: '100%'}}>Το courier παραλαμβάνει δωρεάν</p></div>
                        <div className='d-flex align-items-center mb-3'><img src={CDN + "/pricing-app/icons/offer-icon.svg"} alt="offer icon" width="30px" /><p className='mb-0 py-3 ml-2' style={{borderBottom: '2px solid #f2f2f2', lineHeight: '18px', width: '100%'}}>Μόλις φτάσει, παίρνεις τελική προσφορά</p></div>
                        <div className='d-flex align-items-center mb-3'><img src={CDN + "/pricing-app/icons/hand-pointing-icon.svg"} alt="hand pointing icon" width="30px" /><p className='mb-0 py-3 ml-2' style={{lineHeight: '18px', width: '100%'}}>Αν τη δεχτείς, πληρώνεσαι άμεσα. Αν όχι, στην επιστρέφουμε δωρεάν.</p></div>
                    </div>
                </Col>
                <Col xs="12" md="6" className='courierFormContainer' style={{marginTop: '20px'}}>
                    <div className='d-none d-md-block text-md-center m-auto pb-2 pb-md-4' style={{maxWidth: '350px'}}>
                        <h1 style={{fontWeight: '800'}}>Στείλε με Courier</h1>
                        <div className='editContainer d-md-none' style={{color: '#6c6c6c', fontSize: '14px'}}><span>{address}</span>  <Link to={`${process.env.PUBLIC_URL}/${props.partner}/location`} className=""><img id='address' src={`${process.env.PUBLIC_URL}/edit-icon.svg`} alt="edit icon" /></Link></div>    
                            <h6 style={{fontWeight: '600', fontSize: '16px', maxWidth: '300px'}} className='m-md-auto mt-md-3 mt-3 mb-0'>Θα επικοινωνήσουμε μαζί σου
                            με το που λάβουμε τη συσκευή σου.
                            </h6>
                    </div>
                    <div className='d-md-none text-center mb-5' id="scroll">
                        <img className='mr-3' src={CDN + "/pricing-app/icons/arrow-down-icon.svg"} alt="down arrow icon" width="20px" /> <a href="#scroll" style={{fontWeight: '700', fontSize: '16px', color: '#1a1a1a'}}>Συνέχισε στη φόρμα</a> <img className='ml-3' src={CDN + "/pricing-app/icons/arrow-down-icon.svg"} alt="down arrow icon" width="20px" />
                    </div>
                    <form id='form' className='floatingForm m-auto' style={{maxWidth: '350px'}} onSubmit={validation}>
                        <label for="name">
                            <input type="text" value={name} id="name" placeholder="Όνομα" maxLength={35} onChange={handleName} required/>
                            <span>Όνομα</span>
                        </label>
                        <label for="surname">
                            <input type="text" value={surname} id="surname" placeholder="Επίθετο" maxLength={35} onChange={handleSurname} required />
                            <span>Επίθετο</span>
                        </label>
                        <label for="email">
                            <input type="email" id="email" placeholder="Email" maxLength={320} defaultValue={sessionStorage.getItem('email')} onChange={handleEmail} required/>
                            <span>Email</span>
                        </label>
                        <label for="tel" className='phoneLabel'>
                            <p>Τηλέφωνο</p>
                            <PhoneInput
                                international
                                country={'gr'}
                                defaultValue={phoneNumber}
                                onChange={phone => {handlePhoneNumber(phone)}}
                                className='phoneInput'
                                countryCodeEditable={false}
                                maxLength={15}
                                />
                        </label>
                        <div style={{display: 'grid', gridTemplateColumns: '4fr 2fr', gap: '16px'}}>
                            <label for="email">
                                <input type="text" id="address" value={address} maxLength={70} placeholder="Διεύθυνση" onChange={handleAddress} required/>
                                <span>Διεύθυνση</span>
                            </label>
                            <label for="email">
                                <input type="text" id="address" value={addressNumber} maxLength={10} placeholder="Αριθμός" onChange={handleAddressNumber} required/>
                                <span>Αριθμός</span>
                            </label>
                        </div>
                        <label for="email">
                            <input type="number" id="zipcode" placeholder="Τ.Κ" maxLength={10} onChange={handleZipcode} required/>
                            <span>Ταχυδρομικός Κώδικας</span>
                        </label>
                        <label for="email">
                            <input type="text" value={city} id="address" placeholder="Πόλη" maxLength={50} onChange={handleCity} required/>
                            <span>Πόλη</span>
                        </label>
                        <p style={{fontSize: '14px', fontWeight: '500', marginBottom: '8px'}}>Επίλεξε ημερομηνία και ώρα ραντεβούμε courier.</p>
                        <div className='d-flex justify-content-between mb-4'>
                            <div className='calendar d-flex justify-content-between align-items-center' style={{width: '48%'}}>
                                <div>
                                    <p className='mb-0 ml-3' style={{color: '#6c6c6c', fontSize: '11px', fontWeight: '700'}}>Ημερομηνία</p>
                                    <DatePicker monthsShown={1} minDate={tomorrow} filterDate={isWeekday_Holiday} dateFormat='dd-MM-yyyy' endDate={endDate} selected={startDate} onChange={(date) => { setStartDate(date); setClick(click+1)}} style={{cursor: 'pointer'}}/>
                                </div>
                                <div className='mr-3'><img src={CDN + "/pricing-app/icons/down-arrow.svg"} alt='down arrow' /></div>
                            </div>
                            <div className='time d-flex justify-content-between align-items-center' style={{width: '48%'}}>
                                <div>
                                    <p className='mb-0 ml-3' style={{color: '#6c6c6c', fontSize: '11px', fontWeight: '700'}}>Ώρα</p>
                                    <select className='ml-3' style={{cursor: 'pointer'}} value={courierTime} onChange={(e) => {setCourierTime(e.target.value); setClick(click+1)}}>
                                        <option defaultValue="" disabled selected hidden></option>
                                        <option defaultValue="11:00-15:00">11:00-15:00</option>
                                        <option defaultValue="15:00-18:00">15:00-19:00</option>
                                    </select>
                                </div>
                                <div className='mr-3'><img src={CDN + "/pricing-app/icons/down-arrow.svg"} alt='down arrow' /></div>
                            </div>
                        </div>
                        {props.partner!=='samsunggr' && props.partner!=='xiaomigr' && 
                            <>
                                <label for="IMEI">
                                    <input type="text" id="imei" placeholder="IMEI" onChange={handleImei} required/>
                                    <span className='d-flex'>IMEI <div className="infoLink" maxLength={25} style={{marginLeft: '5px'}} onClick={() => setShowImei(!showImei)}><FontAwesomeIcon icon={faQuestionCircle} /></div></span>
                                </label>
                            </>
                        }
                        <p style={{color: 'red', fontSize: '10px', height: '15px'}}>{imeiErrorMsg}</p>
                        <div className={`${style1} mt-md-3 mt-2 justify-content-center align-items-center`}>
                            <input onClick={() => {setCheck1(!check1); setClick(click+1); if(check1){setStyle1('greyBorder')}else{setStyle1(`blackBorder ${props.partner}Border`)} }} type="checkbox" id="liability" name="liability" required/>
                            {props.partner!=='pandas' ? 
                                <label style={{paddingLeft: '15px', fontSize: '14px', lineHeight: '26px'}} for="liability"> Αποδέχομαι τους <b><u><a style={{color: '#1a1a1a'}} href={termsUrl} target='blank'>όρους χρήσης</a></u></b> </label>
                            :
                                <label style={{paddingLeft: '15px', fontSize: '14px', lineHeight: '26px'}} for="liability"> Αποδέχομαι τους <b><u><a style={{color: '#1a1a1a'}} href='https://www.pandas.io/el-GR/courier/terms' target='blank'>όρους χρήσης</a></u></b> </label>
                            }
                        </div>

                        <div className={`${style2} mt-4 justify-content-center align-items-center`}>
                            <input onClick={() => {setCheck2(!check2); setClick(click+1); if(check2){setStyle2('greyBorder')}else{setStyle2(`blackBorder ${props.partner}Border`)} }}   type="checkbox" id="terms" name="terms" required/>
                            {props.partner!=='pandas' ? 
                                <label style={{paddingLeft: '15px', fontSize: '14px', lineHeight: '26px'}} for="terms">  Αποδέχομαι την <b><u><a style={{color: '#1a1a1a'}} href={privacyUrl} target='blank'>πολιτική απορρήτου</a></u></b> </label>
                            :
                                <label style={{paddingLeft: '15px', fontSize: '14px', lineHeight: '26px'}} for="terms">  Αποδέχομαι την <b><u><a style={{color: '#1a1a1a'}} href='https://www.pandas.io/el-GR/courier/privacy-policy' target='blank'>πολιτική απορρήτου</a></u></b> </label>
                            }
                        </div>
                        <div className={`${style3} mt-4 justify-content-center align-items-center`}>
                            <input onClick={() => {setCheck3(!check3); setClick(click+1); if(check3){setStyle3('greyBorder')}else{setStyle3(`blackBorder ${props.partner}Border`)} }}  style={{}} type="checkbox" id="terms" name="terms" required/>
                            <label style={{paddingLeft: '15px', fontSize: '14px', lineHeight: '26px'}} for="terms">  Υπεύθυνη δήλωση <b><u onClick={handleShow}>κυριότητας συσκευής</u></b> </label>
                        </div>
                        <p className='d-md-none text-center' style={{margin: '25px auto 0', color: '#808080', fontSize: '14px', width: '320px'}}>
                            Η Pandas δεν φέρει καμία ευθύνη για τυχόν φθορές ή σπασίματα που μπορεί να προκληθούν στη συσκευή από τη μεταφορά της με το courier.
                        </p>
                        <div className='text-center'>
                            {!loader && <button id='form' className={`formButton ${props.partner}Next ${props.partner}Border`} style={{marginBottom: '10px'}} type='submit' disabled={submitButton} >Επιβεβαίωση  </button>}
                            {loader &&  <div style={{ margin: '80px auto 100px', fontSize: '10px', }} className={`${partner}Loader`}>Loading...</div>}
                            {error !=="" && <p style={{color: 'red'}}>{error}</p>}
                        </div>
                    </form>
                </Col>
            </Row>

            {props.partner==='pandas' && <Row className='justify-content-center'>
                    <Col className={`container pe-0`}>      
                        <Row className='justify-content-center'>
                            <Col xs="12" lg="12" xl="10" className="textContainer">
                                <Col className="headingContainer">
                                    <Col className='pl-0'>
                                        <h3 style={{fontSize: '32px', fontWeight: '700', marginBottom: '20px'}}>Έχεις ερωτήσεις;</h3>
                                    </Col>
                                    <h6 style={{fontSize: '20px', fontWeight: '400', marginBottom: '35px'}}>Βρες εδώ την απάντηση που ψάχνεις.</h6>
                                </Col>
                                <Col className="accordionContainer" id="courier" >
                                <div className="accordion">
                                    <div className="accordion-item">
                                        <div
                                        className="d-flex align-items-center"
                                        onClick={() => setOpen1(!open1)}
                                        >
                                            <div>{open1 ? <div className="toggle"><img src={`${CDN}/pandas-website/common/icons/arrowDown.svg`} alt={'arrow down'} width="36" height="36" /></div> : <div className="toggleDown"><img src={`${CDN}/pandas-website/common/icons/arrowRight.svg`} alt={'arrow right'} width="36" height="36" /></div>}</div>
                                            {/* <div>{open1 ? {angleRight} : {angleDown}}</div> */}
                                            <div className='accordion-title'>Πόσες μέρες χρειάζονται για να ολοκληρωθεί το trade-in και να πληρωθώ;</div>
                                        </div>
                                        {open1 && <div className="accordion-content">Εξαρτάται από την περιοχή που βρίσκεσαι. Η Pandas συνεργάζεται με την ACS για την courier υπηρεσία μας. Οι αποστολές εντός Αττικής συνήθως φτάνουν σε 1-2 εργάσιμες ημέρες και εκτός Αττικής, συνήθως 2-3 εργάσιμες. Η Pandas, μόλις παραλάβει τη συσκευή σου, προχωράει άμεσα σε αξιολόγηση και εντός 1 εργάσιμης επικοινωνούμε μαζί σου για να σου δώσουμε προσφορά βάσει της κατάστασης του κινητού. Εφόσον τη δεχτείς, προχωράμε άμεσα στην κατάθεση του ποσού.</div>}
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                        className=" d-flex align-items-center"
                                        onClick={() => setOpen2(!open2)}
                                        >
                                            <div>{open2 ? <div className="toggle"><img src={`${CDN}/pandas-website/common/icons/arrowDown.svg`} alt={'arrow down'} width="36" height="36" /></div> : <div className="toggleDown"><img src={`${CDN}/pandas-website/common/icons/arrowRight.svg`} alt={'arrow right'} width="36" height="36" /></div>}</div>
                                            {/* <div>{open1 ? {angleRight} : {angleDown}}</div> */}
                                            <div className='accordion-title'>Είναι δεσμευτικό να αποδεχτώ την προσφορά που θα πάρω;</div>
                                        </div>
                                        {open2 && <div className="accordion-content">Καθόλου! Η Pandas σου προσφέρει την καλύτερη δυνατή τιμή για τη συσκευή σου ελέγχοντας την κατάστασή της και συγκρίνοντας τις τιμές που προσφέρουν τα μεγαλύτερα marketplace. Αν παρόλα αυτά, δεν σε ικανοποιεί η προσφορά μας, θα σου στείλουμε πίσω τη συσκευή σου χωρίς χρέωση.</div>}
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                        className="d-flex align-items-center"
                                        onClick={() => setOpen3(!open3)}
                                        >
                                            <div>{open3 ? <div className="toggle"><img src={`${CDN}/pandas-website/common/icons/arrowDown.svg`} alt={'arrow down'} width="36" height="36" /></div> : <div className="toggleDown"><img src={`${CDN}/pandas-website/common/icons/arrowRight.svg`} alt={'arrow right'} width="36" height="36" /></div>}</div>
                                            {/* <div>{open1 ? {angleRight} : {angleDown}}</div> */}
                                            <div className='accordion-title'>Τι γίνεται με το κινητό μου αν αλλάξω γνώμη;</div>
                                        </div>
                                        {open3 && <div className="accordion-content">Έχεις το ελεύθερο να αλλάξεις γνώμη και να διακόψεις τη διαδικασία του trade-in ανά πάσα στιγμή μέχρι την αποδοχή της προσφοράς, επικοινωνώντας με την Pandas. Εμείς θα σου στείλουμε τη συσκευή πίσω, αν δεν επιθυμείς να προχωρήσουμε με την αξιολόγησή της.</div>}
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                        className="d-flex align-items-center"
                                        onClick={() => setOpen4(!open4)}
                                        >
                                            <div>{open4 ? <div className="toggle"><img src={`${CDN}/pandas-website/common/icons/arrowDown.svg`} alt={'arrow down'} width="36" height="36" /></div> : <div className="toggleDown"><img src={`${CDN}/pandas-website/common/icons/arrowRight.svg`} alt={'arrow right'} width="36" height="36" /></div>}</div>
                                            {/* <div>{open1 ? {angleRight} : {angleDown}}</div> */}
                                            <div className='accordion-title'>Ποιος καλύπτει το κόστος της αποστολής;</div>
                                        </div>
                                        {open4 && <div className="accordion-content">Για την αποστολή στην Pandas, το κόστος καλύπτεται από εσένα. Ωστόσο, αν δεν είσαι ικανοποιημένος με την προσφορά που θα λάβεις, θα λάβεις πίσω το smartphone σου χωρίς χρέωση επιπλέον αποστολής.</div>}
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                        className="d-flex align-items-center"
                                        onClick={() => setOpen5(!open5)}
                                        >
                                            <div>{open5 ? <div className="toggle"><img src={`${CDN}/pandas-website/common/icons/arrowDown.svg`} alt={'arrow down'} width="36" height="36" /></div> : <div className="toggleDown"><img src={`${CDN}/pandas-website/common/icons/arrowRight.svg`} alt={'arrow right'} width="36" height="36" /></div>}</div>
                                            {/* <div>{open1 ? {angleRight} : {angleDown}}</div> */}
                                            <div className='accordion-title'>Πρέπει να καλέσω εγώ το courier για να παραλάβει τη συσκευή μου;</div>
                                        </div>
                                        {open5 && <div className="accordion-content">Όχι, το αναλαμβάνουμε εμείς αυτό! Εσύ χρειάζεται μόνο να συμπληρώσεις τη φόρμα της Pandas και το courier της ACS θα έρθει στην πόρτα σου.</div>}
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                        className="d-flex align-items-center"
                                        onClick={() => setOpen6(!open6)}
                                        >
                                            <div>{open6 ? <div className="toggle"><img src={`${CDN}/pandas-website/common/icons/arrowDown.svg`} alt={'arrow down'} width="36" height="36" /></div> : <div className="toggleDown"><img src={`${CDN}/pandas-website/common/icons/arrowRight.svg`} alt={'arrow right'} width="36" height="36" /></div>}</div>
                                            {/* <div>{open1 ? {angleRight} : {angleDown}}</div> */}
                                            <div className='accordion-title'>Πώς πρέπει να ετοιμάσω τη συσκευή μου για αποστολή;</div>
                                        </div>
                                        {open6 && <div className="accordion-content">
                                            <ol>
                                                <li>Αφαίρεσε το λογαριασμό cloud από τη συσκευή.</li>
                                                <li>Κάνε backup τα δεδομένα σου και έπειτα, κάνε επαναφορά των εργοστασιακών ρυθμίσεων.</li>
                                                <li>Βάλε τη συσκευή σου σε μια ασφαλή συσκευασία για να είναι προστατευμένη από τυχόν φθορές ή κακομεταχείρηση κατά τη μεταφορά της. (Η Pandas δεν ευθύνεται για την κατάσταση που παραλαμβάνουμε τη συσκευή).</li>
                                            </ol>
                                        </div>}
                                    </div>
                                </div>
                                
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
            
            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className='d-flex justify-content-between mt-2 pl-2'>
                        <h3 style={{fontSize: '20px', lineHeight: '26px'}}>Υπεύθυνη Δήλωση(άρθρο 8 Ν.1599/1986)</h3>
                        <div className="float-right" style={{ cursor: "pointer", fontSize: '25px' }} onClick={handleClose}><FontAwesomeIcon icon={faTimes} /></div>
                    </div>
                    
                    <Row className="d-flex flex-column" style={{ height: "100%" }}>
                        <Col className="text-center text-lg-left my-3">
                            <Row className="justify-content-center align-items-center">
                                <Col xs="12" className="text-center pl-4 pr-5 pt-2" style={{ fontSize: '16px', lineHeight: '32px' }}>
                                    <p className="m-auto text-left" style={{maxWidth: '',fontWeight: '400'}}>Η ακρίβεια των στοιχείων που υποβάλλονται με αυτή τη δήλωση μπορεί να ελεγχθεί με βάση το αρχείο άλλων υπηρεσιών (άρθρο 8 παρ. 4 Ν. 1599/1986).ΠΡΟΣ: Πάσα Αρμόδια ΑρχήΜε ατομική μου ευθύνη και γνωρίζοντας τις κυρώσεις, που προβλέπονται από τις διατάξεις της παρ. 6 του άρθρου 22 του Ν. 1599/1986, ότι δηλαδή «[ό]ποιος εν γνώσει του δηλώνει ψευδή γεγονότα ή αρνείται ή αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη δήλωση του άρθρου 8 τιμωρείται με φυλάκιση τουλάχιστον τριών μηνών. Εάν ο υπαίτιος αυτών των πράξεων σκόπευε να προσπορίσει στον εαυτόν του ή σε άλλον περιουσιακό όφελος βλάπτοντας τρίτον ή σκόπευε να βλάψει άλλον, τιμωρείται με κάθειρξη μέχρι 10 ετών», δηλώνω ότι:
                                        Είμαι αδιαμφισβήτητος νόμιμος κύριος της συσκευής κινητού τηλεφώνου που πρόκειται να πουλήσω μέσω της υπηρεσίας "Trade-in App", η οποία περιήλθε στην κατοχή μου νομίμως δι’ αγοράς και παράδοσης της νομής και κατοχής της από εξουσιοδοτημένο πάροχο, κατάστημα κινητής τηλεφωνίας ή οποιονδήποτε άλλο φορέα διενεργεί νομίμως αγοραπωλησίες συσκευών κινητής τηλεφωνίας και διαθέτει όλες τις σχετικές προς τούτο άδειες.Η εν λόγω συσκευή κινητής τηλεφωνίας δεν συνιστά προϊόν κλοπής, υπεξαίρεσης, κλεπταποδοχής ή διάθεσης προϊόντων εγκλήματος, ληστείας ή οποιουδήποτε άλλου ποινικού αδικήματος ή παράνομης πράξης.</p>
                                </Col>
                                {/* <Col xs="10" lg="6" className="text-center mt-5" onClick={test}><a className={`${partner}Next next`} href={`${process.env.PUBLIC_URL}/${props.partner}/samsung-voucher`}>Δες εδώ την προσφορά</a></Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={showImei} onHide={handleCloseImei} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className='d-flex justify-content-end mt-2 pl-2'>
                        <div className="float-right" style={{ cursor: "pointer", fontSize: '25px'}} onClick={handleCloseImei}><FontAwesomeIcon icon={faTimes} /></div>
                    </div>
                    <Row className='justify-content-center flex-column align-items-center' style={{marginBottom: '20px', marginTop: '20px'}}>
                        <div>
                            <img src={CDN + "/pricing-app/photos/help.svg"} alt='help icon' />
                        </div>
                        <div><h3 style={{fontSize: '16px', lineHeight: '26px', marginTop: '30px', fontWeight: '700'}}>Πώς να βρεις τον IMEI</h3></div>
                    </Row>
                    <div className='d-flex' style={{backgroundColor: '#f7f7f7', borderRadius: '16px', padding: '25px 25px', margin: '0 10px'}}>
                        <div>
                            <img src={CDN + "/pricing-app/photos/phone.svg"} alt='down arrow' />
                        </div>
                        <div style={{marginLeft: '35px', width: '90%'}}>
                            <h4 style={{borderBottom: '1.7px #00000010 solid', paddingBottom: '10px', fontWeight: '600'}}>Βήμα 1</h4>
                            <p style={{marginBottom: '0'}}>Στις κλήσεις,</p>
                            <p>πάτα <b>*#06#</b></p>
                        </div>
                    </div>
                    <div className='d-flex' style={{backgroundColor: '#f7f7f7', borderRadius: '16px', padding: '25px 25px', margin: '15px 10px 30px'}}>
                        <div>
                            <img src={CDN + "/pricing-app/photos/phone-2.svg"} alt='down arrow' />
                        </div>
                        <div style={{marginLeft: '35px', width: '90%'}}>
                            <div style={{paddingRight: '20px'}}>
                                <h4 style={{borderBottom: '1.7px #00000010 solid', paddingBottom: '10px', fontWeight: '600'}}>Βήμα 2</h4>
                                <p style={{marginBottom: '0'}}>Στην οθόνη που θα εμφανιστεί, ο IMEI αριθμός της συσκευής σου είναι στη δεύτερη σειρά</p>
                            </div>
                        </div>
                    </div>   
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default withTranslation()(Courier);
