import * as React from 'react';
import { useEffect, useState, useCallback } from "react";
import ReactMapGL, { Marker, Popup, FlyToInterpolator, GeolocateControl } from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { GPContext } from '../context';
import { easeCubic } from 'd3-ease';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { withTranslation } from 'react-i18next';
import {CDN} from '../constants'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const Map = (props) => {
    const { t, i18n } = props;
    const { state } = React.useContext(GPContext);
    const [geolocate, setGeolocate] = useState();
    const TOKEN = 'pk.eyJ1IjoiZ3JlZW5wYW5kYWlvIiwiYSI6ImNrbGZoY2ZnMTBvOWEydnAwaTNxb3ZiZzUifQ.r8zPO79kYM7ronm9v_JVuw';
    const [currentPlaceId, setCurrentPlaceId] = useState((null));
    const mapRef = React.useRef();
    var partner = props.partner ? props.partner : sessionStorage.getItem('partner');
    var brand = props.brand ? props.brand : sessionStorage.getItem('brand');
    var model = props.model ? props.model : sessionStorage.getItem('model')
    var condition = props.model ? props.model : sessionStorage.getItem('condition');
    var capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');
    const [viewport, setViewport] = useState({
        width: '100%',
        height: 700,
        latitude: 37.9838,
        longitude: 23.7618,
        zoom: 8
    });
    const geolocateControlStyle = {
        right: 10,
        top: 10
    };

    const handleMarkerClick = (id) => {
        setCurrentPlaceId(id)
    }

    const zoom = (lat, long) => {
        setViewport({
            ...viewport,
            latitude: lat,
            longitude: long,
            zoom: 14,
            transitionDuration: 3000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: easeCubic
        });
    };

    useEffect(() => {
        window.dataLayer.push({ event: 'map', step: 'map', partner: partner, brand: brand, model: model, capacity: capacity, condition: condition, language: i18n.language });
    }, [])

    const handleViewportChange = useCallback(
        (newViewport) => setViewport(newViewport)
        , []);

    useEffect(() => {
        if (state.latitude && state.longitude) {
            zoom(state.latitude, state.longitude)
            setCurrentPlaceId(state.code)
        }
    }, [state.latitude, state.longitude])

    useEffect(() => {
        if (state.userLocation) {
            setGeolocate(true)
        }
    }, [state.userLocation])

    var partn;
    if (state.partner === 'plaisio')
        partn = 'Plaisio';
    if (state.partner === 'istorm')
        partn = 'iStorm';
    if (state.partner === 'istormcy')
        partn = 'iStorm Cyprus';
    if (state.partner === 'mistore')
        partn = 'Xiaomi Store';

    var locations = state.locations.filter(location => location.code !== 4 && location.code !== 38 && ( state.partner === 'gp' || location.partner === partn ))
    return (
        <div className='map-container'>
            <ReactMapGL
                ref={mapRef}
                {...viewport}
                width="100%" // It always override the view(viewport) width state.
                height="76vh"
                mapStyle='mapbox://styles/greenpandaio/ckryv5wfm6o3t17pkyvktadtm'
                onViewportChange={handleViewportChange}
                mapboxApiAccessToken={TOKEN}
            >
                {geolocate && <GeolocateControl
                    style={geolocateControlStyle}
                    positionOptions={{ enableHighAccuracy: true }}
                    fitBoundsOptions={{ maxZoom: 14 }}
                    trackUserLocation={false}
                    auto
                />}
                {locations.map((l, index) => (
                    <div key={index}>
                        <Marker onClick={() => { zoom(l.latitude, l.longitude) }}
                            latitude={l.latitude}
                            longitude={l.longitude}
                        >
                            <div onClick={() => handleMarkerClick(l.code)} className="marker temporary-marker" ><span><img className="marker-img" src={CDN + "/website/map/gpspotblue.png"} alt="" /></span></div>
                        </Marker>

                        {l.code === currentPlaceId &&
                            <Popup
                                mapref={mapRef}
                                latitude={l.latitude}
                                longitude={l.longitude}
                                closeButton={true}
                                closeOnClick={false}
                                onClose={() => setCurrentPlaceId(null)}
                                anchor="bottom"
                            >
                                <div>{i18n.language === 'el' ? l.title_gr : l.title}</div>
                                <div style={{ fontSize: '12px', opacity: '0.8' }}>{i18n.language === 'el' ? l.partner_gr : l.partner} {l.distance && <small>({l.distance} {t('locator.distance')})</small>}</div>
                                <div style={{ fontSize: '12px', opacity: '0.8', maxWidth: '200px' }}>{i18n.language === 'el' ? l.address_gr : l.address}</div>
                                <a className="directions" href={l.location} >{t('locator.guide')}</a>
                            </Popup>}
                    </div>
                ))}
            </ReactMapGL>
        </div>
    );
}
export default withTranslation()(Map);