import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';
import { Link, navigate  } from "@reach/router";
import { GPContext } from "../context";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { redirectAfterTimeout } from '../viewHelperFunctions';
import {CDN} from '../constants'

const Plaisio = (props) => {
    const { state, dispatch } = React.useContext(GPContext);
    const { t, i18n } = props;
    console.log('props', props)
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    let instore = sessionStorage.getItem('instore');

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});

    function changeToGreek(c) {
        if (c === 'very_good') return 'Πολή Καλή';
        if (c === 'good') return 'Καλή';
        if (c === 'poor') return 'Μέτρια';
        if (c === 'faulty') return 'Κακή';
    }
    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    if (capacity === '') capacity = 'stock';
    var finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    var imageUrl = `https://server.greenpanda.io/images/devices/phone.png`;
    if (brand === 'apple') imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        powerson: powerson,
        condition: changeToGreek(condition),
        finalPrice: finalPrice,
        imageUrl: imageUrl
    }

    console.log(offer);
    console.log(finalPrice);

   

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Offer Page' });
        dispatch({ type: 'partner', payload: state.partner })
        if(instore){
            redirectAfterTimeout('/' + props.partner);
        }
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = window.language = sessionStorage.getItem('lang');
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'offer';
    window.price = finalPrice;

    const [isReady, setIsReady] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
    }, []);

    const [open1, setOpen1] = useState(false);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    if (!device && powerson === 'working') return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);

    else
        if (!isReady) {
            return <>
                <Row className="justify-content-md-center mb-4">
                    <Col lg="12" className="text-center">
                        <h3 className="title mb-4">Παρακαλώ περιμένετε...</h3>
                        <div className={`${partner}Loader`}>Loading...</div>
                    </Col>
                </Row></>
        } return (
            <div>
                <Row className="justify-content-center">
                    <Col xs="12" md="11" lg="8" className="text-center mb-2">
                        <div className="title">Αποχαιρέτησε το {device.name} και καλωσόρισε <span style={{ color: "#2AAF9D" }}> νέα προϊόντα </span> με μεγάλο <span style={{ color: "#2AAF9D" }}> όφελος</span>!</div>
                        {/* <h5 className="title d-md-none mb-3"><b>Αποχαιρέτησε το {device.name} και καλωσόρισε <span style={{ color: "#2AAF9D" }}> νέα προϊόντα </span> με μεγάλο <span style={{ color: "#2AAF9D" }}> όφελος</span>!</b></h5> */}
                    </Col>
                    <Row className="justify-content-center align-items-center">
                        <Col xs="8" md="6" className="text-center text-md-right mb-">
                            <img src={CDN + "/pricing-app/photos/imagecollection.png"} style={{ width: "100%" }} alt="Phone" />
                        </Col>
                        <Col xs="12" md="6" className="d-flex flex-column align-items-center">
                            <h5 className="text-center text-md-left mt-2 mt-md-0 mb-0">Πάρε <b>δωροεπιταγή</b> <br /> <b>Pandas</b> έως <span style={{ fontSize: '30px', fontWeight: 'bold' }}> {finalPrice}€</span></h5>
                            {instore ? <Link to={`${process.env.PUBLIC_URL}/${props.partner}`} className="">
                                <div className="plaisioGreenNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                            </Link>
                            :
                            <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer/locator`} className="">
                                <div className="plaisioGreenNext next px-3 mt-4">Βρες ένα κατάστημα Πλαίσιο</div>
                            </Link>
                            }
                        </Col>
                    </Row>
                    <Col xs="11" sm="9" md="12" className="text-center mt-2 mb-5">
                        <small>*Η τελική τιμή καθορίζεται αφού αξιολογηθεί η κατάσταση της συσκευής και η υγεία της μπαταρίας.</small>
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col xs="12" md="12" className="text-center mb-md-5">
                        <h3 className="d-none d-md-block title">Είναι τόσο απλό!</h3>
                        <h5 className="d-md-none title">Είναι τόσο απλό!</h5>
                    </Col>
                </Row>
                <Row style={{ background: '#F0F3F5' }} className="d-none d-md-block mb-5 py-4">
                    <Col className="d-flex justify-content-center">
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/icons/map_icon.svg"} width="70" alt="map" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα  <b> Πλαίσιο</b></div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/partners/plaisio/smartphone_icon.svg"} width="70" alt="smartphone" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b>, αφού το αξιολογήσουμε</div>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src={CDN + "/pricing-app/icons/wallet_icon.svg"} width="70" alt="wallet" className="mb-3" />
                            <div style={{ maxWidth: '200px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                        </Col>
                    </Col>
                </Row>
                <Slider className="d-md-none mt-2 mb-5" {...settings}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/icons/map_icon.svg"} alt="map" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Φέρε το smartphone σου σε ένα κατάστημα  <b> Πλαίσιο</b></div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/partners/plaisio/smartphone_icon.svg"} alt="smartphone" height="50px" />
                        <div style={{ maxWidth: '200px' }}>Μάθε την <b>τελική</b> του <b>αξία</b> του αφού το αξιολογήσουμε</div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <img src={CDN + "/pricing-app/icons/wallet_icon.svg"} alt="wallet" height="50px" />
                        <div style={{ maxWidth: '180px' }}>Πάρε <b>νέα</b> προϊόντα με μεγάλη <b>έκπτωση</b></div>
                    </div>
                </Slider>
                <Row className="d-flex justify-content-center">
                    <Col xs="11" md="10" className="text-center">
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι είναι το πρόγραμμα Γεια Με γεια ;</div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Το Γεια Με γεια είναι ένα πρόγραμμα trade-in και σου επιτρέπει να αποκτήσεις το νέο σου smartphone φθηνότερα! Μπορείς να φέρεις τo παλιό σου smartphone σε ένα κατάστημα Πλαίσιο για απόσυρση. Η ειδικά εκπαιδευμένη μας ομάδα θα εκτιμήσει την κατάσταση του παλιού σου κινητού και θα σου δώσει voucher αντίστοιχης χρηματικής αξίας ώστε να το εξαργυρώσεις επιτόπου στο νέο ετοιμοπαράδοτο smartphone σου. Άρα λέμε Γεια στο παλιό smartphone Και με γεια στο νέο, ακόμα πιο φθηνά!
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι πρέπει να κάνω; </div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Το μόνο που χρειάζεται να κάνεις είναι να φέρεις το παλιό σου smartphone σε ένα κατάστημα Πλαίσιο.  Θα αξιολογήσουμε άμεσα τη συσκευή σου και θα σε ενημερώσουμε για το όφελος που μπορείς να λάβεις. Στη συνέχεια, μπορείς να αποκτήσεις το νέο σου smartphone σε χαμηλότερη τιμή.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Ποιες συσκευές συμμετέχουν στο πρόγραμμα;   </div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Μπορείς να δεις τα brands αλλά και τις συσκευές που συμμετέχουν στον μηχανισμό απόσυρσης <a href="https://pricing-plaisio.greenpanda.io/">ΕΔΩ</a>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι όφελος θα έχω ανταλλάσσοντας το παλιό μου Smartphone;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η αξία του κάθε smartphone εξαρτάται από το μοντέλο και την κατάσταση στην οποία βρίσκεται.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Πρέπει η συσκευή να είναι λειτουργική προκειμένου να μπορεί να μπει στο πρόγραμμα Γεια Με γεια;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Ναι, η συσκευή σου θα πρέπει να είναι λειτουργική και ενεργή, προκειμένου να λάβεις την αξία που της αντιστοιχεί με βάση την κατάστασή της. Εάν το smartphone σου δεν είναι λειτουργικό μπορείς και πάλι να μας το παραδώσεις, χωρίς να λάβεις χρηματικό ποσό, ώστε να το ανακυκλώσουμε υπεύθυνα.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Με ποια κριτήρια καθορίζεται η αξία της συσκευής μου;  </div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η αξία της συσκευής καθορίζεται από τη μάρκα, το μοντέλο, τη χρονολογία, την κατάσταση, και την τρέχουσα τιμή της στην αγορά ως μεταχειρισμένη συσκευή.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι θα πρέπει να κάνω πριν παραδώσω το smartphone μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Θα πρέπει αρχικά να κάνεις αντιγραφή όλων των δεδομένων σου σε κάποιο ασφαλές αποθηκευτικό χώρο και να επαναφέρεις τη συσκευή στις αρχικές της ρυθμίσεις . Σε κάθε περίπτωση τα δεδομένα θα διαγραφούν για τη δική σου ασφάλεια μόλις παραλάβουμε τη συσκευή. Φυσικά με το back up Μπορούμε να σε βοηθήσουμε και εμείς οπότε μην αγχώνεσαι, αρκεί να έχεις μαζί σου τους κατάλληλους κωδικούς !
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι γίνεται με τα δεδομένα μου; </div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Πριν παραδώσεις το παλιό σου smartphone θα πρέπει να έχεις κάνει backup σε κάποια άλλη συσκευή σου και να έχεις κάνει επαναφορά δεδομένων και ρυθμίσεων.  Ωστόσο, όλες οι συσκευές περνούν από μια συγκεκριμένη διαδικασία διαλογής μετά την παραλαβή. Στην περίπτωση όπου δεν έχει γίνει επαναφορά, το εξειδικευμένο μας προσωπικό πραγματοποιεί μια αυτοματοποιημένη διαδικασία επαναφοράς για να διασφαλίσει την προστασία και την οριστική διαγραφή των προσωπικών σου δεδομένων.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μπορώ να αγοράσω όποια συσκευή θέλω;</div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η εξαργύρωση μπορεί να γίνει μονο στις κατηγορίες Smartphone, Αξεσουάρ Smartphone και Wearables ( Smart Band, Smart Watch) καθώς και υπηρεσίες κινητής τηλεφωνίας
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μπορώ να κάνω την αγορά της νέας μου συσκευής με τιμολόγιο;</div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Δυστυχώς όχι ακόμα. Αν αλλάξει κάτι θα σε ενημερώσουμε άμεσα.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μπορώ να αγοράσω τη νέα μου συσκευή με δόσεις;</div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Φυσικά ! Μπορείς να αποκτήσεις το νέο σου smartphone με έως 48 άτοκες δόσεις με πιστωτική κάρτα η με το Πρόγραμμα δόσεων Μήνα Μήνα του Πλαισίου. Μάθε περισσότερα <a href="https://www.plaisio.gr/campaign/20181019-mina-mina">ΕΔΩ</a>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Μέχρι πότε μπορώ να εξαργυρώσω το voucher μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Η εξαργύρωση του voucher που έλαβες πραγματοποιείται εντός της ημέρας απόκτησής του.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setOpen1(!open1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">Τι έγγραφα χρειάζεται να έχω μαζί μου;</div>
                                            <div><img src={CDN + "/pricing-app/partners/plaisio/more_icon.svg"} alt="more" width="18" /></div>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-left">
                                        Χρειάζεται να έχεις μαζί την αστυνομική ταυτότητα ή το διαβατήριό σου.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="my-5 py-2 justify-content-center">
                    <Col xs="9" sm="7" md="5" lg="4" xl="3">
                        {instore ? <Link to={`${process.env.PUBLIC_URL}/${instore}`} className="">
                            <div className="plaisioGreenNext next px-3 mt-4">Νέα Αξιολόγηση</div>
                        </Link>
                        :
                        <Link to={`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/offer/locator`} className="">
                            <div className="plaisioGreenNext next px-3 mt-4">Βρες ένα κατάστημα Πλαίσιο</div>
                        </Link>
                        }
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="11" md="10">
                        <Row className="py-4" style={{ background: '#12233A', borderRadius: '8px' }}>
                            <Col className="text-center mb-4">
                                <div className="">
                                    <h3 className="font-weight-bold"><span style={{ color: "#ffff" }}>Έχεις απορίες;</span></h3>
                                    <h6 style={{ color: "#77818E" }} className="font-weight-bold">Είμαστε εδώ για να σου τις λύσουμε.</h6>
                                </div>
                            </Col>
                            <Col xs="12">
                                <Row className="d-flex justify-content-between font-weight-bold">
                                    <Col className="d-flex justify-content-center" style={{ color: "#77818E" }}><div>Επισκέψου ένα <br /> κατάστημα <a href="https://www.plaisio.gr/Store-Locator" style={{ color: "#ffff" }} target="_blank" rel="noreferrer">Πλαίσιο </a></div></Col>
                                    <Col className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon style={{ color: '#ffff' }} icon={faMobileAlt} />
                                            <a className="ml-2" href="tel:2102895000" target="_blank" style={{ color: "#ffff" }} rel="noreferrer">2102895000</a>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon style={{ color: '#ffff', height: '12px' }} icon={faPhoneAlt} />
                                            <a className="ml-1" href="tel:8001112345" target="_blank" style={{ color: "#ffff" }} rel="noreferrer">8001112345</a>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-center" style={{ color: "#77818E" }}><div>Συμπλήρωσε την φόρμα<br /> επικοινωνίας <a href="https://www.plaisio.gr/ContactForm" style={{ color: "#ffff" }} target="_blank" rel="noreferrer"> εδώ</a>.</div></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <Row className="mb-5 pb-5">
                    <Col></Col>
                </Row>
            </div >
        );
}


export default Plaisio;