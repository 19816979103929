import { withTranslation } from 'react-i18next';
import React, { useState, useContext, useEffect, useRef } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";
import * as turf from "@turf/turf";
import { GPContext } from "../context";
import useGeolocation from 'react-hook-geolocation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faSearch } from '@fortawesome/free-solid-svg-icons'
import {CDN} from '../constants'
import { Link, navigate } from "@reach/router"
import axios from 'axios';

const Maplist = (props) => {
    const { t, i18n } = props;
    const ref = useRef();
    const { state, dispatch } = useContext(GPContext);
    // const { state, dispatch } = locationContext;
    const { loading, error, data } = state;
    const geolocation = useGeolocation();
    const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyDrwWAOVkgtQ1pLDi9ma-Tq7RUtqCqAiDg";
    const [search, setSearch] = useState();
    // var partner = props.partner ? props.partner.toLowerCase() : '';
    const TOKEN = 'pk.eyJ1IjoiZ3JlZW5wYW5kYWlvIiwiYSI6ImNrbGZoY2ZnMTBvOWEydnAwaTNxb3ZiZzUifQ.r8zPO79kYM7ronm9v_JVuw';
    let countries;
    if(props.partner==='samsunggr'){
       countries = ['gr'];
    }else{
        countries = ['gr', 'cy'];
    }

    function changeToGreek(c) {
        if (c === 'very_good') return `${t('condition.veryGood')}`;
        if (c === 'good') return `${t('condition.good')}`;
        if (c === 'poor') return `${t('condition.poor')}`;
        if (c === 'faulty') return `${t('condition.faulty')}`;
    }

    const [displayedAddress, setDisplayedAddress] = useState(state.address)
    const clearAddress = () => {
        setDisplayedAddress('');
        console.log(displayedAddress)
    }
    
    const [clicked, setClicked] = useState(false);
    useEffect ( () => {
        if(clicked){
            currentPosition()
        }
    }, [clicked])
    const [xClicked, setXClicked] = useState(false);
    useEffect ( () => {
        if(xClicked){
            ref.current.value = ''
            setXClicked(false)
        }
    }, [xClicked])

   useEffect ( () => {
        // if(state.address){
            // setDisplayedAddress(displayedAddress)
            ref.current.value = state.address;
            // ref.setValue(displayedAddress)
            console.log('--------->',displayedAddress)
        // }
   }, [state.address])

    const options = {
        timeout: 5000,
        enableHighAccuracy: false,
        maximumAge: Infinity
    }

    const handleClick = () => {
        setClicked(true)
        console.log('click')
    }

    const handleXClick = () => {
        setXClicked(true)
        setClicked(false)
        console.log('xClicked')
    }
    const currentPosition = () => {
        if(state.userLocation){
            axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${state.userLocation.longitude},${state.userLocation.latitude}.json?access_token=${TOKEN}`).then(res => {
                            const address = res.data.features[0].place_name;
                            let country = address.split(',')[2].toLowerCase();
                            sessionStorage.setItem('country', country);
                            setDisplayedAddress(address);
                            const zipcode = res.data.features[0].context[0].text.replace(/\s/g, '');
                            const geolocation = { latitude: state.userLocation.latitude, longitude: state.userLocation.longitude, code: 1 }
                            dispatch({ type: "SET_ZIPCODE", payload: zipcode });
                            dispatch({ type: "SET_ADDRESS", payload: address });
                            // dispatch({ type: "CLICK", payload1: parseFloat(position.coords.latitude), payload2: parseFloat(position.coords.longitude), payload3: 0 })
                            dispatch({ type: "CLICK", payload1: parseFloat(state.userLocation.latitude), payload2: parseFloat(state.userLocation.longitude), payload3: 0 })
                            dispatch({ type: "USER_LOCATION", data: geolocation })
                        })
            // dispatch({ type: "CLICK", payload1: parseFloat(state.userLocation.latitude), payload2: parseFloat(state.userLocation.longitude), payload3: 0 })
            // dispatch({ type: "CLICK", payload1: parseFloat(state.userLocation.latitude), payload2: parseFloat(state.userLocation.longitude), payload3: 0 })
        }else{
            navigator.geolocation.getCurrentPosition(
                    (position) => {
                        console.log('pos', position)
                        if(position){
                            axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${position.coords.longitude},${position.coords.latitude}.json?access_token=${TOKEN}`).then(res => {
                                const address = res.data.features[0].place_name;
                                let country = address.split(',')[2].toLowerCase();
                                sessionStorage.setItem('country', country);
                                const zipcode = res.data.features[0].context[0].text.replace(/\s/g, '');
                                const geolocation = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                                dispatch({ type: "SET_ZIPCODE", payload: zipcode });
                                dispatch({ type: "SET_ADDRESS", payload: address });
                                dispatch({ type: "CLICK", payload1: parseFloat(position.coords.latitude), payload2: parseFloat(position.coords.longitude), payload3: 1 })
                                dispatch({ type: "USER_LOCATION", data: geolocation })
                                
                            })
                        }
                    },
                    function errorCallback(error) {
                        //do error handling
                        console.log(error)
                    },
                    {
                        timeout:5000
                    }
                );
        }
    }

    const inputChange = (e) => {
        if (e.target.value === '')
            setSearch(false)
    }

    return (
        <Container>
            <Row className="justify-content-center mb-5">
                <div style={{width: '100%'}}>
                    {!loading && <>
                        <div className='d-flex autocompleteContainer mt-2 mt-md-0' style={{width: '100%'}}>
                            <div className='justify-content-center' style={{position:'relative', width: '100%'}}>
                                <Autocomplete id='map'
                                    ref= {ref}
                                    apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                    // onChange={inputChange}
                                    defaultValue = {displayedAddress}
                                    inputAutocompleteValue = {displayedAddress}
                                    className="googleInput"

                                    onPlaceSelected={(place) => {
                                        if(place.formatted_address){
                                            let address = place.formatted_address
                                            // let zipcode = place.address_components.slice(-1)[0].long_name.replace(/\s/g, '')
                                            let zipcode, country;
                                            for(let i=0; i<place.address_components.length; i++){
                                                if(place.address_components[i].types[0]==='postal_code'){
                                                    zipcode = place.address_components[i].long_name.replace(/\s/g, '');
                                                }
                                                if(place.address_components[i].types[0] ==='country'){
                                                    country = place.address_components[i].long_name.toLowerCase();
                                                    sessionStorage.setItem('country', country);
                                                }
                                            }
                                            console.log(zipcode, address)
                                            if (address) {
                                                var lat = place.geometry.location.lat();
                                                var lng = place.geometry.location.lng();
                                                dispatch({ type: "SET_ZIPCODE", payload: zipcode })
                                                dispatch({ type: "SET_ADDRESS", payload: address })
                                                // dispatch({ type: "CLICK", payload: { latitude: lat, longitude: lng, code: 0 } })
                                                dispatch({ type: "CLICK", payload1: parseFloat(lat), payload2: parseFloat(lng), payload3: 0 })
                                                dispatch({ type: "USER_LOCATION", data: { latitude: parseFloat(lat), longitude: parseFloat(lng), code: 0 } })
                                            }
                                            setClicked(false)
                                        }
                                    }}
                                    options={{
                                        types: [],
                                        componentRestrictions: {country: countries}
                                    }}
                                    placeholder={i18n.language === 'el' ? "Αναζήτηση..." : "Search..."}
                                />
                                <div onClick={handleXClick}><img src={`${CDN}/pricing-app/icons/clear-icon.svg`} className='locateXIcon' alt="locate X icon" style={{position: 'absolute', top: '0', right: '8px'}} /></div>
                            </div>
                            <div onClick={handleClick}><img src={process.env.PUBLIC_URL+"/locate.svg"} className='locateIcon' alt="locate icon" style={{marginLeft: '0px', marginTop: '8px'}} /></div>
                        </div>
                    </>}
                    <div className="mb-5"></div>
                </div>
            </Row>
        </Container >
    );
}

export default withTranslation()(Maplist);