import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { GPContext } from "../context";
import { Link, navigate } from "@reach/router"
import { CDN } from '../constants'
import axios from 'axios';
import Obfuscate from 'react-obfuscate';

const CourierThankYou = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = React.useContext(GPContext);    
    const [isReady, setIsReady] = React.useState(false);
    const firstName = sessionStorage.getItem('name');
    const lastName = sessionStorage.getItem('surname');
    let partner = props.partner ? props.partner : sessionStorage.getItem('partner');
    let brand = props.brand ? props.brand : sessionStorage.getItem('brand');
    let model = props.model ? props.model : sessionStorage.getItem('model')
    let condition = props.model ? props.model : sessionStorage.getItem('condition');
    let capacity = props.capacity ? props.capacity : sessionStorage.getItem('capacity');
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 500);
        dispatch({ type: 'partner', payload: props.partner })
    }, []);

    const url = sessionStorage.getItem('pdfUrl');

    useEffect( () => {
        window.dataLayer.push({ event: 'Step Courier Thank You' });
        console.log('location', state.userLocation)
    },[])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'courier thank you';

    return (
        <div className='mt-5'>
            <div className="m-auto samsungThankYou">
                <div className='d-flex justify-content-center'><img className='mr-4' style={{maxWidth: '200px'}} src={`${process.env.PUBLIC_URL}/courier-success.png`} alt="courier girl" /></div>
                <div className='text-center'>
                    <h1 className='mt-4' style={{fontWeight: '700', fontSize: '32px'}}>Ευχαριστούμε</h1>
                    {props.partner!=='xiaomigr' ?
                        <p className='m-auto pt-3 pb-5' style={{fontSize: '14px', color: '#808080', maxWidth: '320px', lineHeight: '19px'}}>Είμαστε τώρα έτοιμοι να λάβουμε τη συσκευή σου. H ACS courier θα σε συναντήσει έξω από την πόρτα σου την ημερομηνία και ώρα που επέλεξες.</p>
                        :
                        <p className='m-auto pt-3 pb-5' style={{fontSize: '14px', color: '#808080', maxWidth: '390px', lineHeight: '19px'}}>Είμαστε τώρα έτοιμοι να λάβουμε τη συσκευή σου. Βρες ένα   courier ACS στη γειτονιά σου και ολοκλήρωσε τη διαδικασία.</p>
                    }
                </div>
                {/* <div className={`pdfContainer mt-2`}> */}
                <div className={`${props.partner}PdfContainer pdfContainer mt-2`}>
                    <div className='image'><img src={`${CDN}/pricing-app/partners/${props.partner}/pdf-icon.svg`} alt='pdf icon'/></div>
                    {props.partner!=='xiaomigr' ?
                    <p className='m-auto' style={{maxWidth: '450px', fontSize: '14px', fontWeight: '400', lineHeight: '19px', paddingRight: '8px', paddingLeft: '50px'}}><span style={{fontWeight: '600'}}>Σημαντικό:</span><br/>
                        Πρέπει να κατεβάσεις την προσωπική σου <u><b>καρτέλα Pandas</b></u> και να τη στείλεις μαζί με το δέμα της συσκευής σου. Στην περίπτωση που η εκτύπωση της καρτέλας δεν είναι δυνατή, κατέγραψε τον αριθμό {props.partner==='samsunggr' ? <span>του voucher που έλαβες από το <b>Samsung Customer Support Center</b></span> : <span> ΙΜΕΙ της συσκευής σου</span> } και πρόσθεσέ το στο δέμα της αποστολής.</p>
                        :
                        <p className='m-auto' style={{maxWidth: '450px', fontSize: '14px', fontWeight: '400', lineHeight: '19px', paddingRight: '8px', paddingLeft: '50px'}}><span style={{fontWeight: '600'}}>Σημαντικό:</span><br/>
                            Πρέπει να κατέβασεις την προσωπική σου καρτέλα Pandas και να τη στείλεις μαζί με το δέμα της συσκευής σου.<br/> Στην περίπτωση που η εκτύπωση της καρτέλας δεν είναι δυνατή, κατέγραψε τον αριθμό του ΙΜΕΙ της καινούριας σου συσκευής Xiaomi 13 ή 13 Pro
                        </p>
                    
                    }
                </div>
                <Row className="justify-content-center mt-md-4">
                    <Col className='mt-5 mt-md-3' xs="12" sm="12" md="9" lg="" xl="8">
                        {/* <Link to={`${process.env.PUBLIC_URL}/${props.partner}/thankyou`}> */}
                            <div className={`${props.partner}Next next px-3 mt-2 mb-4 text-center `}><a href={url} download={`Καρτέλα Courier ${firstName} ${lastName}.pdf`} style={{color: '#ffffff'}} ><img className='mr-1' src={`${process.env.PUBLIC_URL}/download-icon.svg`} /> Κατέβασε την καρτέλα Pandas</a></div>
                        {/* </Link>  */}
                    </Col>
                </Row>
                <p className='text-center m-auto' style={{maxWidth: '250px', color: '#93989e', fontSize: '14px', lineHeight: '19px'}}>Ένα αντίγραφο της καρτέλας έχει αποσταλλει επίσης στο email σου.</p>
                <div className='mt-5'>
                    <p style={{fontWeight: '500'}}>Τι πρέπει να κάνεις:</p>
                    <div style={{display: 'flex'}}>
                        <p style={{fontWeight: '700', fontSize: '24px', lineHeight:'25px', paddingRight: '20px'}}>1</p>
                        {props.partner !=='xiaomigr' ?
                            <p style={{fontSize: '14px', lineHeight: '18px'}}><span style={{fontWeight: '600'}}>Στείλε μας τη συσκευή σου μαζί με την προσωπική σου καρτέλα στα κεντρικά γραφεία της Pandas.</span> <br/><span style={{color: '#717171'}}>Διεύθυνση Pandas: Μέρλιν 2, Αθήνα 10671</span></p>
                            :
                            <p style={{fontSize: '14px', lineHeight: '18px'}}><span style={{fontWeight: '600'}}>Προετοίμασε τη συσκευή σου για παραλαβή </span> <br/><span style={{color: '#717171'}}>Αποσύνδεσε τον cloud λογαριασμό σου, κάνε backup και επαναφορά των εργοστασιακών ρυθμίσεων και βεβαιώσου πως έχει πάνω από 5% μπαταρία.</span></p>
                        }
                    </div>
                    <div style={{display: 'flex'}}>
                        <p style={{fontWeight: '700', fontSize: '24px', lineHeight:'25px', paddingRight: '20px'}}>2</p>
                        {props.partner!=='xiaomigr' ?
                            <p style={{fontSize: '14px', lineHeight: '18px', maxWidth: '410px'}}><span style={{fontWeight: '600'}}>Λάβε τελική προσφορά στο email σου, εντός 1 εργάσιμης ημέρας από τη μέρα που παραλάβαμε τη συσκευή σου.</span> <br/><span style={{color: '#717171'}}>Σε περίπτωση που δεν σε ικανοποιεί η προσφορά, θα σου στείλουμε τη συσκευή σου πίσω.</span></p>
                            :
                            <p style={{fontSize: '14px', lineHeight: '18px', maxWidth: '410px'}}><span style={{fontWeight: '600'}}>Στείλε μας τη συσκευή σου μαζί με την προσωπική σου καρτέλα στα κεντρικά γραφεία της Pandas.</span> <br/><span style={{color: '#717171'}}>Η ACS θα παραλάβει το δέμα την ημέρα και ώρα που έχεις επιλέξει. Βεβαιώσου πως έχεις συσκευάσει καλά το κινητό για να το προστατέψεις από τυχόν φθορές κατά τη μεταφορά.</span></p>
                        }
                    </div>
                    <div style={{display: 'flex'}}>
                        <p style={{fontWeight: '700', fontSize: '24px', lineHeight:'25px', paddingRight: '20px'}}>3</p>
                        {props.partner!=='xiaomigr' ?
                            <p style={{fontSize: '14px', lineHeight: '18px', maxWidth: '400px'}}><span style={{fontWeight: '600'}}>Δες τα χρήματα στο λογαριασμό σου</span> <br/><span style={{color: '#717171'}}>Εντός 1-3 εργάσιμων ημερών</span></p>
                            :
                            <p style={{fontSize: '14px', lineHeight: '18px', maxWidth: '400px'}}><span style={{fontWeight: '600'}}>Λάβε τελική προσφορά στο email σου, εντός 3 εργάσιμων ημερών από τη μέρα που παραλάβαμε τη συσκευή σου.</span> <br/><span style={{color: '#717171'}}>Σε περίπτωση που δεν σε ικανοποιεί η προσφορά, θα σου στείλουμε τη συσκευή σου πίσω.</span></p>
                        }
                    </div> 
                </div>
            </div>
            <div className='samsungFooter' style={{}}>
                <div className='m-auto footerPadding' style={{display: 'flex', maxWidth: '600px'}}>
                    <div style={{marginRight: '10px'}}>
                        <img src={process.env.PUBLIC_URL+"/question-icon.svg"} alt="question mark icon" />
                    </div>
                    <div className=''>
                        <p style={{borderBottom: '1px solid rgba(255, 255, 255, 0.2)', paddingBottom: '10px', fontSize: '14px',marginTop: '5px', marginBottom: '7px'}}>
                            Έχεις απορίες;<br/>
                            <span style={{color: '#cccccc'}}>H Pandas είναι δίπλα σου!</span>
                        </p>
                        <p style={{color: '#666666', fontSize: '10px', marginBottom: '5px'}}>Επικοινώνησε μαζι μας:</p>
                        <div style={{color: '#cccccc', fontSize: '14px'}} className='d-flex flex-column flex-sm-row'>
                            <div style={{display: 'flex'}}>
                                <div className='mr-2'><img src={process.env.PUBLIC_URL+'/phone-icon.svg'} /></div>
                                <a href='tel:+302111982521' style={{color: '#cccccc'}}>2111982521</a>
                            </div>
                            <div style={{display: 'flex'}} className='ml-sm-3 mt-1 mt-sm-0'>
                                <div className='mr-2'><img src={CDN + "/pricing-app/icons/at-icon.svg"} alt="at icon"/></div>
                                <Obfuscate style={{color: 'rgb(204, 204, 204)'}} email="contact@pandas.io"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(CourierThankYou);