import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Router, Redirect } from "@reach/router";
import React, { useEffect } from "react";
import { GPContext, initialState, reducer } from './context';
import Partner from './components/partner';
import Categories from './components/categories';
import Brands from './components/brands';
import Models from './components/models';
import Device from './components/device';
import Condition from './components/condition';
import Loading from './components/loading';
import Email from './components/email';
import Offer from './components/offer';
import Plaisio from './components/plaisio';
import Istorm from './components/istorm';
import Verizon from './components/verizon';
import Yougr from './components/yougr';
import Mistore from './components/mistore';
import Germanos from './components/germanos';
import Publiccy from './components/publiccy';
import Samsung from './components/promoOffer';
import Pmm from './components/pmm';
import Mediamarkt from './components/mediamarkt';
import Locator from './components/locator';
import LocatorResults from './components/locatorResults';
import Courier from './components/courier';
import Location from './components/location';
import Receipt from './components/receipt';
import MyProgressBar from './components/myprogressbar';
import Language from './components/language';
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
import Calendar from './components/calendar';
import samsungCalendar from './components/scooterCalendly';
import TradeinOptions from './components/tradeinOptions';
import CourierMap from './components/courierMap';
import ScooterThankYou from './components/scooterThankYou';
import Thankyou from './components/thankyou';
import history from "./history";
import ScooterCalendly from './components/scooterCalendly';
import SamsungEmail from './components/samsungEmail';
import XiaomiEmail from './components/xiaomiEmail';
import OfferLocations from './components/offerLocations';
import { Container } from 'react-bootstrap';
import CashOptions from './components/cashOptions';
import CourierThankYou from './components/courierThankYou'
import PromoOffer from './components/promoOffer';
import ImeiCode from './components/imeiCode';

const App = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { i18n } = props;
  const instore = sessionStorage.getItem('instore');

  useEffect(() => {
    if (!sessionStorage.getItem('lang'))
      sessionStorage.setItem('lang', i18n.language)
  }, [])

  useEffect(() => {
    var query = new URLSearchParams(window.location.search);
    var fbclid = Cookies.get('_fbc')
    if (!fbclid) {
      fbclid = query.get('fbclid')
      if (fbclid) {
        var timestamp = new Date().getTime()
        fbclid = `fb.1.${timestamp}.${fbclid}`;
      }
    }
    console.log(fbclid)
    localStorage.setItem('fbclid', fbclid)
    localStorage.setItem('ttclid', query.get('ttclid'))
    localStorage.setItem('gclid', query.get('gclid'))
  }, [])


  return (
    <div>
      <GPContext.Provider value={{ state, dispatch, locations: state.locations }} >
        <Router primary={false} history={history} basepath={process.env.PUBLIC_URL} >
          <Language path="/el/:partner" lang="el" />
          <Language path="/en/:partner" lang="en" />
          <Language path="/el/:partner/:brand" lang="el" />
          <Language path="/en/:partner/:brand" lang="en" />
          <Language path="/el/:partner/:brand/:model" lang="el" />
          <Language path="/en/:partner/:brand/:model" lang="en" />
          <Language path="/el/:partner/:brand/:model/:capacity" lang="el" />
          <Language path="/en/:partner/:brand/:model/:capacity" lang="en" />
          <Language path="/el" lang="el" />
          <Language path="/en" lang="en" />
          <Redirect from={'/'} to={process.env.PUBLIC_URL + '/pandas'} noThrow />
          <Partner key="partner" path="/:partner">
            <Container path='/'>
            <MyProgressBar path="/">
              <Brands key="brands" path="/" />
              <Categories key="categories" path="/categories" />
              <Models key="models" path="/:brand" />
              <Device key="device1" path=":brand/:model" />
              <Device key="device2" path="/:brand/:model/:capacity" />
              <Device key="device3" path="/:brand/:model/:capacity/:powerson" />
              <Condition key="condition1" path="/:brand/:model/:capacity/:powerson/condition" />
              <Condition key="condition2" path="/:brand/:model/:capacity/:powerson/:condition" />
              <Loading key="loading" path="/:brand/:model/:capacity/:powerson/:condition/loading" />
              {state.partner !== 'samsunggr' && <Email key="email" path="/:brand/:model/:capacity/:powerson/:condition/:contact" />}
              {state.partner === 'gp' && <Offer key="offer1" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {/* {state.partner === 'samsung' && <Samsung key="offer1" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />} */}
              {state.partner === 'verizon' && <Verizon key="offer1" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {state.partner === 'verizon' && instore && <Verizon key="offer1" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              {state.partner === 'plaisio' && instore && <Plaisio key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              {state.partner === 'plaisio' && <Plaisio key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {state.partner === 'yougr' && <Yougr key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {state.partner === 'yougr' && instore && <Yougr key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              {state.partner === 'mistore' && <Mistore key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {state.partner === 'mistore' && instore && <Mistore key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              {state.partner === 'germanos' && <Germanos key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {state.partner === 'germanos' && instore && <Germanos key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              {state.partner === 'publiccy' && <Publiccy key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {state.partner === 'publiccy' && instore && <Publiccy key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              
              {(state.partner === 'istorm' || state.partner === 'istormcy') && <Istorm key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              {(state.partner === 'pmmgr' || state.partner === 'pmmcy') && <Pmm key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {(state.partner === 'pmmgr' || state.partner === 'pmmcy') && instore && <Pmm key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
              {state.partner === 'mediamarktgr' && <Mediamarkt key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
              {state.partner === 'mediamarktgr' && instore && <Mediamarkt key="offer2" path="/:brand/:model/:capacity/:powerson/:condition/offer" />}
            </MyProgressBar>
            {state.partner === 'xiaomigr' && <XiaomiEmail key="xiaomiEmail" path="/email/" />}
            {(state.partner === 'samsunggr' || state.partner==='xiaomigr') && <PromoOffer key="offer1" path="/offer" />}
            {state.partner === 'samsunggr' && <SamsungEmail key="samsungEmail" path="/:brand/:model/:capacity/:powerson/:condition/samsung-voucher/" />}
            {state.partner === 'samsunggr' && <SamsungEmail key="samsungEmail" path="/samsung-voucher/" />}
            {state.partner === 'xiaomigr' && <ImeiCode key="imeiCode" path="/imei/" />}
            {(state.partner === 'samsunggr' || state.partner==='xiaomigr') && <Location key="location" path="/location" />}
            <Location key="location" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/location" />
            {(state.partner === 'samsunggr' || state.partner==='xiaomigr') && <TradeinOptions key="tradein" path="/tradein" />}
            {(state.partner === 'samsunggr' || state.partner === 'xiaomigr') && <Courier key="courier" path="/courier" />}
            <Courier key="courier" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/courier" />
            <ScooterCalendly key="courier" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/scooter" />
            <ScooterThankYou key="ScooterThankYou" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/scooter-thankyou" />

            {(state.partner === 'samsunggr' || state.partner==='xiaomigr') && <ScooterCalendly key="samsungCourier" path="/scooter" />}
            {(state.partner === 'samsunggr' || state.partner === 'xiaomigr') && <ScooterThankYou key="ScooterThankYou" path="/scooter-thankyou" />}
            {state.partner === 'yougr' && ["/thankyou", "/:brand/:model/:capacity/:powerson/:condition/:contact/offer/scooter/thankyou"].map(page => <Thankyou path={page} />)}
            {state.partner === 'yougr' && ["/scooter", "/:brand/:model/:capacity/:powerson/:condition/:contact/offer/scooter"].map(page => <Calendar path={page} />)}
            <Locator key="locator1" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/locator" />
            <Locator key="locator2" path="/:brand/:model/:capacity/:powerson/:condition/offer/locator" />
            <LocatorResults key="locatorResults1" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/locator/locatorResults" />
            <LocatorResults key="locatorResults2" path="/:brand/:model/:capacity/:powerson/:condition/offer/locator/locatorResults" />
            </Container>
            {state.partner === 'pandas' && <Offer key="offer1" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer" />}
            <OfferLocations key="offerLocations" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/offerLocations"/>
            <CashOptions key="cashOptions" path="/:brand/:model/:capacity/:powerson/:condition/:contact/offer/cash"/>
            <CourierThankYou key="courierThankYou" path="/courier-thankyou" />
            <Courier key="courier" path="/courier" />
          </Partner>
          {(state.partner === 'samsunggr' || state.partner==='xiaomigr') && <CourierMap key="courierMap" path="/:partner/map" />}
          <CourierMap key="courierMap" path="/:partner/:brand/:model/:capacity/:powerson/:condition/:contact/offer/map" />
          <Receipt path="/receipt/:uuid" />
        </Router>
      </GPContext.Provider>
    </div >
  );
}

export default withTranslation()(App);